import React from "react";
import Success from "components/form/success";

const PaymentFormSuccess = () => {
  return (
    <Success
      heading="Success!"
      body={
        <p>
          Your work is officially confirmed! Your job on Generator Z is now
          complete and payment will be processed in the next 10 business days.
          Remember, no confirmation email will be sent to you. If you have any
          questions, please contact{" "}
          <a href="mailto:info@generator-z.org">info@generator-z.org</a>. Thank
          you for being you!
        </p>
      }
    />
  );
};

export default PaymentFormSuccess;
