import React, { useEffect, useRef } from "react";
import styles from "./roundtable.module.scss";
import YouTube from "react-youtube-embed";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
gsap.registerPlugin(ScrollTrigger);

//const videoId = "2S4QDtV_iF0";
const videoId = "V-0K-3jnhh0";
const Roundtable = ({ onEnter, onLeaveBack }) => {
    const ref = useRef();
    useEffect(() => {
        const t = ScrollTrigger.create({
            trigger: ref.current,
            start: "top center",
            end: "bottom center",
            onEnter,
            onLeaveBack,
        });
        return () => {
            t.kill();
        };
    }, [onEnter, onLeaveBack]);

    return (
        <div className={styles.element} ref={ref}>
            <YouTube id={videoId} />
        </div>
    );
};

export default Roundtable;
