import React, { useState, useCallback, useLayoutEffect, useRef } from "react";
import styles from "./filters.module.scss";
import checkboxStyles from "components/form/checkbox-set/radio-set.module.scss";

const Filters = ({
  handleChange,
  checkedItems,
  mobileShowFilters,
  filterOpts,
  introText,
  clear,
}) => {
  return (
    <div>
      <div
        className={`${styles.element} ${
          mobileShowFilters ? styles.mobileShow : ""
        }`}
      >
        <h3>{introText}</h3>
        {clear && (
          <button onClick={clear} className={styles.clear}>
            Clear All
          </button>
        )}
        {filterOpts.map((item, index) => {
          let textVal = "";
          if (item.type === "text" && checkedItems[item.col][0] !== undefined) {
            textVal = checkedItems[item.col][0][0]
              ? checkedItems[item.col][0][0]
              : "";
          }
          return (
            <div key={`col-${index}`} className={styles.filterSection}>
              <h4>{item.heading}</h4>

              {item.type === "checkboxes" && (
                <>
                  {item.options.map(({ value, label }, index) => {
                    const checkedItem = checkedItems[item.col];
                    const checked = checkedItem.get(value);

                    return (
                      <div
                        key={`opt-${index}`}
                        className={`${checkboxStyles.labelWrap} ${styles.labelWrap}`}
                      >
                        <label className={checkboxStyles.label}>
                          {label ? label : value}{" "}
                          <Checkbox
                            name={item.col}
                            value={value}
                            onChange={handleChange}
                            checked={checked || false}
                          ></Checkbox>
                          <span className={checkboxStyles.checkbox}></span>
                        </label>
                      </div>
                    );
                  })}
                </>
              )}
              {item.type === "text" && (
                <>
                  <Text
                    onTypingEnd={handleChange}
                    name={item.col}
                    value={textVal}
                  ></Text>
                </>
              )}
            </div>
          );
        })}
        {clear && (
          <button onClick={clear} className={styles.clear}>
            Clear All
          </button>
        )}
      </div>
    </div>
  );
};

export default Filters;

const Checkbox = ({ type = "checkbox", name, value, onChange, checked }) => {
  return (
    <input
      type={type}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
    />
  );
};

const Text = ({
  type = "text",
  onTypingEnd,
  name,
  value,
  placeholder = "Type a Name",
}) => {
  const firstUpdate = useRef(true);
  const ref = useRef();
  const [inputData, setInputData] = useState();
  const [textValue, setTextValue] = useState(value);
  const [send, setSend] = useState(false);

  const typingDetect = useCallback(
    (e) => {
      setTextValue(e.target.value);
      clearTimeout(ref.current);

      setInputData({ ...e });
      const sendData = () => {
        setSend(true);
      };
      ref.current = setTimeout(() => {
        sendData();
      }, 750);
    },
    [setTextValue]
  );

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (send) onTypingEnd(inputData);
    setSend(false);
  }, [inputData, send, onTypingEnd]);

  return (
    <input
      type={type}
      name={name}
      onChange={typingDetect}
      value={textValue}
      placeholder={placeholder}
    />
  );
};
