import React from "react";
import styles from "./subsection.module.scss";
import Tip from "components/form/tip";

const Subsection = ({ heading, instructions, fields, tip }) => {
  return (
    <div className={styles.element}>
      {tip && <Tip>{tip}</Tip>}
      {heading && <h3 className={styles.heading}>{heading}</h3>}
      {instructions && (
        <div className={styles.instructions}>{instructions}</div>
      )}
      {fields}
    </div>
  );
};

export default Subsection;
