import React, { useContext } from "react";
import styles from "./nav.module.scss";
import ThemeContext from "components/teaser/theme-context";
const grids = {
  four: (
    <div className={styles.gridFour}>
      <div />
      <div />
      <div />
    </div>
  ),
};

const Nav = () => {
  const { theme, navGrid } = useContext(ThemeContext);
  return (
    <div className={`${styles.element} ${theme}`}>
      <div className={styles.inner}>{navGrid && grids[navGrid]}</div>
    </div>
  );
};

export default Nav;
