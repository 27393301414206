import React, { useState, useEffect } from "react";
//import Moment from "react-moment";
import { useForm } from "react-hook-form";
import Text from "components/form/text";
import TextArea from "components/form/textarea";
import styles from "./comments.module.scss";
import useFormPersist from "react-hook-form-persist";
import UserLookup, { useUserLookup } from "components/user-lookup";
import errorMessages from "components/form/errorMessages";
import RadioSet from "components/form/radio-set";
import ReCAPTCHA from "react-google-recaptcha";
import formLayout from "components/form/form-layout.module.scss";
import { useStorageState } from "react-storage-hooks";
import Loader from "components/stories/loader";
// import { css } from "@emotion/core";
// import PacmanLoader from "react-spinners/PacmanLoader";

import axios from "axios";

function Comments({ id, collapse, closeComments }) {
    const { loading, data } = usePost(id);
    const [collapsed, setCollapsed] = useState(collapse ? true : false);

    if (loading) return <>Loading Comments</>;

    const comments = data
        .filter((comment) => !comment["Parent ID"])
        .map((comment) => {
            const children = data.filter(
                (child) => child["Parent ID"] === `${comment["ID"]}`
            );

            return { ...comment, children };
        });

    const commentsToShow = collapsed ? comments.slice(0, 2) : comments;
    return (
        <div className={`${styles.element} bg--accent`}>
            {comments.length > 0 && (
                <>
                    <h3>Generator Feedback</h3>
                    <p className={styles.commentsCount}> {comments.length} Comments</p>
                </>
            )}
            {commentsToShow.map((comment) => {
                return (
                    <div key={comment.ID} className={styles.parent}>
                        <Comment content={comment}></Comment>
                        {comment.children.length > 0 ? (
                            <div className={styles.children}>
                                {comment.children.map((child) => {
                                    return (
                                        <div className={styles.child} key={child.ID}>
                                            <Comment content={child}></Comment>
                                        </div>
                                    );
                                })}

                                <ReplyBack parentId={comment.ID} providerId={id}></ReplyBack>
                            </div>
                        ) : (
                            <>
                                <ReplyBack parentId={comment.ID} providerId={id}></ReplyBack>
                            </>
                        )}
                    </div>
                );
            })}
            {collapse && (
                <button
                    className={styles.toggle}
                    onClick={() => setCollapsed(!collapsed)}
                >
                    Show{" "}
                    {collapsed ? `${comments.length - 2} more comments` : "less comments"}
                </button>
            )}
            {!closeComments && (
                <>
                    {comments.length > 0 && <hr />}
                    <h5>Join the discussion</h5>
                    <AddComments providerId={id} />
                </>
            )}
        </div>
    );
}

const Comment = ({ content }) => {
    return (
        <div className={styles.commentWrap}>
            <div className={`${styles.screenname} ui`}>
                {content["Screen Name"]}{" "}
                {content["Generator ID"] && (
                    <span className={styles.from}>
                        from{" "}
                        {content.Region === "New York"
                            ? "Western New York"
                            : "Southeast Michigan"}
                    </span>
                )}
            </div>
            <div className={styles.comment}>{content["Comment"]}</div>

            {/* <Moment format="YYYY-MM-DD">{content["Created"]}</Moment> */}
        </div>
    );
};

const ReplyBack = (props) => {
    const [showReply, setShowReply] = useState(false);
    const toggleReply = () => {
        setShowReply(!showReply);
    };
    return (
        <>
            <div className={`${styles.reply} ui`} onClick={() => toggleReply(true)}>
                {showReply ? "Cancel" : "Reply"}
            </div>
            {showReply && <AddComments {...props} />}
        </>
    );
};

const AddComments = ({ providerId, parentId }) => {
    const [storedVal, setStoredVal] = useStorageState(
        localStorage,
        `commenter-type`,
        []
    );

    return (
        <>
            <div className={styles.types}>
                I am:{" "}
                <button
                    tabIndex={0}
                    className={`ui ${storedVal === "generator" ? styles.active : ""} ${styles.type
                        }`}
                    onClick={() => {
                        setStoredVal("generator");
                    }}
                >
                    A Generator
                </button>
                <button
                    className={`ui ${storedVal === "nonGenerator" ? styles.active : ""} ${styles.type
                        }`}
                    onClick={() => {
                        setStoredVal("nonGenerator");
                    }}
                    tabIndex={0}
                >
                    Not a Generator
                </button>
            </div>
            {storedVal === "generator" ? (
                <GeneratorComments providerId={providerId} parentId={parentId} />
            ) : (
                storedVal === "nonGenerator" && (
                    <NonGeneratorComments
                        providerId={providerId}
                        parentId={parentId}
                    ></NonGeneratorComments>
                )
            )}
            {/* // <NonGeneratorComments
      //   providerId={providerId}
      //   parentId={parentId}
      // ></NonGeneratorComments> */}
        </>
    );
};

const submitComment = (data, setStatus) => {
    axios
        .post(
            `${process.env.REACT_APP_EXPRESS ? process.env.REACT_APP_EXPRESS : ""
            }/submit-comment`,
            data
        )

        .then((res) => {
            console.log(res);
            setStatus("Success");
        })
        .catch((err) => {
            console.log(err.message);
            setStatus("Error");
        });
};

const NonGeneratorComments = ({ providerId, parentId }) => {
    const { register, handleSubmit, errors, watch, setValue } = useForm();
    const formRef = React.useRef();
    const recaptchaRef = React.useRef();
    const [status, setStatus] = useState(null);
    const onSubmitWithReCAPTCHA = async (data) => {
        const token = await recaptchaRef.current.executeAsync();
        if (token) {
            setStatus("Loading");
            submitComment(data, setStatus);
        }
    };
    const watched = watch(["User"]);

    useFormPersist(
        "non-generator-comment-module",
        { watch, setValue },
        {
            storage: window.localStorage,
            include: ["Screen Name", "Region"],
        }
    );

    if (status === "Loading" || status === "Success") {
        return statusCheck(status);
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmitWithReCAPTCHA)}
            ref={formRef}
            className={styles.form}
        >
            <h4 className="ui">Screenname</h4>
            <Text
                type="text"
                name="Screen Name"
                errors={errors}
                forwardRef={register({ required: errorMessages.required })}
                styles={{
                    visibility: watched.Type === "1" ? "visible" : "hidden",
                    opacity: watched.Type === "1" ? "0" : "1",
                }}
            />
            <h4 className="ui">Region</h4>
            <RadioSet
                name="Region"
                options={[
                    { label: "Western New York", value: "New York" },
                    { label: "Southeast Michigan", value: "Michigan" },
                    { label: "Other", value: "Other" },
                ]}
                generateRef={() => register({ required: errorMessages.required })}
                errors={errors}
            />
            <>
                <h4 className="ui">Comment</h4>
                <TextArea
                    minLength="75"
                    maxLength="500"
                    errors={errors}
                    name="Comment"
                    forwardRef={register({
                        required: errorMessages.required,
                        minLength: {
                            value: 75,
                            message: lenghtMsg,
                        },
                    })}
                ></TextArea>
                <button className={formLayout.submit}>Post Comment</button>
            </>
            <input
                type="hidden"
                value={providerId}
                name="Provider ID"
                ref={register()}
                readOnly
            />
            <input
                type="hidden"
                name={"Parent ID"}
                value={parentId}
                ref={register()}
            />
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LeYG-UZAAAAAGscT5cfnHrGve9DmCRSzUNAefdu"
            />
        </form>
    );
};

const lenghtMsg = "Comments must be at least 75 characters in length.";

const GeneratorComments = ({ providerId, parentId }) => {
    const { loading, data, setTerm } = useUserLookup();
    const { register, handleSubmit, errors, watch, setValue } = useForm();
    const formRef = React.useRef();
    const recaptchaRef = React.useRef();
    const [status, setStatus] = useState(null);
    const onSubmitWithReCAPTCHA = async (data) => {
        //const token = await recaptchaRef.current.executeAsync();
        //if (token) {

        setStatus("Loading");
        submitComment(data, setStatus);
        //}
    };
    const watched = watch(["User"]);

    useFormPersist(
        "generator-comment-module",
        { watch, setValue },
        {
            storage: window.localStorage,
            include: ["Screen Name", "Generator ID"],
        }
    );

    if (status === "Loading" || status === "Success") {
        return statusCheck(status);
    }

    if (loading) {
        return (
            <div className={styles.loading}>
                <Loader />
            </div>
        );
    }

    if (status === "Error") {
        return <>Something went wrong. Please try again later.</>;
    }
    return (
        <>
            {/* <div className={styles.loading}>
        loading.. <PacmanLoader size={8} />
      </div> */}
            <form
                onSubmit={handleSubmit(onSubmitWithReCAPTCHA)}
                ref={formRef}
                className={styles.form}
            >
                <div className={`commenting`}>
                    <UserLookup
                        name="User"
                        forwardRef={register({
                            required: errorMessages.required,
                            // validate: (value) => {
                            //   return (
                            //     JSON.parse(value).length > 4 ||
                            //     "You must add at least 5 stories"
                            //   );
                            // },
                        })}
                        errors={errors}
                        data={data}
                        setTerm={setTerm}
                    />

                    {watched.User && watched.User.length > 0 && (
                        <>
                            <input
                                type="hidden"
                                value={JSON.parse(watched.User)[0].screenname}
                                name="Screen Name"
                                ref={register()}
                                readOnly
                            />
                            <input
                                type="hidden"
                                value={JSON.parse(watched.User)[0].id}
                                name="Generator ID"
                                ref={register()}
                                readOnly
                            />
                            <input
                                type="hidden"
                                value={JSON.parse(watched.User)[0].region}
                                name="Region"
                                ref={register()}
                                readOnly
                            />
                        </>
                    )}
                    <input
                        type="hidden"
                        value={providerId}
                        name="Provider ID"
                        ref={register()}
                        readOnly
                    />

                    {/* Common */}
                    <>
                        <input
                            type="hidden"
                            name={"Parent ID"}
                            value={parentId}
                            ref={register()}
                        />

                        <TextArea
                            minLength="75"
                            maxLength="500"
                            name="Comment"
                            errors={errors}
                            forwardRef={register({
                                required: errorMessages.required,
                                message: errorMessages.minLength(75),
                            })}
                        ></TextArea>
                        <button className={formLayout.submit}>Post Comment</button>
                    </>
                </div>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6LeYG-UZAAAAAGscT5cfnHrGve9DmCRSzUNAefdu"
                />
            </form>
        </>
    );
};

export default Comments;

const usePost = (id) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        axios
            .get(`/api/comments`, {
                params: { id },
            })
            .then((res, err) => {
                setData(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log("error", err);
                setLoading(false);
                setError(true);
            });
    }, [id]);

    return { data, loading, error };
};

const statusCheck = (status) => {
    switch (status) {
        case "Loading":
            return (
                <div className={styles.loading}>
                    <div>Submitting your comment </div>
                    <Loader />
                </div>
            );

        case "Error":
            return <p>Something went wrong. Please try again later.</p>;

        case "Success":
            return (
                <p>
                    Thank you for your comment. Please allow up to 5 minutes for the
                    comment to display on the site.
                </p>
            );

        default:
            return <>default</>;
    }
};
