import React, { useContext, useEffect } from "react";
import FilterContext from "components/stories/filter-context";
import { Link } from "react-router-dom";
import styles from "./posts-nav.module.scss";
import usePosts from "../usePosts";
import SVG from "react-inlinesvg";

import prevIcon from "./prev.svg";
import nextIcon from "./next.svg";

const StoryNav = ({ sect, recId, theme }) => {
  const { data } = usePosts(`/api/${sect}/`);

  const {
    setFilteredStories,
    filteredStories,
    setFilteredProviders,
    filteredProviders,

    filterStoriesQS,
    filterProvidersQS,
  } = useContext(FilterContext);

  // console.log(
  //   "filtered Records",
  //   filteredRecords,
  //   filteredRecords ? filteredRecords.length : ""
  // );
  // const next = filteredRecords ? filteredRecords.
  // let next = null;
  // let prev = null;
  let links = null;

  if (sect === "stories") {
    links = getSectionLinks(sect, recId, filteredStories);
  }
  if (sect === "providers") {
    links = getSectionLinks(sect, recId, filteredProviders);
  }

  useEffect(() => {
    if (sect === "stories") {
      if (!filteredStories) {
        setFilteredStories(data);
      }
    }
    if (sect === "providers") {
      if (!filteredProviders) {
        setFilteredProviders(data);
      }
    }
  }, [
    data,
    setFilteredStories,
    filteredStories,
    setFilteredProviders,
    filteredProviders,
    sect,
  ]);
  const parent = sect === "stories" ? sect : "afterschool-ideas";
  const filterQS = sect === "stories" ? filterStoriesQS : filterProvidersQS;
  return (
    <div className={`bg--${theme} ${styles.element}`}>
      <div className={styles.all}>
        <Link to={`/${parent}/${filterQS ? "?" + filterQS : ""}`}>
          All {sect === "stories" ? sect : "Ideas"}
        </Link>
      </div>
      {links && (
        <div className={styles.buttons}>
          {links.prev && (
            <Link
              to={`/${parent}/${links.prev["fields"].Slug}`}
              className={`${styles.button}`}
            >
              <SVG src={prevIcon}></SVG>
            </Link>
          )}

          {links.next && (
            <Link
              to={`/${parent}/${links.next["fields"].Slug}`}
              className={`${styles.button}`}
            >
              <SVG src={nextIcon}></SVG>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

const getSectionLinks = (sect, recId, filteredRecords) => {
  if (filteredRecords) {
    let next = null;
    let prev = null;
    const keys = Object.keys(filteredRecords);
    if (keys.length > 1) {
      keys.some((key) => {
        // console.log(filteredRecords[key].id);

        if (next === 1) {
          next = filteredRecords[key];
          return true;
        }
        if (filteredRecords[key].id === recId) {
          next = 1;
        } else {
          prev = filteredRecords[key];
        }

        return false;
      });

      // cyclical nav
      if (prev == null) {
        prev = filteredRecords[keys.length - 1];
      }
      if (next === 1) {
        next = filteredRecords[0];
        // if (next === prev) {
        //   next = null;
        // }
      }
    }
    return { prev: prev, next: next };
  }
  return null;
};

export default StoryNav;
