import React, { useContext } from "react";
import styles from "./logo.module.scss";
import logo from "./Logo.svg";
import logoMobile from "./LogoMobile.svg";
import SVG from "react-inlinesvg";
import { HashLink } from "react-router-hash-link";

import DataZ from "components/nav/z/data_z.svg";
import FoundationZ from "components/nav/z/foundation_z.svg";
import OrgZ from "components/nav/z/org_z.svg";
import StoryZ from "components/nav/z/story_z.svg";
import TeenZ from "components/nav/z/teen_z.svg";
import ThemeContext from "../teaser/theme-context";

const Logo = () => {
    const { theme } = useContext(ThemeContext);
    return (
        <div className={`${styles.logo} ${theme} logo`}>
            <div className={`${styles.backing} logo-backing bg--${theme}`}></div>
            <div className={styles.z}>
                {window.location.pathname === "/" && <Z theme={theme}></Z>}
            </div>
            <HashLink
                to="/#ModelToRemember"
                className={`${styles.logoLink} ${styles.logoDesktop}`}
            >
                <SVG src={logo} className={`bg--${theme}`} alt="Generator Z" />
            </HashLink>
            <HashLink
                to="/#ModelToRemember"
                className={`${styles.logoLink} ${styles.logoMobile}`}
            >
                <SVG src={logoMobile} alt="Generator Z" />
            </HashLink>
        </div>
    );
};

const Z = ({ theme }) => {
    switch (theme) {
        case "peach":
            return <SVG src={OrgZ}></SVG>;
        case "black":
            return <SVG src={StoryZ}></SVG>;

        case "white":
            return <SVG src={DataZ}></SVG>;

        case "yellow":
            return <SVG src={FoundationZ}></SVG>;

        case "lilac":
            return <SVG src={TeenZ}></SVG>;
        default:
            return null;
    }
};

export default Logo;
