import React, { useState, useEffect, useContext } from "react";
import styles from "./stories-form.module.scss";
import formLayout from "components/form/form-layout.module.scss";

import errorMessages from "components/form/errorMessages";
import { useForm } from "react-hook-form";
import axios from "axios";
import MediaBuilder from "components/form/media-builder";

// import VideoUpload from "components/form/video-upload";
import withPrompt from "components/form/prompt";
import ColumnLayout from "layouts/columns";
import TextArea from "components/form/textarea";
import themeData from "blocks/themes/data";

import Dropdown from "components/dropdown";
import ZPicker from "components/form/z-picker";
import ThemeColorPicker from "components/form/theme-color-picker";
//import QuestionPicker from "components/form/question-picker";
import questions from "../questions";
import Text from "components/form/text";
import Select from "components/form/select";
import { Link } from "react-router-dom";
import Section from "components/stories/stories-form/section";
import Subsection from "components/stories/stories-form/subsection";

import zData from "components/nav/z/z-data";
import Header from "components/stories/stories-form/header";
import useFormPersist from "react-hook-form-persist";
import ThemeContext, { ThemeProvider } from "components/teaser/theme-context";
import { Redirect } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useParams } from "react-router-dom";
import InnerHTML from "dangerously-set-html-content";
import ValidateMedia from "components/form/media-builder/validate-media";

import useIDCheck, { IDNotFound } from "components/id-check";

const initialValues = {
  "Joy Question": questions.joy[0].value,
  "Challenge Question": questions.challenge[0].value,
  Theme: "peach",
  "Z Style": "z1",
};

// const defaultValues = {
//   ...initialValues,
//   // "First Name": "Dylan",
//   // "Last Name": "Test",
//   // Email: "notanemail@email.com",
// };
export const statuses = {
  SUCCESS: "success",
  LOADING: "loading",
  ERROR: "error",
};

// const personalQuestions = [
//   {
//     value: "Question 1",
//     label: "What is one of your favorite things in the world to do? ",
//     prompt:
//       "“Take us there” and show us what you love and why? Is it a place? A person? An activity? Bring it to life for us and tell us why it’s so special to you.",
//   },
//   {
//     value: "Question 2",
//     label:
//       "What’s the song (or movie, book, poem, or piece of art) that means everything to you?",
//     prompt:
//       "Make sure you identify what it is and tell us why it’s so meaningful to you.",
//   },
// ];

export function getTypeHandles(types) {
  return types.reduce((r, d) => {
    if (typeof d === "string") {
      r.push(d);
    } else if (typeof d === "object" && d.type) {
      r.push(d.type);
    } else {
      throw new Error(`Invalid MediaBuilder types configuration`);
    }
    return r;
  }, []);
}
function getInstructions(types) {
  const typeHandles = getTypeHandles(types);
  const audio = typeHandles.includes("audio");
  const video = typeHandles.includes("video");
  const text = typeHandles.includes("text");
  const image = typeHandles.includes("image");
  const textSettings = types.find(({ type: findType }) => findType === "text");

  if (image && typeHandles.length === 1) {
    return "One image required";
  }
  return `${
    typeHandles.length > 1 &&
    "Choose the media that best suits your answer. Only one form of media is required. "
  }
  ${
    text &&
    `Text should be no more than ${textSettings?.wordCount || 300} words / ${(
      textSettings?.maxLength || 1700
    ).toLocaleString()} characters.`
  }
  ${
    (audio || video) &&
    `${
      audio && video ? " Audio or video" : audio ? " Audio" : " Video"
    } should be no more than 90 seconds`
  }`;
}

const themeColors = [
  "peach",
  "blue",
  "yellow",
  "black",
  "green",
  "lilac",
  "pink",
];

const zOptions = zData;

const QuestionWithPrompt = withPrompt(Dropdown);

const reg = {
  text: {
    required: errorMessages.required,
    minLength: {
      value: 200,
      message: errorMessages.minLength(200),
    },
  },
};

const StoriesForm = () => {
  const { surveyid } = useParams();
  const { loading, error, data } = useIDCheck(surveyid);
  let user = null;
  let userDefaultValues = null;

  if (loading) {
    return <>Loading</>;
  }
  if (error || !data) {
    return <IDNotFound></IDNotFound>;
  }

  if (data) {
    user = data[0].fields;
    userDefaultValues = {
      ...initialValues,
      "Survey ID": user["Applicant System ID"],
      "First Name": user["Applicant First Name"],
      "Last Name": user["Applicant Last Name"],
      Email: user["Applicant Email Address"],
      "Afterschool Theme":
        user[
          "What afterschool theme and related idea(s) will you tackle if you are selected as a Generator Z contributor? Select one"
        ],
      "Airtable ID": data[0].id,
      Region: user["State"],
    };
    if (user["Story Submissions"]) {
      return (
        <ThemeProvider value={`peach`}>
          <ColumnLayout banner={false}>
            <Header
              heading={<>You've already submitted a story</>}
              body={
                <>
                  <p>
                    It looks like you already submitted your story. If you think
                    this is a mistake, or you need to change your information,
                    please reach out to us at{" "}
                    <a
                      href="mailto:info@generator-z.org."
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      info@generator-z.org.
                    </a>{" "}
                  </p>
                </>
              }
            />
          </ColumnLayout>
        </ThemeProvider>
      );
    }
  }

  return (
    <ColumnLayout initTheme={`pale-peach`} banner={false}>
      <div className={styles.element}>
        <Header heading={heading} body={body} />
        <Form defaultValues={userDefaultValues}></Form>
      </div>
    </ColumnLayout>
  );
};
const ThemedStoriesForm = (props) => {
  return (
    <ThemeProvider value={"peach"}>
      <StoriesForm {...props} />
    </ThemeProvider>
  );
};
export default ThemedStoriesForm;

const OneImage = ({ name, register, errors }) => {
  const types = ["image"];
  return (
    <MediaBuilder
      forwardRef={register({
        required: errorMessages.required,
        validate: (value) => {
          return JSON.parse(value).length > 0 || "You must add an image";
        },
      })}
      name={name}
      errors={errors}
      types={types}
      heading={"Add Image"}
      limit={1}
      required={{
        message: getInstructions(types),
        validate: (blocks) => blocks && blocks.length > 0 && blocks[0].value,
      }}
    />
  );
};

// const ValidateMedia = (blocks, types) => {
//   if (blocks.length === 0) {
//     return "You must add something";
//   }

//   const textBlocks = blocks.filter(({ type }) => type === "text");
//   const mediaBlocks = blocks.filter(({ type }) => type !== "text");

//   const textProblem = textBlocks.reduce((r, d) => {
//     if (!d.value || d.value.length < 100) {
//       r = true;
//     }
//     return r;
//   }, false);

//   const mediaProblem = mediaBlocks.reduce((r, d) => {
//     if (!d.value) {
//       r = true;
//     }
//     return r;
//   }, false);

//   const textSettings =
//     types && types.find(({ type: findType }) => findType === "text");

//   if (textProblem || mediaProblem) {
//     let message = "";
//     if (textProblem) {
//       message += `There is a minimum character count of ${
//         textSettings?.minLength || 100
//       } characters long. `;
//     }
//     if (mediaProblem) {
//       message +=
//         "You are missing an upload – please add one or remove the block if you don't need it. ";
//     }

//     return message;
//   }

//   return true;
// };
const VideoAudioText = ({ types = ["video", "audio", "text"], ...props }) => {
  return (
    <MediaBuilder
      {...props}
      required={{
        message: getInstructions(types),
        validate: (blocks) => {
          return ValidateMedia(blocks, types) === true;
        },
      }}
      forwardRef={props.register({
        required: errorMessages.required,
        validate: (value) => {
          const blocks = JSON.parse(value);
          return ValidateMedia(blocks, types);
        },
      })}
      types={types}
    />
  );
};

const heading = (
  <>
    Generator Z<br />
    Where You Have the Power
  </>
);

const body = (
  <>
    <p>
      Your story is powerful because it’s yours. Your personal experience and
      insight can help shape the world in unexpected ways. Sharing your story
      can generate connection, purpose, inspiration, and direction. It can
      impact you, your friends, your family and other people that you don’t even
      know. It can help you discover and explore passions in your life, lead to
      jobs and career paths, and educate other people.
    </p>
    <p>
      Think of yourself as a storyteller, director, architect, guide, muse,
      collaborator, consultant, expert, leader or, as we like to say, a
      Generator. As a Generator, you will share your story to direct the change
      that you want to see. Your contribution is valuable. Over the next year,
      Generator Z will pay you $1,000 for your participation. The first step is
      to share your story and <strong>$500 for your story</strong>.
    </p>
    <p>
      So here’s the big question: How do you reimagine the future of
      afterschool?
    </p>
    <h3>So Generator Z, How Does This Work?</h3>
    <p>
      Below are a few questions. Your job is to answer these questions in your
      own genuine voice. Your answers will come together to tell your story.
    </p>
    <p>
      Adults that provide afterschool opportunities will learn from you and your
      fellow Generators. Your stories will guide their ideas for action (Step 1:
      $500). They will apply for funding to make those ideas come to life (Step
      2: $250) and you will help them stay on the right track (Step 3: $250).
    </p>{" "}
    <p>
      But first, it’s about you and your world. Get to know the questions below.
      Some questions require words and photography; others are flexible where
      you choose the question and media that best suit your voice, whether it’s
      video, audio, or words.
    </p>
    <p>
      Think about your story, talk about it, take notes, piece your answers
      together, edit, and refine it. Your application will not automatically
      save, so our advice is to find your own way to work on these questions
      over time.
    </p>{" "}
    <p>
      If you have questions, need help, or just want to talk with other
      Generators, we’ve got you covered. Check out our{" "}
      <Link to="/program-guidelines">Program Guidelines</Link> and{" "}
      <Link to="/faq">Frequently Asked Questions</Link>. Live chat with us or
      email{" "}
      <a
        href="mailto:info@generator-z.org"
        rel="noopener noreferrer"
        target="_blank"
      >
        info@generator-z.org
      </a>
      . Our best advice is to sign up for a{" "}
      <a
        href="https://www.eventbrite.com/e/generator-z-storytelling-workshops-tickets-129894962329"
        target="_blank"
        rel="noopener noreferrer"
      >
        workshop
      </a>{" "}
      with storytelling experts that will guide you through the process.
    </p>{" "}
    {/* <p>
      <strong>
        You have 30 days to complete your submission. All submissions are due on
        Sunday, December 20th at 11:59pm.
      </strong>
    </p> */}
  </>
);

const Form = ({ defaultValues }) => {
  const [status, setStatus] = useState(null);
  const recaptchaRef = React.useRef();
  const { register, handleSubmit, control, watch, errors, setValue } = useForm({
    defaultValues,
  });

  useFormPersist(
    "story-submission-" + defaultValues["Survey ID"],
    { watch, setValue },
    {
      storage: window.localStorage,
    },
    {
      exclude: [
        "Survey ID",
        "Airtable ID",
        "Region",
        "Afterschool Theme",
        "Email",
      ],
    }
  );

  const onSubmitWithReCAPTCHA = async (data) => {
    const token = await recaptchaRef.current.executeAsync();
    console.log("submit", data);
    if (token) {
      setStatus(statuses.LOADING);
      axios
        .post(`/submit-story`, data)
        .then((res) => {
          console.log(res);
          setStatus(statuses.SUCCESS);
        })
        .catch((err) => {
          console.log(err.message);
          setStatus(statuses.ERROR);
        });
    }
  };

  const watched = watch(["Theme", "Z Style", "Age"]);
  const { setTheme } = useContext(ThemeContext);

  const themeOptions = themeData.map((row) => row.id);

  useEffect(() => {
    setTheme(watched.Theme);
  }, [watched.Theme, setTheme]);

  if (status === statuses.SUCCESS) {
    return <Redirect to="/stories/add/success" />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmitWithReCAPTCHA)}>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LeYG-UZAAAAAGscT5cfnHrGve9DmCRSzUNAefdu"
      />
      <InnerHTML
        html={`<script type="text/javascript">!function(e,s){function a(o){function e(e){switch(e.data.type){case"sizeUpdate":o.style.height=e.data.height,o.style.width=e.data.width,o.style.bottom=e.data.bottom,o.style.right=e.data.right;break;case"fetchSession":var t;try{t=JSON.parse(s.localStorage.getItem(n))}catch(a){t={}}t.type="fetchSession",t.accessedAt=(new Date).getTime(),o.contentWindow.postMessage(t,o.src);break;case"saveSession":s.localStorage.setItem(n,JSON.stringify(e.data))}}var n="avochato-widget-pP9R3ylA3v";return e}function t(){if(!e.getElementById("avochato-text-us")&&n===s.location.href){s._avo_handle_message_func&&s.removeEventListener("message",s._avo_handle_message_func);var t=e.createElement("iframe");t.id="avochato-text-us",t.src="https://www.avochato.com/text_us/pP9R3ylA3v?iframe_domain="+encodeURIComponent(s.location.href),t.setAttribute("style","position:fixed;bottom:2%;right:2%;border:none;height:100px;width:130px;z-index: 999999;"),s._avo_handle_message_func=a(t),s.addEventListener("message",s._avo_handle_message_func),s.Avochato=s.Avochato||{},s.Avochato.widgets=s.Avochato.widgets||{},s.Avochato.widgets.pP9R3ylA3v={hide:function(){t.style.setProperty("display","none")},openWithOption:function(e){t.style.setProperty("display",null),t.contentWindow.postMessage({type:"openWithOption",value:e},t.src)}},e.body.appendChild(t)}}function o(){i||(i=!0,t())}var n=s.location.href,i=!1;e.addEventListener&&e.addEventListener("DOMContentLoaded",o,!1),/loaded|interactive|complete/.test(e.readyState)&&o(),s.addEventListener("load",o)}(document,window);</script>`}
      ></InnerHTML>
      <Section heading="Your Profile">
        <Subsection
          // instructions={<h4>What is your name? (First/Last)</h4>}
          fields={
            <>
              <input
                type="hidden"
                name="Survey ID"
                value={defaultValues["Survey ID"]}
                ref={register}
              ></input>
              <input type="hidden" name="Airtable ID" ref={register}></input>
              <div className={formLayout.half}>
                <Text
                  name="First Name"
                  label="First Name"
                  forwardRef={register({ required: true })}
                  errors={errors}
                  readonly
                />
                <Text
                  name="Last Name"
                  label="Last Name"
                  forwardRef={register({ required: true })}
                  errors={errors}
                  readonly
                />
              </div>
              <div className={formLayout.half}>
                <Text
                  name="Email"
                  type="email"
                  label="Email"
                  forwardRef={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  })}
                  readonly
                  errors={errors}
                />
                {!watched.Age && (
                  <input
                    type="text"
                    name="Age Required"
                    className={styles.hidden}
                    ref={register({
                      required: true,
                    })}
                  />
                )}
                <Select
                  name="Age"
                  label="Age"
                  control={control}
                  defaultValue=""
                  options={["13", "14", "15", "16", "17", "18", "19"]}
                  forwardRef={register({
                    required: true,
                  })}
                  required={true}
                  errors={errors}
                />
              </div>
              <div className={formLayout.half}>
                <Select
                  name="Region"
                  label="Region"
                  control={control}
                  options={[
                    { label: "Western New York", value: "New York" },
                    { label: "Southeast Michigan", value: "Michigan" },
                  ]}
                  forwardRef={register({ required: true })}
                  errors={errors}
                  disabled={"disabled"}
                />

                <Select
                  name="Afterschool Theme"
                  label="Afterschool Theme"
                  options={themeOptions}
                  control={control}
                  forwardRef={register({ required: errorMessages.required })}
                  errors={errors}
                  disabled={"disabled"}
                ></Select>
              </div>
              <p>
                Generator Z will use your first name and last initial on your
                published story to maintain your privacy. We understand that
                some Generators may want to keep their name completely
                confidential. If this is the case, please provide a screen name.
              </p>
              <div className={formLayout.full}>
                <Text
                  name="Screen Name"
                  type="text"
                  label="Screen Name"
                  forwardRef={register()}
                  errors={errors}
                />
              </div>
            </>
          }
          tip={
            <p>
              <strong>Tip 1:</strong> Work on developing your story in a google
              doc, notebook, or on your phone -- anywhere that you can save your
              work. When you’re ready with your final version, begin the
              application and plan to complete it from beginning to end.
            </p>
          }
        ></Subsection>
      </Section>
      <Section heading="Section 1: Introduce Yourself">
        <Subsection
          heading={"Your Story, Short & Sweet"}
          instructions={
            "What makes you, you? Share your life’s journey. You’d be amazed at how much territory you can cover in a few sentences.  Share an image that represents you, or your life, to go with your words. This will be the cover image for your story."
          }
          fields={
            <>
              <TextArea
                name="Your Story Response"
                placeholder="Start typing here"
                forwardRef={register(reg.text)}
                minLength={200}
                maxLength={600}
                errors={errors}
                required={{
                  message: "TEXT IS REQUIRED, 100 WORDS/600 CHARACTERS*",
                }}
              />
              <OneImage
                name={"Your Story Image"}
                errors={errors}
                register={register}
              />
            </>
          }
          tip={
            <>
              <p>
                <strong>Tip 2:</strong> When you build your story, help us see
                your experience through your eyes. Be as descriptive and as
                colorful as you can be to bring your answers to life no matter
                the topic you share or the media you choose.
              </p>
              <p>
                <strong>Tip 3:</strong> Write what you want to say in your own
                words in a way that people, of any age, can understand. You do
                not have to be a poet or a journalist to answer questions with
                text. Just make sure it fits within word and character limits.
              </p>
            </>
          }
        />
        <Subsection
          heading={"Your Joy"}
          fields={
            <>
              <QuestionWithPrompt
                className={styles.question}
                name={"Joy Question"}
                control={control}
                options={questions.joy}
                prompts={questions.joy}
                watch={watch}
                forwardRef={register({
                  required: errorMessages.required,
                })}
                errors={errors}
              />
              {/* this is the honeypot */}
              <input
                className={styles.permission}
                type="checkbox"
                name="I have permission"
                ref={register()}
              />
              <VideoAudioText
                register={register}
                name="Joy Content"
                types={[
                  "video",
                  "audio",
                  {
                    type: "text",
                    minLength: 285,
                    maxLength: 850,
                    wordCount: 150,
                  },
                ]}
                errors={errors}
              />
            </>
          }
          tip={
            <>
              <p>
                <strong>Tip 4:</strong> Sometimes questions require words and a
                photo, other times you get to pick between video, audio, or
                text. There is no right or wrong way to answer. Pick the media
                that you are most interested in using and stick with it or mix
                it up!
              </p>
            </>
          }
        />
        <Subsection
          heading={"Your Challenge"}
          tip={
            <>
              <p>
                <strong>Tip 5:</strong> Record video or audio using a
                smartphone, tablet, or computer. You can use the app of your
                choice to make this happen.{" "}
              </p>
              <p>
                <strong>Tip 6:</strong> Recording video or audio may take a few
                tries before it’s just right. Help us see and hear what is
                important to your story within the time limit. Be mindful of
                unwanted background noises and other distractions.
              </p>
            </>
          }
          fields={
            <>
              <QuestionWithPrompt
                className={styles.question}
                name={"Challenge Question"}
                control={control}
                options={questions.challenge}
                prompts={questions.challenge}
                watch={watch}
                forwardRef={register({
                  required: errorMessages.required,
                })}
                errors={errors}
              />
              <VideoAudioText
                register={register}
                name="Challenge Content"
                errors={errors}
                types={[
                  "video",
                  "audio",
                  {
                    type: "text",
                    minLength: 285,
                    maxLength: 850,
                    wordCount: 150,
                  },
                ]}
              />
            </>
          }
        />
        <Subsection
          heading={"It’s 3 pm, What’s Up?"}
          tip={
            <>
              <p>
                <strong>Tip 7:</strong> Think about how images support your
                answer. An image could be a photo (selfies included), something
                that you have created, or an activity, place, or space that you
                really love.{" "}
              </p>
            </>
          }
          instructions={
            <p>
              <strong>
                What do you do on a typical weekday between 3 pm and when you go
                to bed?
              </strong>{" "}
              Tell us what this time outside of school looks like for you. Share
              an image that represents this time to go with your words.
            </p>
          }
          fields={
            <>
              <TextArea
                name="3pm Response"
                placeholder="Start typing here"
                forwardRef={register(reg.text)}
                minLength={150}
                maxLength={300}
                errors={errors}
                required={{
                  message: "TEXT IS REQUIRED, 50 WORDS/300 CHARACTERS*",
                }}
              ></TextArea>{" "}
              <OneImage
                name={"3pm Content"}
                register={register}
                errors={errors}
              />
            </>
          }
        />
      </Section>
      <Section heading="Section 2: Reimagine Afterschool">
        <Subsection
          heading="Your Experience Afterschool"
          tip={
            <>
              <p>
                <strong>Tip 8:</strong> Remember that “afterschool” is the time
                and space to do all the interesting things, outside of school,
                that have a positive impact on your life. This can be any type
                of structured program or experience - in-person or virtually -
                that functions outside of school or school hours.
              </p>
            </>
          }
          instructions={
            <p>
              <strong>
                Remember when you applied to be a Generator and picked a theme?
                Why did you choose it?
              </strong>{" "}
              Tell us how that theme relates to your life and your afterschool
              experience. Share an image that represents your theme to go with
              your words.
            </p>
          }
          fields={
            <>
              {" "}
              <TextArea
                name="Afterschool Response"
                placeholder="Start typing here"
                forwardRef={register({
                  required: errorMessages.required,
                  minLength: {
                    value: 100,
                    message: errorMessages.minLength(100),
                  },
                })}
                minLength={100}
                maxLength={300}
                errors={errors}
                required={{
                  message: "TEXT IS REQUIRED, 50 WORDS/300 CHARACTERS*",
                }}
              ></TextArea>
              <OneImage
                register={register}
                name="Afterschool Image"
                errors={errors}
              />
            </>
          }
        />
        <Subsection
          heading="Your Big Afterschool Dream"
          instructions={
            <p>
              <strong>
                Imagine that you have a magic wand that can make the best
                afterschool experience ever *that relates to your afterschool
                theme.* What is it?
              </strong>{" "}
              What kinds of things could teens do there? What could they learn
              about? Who would be there? Describe the space, mood, and vibe. Be
              detailed and specific. Focus on your afterschool theme. Don’t be
              afraid to dream big.
            </p>
          }
          fields={
            <>
              <VideoAudioText
                register={register}
                name="Afterschool Advice Content"
                errors={errors}
              />
            </>
          }
        />
        <Subsection
          heading="Your Creative Solution"
          instructions={
            <p>
              <strong>
                Imagine that you are an inventor that finds clever ways to solve
                problems. How does your afterschool dream happen during a
                pandemic?
              </strong>{" "}
              Come up with creative and experimental ideas. Don’t hold back.
              There are no right or wrong answers. What changes about your big
              dream? What stays exactly the same?
            </p>
          }
          fields={
            <VideoAudioText
              name="Creative Solution Content"
              register={register}
              errors={errors}
            />
          }
        />
        <Subsection
          heading="Name It"
          tip={
            <>
              <p>
                <strong>Tip 9:</strong> Come up with a title that suits your
                afterschool dream. Think about how it reveals something
                important about your insight or advice. Have fun with this! This
                will appear as a header on your storytelling page.
              </p>
            </>
          }
          instructions={
            <p>
              <strong>
                Give your afterschool dream a name and explain it.
              </strong>
            </p>
          }
          fields={
            <TextArea
              name="Sum It Up Response"
              placeholder="Start typing here"
              // generateRef={() => register({ required: errorMessages.required })}
              forwardRef={register({
                required: errorMessages.required,
                minLength: {
                  value: 50,
                  message: errorMessages.minLength(50),
                },
              })}
              minLength={50}
              maxLength={156}
              errors={errors}
              required={{
                message: "Text is required, up to 30 words/156 characters*",
              }}
            />
          }
        />
      </Section>
      <Section heading="Make it yours">
        <Subsection
          heading="Pick your Z"
          instructions={
            <p>
              Once your story is complete, choose how it will appear to those
              viewing it online. Pick a custom Z that fits your aesthetic and
              choose your color palette below. This is how your page will appear
              when it’s published on Generator-Z.org.
            </p>
          }
          fields={
            <ZPicker
              options={zOptions}
              name="Z Style"
              current={watched["Z Style"]}
              generateRef={() => register({ required: errorMessages.required })}
              errors={errors}
            ></ZPicker>
          }
        />
        <Subsection
          heading={"Pick your color palette"}
          fields={
            <ThemeColorPicker
              options={themeColors}
              name="Theme"
              current={watched.Theme}
              generateRef={() => register({ required: errorMessages.required })}
              errors={errors}
            ></ThemeColorPicker>
          }
        />
        {/* <div className={styles.subsection}>
              <p>
                Once your story is complete, save it and click review to see how
                it will appear to those viewing it online. If you want to return
                to make changes, you can do so. The next step is to design your
                page.
              </p>
              <h4>Pick your Z</h4>
              <ZPicker
                options={zOptions}
                name="Z Style"
                current={watched["Z Style"]}
                generateRef={() =>
                  register({ required: errorMessages.required })
                }
                errors={errors}
              ></ZPicker>
              <h4>Pick your color palette</h4>
              <ThemeColorPicker
                options={themeColors}
                name="Theme"
                current={watched.Theme}
                generateRef={() =>
                  register({ required: errorMessages.required })
                }
                errors={errors}
              ></ThemeColorPicker>
            </div> */}
      </Section>
      <Section heading="Send It Off">
        <Subsection
          tip={
            <p>
              <strong>Tip 10:</strong> Payment will be processed when you submit
              your application. This means you can get paid as soon or as late
              as you would like so make a plan. Figure out how to fit in genuine
              thinking time, a workshop, a few drafts or takes, and your final
              submission.
            </p>
          }
          instructions={
            <p>
              Ready to send? Remember that other teens, adults, and the world at
              large will be checking out your profile, so share things that feel
              comfortable and are right for you. You will not be able to edit
              your submission after you hit submit. If you need to fix
              something, email us at{" "}
              <a
                href="mailto:info@generator-z.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                info@generator-z.org
              </a>{" "}
              to fill out an entirely new submission.
            </p>
          }
          fields={
            <>
              {status === statuses.LOADING ? (
                "Sending Story"
              ) : status === statuses.ERROR ? (
                <p className={styles.error}>
                  A server error occured. Please try again in a few moments. If
                  you continue experiencing issues, please email us at{" "}
                  <a
                    href="mailto:info@generator-z.org"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    info@generator-z.org
                  </a>
                  .
                </p>
              ) : (
                <button type="submit" className={styles.submit}>
                  Submit
                </button>
              )}
            </>
          }
        ></Subsection>
      </Section>
    </form>
  );
};
