import React from "react";
import styles from "./faq.module.scss";
import layout from "scss/layout.module.scss";
import Accordion from "components/accordion/";
import shape from "./shape.svg";
import ColumnLayout from "layouts";
import { ThemeProvider, themes } from "components/teaser/theme-context";
import { countiesList } from "components/program-guidelines/program-guidelines";
import { OutboundLink } from "react-ga";
import { Link } from "react-router-dom";

const Faq = () => {
    const theme = themes[6];
    return (
        <ThemeProvider value={theme}>
            <ColumnLayout shape={shape} styles={styles} initTheme={theme}>
                <h1 className={layout.heading}>Frequently Asked Questions</h1>
                <div className={styles.element}>
                    <h3>
                        The Generator Z Initiative is now closed. We continue to share our FAQs as a source of learning.
                    </h3>

                    <h4>General questions? We got you!</h4>
                    <Accordion rows={accordionContent}></Accordion>
                    <h4>Generators, specific questions?</h4>
                    <Accordion rows={generatorQuestions}></Accordion>
                    <h4>Afterschool providers, specific questions?</h4>
                    <Accordion rows={providerQuestions}></Accordion>
                </div>
            </ColumnLayout>
        </ThemeProvider>
    );
};

export default Faq;

const accordionContent = [
    {
        heading:
            "👉 Generator Z is sometimes described as an ‘ideas lab’ and sometimes described as an ‘ideas platform.’ What does this mean?",
        text: (
            <p>
                Glad you asked! We use ‘ideas lab’ and ‘ideas platform’ interchangeably.
                Let’s break it down. An idea is a suggestion that can lead to action. A
                lab is a space for experiments, research, teaching or all the above. A
                platform is an opportunity to voice a view and initiate an action.
                Generator Z is all of this virtually on this site. We recognize that it
                takes all sorts of people, of all ages, with all sorts of ideas engaging
                with each other to influence what becomes action.
            </p>
        ),
    },
    {
        heading:
            "👉 What is ‘afterschool’ and how do I know if I am in an afterschool program?",
        text: (
            <p>
                It’s easy to think of afterschool as the time when you are not in
                school. Generator Z goes deeper. Think of afterschool as the time and
                space to do all the interesting things, outside of school, that have a
                positive impact on your life. If you are in any type of structured
                program or experience - in-person or virtually - that functions outside
                of school hours, you are in an afterschool program.
            </p>
        ),
    },
    {
        heading: "👉 What exactly is an ‘afterschool provider?’",
        text: (
            <p>
                Generator Z defines an afterschool provider as a structured program or
                experience that functions outside of school hours to provide young
                people the time and space to explore their interests. We recognize that
                the afterschool providers that serve teens come in all shapes and sizes.
                Some are dedicated exclusively to teens while others are hosted through
                faith-based, school, government, arts, or community organizations. Some
                providers are large and known, others are small and not so known.
            </p>
        ),
    },
    {
        heading:
            "👉 Can I apply to Generator Z more than once? Can I tackle more than one afterschool theme? ",
        text: (
            <p>
                Teens and afterschool providers may only submit one application to
                Generator Z with one afterschool theme. You likely have multiple
                interests. experiences, and ideas so choose the one afterschool theme
                that you find most important to you or your organization and apply with
                that selection. Multiple applications will result in disqualification.
            </p>
        ),
    },
    {
        heading: "👉 What does Generator Z do with data and information? ",
        text: (
            <>
                <p>
                    For teens who applied to be Generators, information first collected
                    during intake will remain confidential. Applicant contact information
                    will only be used to communicate afterschool opportunities within the
                    Generator Z network.
                </p>
                <p>
                    For Generators, story submissions will be published on the Generator Z
                    platform with Generators identified per their preferred screen names
                    and home region. All last names and home cities will remain
                    confidential. Generator stories are searchable by selected afterschool
                    theme, geography, demographics, and afterschool participation.{" "}
                </p>
                <p>
                    For afterschool providers applying for grants, financial information
                    will remain confidential. Relevant contact information will be
                    publicized on the Generator Z platform to encourage teens to connect
                    to local afterschool providers and opportunities. Afterschool provider
                    ideas will also be published alongside Generator stories on the
                    Generator Z Platform.
                </p>
                <p>Applicant data will not be sold. </p>
            </>
        ),
    },
    {
        heading:
            "👉 Is Generator Z open to applicants outside of Southeast Michigan or Western New York? ",
        text: (
            <>
                <p>
                    No. Generator Z is only open to teens and afterschool providers in the
                    sixteen-county region of Southeast Michigan and Western New York:
                </p>
                {countiesList}
                <p>
                    Teens must live in any one of the eligible counties for at least
                    six-months of the year.
                </p>
                <p>
                    Afterschool providers must be based in an eligible county and serve
                    teens from one or multiple eligible counties.
                </p>
            </>
        ),
    },
    {
        heading:
            "👉  I’m having technical difficulties on-line. How do I get help?",
        text: (
            <>
                <p>
                    First, you want to copy and paste your work, if any, to another place
                    because the web-based platform is not structured to save information!
                    Next, send us an email at{" "}
                    <OutboundLink
                        to="mailto:info@generator-z.org"
                        eventLabel="email"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        info@generator-z.org
                    </OutboundLink>{" "}
                    or text us via Live Chat on-line, if applicable. If you text after 6
                    pm or on a weekend, we will answer your question the next business
                    day.
                </p>
            </>
        ),
    },
    {
        heading:
            "👉 What if I need more time to apply to Generator Z or I miss a deadline?",
        text: (
            <>
                <p>
                    Generally, a missed deadline - by teens, Generators, or afterschool
                    providers - will result in disqualification. However, if there is an
                    emergency that makes it hard to meet a deadline, please contact us to
                    discuss your circumstances. These decisions will be made on a
                    case-by-case basis. Generator Z cannot guarantee an extension.
                </p>
            </>
        ),
    },
    {
        heading: "👉 Will I receive an email confirmation of my application?",
        text: (
            <>
                <p>
                    No, you will <strong>not</strong> receive an email. After you submit
                    your application or story form, a confirmation window will appear that
                    confirms a successful submission. If you see this, we have received
                    your application.
                </p>
            </>
        ),
    },
];
const generatorQuestions = [
    {
        heading: "👉 Can I participate in Generator Z if I am not a U.S. citizen? ",
        text: (
            <>
                <p>
                    Yes, you are welcome to participate regardless of your citizenship
                    status.{" "}
                    <em>
                        We will never ask you a question related to your legal status in the
                        United States.
                    </em>
                </p>
            </>
        ),
    },
    {
        heading:
            "👉 Can I participate in Generator Z if I am not actively enrolled in school?  ",
        text: (
            <>
                <p>
                    You <strong>do not</strong> have to be enrolled in school or in an
                    afterschool program to participate in Generator Z. We will never ask
                    you for your grade or status in school.
                </p>
            </>
        ),
    },
    {
        heading: "👉 How much and when do I get paid? What about taxes?",
        text: (
            <>
                <p>
                    Over one year, Generator Z will pay selected Generators $1,000 for
                    their participation. Payments are broken down into three separate
                    stipends:
                </p>
                <ol>
                    <li>The first is $500 for personal stories on afterschool (2020).</li>
                    <li>
                        The second is $250 for feedback to afterschool providers (2021).
                    </li>
                    <li>
                        The third is $250 for feedback on afterschool progress (2022).{" "}
                    </li>
                </ol>
                <p>
                    Generators must complete their work by the stated deadlines to be
                    paid.
                </p>
                <p>
                    Generator Z is structured to limit stipends to no more than $500 in
                    one calendar year. However, this may not be the case for some
                    Generators for a variety of reasons. If you collect over $500 in
                    stipends in one calendar year, you will be required to provide a
                    completed IRS Form W-9 to Lakeshore Connections, Inc and be issued a
                    Form 1099 by Lakeshore Connections, Inc for reporting the income to
                    the IRS.{" "}
                </p>
            </>
        ),
    },
    {
        heading: "👉 Can I use someone else’s Venmo, Paypal, CashApp?",
        text: (
            <>
                <p>
                    Yes, you may. We suggest you speak to a trusted adult about payment
                    arrangements for the best advice.
                </p>
            </>
        ),
    },
    {
        heading: "👉 Can I change my selected payment options?",
        text: (
            <>
                Yes, you may. Please contact{" "}
                <OutboundLink
                    to="mailto:info@generator-z.org"
                    eventLabel="email"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    info@generator-z.org
                </OutboundLink>{" "}
                with this request.{" "}
            </>
        ),
    },
];

const providerQuestions = [
    {
        heading:
            "👉 We provide teen programming but young people are not the focus of our mission. Can we apply to Generator Z?",
        text: (
            <>
                <p>
                    Yes. If you serve teens in a meaningful way outside of school, you may
                    apply to Generator Z! We recognize that afterschool providers come in
                    all shapes and sizes. Some are dedicated exclusively to teens while
                    others are hosted through faith-based, school, government, arts, or
                    community organizations.
                </p>
            </>
        ),
    },
    {
        heading:
            "👉 Can we apply to Generator Z as an afterschool provider without a physical address? ",
        text: (
            <>
                <p>
                    Generator Z requires a physical address to apply. This address may be
                    affiliated with operations or it may be the mailing address where your
                    organization is registered. We know that the pandemic has shifted
                    opportunities from physical to virtual spaces for many and we are
                    taking that into consideration.
                </p>
            </>
        ),
    },
    {
        heading:
            "👉 We are an afterschool provider that serves young people of many ages. Can we still apply for funding if some, but not all, of our programs serve teens?",
        text: (
            <>
                <p>
                    Yes. If you serve teens between 13 to 18 years of age in a meaningful
                    way, you may apply to Generator Z!{" "}
                </p>
            </>
        ),
    },
    {
        heading:
            "👉 We are an afterschool provider partnered with a 501(c)(3). Should they apply or should we? ",
        text: (
            <>
                <p>
                    Either of you may apply. However, we strongly recommend that the
                    entity directly serving young people, with the most meaningful
                    relationships to teens, apply. This experience and perspective is
                    important when navigating the application.{" "}
                </p>
            </>
        ),
    },
    {
        heading:
            "👉 We do not have a filed 2019 Form 990 and will, instead, be providing a 2019 financial statement. What should our financial statement include?",
        text: (
            <>
                <p>
                    Afterschool providers who are submitting a 2019 financial statement
                    should include (1) a balance sheet, also known as a statement of
                    financial position; and (2) an income statement, also known as a
                    statement of activity.
                </p>
            </>
        ),
    },
    {
        heading:
            "👉 Does Generator Z require a minimum or maximum organization budget and/or program budget?",
        text: (
            <>
                <p>
                    No, we do not. However, requested grant amounts are tied to
                    organization budgets. Refer to the Program Guidelines for more
                    information.{" "}
                </p>
                <p>
                    If you request a grant amount that does <strong>not</strong> follow
                    our stated grant amount guidelines, you may be disqualified.
                </p>
            </>
        ),
    },
    {
        heading: "👉 Why does Generator Z require liability insurance?",
        text: (
            <>
                <p>
                    Liability insurance is required by Lakeshore Connections, Inc. as a
                    term of their grant agreement. Proper liability insurance offers
                    protection in the event that someone is injured or believes that they
                    were otherwise wronged and seeks recovery in a lawsuit. In the context
                    of Generator Z, liability insurance protects afterschool providers who
                    become grant recipients and the young people they serve.{" "}
                </p>
            </>
        ),
    },
    {
        heading:
            "👉 I do not want my grant application to be public. Do I have an option?",
        text: (
            <>
                <p>
                    No. Generator Z is not a traditional grant application. We believe
                    that young people deserve to understand their influence. Afterschool
                    providers are ultimately accountable to the teens they serve. In this
                    case, Generator Z applicants are accountable to the Generators that
                    kickstarted this effort. We also believe that there is incredible
                    value in peer-to-peer learning. Great ideas can inspire other great
                    ideas.{" "}
                </p>
                <p>
                    If you are uncomfortable with this level of transparency, Generator Z
                    is not a good fit.
                </p>
            </>
        ),
    },
];
