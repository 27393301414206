import React from "react";
import styles from "./tip.module.scss";
const CoachingTip = ({ children }) => {
  return (
    <div className={styles.element}>
      <div className={`${styles.flag} bg--accent`}>
        <span>Coaching Tip</span>
      </div>
      <div className={`fg--accent`}>{children}</div>
    </div>
  );
};

export default CoachingTip;
