import React, { useState } from "react";

export const themes = [
    "peach",
    "lilac",
    "black",
    "white",
    "yellow",
    "pale-peach",
    "pink",
    "green",
];

const ThemeContext = React.createContext();

export default ThemeContext;

export const ThemeProvider = ({ children, value }) => {
    const [theme, setTheme] = useState(value || themes[0]);
    const [sectionId, setSectionId] = useState(null);
    const [navGrid, setNavGrid] = useState(null);

    return (
        <ThemeContext.Provider
            value={{
                theme,
                setTheme: (value) => setTheme(value),
                sectionId,
                setSectionId,
                navGrid,
                setNavGrid,
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};
