import React, { useEffect, useRef, useState } from "react";
import useMeasure from "hooks/useMeasure";
import styles from "./nodes.module.scss";
import FORCE from "./force";
import { select } from "d3-selection";
import groups from "./dummy.js";
import Text from "react-svg-text";
import SVG from "react-inlinesvg";

const TOTAL_DOTS = 100;
const RADIUS = 8;
const RADIUSX = RADIUS * 2;
const RADIUSY = RADIUS;

const force = FORCE();
const makeDots = () => {
  const dots = new Array(TOTAL_DOTS).fill({}).map((d, i) => ({ id: i }));
  Object.keys(groups).forEach((group) => {
    let cursor = 0;
    if (groups[group].groups) {
      groups[group].groups.forEach(({ key, quantity, fill }) => {
        const last = quantity + cursor;
        const fillTo = cursor + fill;
        for (let i = cursor; i < last; i++) {
          if (i > TOTAL_DOTS - 1) {
            console.log(group, "dots over");
          } else {
            dots[i] = {
              ...dots[i],
              [group]: {
                value: key,
                fill: i >= fillTo,
              },
            };
          }
        }
        cursor += quantity;
      });
    }
  });

  return dots;
};
const dots = makeDots();

function Nodes({ currentStep }) {
  const [ref, bounds] = useMeasure();
  const elem = useRef();

  useEffect(() => {
    if (bounds.width) {
      force.initForce({
        nodes: dots,
        width: bounds.width,
        height: bounds.height,
      });
      force.tick(elem.current);
      force[currentStep]();

      return () => {
        force.destroy();
      };
    }
  }, [currentStep, bounds.width, bounds.height]);

  useEffect(() => {
    const onResize = () => {
      const w = bounds.width;
      if (w > 0) {
        if (w < 400) {
          force.maxColumns = 1;
        } else if (w < 1000) {
          force.maxColumns = 2;
        } else {
          force.maxColumns = 4;
        }
      }
    };
    onResize();
  }, [bounds.width]);

  const current = groups[currentStep];
  const rmult = current.zoomOut ? 0.3 : 1;

  return (
    <div className={styles.container}>
      <div className={styles.svgWrap} ref={ref}>
        <svg
          ref={elem}
          viewBox={`0 0 ${bounds.width} ${bounds.height}`}
          className={styles.svg}
        >
          {current.groups &&
            current.groups.map(({ key, shape }) => {
              if (!shape) {
                return null;
              }

              const { x, y } = force.xy(currentStep)({
                [currentStep]: { value: key },
              });

              return (
                <g key={shape} transform={`translate(${x}, ${y})`}>
                  <Shape src={shape} />
                </g>
              );
            })}

          <g>
            {dots.map((d) => (
              <Node
                data={d}
                key={d.id}
                currentStep={currentStep}
                rx={RADIUSX * rmult}
                ry={RADIUSY * rmult}
              />
            ))}
          </g>
          {current.groups &&
            current.groups.map(
              ({ quantity, labelQuantity, key, label, sublabel, shape }) => {
                const { x, y } = force.xy(currentStep)({
                  [currentStep]: { value: key },
                });
                const { bumpLabels } = current;

                return (
                  <React.Fragment
                    key={`${currentStep}${key}${quantity}heading`}
                  >
                    <g
                      transform={`translate(${x}, ${
                        y - (bumpLabels ? 235 : 150)
                      })`}
                    >
                      <Text className={styles.clusterValue}>{`${
                        labelQuantity ? labelQuantity : quantity
                      }%`}</Text>
                    </g>

                    <g
                      transform={`translate(${x}, ${
                        y - (bumpLabels ? 220 : 140)
                      })`}
                    >
                      <Text
                        verticalAnchor="start"
                        className={styles.clusterValueLabel}
                        width={200}
                      >
                        {label}
                      </Text>
                    </g>
                    {sublabel && (
                      <g
                        transform={`translate(${x}, ${
                          y - (bumpLabels ? 160 : 90)
                        })`}
                      >
                        <Text
                          className={styles.clusterSubValue}
                          width={200}
                          lineHeight={16}
                        >
                          {sublabel}
                        </Text>
                      </g>
                    )}
                  </React.Fragment>
                );
              }
            )}
        </svg>
      </div>
    </div>
  );
}

export default Nodes;

const Shape = ({ src }) => {
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  return (
    <SVG
      {...offset}
      src={src}
      alt=""
      width={300}
      height={300}
      preProcessor={(code) => {
        const regexp = /viewBox="0 0 (\d+) (\d+)"/g;
        const match = regexp.exec(code);
        setOffset({
          x: -match[1] / 2,
          y: -match[2] / 2,
        });
        return code;
      }}
    />
  );
};

const Node = ({ data, rx, ry, currentStep }) => {
  const ref = useRef();

  useEffect(() => {
    select(ref.current)
      .datum(data)
      .call(force.updateNode)
      .call(force.updateFill(currentStep));
  }, [data, currentStep]);

  return (
    <g className="node" ref={ref}>
      <path d={`M 0,0 a ${rx},${ry} 0 1,0 1,0`} />
    </g>
  );
};
