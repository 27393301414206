import React from "react";
import { Link } from "react-router-dom";
import styles from "./resource-bar.module.scss";
function ResourceBar() {
  return (
    <div className={styles.element}>
      <Link to="/resources" className={styles.link} />
      <div className={styles.inner}>
        <div>
          Does a story have you feeling a certain way? We get it. Visit our{" "}
          <span>Resources & Support</span> page.
        </div>
      </div>
    </div>
  );
}

export default ResourceBar;
