import React, { useEffect, useRef, useState } from "react";
import styles from "./themes.module.scss";
import arts from "./creativearts(blue).svg";
import learning from "./excelling(yellow).svg";
import friends from "./friends(blush).svg";
import fun from "./fun(pink).svg";
import play from "./play(red).svg";
import identity from "./identity(lilac).svg";
import jobs from "./jobs(purple).svg";
import growth from "./growth(green).svg";
import mental from "./mental(blue).svg";
import life from "./lifeskills(yellow).svg";
import useMatchMedia from "hooks/useMatchMedia";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
gsap.registerPlugin(ScrollTrigger);

const themes = [
  {
    shape: mental,
    heading: "Mental, Social & Emotional Health",
    padding: "4rem 6rem",
    paddingMobile: "1.5em 2.5em",
    x: 20,
    color: "#fff",
    tw: "8em",
    tx: -2,
  },
  {
    shape: identity,
    heading: "Identity & Acceptance",
    padding: "5.25rem 6rem 0 4rem",
    paddingMobile: "2.25rem 6rem 0 3rem",
    x: 33,
    tw: "7em",
    tx: -5,
    ty: 5,
  },
  {
    shape: growth,
    heading: "Growth & Wellness",
    padding: "4rem 15rem 0 1rem",
    paddingMobile: "1.5em 6.5em 0 0em",
    x: 30,
    tw: "4em",
    tx: -30,
  },
  {
    shape: friends,
    heading: "Friends & Community",
    padding: "12rem 2rem 0 0",
    paddingMobile: "12rem 2rem 0 2em",
    tw: "10em",
    x: 0,
    ty: 5,
    tx: -5,
  },
  {
    shape: learning,
    heading: "Excellence in Learning",
    padding: "2.75rem 5rem 4rem 4.5rem",
    paddingMobile: "1.75rem 4rem 4rem 4.15rem",
    x: 30,
    tw: "10em",
    tx: -5,
    ty: 3,
  },
  {
    shape: jobs,
    heading: "Jobs &  Careers",
    padding: "2.5rem 6rem 4rem 6.5rem",
    paddingMobile: "2rem 8rem 0 7rem",
    x: 15,
    color: "#fff",
    tx: 10,
  },
  {
    shape: arts,
    heading: "Creative Arts & Culture ",
    padding: "4rem 12rem 0 10rem",
    tx: -5,
    ty: -10,
    tw: "5em",
    x: 10,
    color: "#fff",
    minWidth: "500px",
  },
  {
    shape: fun,
    heading: "Fun & Games",
    padding: "4rem 6rem 0 3.5rem",
    paddingMobile: "1.5rem 7rem 0 1.5rem",
    x: 25,
    tx: -20,
    tw: "5em",
  },

  {
    shape: play,
    heading: "Play & Sports",
    padding: "3rem 8rem",
    paddingMobile: "2.5rem 6.5rem",
    x: 25,
  },

  {
    shape: life,
    heading: "Life Skills & Balance",
    padding: "3rem 9rem 0 4rem",
    paddingMobile: "3rem 7rem 0 4rem",
    x: 0,
    tw: "6em",
    tx: -10,
  },
];

const Themes = ({ onEnter, onLeaveBack }) => {
  const ref = useRef();
  useEffect(() => {
    const t = ScrollTrigger.create({
      trigger: ref.current,
      start: "top center",
      end: "bottom center",
      onEnter,
      onLeaveBack,
    });
    return () => {
      t.kill();
    };
  }, [onEnter, onLeaveBack]);
  return (
    <ul className={styles.element} ref={ref}>
      {themes.map((theme) => (
        <Theme {...theme} key={theme.heading} />
      ))}
    </ul>
  );
};

export default Themes;

const Theme = ({
  shape,
  heading,
  padding,
  paddingMobile,
  tx = 0,
  ty = 0,
  tw,
  x,
  color,
}) => {
  const ref = useRef();
  const [loaded, setLoaded] = useState();
  const desktop = useMatchMedia(`(min-width: 800px)`);

  useEffect(() => {
    let t;
    if (desktop && loaded) {
      t = gsap.to(ref.current, {
        duration: 1,
        scrollTrigger: {
          trigger: ref.current,
          start: "top bottom",
          scrub: 1,
          toggleActions: "play pause resume reverse",
        },
        x: () => `-=${window.innerWidth / 5}`,
      });
    }

    return () => {
      t && t.scrollTrigger.kill();
    };
  }, [loaded, desktop]);

  return (
    <li
      className={styles.theme}
      style={{ marginLeft: `${x}%`, color }}
      ref={ref}
    >
      <img src={shape} alt="" onLoad={() => setLoaded(true)} />
      <div
        className={styles.themeHeading}
        style={{ transform: `translate(${tx}%, ${ty}%)` }}
      >
        <div style={{ maxWidth: tw }}>{heading}</div>
      </div>
    </li>
  );
};
