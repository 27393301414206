import React from "react";
import styles from "./tileContent.module.scss";
import { screenname } from "helpers";

const TileConent = ({ fields }) => (
  <>
    <div className={styles.name}>{screenname(fields)} </div>
    <div className={styles.theme}>{fields["Afterschool Theme"]}</div>
    <div className={styles.age}>Age {fields["Age"]}</div>
    <div className={styles.region}>{fields["Region"]}</div>
  </>
);

export default TileConent;
