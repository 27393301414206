import React, { useState } from "react";
import accordion from "./accordion.module.scss";
import { CSSTransition } from "react-transition-group";

const Accordion = ({ rows }) => {
  return (
    <div>
      {rows.map((row, index) => (
        <AccordionRow key={index} content={row}></AccordionRow>
      ))}
    </div>
  );
};

const AccordionRow = ({ content }) => {
  const [rowOpen, setRowOpen] = useState(false);
  const toggleRow = () => {
    setRowOpen(!rowOpen);
  };
  return (
    <div className={accordion.row}>
      <div
        className={`${accordion.heading} ${rowOpen ? "open" : ""}`}
        onClick={toggleRow}
      >
        <h3>{content.heading}</h3>
        <button className={accordion.caret} tabIndex={0} onClick={toggleRow}>
          <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.1274 16.5637L17.5637 33.1274L1 16.5637"
              stroke="black"
              strokeWidth="2"
            />
          </svg>
        </button>
      </div>
      <CSSTransition in={rowOpen} timeout={0} classNames="anim" unmountOnExit>
        <div className={accordion.inner}>{content.text}</div>
      </CSSTransition>
    </div>
  );
};

export default Accordion;
