import React from "react";
//import styles from "./audio-upload.module.scss";
import { FilestackUpload } from "components/form/filestack-upload";
import Audio from "components/stories/audio";
import Upload from "components/form/upload";

const AudioUpload = ({ ...props }) => {
  return (
    <Upload
      type="audio"
      media={
        <FilestackUpload
          {...props}
          accept={["audio/*"]}
          Preview={Audio}
          buttonText="Add Audio"
        />
      }
      filename={props.value && props.value.filename}
    />
  );
};

export default AudioUpload;
