import React, { useContext, useState } from "react";
import styles from "./modal.module.scss";
import mailchimp from "./mailchimp.module.scss";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import closeIcon from "components/nav/IconClose.svg";
import SVG from "react-inlinesvg";
import { CSSTransition } from "react-transition-group";
import ScrollLock from "react-scrolllock";
import ui from "scss/ui.module.scss";
import ModalContext from "../teaser/modal-context";

const Modal = () => {
  const url = `//generator-z.us2.list-manage.com/subscribe/post?u=1bb23d715438cb73d95087829&id=6ffd3577ea`;
  const { showModal, setShowModal } = useContext(ModalContext);
  return (
    <>
      <CSSTransition in={showModal} appear={true} unmountOnExit timeout={100}>
        <ScrollLock>
          <div className={styles.outer} onMouseDown={() => setShowModal(false)}>
            <div
              className={styles.element}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <div className={styles.border}>
                <button
                  onClick={() => setShowModal(false)}
                  className={styles.closeModal}
                >
                  <SVG src={closeIcon} alt="Close Modal"></SVG>
                </button>
                <div className={styles.inner}>
                  <h3>Sign up for Generator Z updates</h3>

                  <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                      <CustomForm
                        status={status}
                        message={message}
                        onValidated={(formData) => subscribe(formData)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </ScrollLock>
      </CSSTransition>
    </>
  );
};

export default Modal;

const CustomForm = ({ status, message, onValidated }) => {
  const [provider, setProvider] = useState(false);
  const [textMsgPermission, setTextMsgPermission] = useState("No");
  let email, fname, lname, organization, zip, phone;
  const submit = () =>
    email &&
    fname &&
    lname &&
    zip &&
    phone &&
    textMsgPermission &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
      FNAME: fname.value,
      LNAME: lname.value,
      ZIP: zip.value,
      ORG: organization ? organization.value : "",
      PROVIDER: provider,
      PHONE: phone.value,
      TEXTMSG: textMsgPermission,
    });

  return (
    <div className={mailchimp.element}>
      {status === "success" && (
        <div className={mailchimp.intro}>
          <p>
            Thank you for subscribing! We will be in touch with applicable
            updates and information via email and text. We encourage you to
            follow Generator Z on social media on{" "}
            <a
              href="https://instagram.com/generatorzfuture?igshid=19vepje9amurd"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>{" "}
            and/or{" "}
            <a
              href="https://www.facebook.com/GeneratorZFuture/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
            .
          </p>
        </div>
      )}
      {status !== "success" && (
        <>
          <div className={mailchimp.intro}>
            <p>
              Are you interested in following along? Are you a youth advocate or
              afterschool provider who works with teens? Do you plan on applying
              for a Generator Z grant? Big things are in the works. Stay
              connected to know what's next.
            </p>
          </div>
          <h4 className={mailchimp.label}>What is your name?</h4>
          <div className={mailchimp.row}>
            <input
              ref={(node) => (fname = node)}
              type="text"
              placeholder="First name"
            />

            <input
              ref={(node) => (lname = node)}
              type="text"
              placeholder="Last name"
            />
          </div>
          <div className={mailchimp.row}>
            <div className={mailchimp.fieldWrap}>
              <h4 className={mailchimp.label}>What is your email?</h4>
              <input
                ref={(node) => (email = node)}
                type="email"
                placeholder="Your email"
              />
            </div>
            <div className={mailchimp.fieldWrap}>
              <h4 className={mailchimp.label}>What is your zipcode?</h4>
              <input
                ref={(node) => (zip = node)}
                type="text"
                placeholder="Zip Code"
              />
            </div>
          </div>
          <div className={mailchimp.row}>
            <div className={mailchimp.row}>
              <div className={mailchimp.fieldWrap}>
                <h4 className={mailchimp.label}>Phone Number?</h4>
                <input
                  ref={(node) => (phone = node)}
                  type="text"
                  placeholder="Phone Number"
                />
              </div>
            </div>
            <div className={mailchimp.row}>
              <div className={mailchimp.fieldWrap}>
                <h4>I agree to receive text Messgaes</h4>
                <div className={mailchimp.radioSet}>
                  <div className={mailchimp.radioWithLabel}>
                    <input
                      onChange={() => setTextMsgPermission("Yes")}
                      type="radio"
                      value="Yes"
                      id="textMsgYes"
                      name="textMsg"
                    />
                    <label htmlFor="textMsgYes">Yes</label>
                  </div>
                  <div className={mailchimp.radioWithLabel}>
                    <input
                      onChange={() => setTextMsgPermission("No")}
                      type="radio"
                      value="No"
                      id="textMsgNo"
                      name="textMsg"
                    />
                    <label htmlFor="textMsgNo">No</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={mailchimp.row}>
            <div className={mailchimp.fieldWrap}>
              <h4>Are you an afterschool provider? </h4>
              <div className={mailchimp.radioSet}>
                <div className={mailchimp.radioWithLabel}>
                  <input
                    onChange={() => setProvider("Yes")}
                    type="radio"
                    value="Yes"
                    id="providerYes"
                    name="provider"
                  />
                  <label htmlFor="providerYes">Yes</label>
                </div>
                <div className={mailchimp.radioWithLabel}>
                  <input
                    onChange={() => setProvider("No")}
                    type="radio"
                    value="No"
                    id="providerNo"
                    name="provider"
                  />
                  <label htmlFor="providerNo">No</label>
                </div>
              </div>
            </div>

            <div className={mailchimp.row}>
              <CSSTransition
                in={provider === "Yes"}
                appear={true}
                unmountOnExit
                timeout={100}
              >
                <div
                  className={`${mailchimp.fieldWrap} ${mailchimp.conditionalField}`}
                >
                  <h4 className={mailchimp.label}>
                    What is your organization?
                  </h4>
                  <input
                    ref={(node) => (organization = node)}
                    type="text"
                    placeholder="Your organization"
                  />
                </div>
              </CSSTransition>
            </div>
          </div>
          {status === "sending" && (
            <div className={mailchimp.row}>
              <div className={mailchimp.statusMsg}>sending...</div>
            </div>
          )}
          {status === "error" && (
            <div className={mailchimp.row}>
              <div
                className={mailchimp.errorMsg}
                dangerouslySetInnerHTML={{ __html: message }}
              />
            </div>
          )}
          <div className={mailchimp.submitWrap}>
            <button
              className={`${ui.button} ${mailchimp.submit}`}
              onClick={submit}
            >
              Submit
            </button>
          </div>
        </>
      )}
    </div>
  );
};
