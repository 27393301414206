import React from "react";
import styles from "./header.module.scss";
import collage from "./web_replacement_image-min.png";
import eye from "./eye.gif";

const Header = ({ heading, body, hideCollage }) => {
  return (
    <div className={styles.element}>
      {collage && !hideCollage && (
        <div className={`${styles.railImage}`}>
          <img src={collage} alt={`art`} className={styles.collage} />
          <img className={styles.eye} src={eye} alt={eye} />
        </div>
      )}
      <h1 className={`${styles.heading} fg--accent`}>{heading}</h1>
      <div className={styles.body}>{body}</div>
    </div>
  );
};

export default Header;
