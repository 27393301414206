import React, { useState, useEffect } from "react";
import ColumnLayout from "layouts/columns";
import axios from "axios";
import { ThemeProvider } from "components/teaser/theme-context";
import Header from "components/stories/stories-form/header";

function useIDCheck(id) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  //const { loading, error, data } = usePost(surveyid);

  useEffect(() => {
    axios
      .get(`/api/applicant`, {
        params: { id },
      })
      .then((res) => {
        if (res.data.length === 0) {
          setError(true);
          setLoading(false);
        } else {
          setData(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  }, [id]);

  return { data, loading, error };
}

export default useIDCheck;

export const IDNotFound = () => {
  return (
    <ThemeProvider value={"peach"}>
      <ColumnLayout styles={null}>
        <Header
          heading={`Uh-Oh!`}
          body={
            <>
              <p>
                The page you’re looking for requires a unique url that’s just
                for you. Please refer to the confirmation email we sent you and
                use that link. If you cannot find it or have questions, please
                email us at{" "}
                <a
                  href="mailto:info@generator-z.org."
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  info@generator-z.org.
                </a>{" "}
              </p>
            </>
          }
        ></Header>
        {/* Stories Form will now use custom URLs generated from imported survey IDs
        to prepopulate content, and maintain a reference between tables:
        <ul>
          <li>
            <a href="/stories/add/118886948">/stories/add/118886948</a>
          </li>

          <li>
            <a href="/payment-info/118886948">/payment-info/add/118886948</a>
          </li>
        </ul>
        URLs without ID or with incorrect IDs should display a message for the
        user to contact Generator Z if they are reaching this page in error. */}
      </ColumnLayout>
    </ThemeProvider>
  );
};

// const usePost = (id) => {
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(false);
//   const [data, setData] = useState(null);

//   useEffect(() => {
//     axios
//       .get(`http://localhost:3001/api/applicant`, {
//         params: { id },
//       })
//       .then((res) => {
//         if (res.data.length === 0) {
//           setError(true);
//           setLoading(false);
//         } else {
//           setData(res.data);
//           setLoading(false);
//         }
//       })
//       .catch((err) => {
//         setLoading(false);
//         setError(true);
//       });
//   }, [id]);

//   return { data, loading, error };
// };
