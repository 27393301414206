import React from "react";
import styles from "./providers.module.scss";
import Badge from "./badge";
import { themeColors } from "./theme-colors";

const TileContent = ({ fields, inheritColors }) => {
  if (!fields || !fields["Theme"]) {
    return null;
  }
  const afterschoolTheme = fields["Theme"].trim();
  const theme = themeColors.filter(({ theme }) => theme === afterschoolTheme)[0]
    ?.colorScheme;

  return (
    <>
      <h3 className={styles.name}>
        {fields.Title ? fields.Title : "Missing Title"}
      </h3>
      {fields?.Grantees && (
        <div className={inheritColors ? "bg--accent" : ` bg--${theme}`}>
          <Badge color={theme} inheritColors={inheritColors} />
        </div>
      )}
      <div className={styles.meta}>{fields["Organization"]}</div>
    </>
  );
};

export default TileContent;
