const questions = {
  which: {
    key: "slug",
    question: "Select your afterschool idea",
    show: false,
  },
  joy: {
    key: "Joys and Challenges",
    question:
      "Explain the joys and challenges of bringing your idea to life so far. How did you celebrate the joys? How did you overcome the challenges?",
    publicQuestion:
      "Discuss the joys and challenges of bringing your idea to life so far.",
  },
  success: {
    key: "Defining Success",
    question:
      "We asked you to define success at the time you applied. Here's what you wrote:",
    publicQuestion:
      "How has your definition of success changed since the time you applied?",
  },
  quote: {
    key: "Teen Experience Quote",
    question:
      "Select a teen that is working with you to implement this idea. Ask them about their experience so far and provide a one-sentence quote.",
    type: "quote",
  },
  quoteAttribution: {
    question: "Whose words are these?",
    type: "quoteAttribution",
  },
  quoteAttributionFirst: {
    key: "Quote Attribution First Name",
    question: "First Name",
    type: "quoteAttribution",
  },
  quoteAttributionLast: {
    key: "Quote Attribution Last Initial",
    question: "Last Initial",
    type: "quoteAttribution",
  },
  quoteAttributionAge: {
    key: "Quote Attribution Age",
    question: "Age",
    type: "quoteAttribution",
  },
  progress: {
    key: "Progress Images",
    question: "Attach any images of your progress.",
    type: "image",
  },
  progressVideo: {
    key: "Progress Video",
    question: "Attach an optional 90 seconds or less video.",
    type: "video",
  },
  learning: {
    key: "Staff Learnings",
    question:
      "What are you and your staff learning through your participation in Generator Z?",
    show: false,
  },
  changes: {
    key: "Org Change",
    question: "Has your organization changed because of Generator Z? Explain",
    show: false,
  },
  experienceRating: {
    key: "Experience Rating",
    question:
      "How would you rate your Generator Z experience so far? (1 is terrible; 10 is awesome)",
    show: false,
  },
  completion: {
    key: "Completion Level",
    question:
      "What percentage of your afterschool idea is complete? (Drag 0% to 100%)",
    show: false,
  },
};

export default questions;
