import React, { useContext } from "react";
import Logo from "components/logo";
import layout from "scss/layout.module.scss";
import SVG from "react-inlinesvg";
import ThemeContext from "components/teaser/theme-context";
import Banner from "components/form/banner";
import NavOverlay from "components/nav/overlay";
import Footer from "components/footer";

const Columns = ({ shape, styles, children, banner, railContent, sidebarContent,overflowFix=false }) => {
  const { theme } = useContext(ThemeContext);
  const ext = shape
    ? shape.slice(((shape.lastIndexOf(".") - 1) >>> 0) + 2)
    : null;
  return (
    <div
      className={`${layout.element} bg--${theme} ${
        banner ? layout.bannerTop : ""
      }`}
    >
      <Logo theme={theme}></Logo>
      {banner && <Banner />}
      <div className={layout.columns}>
        <div className={layout.column_inner}>
          <div className={layout.sidebar}>
            {sidebarContent}
            {ext && ext === "svg" && (
              <SVG src={shape} className={layout.shape}></SVG>
            )}
            {ext && ext === "png" && (
              <img
                src={shape}
                alt={`art`}
                className={`${layout.shape} ${layout.overflow}`}
              />
            )}
            {railContent}
          </div>
          <section
            className={`${overflowFix ? layout.contentOverflowFix : layout.content} ${styles ? styles.content : ""}`}
          >
            <div className={layout.inner}>{children}</div>
          </section>
        </div>
      </div>
      <Footer></Footer>

      <NavOverlay />
    </div>
  );
};

export default Columns;
