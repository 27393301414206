import React from "react";
import ColumnLayout from "layouts/columns";
import { ThemeProvider } from "components/teaser/theme-context";
import map from "./map.svg";
import SVG from "react-inlinesvg";
import styles from "./landing.module.scss";
import { Link } from "react-router-dom";

const ProviderDirectoryLanding = () => {
    return (
        <ThemeProvider value={"white"}>
            <LandingInner theme={"white"} />
        </ThemeProvider>
    );
};

const LandingInner = ({ theme }) => {
    return (
        <ColumnLayout>
            <div className={styles.element}>
                <h1>Provider Directory</h1>
                <p>
                    Generator Z was an ideas lab for teens and afterschool providers to reimagine
                    the future of afterschool in Southeast Michigan and Western New York.
                    If you’re a teen interested in afterschool programs, check out this interactive
                    directory of Generator Z afterschool providers.
                    These organizations are among the many programs offered across these regions!
                </p>
                <div className={styles.map}>
                    <SVG src={map}></SVG>
                    <div className={styles.links}>
                        <Link
                            to={"/provider-directory/michigan"}
                            className={`${styles.mi}`}
                        >
                            Southeast Michigan
                        </Link>
                        <Link
                            to={"/provider-directory/new-york"}
                            className={`${styles.btn} ${styles.ny}`}
                        >
                            Western New York
                        </Link>
                    </div>
                </div>
            </div>
        </ColumnLayout>
    );
};

export default ProviderDirectoryLanding;
