import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./provider-application.module.scss";
import formLayout from "components/form/form-layout.module.scss";
import { ThemeProvider } from "../teaser/theme-context";
import { useLocation, Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import ColumnLayout from "layouts";
import Text from "components/form/text";
import Email from "components/form/email";
import errorMessages from "components/form/errorMessages";
import MediaBuilder from "components/form/media-builder";
import RadioSet from "components/form/radio-set";
import CheckboxSet from "components/form/checkbox-set";
import Select from "components/form/select";
import TextArea from "components/form/textarea";
import themeData from "blocks/themes/data";
import RadioIconSet from "components/form/radio-icon-set";
import useFormPersist from "react-hook-form-persist";
import { Link } from "react-router-dom";
import Section from "components/stories/stories-form/section";
import Subsection from "components/stories/stories-form/subsection";
import Header from "components/stories/stories-form/header";
import StoryLookup from "components/story-lookup";
import ValidateMedia from "components/form/media-builder/validate-media";
import InnerHTML from "dangerously-set-html-content";
import ReCAPTCHA from "react-google-recaptcha";
//const concat = require("concat-stream");

const defaultValues = {
  firstName: "Dylan",
  lastName: "Viola",
  email: "notarealemail@hyperakt.com",
  JSON: "test",
};
const statuses = {
  SUCCESS: "success",
  LOADING: "loading",
  ERROR: "error",
};
const body = (
  <>
    <h3 className={styles.title}>Before You Begin: Meet the Generators</h3>
    <p className={styles.copy}>
      Did you know that there are over half-a-million teens in Southeast
      Michigan and Western New York? Generator Z invited 1,000 young people - or
      Generators - across both regions to share their insight and experience
      with afterschool providers like you. Afterschool stories are housed on the{" "}
      <Link to={`/stories`}>Generator Stories</Link> page. This collective voice
      and vision is intended to inspire opportunities for young people. The most
      important part of the Generator Z grant opportunity is to familiarize
      yourself with this content in ways that matter to you.
    </p>
    <p className={styles.copy}>
      Here’s our advice as you begin: read through the{" "}
      <strong>
        <Link to="/program-guidelines">Program Guidelines</Link>
      </strong>{" "}
      and{" "}
      <strong>
        <Link to="/faq">Frequently Asked Questions</Link>
      </strong>{" "}
      first. Check your eligibility next. If all is well, get to work. Please
      note, your application will <strong>not</strong> automatically save until
      you complete and submit the application. You may{" "}
      <Link
        to={"/GenZ_Provider_App.pdf"}
        target="_blank"
        rel="noopener noreferrer"
      >
        download the application questions here
      </Link>{" "}
      to help you plan ahead. If you have questions or need help, sign up for an
      information session to help guide you through the process. Still have
      questions? Text the live chat or email{" "}
      <a
        href="mailto:info@generator-z.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        info@generator-z.org
      </a>
      .
    </p>
    <p className={styles.copy}>
      <strong>
        All applications are due on Monday, February 22nd at 11:59pm.
      </strong>
    </p>
  </>
);

const ProviderApplication = () => {
  const [status, setStatus] = useState(null);
  useEffect(() => {
    if (status === statuses.SUCCESS) {
      if (typeof window !== "undefined") {
        window.snaptr("track", "SIGN_UP");
        window.fbq("track", "Lead");
        window.gtag("event", "conversion", {
          send_to: "AW-481265390/NzLSCJCK0uYBEO6NvuUB",
        });
      }
    }
  }, [status]);
  const [preformComplete, setPreformComplete] = useState(null);

  if (status === statuses.SUCCESS) {
    return <Redirect to="/provider/apply/success" />;
  }

  return (
    <ThemeProvider value={"peach"}>
      <ColumnLayout banner={true}>
        <Header
          heading={
            <>
              Apply With An
              <br /> Inspired Idea
            </>
          }
          body={body}
        />
        <div className={styles.element}>
          {status === statuses.SUCCESS ? (
            <>Sending</>
          ) : (
            <>
              {/* <Form status={status} setStatus={setStatus} /> */}
              {preformComplete === null && (
                <QualifyForm setQualify={setPreformComplete}></QualifyForm>
              )}
              {preformComplete === false && <NotEligible />}
              {preformComplete === true && (
                <ProviderForm
                  status={status}
                  setStatus={setStatus}
                ></ProviderForm>
              )}
            </>
          )}
        </div>
      </ColumnLayout>
    </ThemeProvider>
  );
};

export default ProviderApplication;

const QualifyForm = ({ setQualify }) => {
  const { register, handleSubmit, errors, watch, setValue } = useForm();
  useFormPersist(
    "provider-qualify-1",
    { watch, setValue },
    {
      storage: window.localStorage,
    }
  );

  const qualifyCheck = (data) => {
    const noCount = Object.keys(data).reduce((acc, curr) => {
      return +data[curr] + acc;
    }, 0);
    if (noCount === 0) {
      console.log("qualify check", data);
      setQualify(true);
      return;
    }
    setQualify(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(qualifyCheck)} className={styles.form}>
        <Section heading={"Section 1: Eligibility"}>
          <Subsection
            tip={
              <>
                <p>
                  {" "}
                  We encourage you to check your eligibility as soon as
                  possible. It only takes a few minutes.{" "}
                </p>
              </>
            }
            fields={
              <>
                <h3 className={styles.title}>Are you eligible?</h3>
                <p className={styles.copy}>
                  The first part of this application determines your
                  eligibility. If you answer ‘yes’ to each of these questions,
                  you are eligible to apply and may continue to the essentials
                  and ideas sections of this application. If you answer ‘no’ to
                  any of these questions, you are not eligible for a grant from
                  Generator Z.
                </p>
                <label className={styles.label}>
                  We are an afterschool provider
                </label>
                <RadioSet
                  name="isProvider"
                  label=""
                  options={["Yes", "No"]}
                  generateRef={() =>
                    register({ required: errorMessages.required })
                  }
                  errors={errors}
                />
                <label className={styles.label}>
                  We are based in Western New York or Southeast Michigan
                </label>{" "}
                <p className={styles.info}>
                  <strong>Southeast Michigan:</strong> Wayne, Oakland, Macomb,
                  Livingston, Monroe, St. Clair, Washtenaw
                  <br />
                  <strong>Western New York:</strong> Allegany, Cattaraugus,
                  Chautauqua, Erie, Genesee, Monroe, Niagara, Orleans, Wyoming
                </p>
                <RadioSet
                  name="locationQualifies"
                  label=""
                  options={["Yes", "No"]}
                  generateRef={() =>
                    register({ required: errorMessages.required })
                  }
                  errors={errors}
                />
                <label className={styles.label}>
                  We serve teens between 13 - 18 years of age
                </label>
                <RadioSet
                  name="teensQualify"
                  label=""
                  options={["Yes", "No"]}
                  generateRef={() =>
                    register({ required: errorMessages.required })
                  }
                  errors={errors}
                />
                <label className={styles.label}>
                  We are a non-profit organization, partnered with a non-profit,
                  a school, or a unit of local government
                </label>
                <RadioSet
                  name="typeQualify"
                  label=""
                  options={["Yes", "No"]}
                  generateRef={() =>
                    register({ required: errorMessages.required })
                  }
                  errors={errors}
                />
                <label className={styles.label}>
                  We have an active board of directors
                </label>
                <RadioSet
                  name="hasBoard"
                  label=""
                  options={["Yes", "No"]}
                  generateRef={() =>
                    register({ required: errorMessages.required })
                  }
                  errors={errors}
                />
                <label className={styles.label}>We have 2019 Financials</label>
                <RadioSet
                  name="hasFinancials"
                  label=""
                  options={["Yes", "No"]}
                  generateRef={() =>
                    register({ required: errorMessages.required })
                  }
                  errors={errors}
                />
                <button className={formLayout.submit} type="submit">
                  Continue
                </button>
              </>
            }
          />
        </Section>
      </form>
    </>
  );
};

const ProviderForm = ({ status, setStatus }) => {
  const search = useLocation().search;
  const recaptchaRef = React.useRef();
  const formRef = React.useRef();
  const defaultForm = search === "?fill" ? { defaultValues } : undefined;
  const { register, handleSubmit, errors, watch, setValue, control } = useForm(
    defaultForm
  );

  useFormPersist(
    "provider-submission",
    { watch, setValue },
    {
      storage: window.localStorage,
    },
    {
      exclude: [
        // "Survey ID",
        // "Airtable ID",
        // "Region",
        // "Afterschool Theme",
        // "Email",
      ],
    }
  );

  const onSubmitWithReCAPTCHA = async (data) => {
    const token = await recaptchaRef.current.executeAsync();
    console.log("submit", data);

    if (token) {
      setStatus(statuses.LOADING);
      // data = { ...data, file: data.financials[0] };
      // const formData = new FormData();

      // formData.append("username", "Chris");

      //console.log("submit appended", data);
      // Object.keys(data).forEach((key) => {
      //   formData.append(key, data[key]);
      // });
      // if (data.financials && data.financials[0] !== undefined) {
      //   formData.append("financials", data.financials[0]);
      // }
      axios
        .post(
          `${
            process.env.REACT_APP_EXPRESS ? process.env.REACT_APP_EXPRESS : ""
          }/submit-provider`,
          data

          // { headers: { "Content-Type": "multipart/form-data" } }
        )

        .then((res) => {
          console.log(res);
          setStatus(statuses.SUCCESS);
        })
        .catch((err) => {
          console.log(err.message);
          setStatus(statuses.ERROR);
        });
    }
  };
  const watched = watch([
    "afterschoolTheme",
    "registered501c3",
    "partner501c3",
    "nonProfitSchool",
    "region",
    "weAre",
    "afterschoolProgrammingYears",
    "region",
    "county",
    "liabilityInsurance",
    "boardMeetings",
    "taxForm",
    "uploadFinancials",
    "generatorsList",
    "financialsUpload",
    "localGovernment",
  ]);

  const reg = {
    text: {
      required: errorMessages.required,
      minLength: {
        value: 5,
        message: errorMessages.minLength(5),
      },
    },
    text1800: {
      required: errorMessages.required,
      minLength: {
        value: 500,
        message: errorMessages.minLength(500),
      },
    },
    text3000: {
      required: errorMessages.required,
      minLength: {
        value: 1000,
        message: errorMessages.minLength(1000),
      },
    },
  };
  useEffect(() => {
    formRef.current.scrollIntoView();
  }, []);

  return (
    <form
      className={`${styles.element} ${styles.form}`}
      onSubmit={handleSubmit(onSubmitWithReCAPTCHA)}
      id="Form"
      ref={formRef}
    >
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LeYG-UZAAAAAGscT5cfnHrGve9DmCRSzUNAefdu"
      />
      <InnerHTML
        html={`<script type="text/javascript">!function(e,s){function a(o){function e(e){switch(e.data.type){case"sizeUpdate":o.style.height=e.data.height,o.style.width=e.data.width,o.style.bottom=e.data.bottom,o.style.right=e.data.right;break;case"fetchSession":var t;try{t=JSON.parse(s.localStorage.getItem(n))}catch(a){t={}}t.type="fetchSession",t.accessedAt=(new Date).getTime(),o.contentWindow.postMessage(t,o.src);break;case"saveSession":s.localStorage.setItem(n,JSON.stringify(e.data))}}var n="avochato-widget-pP9R3ylA3v";return e}function t(){if(!e.getElementById("avochato-text-us")&&n===s.location.href){s._avo_handle_message_func&&s.removeEventListener("message",s._avo_handle_message_func);var t=e.createElement("iframe");t.id="avochato-text-us",t.src="https://www.avochato.com/text_us/pP9R3ylA3v?iframe_domain="+encodeURIComponent(s.location.href),t.setAttribute("style","position:fixed;bottom:2%;right:2%;border:none;height:100px;width:130px;z-index: 999999;"),s._avo_handle_message_func=a(t),s.addEventListener("message",s._avo_handle_message_func),s.Avochato=s.Avochato||{},s.Avochato.widgets=s.Avochato.widgets||{},s.Avochato.widgets.pP9R3ylA3v={hide:function(){t.style.setProperty("display","none")},openWithOption:function(e){t.style.setProperty("display",null),t.contentWindow.postMessage({type:"openWithOption",value:e},t.src)}},e.body.appendChild(t)}}function o(){i||(i=!0,t())}var n=s.location.href,i=!1;e.addEventListener&&e.addEventListener("DOMContentLoaded",o,!1),/loaded|interactive|complete/.test(e.readyState)&&o(),s.addEventListener("load",o)}(document,window);</script>`}
      ></InnerHTML>
      {/* <input type="file" name="financials" ref={register} /> */}
      <Section heading={`Section 2: Essentials`}>
        <Subsection
          fields={
            <>
              <div className={formLayout.full}>
                <h3 className={styles.title}>Provider Information</h3>
                <Text
                  className={styles.text}
                  name={"organizationName"}
                  label="Organization"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />

                <Text
                  className={styles.text}
                  name={"website"}
                  label="Website:"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />

                <Email
                  className={styles.text}
                  name={"email"}
                  label="Email:"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />
                <Text
                  className={styles.text}
                  name={"phoneNumber"}
                  type="tel"
                  //pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  label="Phone Number:"
                  placeholder="xxx-xxx-xxxx"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />
              </div>

              <label className={styles.label}>
                Social Media Handles{" "}
                <span className={styles.optional}>(Optional)</span>:
              </label>
              <fieldset className={`${formLayout.thirds}`}>
                <Text
                  className={styles.text}
                  name={"twitter"}
                  // label="Twitter"
                  placeholder="Twitter"
                  forwardRef={register}
                  errors={errors}
                />
                <Text
                  className={styles.text}
                  name={"instagram"}
                  placeholder="Instagram"
                  forwardRef={register}
                  errors={errors}
                />
                <Text
                  className={styles.text}
                  name={"facebook"}
                  placeholder="Facebook"
                  forwardRef={register}
                  errors={errors}
                />
                <Text
                  className={styles.text}
                  name={"snapChat"}
                  placeholder="SnapChat"
                  forwardRef={register}
                  errors={errors}
                />
                <Text
                  className={styles.text}
                  name={"tikTok"}
                  placeholder="TikTok"
                  forwardRef={register}
                  errors={errors}
                />
                <Text
                  className={styles.text}
                  name={"linkedIn"}
                  placeholder="LinkedIn"
                  forwardRef={register}
                  errors={errors}
                />
              </fieldset>
              <fieldset className={`${formLayout.full} pad`}>
                <Text
                  className={styles.text}
                  name={"individualName"}
                  label="Name of Individual Applying on behalf of provider:"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />
                <Text
                  className={styles.text}
                  name={"individualRole"}
                  label="Role of Individual Applying on behalf of provider:"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />
                <Email
                  className={styles.text}
                  name={"individualEmail"}
                  label="Email of Individual Applying:"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />
                <Text
                  className={styles.text}
                  name={"individualPhone"}
                  placeholder="xxx-xxx-xxxx"
                  label="Phone Number for Individual Applying:"
                  type="tel"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />
                <label className={styles.label}>
                  Do you agree to receive text messages about your application?
                </label>
                <RadioSet
                  name="textPermission"
                  label=""
                  options={["Yes", "No"]}
                  generateRef={() =>
                    register({ required: errorMessages.required })
                  }
                  errors={errors}
                />
                <Select
                  className={styles.text}
                  name={"weAre"}
                  label="We Are"
                  control={control}
                  defaultValue=""
                  options={[
                    {
                      label: "an existing independent local organization",
                      value: "1",
                    },
                    {
                      label:
                        "an existing local branch of a national organization",
                      value: "2",
                    },
                    {
                      label:
                        "an existing program housed in an organization or institution",
                      value: "3",
                    },
                    {
                      label: "an existing program housed in local government ",
                      value: "4",
                    },
                    {
                      label: "an existing school (public or non-profit)",
                      value: "0",
                    },
                  ]}
                  forwardRef={register({ required: true })}
                  required={true}
                  errors={errors}
                />
                {watched && !watched.weAre && (
                  <input
                    className={formLayout.hidden}
                    type="text"
                    name={`weAre required`}
                    ref={register({ required: true })}
                  ></input>
                )}
                <Select
                  className={styles.text}
                  control={control}
                  name={"afterschoolProgrammingYears"}
                  label="We have provided aferschool programming for"
                  options={[
                    { label: "Less than 1 year", value: "0" },
                    { label: "1 year", value: "1" },
                    { label: "2 years", value: "2" },
                    { label: "3 - 5 years", value: "3" },
                    { label: "6 - 10 years", value: "4" },
                    { label: "11 - 20 years", value: "5" },
                    { label: "20+ years", value: "6" },
                  ]}
                  forwardRef={register({ required: true })}
                  required={true}
                  errors={errors}
                />
                {watched && !watched.afterschoolProgrammingYears && (
                  <input
                    className={formLayout.hidden}
                    type="text"
                    name={`afterschoolProgrammingYears required`}
                    ref={register({ required: true })}
                  ></input>
                )}
              </fieldset>
            </>
          }
        />
        <Subsection
          fields={
            <>
              <h3 className={styles.title}>Provider Location</h3>
              <div className={`${formLayout.half}`}>
                <Select
                  className={styles.text}
                  control={control}
                  name={"region"}
                  label="Region:"
                  options={[
                    { label: "Western New York", value: "0" },
                    { label: "Southeast Michigan", value: "1" },
                  ]}
                  onChange={(val) => {
                    console.log(val);
                  }}
                  forwardRef={register({ required: true })}
                  required={true}
                  errors={errors}
                />
                {watched && !watched.region && (
                  <input
                    className={formLayout.hidden}
                    type="text"
                    name={`region required`}
                    ref={register({ required: true })}
                  ></input>
                )}
                <Select
                  className={styles.text}
                  control={control}
                  name={"county"}
                  label="County:"
                  options={[
                    { label: "Oakland", value: "0" },
                    { label: "Livingston", value: "1" },
                    { label: "Macomb", value: "2" },
                    { label: "St. Clair", value: "3" },
                    { label: "Washtenaw", value: "4" },
                    { label: "Wayne", value: "5" },
                    { label: "Monroe", value: "6" },
                    { label: "Allegany", value: "7" },
                    { label: "Cattaraugus", value: "8" },
                    { label: "Chautauqua", value: "9" },
                    { label: "Erie", value: "10" },
                    { label: "Genesee", value: "11" },
                    { label: "Niagara", value: "12" },
                    { label: "Orleans", value: "13" },
                    { label: "Wyoming", value: "14" },
                  ]}
                  onChange={(val) => {
                    console.log(val);
                  }}
                  forwardRef={register({ required: true })}
                  required={true}
                  errors={errors}
                />
                {watched && !watched.county && (
                  <input
                    className={formLayout.hidden}
                    type="text"
                    name={`county required`}
                    ref={register({ required: true })}
                  ></input>
                )}
              </div>
              <div className={formLayout.full}>
                <Text
                  className={styles.text}
                  name={"address"}
                  label="Address:"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />
              </div>
              <div className={formLayout.half}>
                <Text
                  className={styles.text}
                  name={"city"}
                  label="City:"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />
                <Text
                  className={styles.text}
                  name={"zip"}
                  label="Zip Code:"
                  type={"number"}
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />
              </div>
            </>
          }
        />
        <Subsection
          fields={
            <>
              <h3 className={styles.title}>Teens Served</h3>
              <div className={`${formLayout.full}`}>
                <Text
                  className={styles.text}
                  name={"teensServed"}
                  label="We serve this many teens per year"
                  type="number"
                  pattern="\d*"
                  min="0"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />
              </div>
              <label className={styles.label}>
                Teens we serve are from the following counties
              </label>
              <CheckboxSet
                name={"teensServedCounties"}
                options={[
                  "Oakland",
                  "Livingston",
                  "Macomb",
                  "St. Clair",
                  "Washtenaw",
                  "Wayne",
                  "Monroe",
                  "Allegany",
                  "Cattaraugus",
                  "Chautauqua",
                  "Erie",
                  "Genesee",
                  "Niagara",
                  "Orleans",
                  "Wyoming",
                ]}
                generateRef={() =>
                  register({ required: errorMessages.required })
                }
                errors={errors}
              />
            </>
          }
        />{" "}
        <Subsection
          fields={
            <>
              <h3 className={styles.title}>Tax Information</h3>
              <div className={formLayout.full}>
                <label className={styles.label}>
                  We are a registered 501(c)(3) Organization
                </label>
                <RadioSet
                  name="registered501c3"
                  options={["Yes", "No"]}
                  generateRef={() =>
                    register({ required: errorMessages.required })
                  }
                  errors={errors}
                />
                {watched.registered501c3 && watched.registered501c3 === "0" && (
                  <Text
                    className={styles.text}
                    name={"organizationEIN"}
                    label="Organization EIN:"
                    forwardRef={register({
                      required: errorMessages.required,
                    })}
                    errors={errors}
                  />
                )}
                <label className={styles.label}>
                  We have a 501(c)(3) fiduciary partner
                </label>
                <RadioSet
                  name="partner501c3"
                  options={["Yes", "No"]}
                  generateRef={() =>
                    register({ required: errorMessages.required })
                  }
                  errors={errors}
                />
                {watched.partner501c3 && watched.partner501c3 === "0" && (
                  <>
                    <Text
                      className={styles.text}
                      name={"fiduciaryName"}
                      label="Fiduciary Name:"
                      forwardRef={register({
                        required: errorMessages.required,
                      })}
                      errors={errors}
                    />
                    <Text
                      className={styles.text}
                      name={"fiduciaryEIN"}
                      label="Fiduciary EIN:"
                      forwardRef={register({
                        required: errorMessages.required,
                      })}
                      errors={errors}
                    />
                    <Text
                      className={styles.text}
                      name={"fiduciaryContact"}
                      label="Fiduciary Contact Person:"
                      forwardRef={register({
                        required: errorMessages.required,
                      })}
                      errors={errors}
                    />
                  </>
                )}

                <h4>We are a school (public or non-profit)</h4>
                <RadioSet
                  name="nonProfitSchool"
                  options={["Yes", "No"]}
                  generateRef={() =>
                    register({ required: errorMessages.required })
                  }
                  errors={errors}
                />

                {watched.nonProfitSchool && watched.nonProfitSchool === "0" && (
                  <>
                    <Text
                      className={styles.text}
                      name={"schoolName"}
                      label="School Name"
                      forwardRef={register({
                        required: errorMessages.required,
                      })}
                      errors={errors}
                    />
                    <Text
                      className={styles.text}
                      name={"schoolDistrict"}
                      label="School District, If Applicable"
                      forwardRef={register()}
                      errors={errors}
                    />
                    <Text
                      className={styles.text}
                      name={"schoolEIN"}
                      label="EIN number, If Applicable"
                      forwardRef={register()}
                      errors={errors}
                    />
                  </>
                )}

                <h4>We are a unit of local government</h4>
                <RadioSet
                  name="localGovernment"
                  options={["Yes", "No"]}
                  generateRef={() =>
                    register({ required: errorMessages.required })
                  }
                  errors={errors}
                />
                {watched.localGovernment && watched.localGovernment === "0" && (
                  <>
                    <Select
                      control={control}
                      name={"levelOfGovernment"}
                      label="Level of Government:"
                      options={[
                        { label: "Village", value: "0" },
                        { label: "Town", value: "1" },
                        { label: "City", value: "2" },
                        { label: "County", value: "3" },
                      ]}
                      onChange={(val) => {
                        console.log(val);
                      }}
                      forwardRef={register({
                        required: errorMessages.required,
                      })}
                      errors={errors}
                    />
                    <Text
                      className={styles.text}
                      name={"unitDept"}
                      label="Unit/Department Name:"
                      forwardRef={register({
                        required: errorMessages.required,
                      })}
                      errors={errors}
                    />
                    <Text
                      className={styles.text}
                      name={"governmentEIN"}
                      label="EIN number, If Applicable"
                      forwardRef={register()}
                      errors={errors}
                    />
                  </>
                )}
              </div>
            </>
          }
        />
        <Subsection
          fields={
            <>
              <h3 className={styles.title}>Insurance Information</h3>
              <Select
                control={control}
                name={"liabilityInsurance"}
                label="Our school, non-profit organization, or partner organization:"
                options={[
                  { label: "does have liability insurance", value: "0" },
                  {
                    label: "does not have liability insurance",
                    value: "1",
                  },
                ]}
                onChange={(val) => {
                  console.log(val);
                }}
                forwardRef={register({ required: errorMessages.required })}
                errors={errors}
              />
              {watched && !watched.liabilityInsurance && (
                <input
                  className={formLayout.hidden}
                  type="text"
                  name={`liabilityInsurance required`}
                  ref={register({ required: true })}
                ></input>
              )}
            </>
          }
        />
        <Subsection
          fields={
            <>
              <h3 className={styles.title}>Board of Directors</h3>
              <div className={formLayout.half}>
                <Select
                  control={control}
                  name={"boardMeetings"}
                  label="We meet this many times a year"
                  options={[
                    { label: "1", value: "0" },
                    { label: "2", value: "1" },
                    { label: "3", value: "2" },
                    { label: "4", value: "3" },
                    { label: "5+", value: "4" },
                  ]}
                  onChange={(val) => {
                    console.log(val);
                  }}
                  forwardRef={register({ required: errorMessages.required })}
                  errors={errors}
                />
                {watched && !watched.boardMeetings && (
                  <input
                    className={formLayout.hidden}
                    type="text"
                    name={`boardMeetings required`}
                    ref={register({ required: true })}
                  ></input>
                )}
              </div>
            </>
          }
        />{" "}
        <Subsection
          tip={
            <>
              <p>
                Your afterschool program budget and your organization budget may
                be the same amount.{" "}
              </p>
            </>
          }
          fields={
            <div className={formLayout.full}>
              <h3 className={styles.title}>Financials</h3>

              <Text
                className={styles.text}
                name={"afterSchoolBudget2019"}
                label="Our 2019 afterschool program budget:"
                pattern="\d*"
                min="0"
                type="number"
                prefix="$"
                forwardRef={register({
                  required: errorMessages.required,
                })}
                errors={errors}
              />

              <Text
                className={styles.text}
                name={"orgBudget2019"}
                pattern="\d*"
                min="0"
                type="number"
                prefix="$"
                label="Our 2019 organization budget:"
                forwardRef={register({
                  required: errorMessages.required,
                })}
                errors={errors}
              />

              <br />
              <Text
                className={styles.text}
                name={"afterSchoolBudget2020"}
                label="Our 2020 afterschool program budget:"
                pattern="\d*"
                min="0"
                type="number"
                prefix="$"
                forwardRef={register({
                  required: errorMessages.required,
                })}
                errors={errors}
              />
              <Text
                className={styles.text}
                name={"orgBudget2020"}
                pattern="\d*"
                min="0"
                type="number"
                prefix="$"
                label="Our 2020 organization budget:"
                forwardRef={register({
                  required: errorMessages.required,
                })}
                errors={errors}
              />
              <br />
              <div className={formLayout.half}>
                <Select
                  control={control}
                  name={"taxForm"}
                  label="We have a:"
                  defaultValue=""
                  options={[
                    {
                      value: "0",
                      label: "2019 Form 990",
                    },
                    {
                      value: "1",
                      label: "2019 Financial statement",
                    },
                  ]}
                  onChange={(val) => {
                    console.log(val);
                  }}
                  forwardRef={register({ required: errorMessages.required })}
                  errors={errors}
                />
                {watched && !watched.taxForm && (
                  <input
                    className={formLayout.hidden}
                    type="text"
                    name={`taxForm required`}
                    ref={register({ required: true })}
                  ></input>
                )}
              </div>
              <div className={formLayout.half}>
                <Select
                  control={control}
                  name={"uploadFinancials"}
                  defaultValue=""
                  label="We choose to upload our financials:"
                  options={[
                    { label: "Now", value: "0" },
                    { label: "If we become a Finalist", value: "1" },
                  ]}
                  onChange={(val) => {
                    console.log(val);
                  }}
                  forwardRef={register({ required: errorMessages.required })}
                  errors={errors}
                />
                {watched && !watched.uploadFinancials && (
                  <input
                    className={formLayout.hidden}
                    type="text"
                    name={`uploadFinancials required`}
                    ref={register({ required: true })}
                  ></input>
                )}
              </div>
              {watched.uploadFinancials && watched.uploadFinancials === "0" && (
                <>
                  <label className={styles.label}>
                    Financials Upload (PDF)
                  </label>
                  <Document
                    heading={""}
                    name={"financialsUpload"}
                    errors={errors}
                    register={register({
                      required: errorMessages.required,
                      validate: (value) => {
                        return value !== "" || "You must add an document";
                      },
                    })}
                    extensions={[".pdf"]}
                    required={{
                      message: getInstructions(["document"]),
                      validate: (blocks) =>
                        blocks && blocks.length > 0 && blocks[0].value,
                    }}
                  />

                  {/* <input
                    className={formLayout.hidden}
                    type="text"
                    name={`financialsUrl`}
                    ref={register()}
                    readOnly
                  ></input> */}
                </>
              )}
            </div>
          }
        />
      </Section>
      <Section heading={`Section 3: Inspired Idea`}>
        <Subsection
          fields={
            <div className={styles.copy}>
              <p>
                Filter and read through{" "}
                <Link to={`/stories`} target="_blank" rel="noopener noreferrer">
                  Generator Stories
                </Link>{" "}
                that are relevant to what you do or what you want to do. Think
                about what teens are sharing and how it inspires an idea that
                you have as a provider. We are living in uncharted times so we
                encourage you to experiment and define your own success.
                Generator Z is looking for actionable ideas that are clearly
                inspired by Generators and can be implemented in one-year.
              </p>{" "}
              <p>
                Each prompt in this application has a maximum word count. We
                invite you to supplement your written application with optional
                images, audio, or video.
              </p>
            </div>
          }
          tip={
            <>
              <p>
                <strong>Make it understandable:</strong>{" "}
                <em>Teens are your most important audience.</em> They will read
                your application and share their feedback. Speak in a language
                that meets them where they are.
              </p>
            </>
          }
        />
        <Subsection
          tip={
            <>
              <p>
                <strong>Be courageous:</strong>{" "}
                <em>Be your best organizational self.</em> You are the ones to
                define success. Your actionable idea should reinforce your
                passion for serving and supporting young people now and in the
                future.
              </p>
            </>
          }
          fields={
            <>
              <label className={styles.label}>
                What do you do as an afterschool provider?
              </label>
              <TextArea
                name="whatWeDo"
                placeholder="Start typing here"
                forwardRef={register(reg.text1800)}
                minLength={500}
                maxLength={1800}
                errors={errors}
                required={{
                  message: "TEXT IS REQUIRED, MINIMUM 500 CHARACTERS*",
                }}
              />
            </>
          }
        />
        <Subsection
          tip={
            <>
              <p>
                <strong>Be experimental:</strong> Try something without
                restriction. New models, prototypes, pilots, and policies are
                encouraged to meet the demands of teens and a changing world.
                Formulate an approach without fear of penalty or failure.
              </p>
            </>
          }
          fields={
            <>
              <label className={styles.label}>
                What is your Generator Z afterschool idea?
              </label>
              <TextArea
                name="ourIdea"
                placeholder="Start typing here"
                forwardRef={register(reg.text3000)}
                minLength={1000}
                maxLength={3000}
                errors={errors}
                required={{
                  message: "TEXT IS REQUIRED, MINIMUM 1000 CHARACTERS*",
                }}
              />
            </>
          }
        />
        <Subsection
          tip={
            <>
              <p>
                Walk us through how Generators influenced you. Did they surprise
                you, reinforce a thought, teach you something new, shock you,
                make you proud? How did their experience, insight, and advice
                influence your proposed idea?{" "}
                <strong>Be specific and give examples.</strong>
              </p>
            </>
          }
          fields={
            <>
              <label className={styles.label}>
                How did Generators inspire your idea?
              </label>
              <TextArea
                name="inspiration"
                placeholder="Start typing here"
                forwardRef={register(reg.text3000)}
                minLength={1000}
                maxLength={3000}
                errors={errors}
                required={{
                  message: "TEXT IS REQUIRED, MINIMUM 1000 CHARACTERS*",
                }}
              />
              <label className={styles.label}>
                Who are the Generators that influenced you?
              </label>
              <p>Identify five minimum (or more!)</p>
              <StoryLookup
                name="generatorsList"
                forwardRef={register({
                  required: errorMessages.required,
                  validate: (value) => {
                    return (
                      JSON.parse(value).length > 4 ||
                      "You must add at least 5 stories"
                    );
                  },
                })}
                errors={errors}
              />
              {watched && watched.generatorsList && (
                <>
                  {Array.from(Array(10).keys()).map((el, index) => (
                    <input
                      key={`empty-${index}`}
                      type="text"
                      name={`storyLink${index + 1}`}
                      readOnly
                      value="no entry"
                      ref={register()}
                      className={styles.hidden}
                    />
                  ))}
                  {JSON.parse(watched.generatorsList).map((story, index) => (
                    <input
                      key={`genLink-${index}`}
                      type="text"
                      ref={register()}
                      readOnly
                      name={`storyLink${index + 1}`}
                      value={`https://www.generator-z.org/stories/${story["slug"]}`}
                      className={styles.hidden}
                    />
                  ))}
                </>
              )}
            </>
          }
        />
        <Subsection
          fields={
            <>
              <label className={styles.label}>
                Our idea best connects to this teen-driven afterschool theme
              </label>
              <RadioIconSet
                name="afterschoolTheme"
                current={watched.afterschoolTheme}
                options={themeData}
                generateRef={() =>
                  register({ required: errorMessages.required })
                }
                errors={errors}
              />
            </>
          }
        />
        <Subsection
          tip={
            <>
              <p>
                <strong>Make it relevant:</strong>{" "}
                <em>Centralize young people at this time.</em> Generators are
                sharing their insight on their lives and afterschool dreams
                during immense uncertainty and change. Place them and the world
                they are navigating front and center.
              </p>
            </>
          }
          fields={
            <>
              <label className={styles.label}>
                Why does this idea matter to you?
              </label>
              <TextArea
                name="ideaMatters"
                placeholder="Start typing here"
                forwardRef={register(reg.text1800)}
                minLength={500}
                maxLength={1800}
                errors={errors}
                required={{
                  message: "TEXT IS REQUIRED, MINIMUM 500 CHARACTERS*",
                }}
              />
            </>
          }
        />
        <Subsection
          tip={
            <>
              <p>
                <strong>Be collaborative:</strong> <em>Work with teens.</em> You
                hold the most meaningful relationship with young people. Tell us
                how you will work with teens that you know to make your proposed
                idea come to life.
              </p>
            </>
          }
          fields={
            <>
              <label className={styles.label}>
                How will the teens you serve be involved with implementing this
                idea?
              </label>
              <TextArea
                name="teensInvolved"
                placeholder="Start typing here"
                forwardRef={register(reg.text3000)}
                minLength={1000}
                maxLength={3000}
                errors={errors}
                required={{
                  message: "TEXT IS REQUIRED, MINIMUM 1000 CHARACTERS*",
                }}
              />
            </>
          }
        />
        <Subsection
          fields={
            <>
              <label className={styles.label}>
                If your idea is selected for a grant, how do you define success?
              </label>
              <TextArea
                name="defineSuccess"
                placeholder="Start typing here"
                forwardRef={register(reg.text3000)}
                minLength={1000}
                maxLength={3000}
                errors={errors}
                required={{
                  message: "TEXT IS REQUIRED, MINIMUM 1000 CHARACTERS*",
                }}
              />
              {/*<label>Title your idea.</label>
               <TextArea
                name="ideaTitle"
                placeholder="Start typing here"
                forwardRef={register(reg.text)}
                minLength={3}
                maxLength={30}
                errors={errors}
                required={{
                  message: "TEXT IS REQUIRED, 30 CHARACTERS*",
                }}
              /> */}
            </>
          }
        />
        <Subsection
          fields={
            <>
              <div className={formLayout.full}>
                <Text
                  className={styles.text}
                  name={"ideaTitle"}
                  label="Title your idea."
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />
                <label className={styles.label}>
                  Provide a headline for your idea.{" "}
                </label>
                <TextArea
                  name="ideaHeadline"
                  placeholder="Start typing here"
                  forwardRef={register({
                    required: errorMessages.required,
                    minLength: {
                      value: 10,
                      message: errorMessages.minLength(10),
                    },
                  })}
                  minLength={10}
                  maxLength={185}
                  errors={errors}
                  required={{
                    message: "TEXT IS REQUIRED, MINIMUM 10 CHARACTERS*",
                  }}
                />
              </div>
            </>
          }
        />
        <Subsection
          fields={
            <>
              <label className={styles.label}>
                How do you plan to implement your idea within one year?
              </label>
              <TextArea
                name="implementationPlan"
                placeholder="Start typing here"
                forwardRef={register(reg.text1800)}
                minLength={500}
                maxLength={1800}
                errors={errors}
                required={{
                  message: "TEXT IS REQUIRED, MINIMUM 500 CHARACTERS*",
                }}
              />
            </>
          }
        />
        <Subsection
          tip={
            <>
              <p>
                Grant awards will range from $10,000 to $100,000. You may apply
                with an idea that is <strong>no more than half</strong> of your
                2019 organizational budget. Visit the Program Guidelines for
                full details.{" "}
              </p>
              <p>
                <strong>Make it fundable:</strong>{" "}
                <em>Bigger isn’t always better.</em> A $10,000 idea is just as
                exciting as a $100,000 idea. Impact comes in all shapes and
                sizes. Make sure that your idea can happen in twelve months.{" "}
              </p>
            </>
          }
          fields={
            <>
              <div className={formLayout.half}>
                <Text
                  className={styles.text}
                  name={"cost"}
                  label="How much will your idea cost?"
                  type="number"
                  pattern="\d*"
                  min="0"
                  prefix="$"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />
              </div>
            </>
          }
        />
        <Subsection
          fields={
            <>
              <h4>How will you budget the money?</h4>
              <TextArea
                name="budgeting"
                placeholder="Start typing here"
                forwardRef={register(reg.text1800)}
                minLength={500}
                maxLength={1800}
                errors={errors}
                required={{
                  message: "TEXT IS REQUIRED, MINIMUM 500 CHARACTERS*",
                }}
              />
              <label className={styles.label}>Upload your budget:</label>
              <h5>(Optional)</h5>
              <Document
                name={"budgetUpload"}
                errors={errors}
                register={register()}
                required={""}
                extensions={[".xls", ".xlsx", ".pdf", ".doc", ".docx"]}
                heading=""
              />
            </>
          }
        />
        <Subsection
          fields={
            <>
              <label className={styles.label}>
                Sum up the work you do and/or your inspired idea with an image:
              </label>
              <h5>Image Upload (3 MAX)</h5>
              <Images
                name={"images"}
                heading={`Add Image`}
                errors={errors}
                register={register}
              />
            </>
          }
        />
        <Subsection
          fields={
            <>
              <label className={styles.label}>
                Bring your idea to life by sharing more:
              </label>
              <h5>Optional (90 seconds or less)</h5>
              <Media name={"shareMore"} errors={errors} register={register} />
            </>
          }
        ></Subsection>
      </Section>
      {!status || !status.LOADING ? (
        <button className={formLayout.submit} type="submit">
          Submit
        </button>
      ) : (
        "Submitting"
      )}
    </form>
  );
};

// STUB create component from stories form

// const OneImage = ({ name, register, errors }) => {
//   const types = ["image"];
//   return (
//     <MediaBuilder
//       forwardRef={register({
//         required: errorMessages.required,
//         validate: (value) => {
//           return JSON.parse(value).length > 0 || "You must add an image";
//         },
//       })}
//       name={name}
//       errors={errors}
//       types={types}
//       heading={"Add Image"}
//       limit={1}
//       required={{
//         message: getInstructions(types),
//         validate: (blocks) => blocks && blocks.length > 0 && blocks[0].value,
//       }}
//     />
//   );
// };

export const Document = ({
  types = ["document"],
  extensions,
  errors,
  heading,
  register,
  required,
  ...props
}) => {
  return (
    <MediaBuilder
      {...props}
      heading={heading}
      limit={1}
      name={props.name}
      forwardRef={register}
      required={required}
      urlOnly={true}
      types={types}
      extensions={extensions}
      errors={errors}
    />
  );
};
const Images = ({ types = ["image"], ...props }) => {
  return (
    <MediaBuilder
      {...props}
      limit={3}
      required={{
        message: getInstructions(types),
        validate: (blocks) => {
          return ValidateMedia(blocks, types) === true;
        },
      }}
      forwardRef={props.register({
        required: errorMessages.required,
        validate: (value) => {
          const blocks = JSON.parse(value);
          return ValidateMedia(blocks, types);
        },
      })}
      types={types}
    />
  );
};
const Media = ({ types = ["video", "audio"], ...props }) => {
  return (
    <MediaBuilder
      {...props}
      limit={3}
      required={{
        message: getInstructions(types),
        validate: (blocks) => {
          return ValidateMedia(blocks, types) === true;
        },
      }}
      forwardRef={props.register({
        required: false,
        validate: (value) => {
          if (value === "") return false;
          const blocks = JSON.parse(value);
          return ValidateMedia(blocks, types, true);
        },
      })}
      types={types}
    />
  );
};

function getInstructions(types) {
  const typeHandles = getTypeHandles(types);
  const audio = typeHandles.includes("audio");
  const video = typeHandles.includes("video");
  const text = typeHandles.includes("text");
  const image = typeHandles.includes("image");
  const doc = typeHandles.includes("document");
  const textSettings = types.find(({ type: findType }) => findType === "text");

  if (image && typeHandles.length === 1) {
    return "One image required";
  }
  if (doc && typeHandles.length === 1) {
    return "One document required";
  }
  return `${
    typeHandles.length > 1
      ? "Choose the media that best suits your answer. Only one form of media is required. "
      : "One file required"
  }
  ${
    text
      ? `Text should be no more than ${
          textSettings?.wordCount || 300
        } words / ${(
          textSettings?.maxLength || 1700
        ).toLocaleString()} characters.`
      : ""
  }
  ${
    audio || video
      ? `${
          audio && video ? " Audio or video" : audio ? " Audio" : " Video"
        } should be no more than 90 seconds`
      : ""
  }`;
}
export function getTypeHandles(types) {
  return types.reduce((r, d) => {
    if (typeof d === "string") {
      r.push(d);
    } else if (typeof d === "object" && d.type) {
      r.push(d.type);
    } else {
      throw new Error(`Invalid MediaBuilder types configuration`);
    }
    return r;
  }, []);
}

const NotEligible = () => {
  return (
    <Section heading={"Section 1: Eligibility"}>
      <Subsection
        fields={
          <>
            <h3 className={styles.title}>You are not you eligible.</h3>

            <p className={styles.copy}>
              You answered 'no' to one or more of the eligibility questions and
              are, therefore, ineligible to apply to Generator Z. If you have
              questions or concerns, please visit our Program Guidelines and
              Frequently Asked Questions. If you cannot find clarity there, you
              may email us at{" "}
              <Link to="mailto:info@generator-z.org">info@generator-z.org</Link>
              .
            </p>
          </>
        }
      />
    </Section>
  );
};
