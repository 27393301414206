import React, { useEffect } from "react";
import Theme from "./theme";
import styles from "./themes.module.scss";
import useMeasure from "hooks/useMeasure";

const ThemeRow = ({
  themes,
  active,
  setActive,
  animate = true,
  maxHeight,
  setMaxHeight,
}) => {
  const activeIndex = themes.map(({ id }) => id).indexOf(active);
  const rowHasActive = activeIndex !== -1;
  const [ref, bounds] = useMeasure();

  useEffect(() => {
    if (setMaxHeight) {
      setMaxHeight(bounds.height);
    }
  }, [setMaxHeight, bounds.height]);

  return (
    <div
      className={animate ? styles.themeRowAnimated : styles.themeRow}
      ref={ref}
      style={{ maxHeight: animate ? maxHeight : "auto" }}
    >
      {themes.map((theme) => (
        <Theme
          key={theme.id}
          {...theme}
          collapsed={rowHasActive && active !== theme.id}
          active={active === theme.id}
          onClick={() => setActive(active === theme.id ? null : theme.id)}
        />
      ))}
    </div>
  );
};

export default ThemeRow;
