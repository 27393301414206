import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import styles from "./radio-set.module.scss";

const RadioSet = ({ name, options, generateRef, errors, layout }) => {
  console.log(errors);
  if (layout === "line") {
    return (
      <>
        <fieldset className={layout ? styles[layout] : styles.element}>
          {options.map((option, i) => {
            return (
              <label key={`option-${i}`} className={styles.label}>
                <input
                  className={styles.input}
                  name={name}
                  value={option.value ? option.value : i}
                  type="radio"
                  tabIndex={0}
                  ref={generateRef()}
                  // required={required && i === 0}
                />
                <div>{option.label ? option.label : option}</div>
              </label>
            );
          })}
        </fieldset>
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <div className="error">{message}</div>}
        />
      </>
    );
  }
  return (
    <fieldset className={layout ? styles[layout] : styles.element}>
      {options.map((option, i) => (
        <label key={`option-${i}`} className={styles.label}>
          <input
            className={styles.input}
            name={name}
            value={option.value ? option.value : i}
            type="radio"
            tabIndex={0}
            ref={generateRef()}
          />
          <span className={`${styles.radio} ui`} />
          {option.label ? option.label : option}
        </label>
      ))}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="error">{message}</div>}
      />
    </fieldset>
  );
};

export default RadioSet;
