const questions = {
  which: {
    key: "slug",
    question: "Select your afterschool idea",
    show: false,
  },
  isItComplete: {
    key: "Is It Complete",
    question: "Is your afterschool idea complete?",
    show: false,
  },
  completion: {
    key: "Completion Level",
    question:
      "What percentage of your afterschool idea is complete? (Drag 0% to 100%)",
    show: false,
  },
  whatHappened: {
    key: "What Happened",
    question:
      "We recognize that things happen and the last year has been tough for many organizations. Explain why your idea is not fully implemented and detail your path to completion.",
  },
  contactName: {
    key: "Contact Name",
    question: "Name",
    show: false,
  },
  contactRole: {
    key: "Contact Role",
    question: "Role",
    show: false,
  },
  contactEmail: {
    key: "Contact Email",
    question: "Email",
    show: false,
  },
  contactPhone: {
    key: "Contact Phone",
    question: "Phone",
    show: false,
  },
  moment: {
    key: "Impactful Moment",
    question:
      "Share an impactful moment during this last year of implementation. This may encompass a joy, an obstacle, an expectation, or a surprise - keep it real.",
  },
  how: {
    key: "How Did You Work With Teens",
    question: "Tell us how you worked with teens to bring your idea to life. ",
  },
  quote: {
    key: "Teen Experience Quote",
    question:
      "Select a teen that worked alongside you to bring your idea to life. Ask them to share their final thoughts about the experience.",
    type: "quote",
  },
  quoteAttribution: {
    question: "Whose words are these?",
    type: "quoteAttribution",
  },
  quoteAttributionFirst: {
    key: "Quote Attribution First Name",
    question: "First Name",
    type: "quoteAttribution",
  },
  quoteAttributionLast: {
    key: "Quote Attribution Last Initial",
    question: "Last Initial",
    type: "quoteAttribution",
  },
  quoteAttributionAge: {
    key: "Quote Attribution Age",
    question: "Age",
    type: "quoteAttribution",
  },
  influence: {
    key: "GZ Influence",
    question:
      "How will Generator Z influence your work with teens moving forward?",
  },
  advice: {
    key: "Funder Advice",
    question:
      "What advice do you have to those funding afterschool opportunities?",
  },
  progress: {
    key: "Progress Images",
    question: "Attach any images of your progress.",
    type: "image",
  },
  progressVideo: {
    key: "Progress Video",
    question: "Attach an optional 90 seconds or less video.",
    type: "video",
  },
  future: {
    key: "Program Future",
    question:
      "Will the programming shaped by Generator Z continue in the future?",
    show: false,
  },
  generatorParticipation: {
    key: "Generator Participation",
    question: (
      <>
        Did any new teens, Generators or non-Generators, participate in
        programming because of Generator Z?
      </>
    ),
    show: false,
  },
  generatorParticipationDetails: {
    key: "Generator Participation Details",
    question: "If applicable, please share additional details.",
    show: false,
  },
  learning: {
    key: "Staff Learnings",
    question:
      "What are you and your staff learning through your participation in Generator Z?",
    show: false,
  },
  changes: {
    key: "Org Change",
    question: "Has your organization changed because of Generator Z? Explain",
    show: false,
  },
  experienceRating: {
    key: "Experience Rating",
    question:
      "How would you rate your Generator Z experience so far? (1 is terrible; 10 is awesome)",
    show: false,
  },
};

export default questions;
