import React, { useState, useEffect, useContext, useRef } from "react";
import ColumnLayout from "layouts/columns";
import axios from "axios";
import Comments from "components/comments";
import Social from "./social";
import SVG from "react-inlinesvg";
import themeData from "blocks/themes/data";
import { StoryTileLoad } from "components/tile";
import PostsNav from "components/posts-nav";
import ThemeContext, { ThemeProvider } from "components/teaser/theme-context";
import Accordion from "components/accordion/";
import { OutboundLink } from "react-ga";
import styles from "./provider.module.scss";
import Media from "components/stories/story/media";
import story from "components/stories/story/story.module.scss";
import MoreProviders from "./more-providers";
import YouTube from "react-youtube";
import { normalizeURL, noProtocol } from "helpers";
import quote from "./GZ_quote.svg";
import { useParams } from "react-router";
import ResizeObserver from "resize-observer-polyfill";

import { themeColors } from "../theme-colors";
import Badge from "../badge";
import SixMonth from "../../provider-six-month-progress/six-month/six-month";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Completion from "../../provider-six-month-progress/completion";

const LAUNCH_SIXMONTH = true;
const LAUNCH_COMPLETION = false;

function Provider({ match }) {
    const slug = match.params.slug;
    const { loading, error, data } = usePost(slug);

    let theme = "pink";
    let fields = null;

    if (loading) return <>loading</>;
    if (error) {
        return <>Page not found</>;
    }
    if (data) {
        fields = data.fields;
        const afterschoolTheme = fields["Theme"].trim();

        theme = themeColors.filter(({ theme }) => theme === afterschoolTheme)[0]
            ?.colorScheme;

        if (!fields) {
            return <>Not Found</>;
        }
    }

    return (
        <ThemeProvider value={theme}>
            {data && (
                <>
                    <ProviderInner fields={fields} theme={theme} id={data.id} />
                </>
            )}
        </ThemeProvider>
    );
}
const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
};
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const ProviderInner = ({ fields, theme, id }) => {
    const { setTheme } = useContext(ThemeContext);
    useEffect(() => {
        setTheme(theme);
    }, [theme, setTheme]);
    const [websiteBreakPoint, setWebsiteBreakPoint] = useState();

    useEffect(() => {
        const media = window.matchMedia(`(min-width: 1800px)`);
        media.onchange = onChange;
        onChange();
        function onChange() {
            setWebsiteBreakPoint(!media.matches);
        }
    }, []);

    const themeIcon = themeData.filter(
        ({ id }) => id === fields["Theme"].trim()
    )[0];

    const paragraphs = (text) => {
        return text
            .split("  ")
            .map((para, index) => <p key={`p-${index}`}>{para}</p>);
    };

    const accordionContent = [
        {
            heading: "Why does this idea matter to you?",
            text: <p>{fields["Importance"]}</p>,
        },
        {
            heading:
                "How will the teens you serve be involved with implementing this idea?",
            text: <p>{fields["Implementation"]}</p>,
        },
        {
            heading: "More about this afterschool provider",
            text: (
                <p dangerouslySetInnerHTML={{ __html: fields["About Provider"] }} />
            ),
        },
    ];

    const [currentAnchor, setCurrentAnchor] = useState("top");
    const params = useParams();
    const { data: sixMonthData, error: sixMonthError } = useData(
        params.slug,
        "six-month"
    );
    const { data: completionData, error: completionError } = useData(
        params.slug,
        "completion"
    );

    return (
        <ColumnLayout
            initTheme={theme}
            overflowFix
            railContent={
                <>
                    <PostsNav sect="providers" recId={id} theme={theme} />
                </>
            }
        >
            <div className={styles.element}>
                <Top
                    fields={fields}
                    themeIcon={themeIcon}
                    theme={theme}
                    sixMonthData={sixMonthData}
                    completionData={completionData}
                    websiteBreakPoint={websiteBreakPoint}
                    currentAnchor={currentAnchor}
                    setCurrentAnchor={setCurrentAnchor}
                />
                <AfterschoolIdea
                    setCurrentAnchor={setCurrentAnchor}
                    anchorId={"idea"}
                    fields={fields}
                    paragraphs={paragraphs}
                    accordionContent={accordionContent}
                />
                <CommentsSection
                    setCurrentAnchor={setCurrentAnchor}
                    anchorId={"ideafeedback"}
                    commentsId={fields["Application ID"]}
                    collapse
                    closeComments
                />
                {sixMonthData && !sixMonthError && Object.keys(sixMonthData).length && (
                    <>
                        <SixMonth
                            {...sixMonthData}
                            ideaData={fields}
                            setCurrentAnchor={setCurrentAnchor}
                        />
                        <CommentsSection
                            setCurrentAnchor={setCurrentAnchor}
                            anchorId="sixmonthfeedback"
                            commentsId={`${fields["Application ID"]}-6month`}
                        />
                    </>
                )}
                {completionData &&
                    !completionError &&
                    Object.keys(completionData).length &&
                    LAUNCH_COMPLETION && (
                        <>
                            <Completion
                                {...completionData}
                                setCurrentAnchor={setCurrentAnchor}
                            />
                        </>
                    )}
                <MoreProviders currentId={id} themeColor={theme} />
            </div>
        </ColumnLayout>
    );
};

export default Provider;

const Top = ({
    currentAnchor,
    setCurrentAnchor,
    fields,
    themeIcon,
    theme,
    sixMonthData,
    completionData,
    websiteBreakPoint,
}) => {
    const ref = useAnchorTrigger({ setCurrentAnchor, anchorId: "top" });

    return (
        <section id={"top"} ref={ref}>
            <div className={`${story.heading} ${styles.heading}`}>
                <div>
                    <div className={`mobile--only ${styles.iconsBadges}`}>
                        <div className={` ${styles.themeIcon}`}>
                            <SVG src={themeIcon.icon} className={`story--accent`} />
                        </div>
                        {fields["Grantees"] && (
                            <div className={styles.mobileBadge}>
                                <Badge color={theme} />
                            </div>
                        )}
                    </div>
                    <h2>{fields["Title"]}</h2>

                    <h3>{fields["Headline"]}</h3>
                    <div className={"desktop--only"}>
                        {fields["Grantees"] && <Badge color={theme} />}
                    </div>

                    <div className={styles.headerStats}>
                        {fields["Grantees"] && (
                            <div className={styles.grantAmt}>
                                <strong>Grant Amount:</strong>
                                <div className={styles.cost}>
                                    ${numberWithCommas(fields["Cost"])}
                                </div>
                            </div>
                        )}
                        {completionData &&
                            (typeof completionData["Completion Level"] !== "undefined" ||
                                completionData["Is It Complete"]) && (
                                <div className={styles.grantAmt}>
                                    <strong>Percent Complete:</strong>
                                    <div className={styles.cost}>
                                        {completionData["Is It Complete"] === "yes"
                                            ? "100"
                                            : completionData["Completion Level"]}
                                        %
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
                <div></div>
                <div className={`${story.shape} story--accent ${styles.shape}`}>
                    <AnchorNav
                        title={fields["Title"]}
                        currentAnchor={currentAnchor}
                        sixMonthData={sixMonthData}
                        completionData={completionData}
                        theme={theme}
                    />
                    <SVG src={themeIcon.icon} className="story--accent" />
                </div>
            </div>
            <div className={styles.mobileAnchor}>
                <AnchorNav
                    noTitle
                    title={fields["Title"]}
                    currentAnchor={currentAnchor}
                    sixMonthData={sixMonthData}
                    completionData={completionData}
                    theme={theme}
                />
            </div>
            <div className={styles.org}>
                <div className={styles.orgInner}>
                    <div className={styles.summary}>
                        <span>{fields["Organization"]}</span> is an organization in{" "}
                        {fields["Region"]} focusing on <span>{fields["Theme"]}</span> as an
                        afterschool theme.
                    </div>

                    <div className={styles.contact}>
                        {fields["Website"] && (
                            <li className={styles.url}>
                                <OutboundLink
                                    to={normalizeURL(fields["Website"])}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    eventLabel="link"
                                >
                                    {websiteBreakPoint
                                        ? "Website"
                                        : noProtocol(fields["Website"])}
                                </OutboundLink>
                            </li>
                        )}
                        {fields["Phone Number"] && (
                            <li className={styles.phone}>
                                <OutboundLink
                                    to={`tel:${fields["Phone Number"]}`}
                                    eventLabel="phone_number"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {formatPhoneNumber(fields["Phone Number"])}
                                </OutboundLink>
                            </li>
                        )}
                    </div>
                    <div className={styles.adress}>
                        {fields["County"] && <div>{fields["County"]} County</div>}
                        {fields["Address"] && <div>{fields["Address"]}</div>}
                        {fields["City"] && (
                            <div>
                                {fields["City"]},
                                {fields["Region"].substring(fields["Region"].indexOf(" "))}{" "}
                                {fields["Zip Code"]}
                            </div>
                        )}
                    </div>

                    <div className={styles.social}>
                        <Social fields={fields}></Social>
                    </div>
                </div>
            </div>
            <div className={`${styles.section}`}>
                <Media blocks={JSON.parse(fields["Your Story Image"])} slider={true} />
            </div>
            {JSON.parse(fields["Bring your idea to life by sharing more:"]).length >
                0 && (
                    <div className={`${styles.section}`}>
                        <Media
                            blocks={JSON.parse(
                                fields["Bring your idea to life by sharing more:"]
                            )}
                        />
                    </div>
                )}
            {fields["YouTube"] && (
                <div className={styles.section}>
                    <div className={styles.youtube}>
                        <YouTube videoId={fields["YouTube"]} />
                    </div>
                </div>
            )}
        </section>
    );
};

const AfterschoolIdea = ({
    paragraphs,
    fields,
    accordionContent,
    setCurrentAnchor,
}) => {
    const ref = useAnchorTrigger({ setCurrentAnchor, anchorId: "idea" });
    return (
        <section ref={ref} id={"idea"}>
            <div className={`${styles.section} bg--accent`}>
                <div className={`${styles.intro} bg--accent`}>
                    <SVG
                        src={quote}
                        className={`${styles.quote} ${styles.startquote}  svg--bg-color`}
                    />
                    <SVG
                        src={quote}
                        className={`${styles.quote} ${styles.endquote}  svg--bg-color`}
                    />
                    <h3 className={styles.h3}>
                        What is your Generator Z afterschool idea?
                    </h3>
                    <div className={styles.pullquote}>{fields["Idea Summary"]}</div>
                </div>
            </div>
            <div className={styles.section}>
                <h2>
                    <span role="img" aria-label="Idea">
                        💡
                    </span>{" "}
                    Afterschool Idea
                </h2>
                {paragraphs(fields["What is your Generator Z afterschool idea?"])}
            </div>
            <div className={styles.section}>
                <h3 className={styles.h3}>How did Generators inspire your idea?</h3>
                {paragraphs(fields["How did Generators inspire your idea?"])}
            </div>
            <div className={styles.section}>
                <h3 className={styles.h3}>
                    Who are the Generators that influenced you?
                </h3>
                <div className={styles.inspiration}>
                    <ul>
                        {JSON.parse(fields["Inspired by Generators"]).map((story) => {
                            return (
                                <StoryTileLoad id={story.id} slug={story.slug} key={story.id} />
                            );
                        })}
                    </ul>
                </div>
            </div>
            <Accordion rows={accordionContent}></Accordion>
        </section>
    );
};

const AnchorNav = ({
    noTitle,
    currentAnchor,
    title,
    sixMonthData,
    completionData,
    theme,
}) => {
    return (
        <>
            {noTitle && <p>Navigate to</p>}
            <ul className={`${styles.anchorNav} bg--${theme}`}>
                {!noTitle && (
                    <li>
                        <AnchorLink id={"top"} isCurrent={currentAnchor === "top"}>
                            {title}
                        </AnchorLink>
                    </li>
                )}
                <li>
                    <AnchorLink id={"idea"} isCurrent={currentAnchor === "idea"}>
                        Afterschool Idea
                    </AnchorLink>
                    <AnchorLink
                        id={"ideafeedback"}
                        isCurrent={currentAnchor === "ideafeedback"}
                    >
                        Generator Feedback
                    </AnchorLink>
                </li>
                <li>
                    {sixMonthData && !sixMonthData.error && LAUNCH_SIXMONTH ? (
                        <>
                            <AnchorLink
                                id={"sixmonth"}
                                isCurrent={currentAnchor === "sixmonth"}
                            >
                                Progress at 6 months
                            </AnchorLink>
                            <AnchorLink
                                id={"sixmonthfeedback"}
                                isCurrent={currentAnchor === "sixmonthfeedback"}
                            >
                                Generator Feedback
                            </AnchorLink>
                        </>
                    ) : (
                        <div className={styles.fakeAnchor}>Progress at 6 months</div>
                    )}
                </li>
                {!noTitle && (
                    <li>
                        {completionData && LAUNCH_COMPLETION ? (
                            <AnchorLink
                                id={"completion"}
                                isCurrent={currentAnchor === "completion"}
                            >
                                Completion at 12 months
                            </AnchorLink>
                        ) : (
                            <div className={styles.fakeAnchor}>Completion at 12 months</div>
                        )}
                    </li>
                )}
            </ul>
        </>
    );
};

const AnchorLink = ({ id, isCurrent, children }) => {
    return (
        <a
            href={`#${id}`}
            className={isCurrent ? styles.currentAnchorLink : styles.anchorLink}
        >
            {children}
            {isCurrent ? <>&nbsp;☻</> : <>&nbsp;&nbsp;</>}
        </a>
    );
};

const usePost = (slug, id = null) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        let mounted = true;
        setLoading(true);
        axios
            .get(`/api/provider`, {
                params: { slug, id },
            })
            .then((res, err) => {
                if (mounted) {
                    setData(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log("error", err);
                setLoading(false);
                setError(true);
            });

        return () => {
            mounted = false;
        };
    }, [id, slug]);

    return { data, loading, error };
};

const useData = (slug, endpoint) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    useEffect(() => {
        let mounted = true;
        axios
            .get(`/api/provider/${endpoint}`, {
                params: { slug },
            })
            .then((res) => {
                if (mounted) {
                    setData(res.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                if (mounted) {
                    console.log("error", err);
                    setLoading(false);
                    setError(true);
                }
            });

        return () => (mounted = false);
    }, [slug, endpoint]);

    return { data, loading, error };
};

export const useAnchorTrigger = ({ anchorId, setCurrentAnchor }) => {
    const ref = useRef();
    const t = useRef();

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            //console.log('Body height changed:', entries[0].target.clientHeight)
            t.current && t.current.refresh();
        });

        // start observing a DOM node
        resizeObserver.observe(document.body);
    }, []);

    useEffect(() => {
        t.current = ScrollTrigger.create({
            trigger: ref.current,
            start: "top center",
            end: "bottom center",
            //markers: true,
            onEnter: () => {
                setCurrentAnchor(anchorId);
            },
            onEnterBack: () => {
                setCurrentAnchor(anchorId);
            },
        });
        return () => {
            t.current && t.current.kill();
        };
    }, [anchorId, setCurrentAnchor]);
    return ref;
};

const CommentsSection = ({
    anchorId,
    commentsId,
    setCurrentAnchor,
    collapse,
    closeComments,
}) => {
    const ref = useAnchorTrigger({ setCurrentAnchor, anchorId });
    return (
        <section className={styles.comments} id={anchorId} ref={ref}>
            <Comments
                id={commentsId}
                collapse={collapse}
                closeComments={closeComments}
            ></Comments>
        </section>
    );
};
