import React from "react";
import styles from "./select.module.scss";
import Dropdown from "components/dropdown";
import { ErrorMessage } from "@hookform/error-message";

const Select = ({ className, ...props }) => {
  return (
    <div className={className}>
      <label className={styles.element}>
        <span className={styles.label}>{props.label}</span>
        <Dropdown {...props} className={styles.dropdown} />
        <ErrorMessage
          errors={props.errors}
          name={props.name}
          render={({ message }) => <div className="error">{message}</div>}
        />
      </label>
    </div>
  );
};

export default Select;
