import React, { useEffect, useRef } from "react";
import styles from "./for-teens.module.scss";
import Intro, { Copy } from "components/teaser/intro";
import HowItWorks from "components/teaser/how-it-works";
// import MoneyShapes from "./money-shapes";
// import Convince from "components/teaser/intro/convince";
// import ConvinceShapes from "components/teaser/intro/convince/convince-shapes";
// import people from "./hero-people-2.png";
import people from "./future3.png";
import z from "./hero-z.svg";
// import girl from "./girl.png";
// import convinceZ from "./@z.svg";
import howItWorksShape from "./howitworksshape.svg";
import progressShape from "../how-it-works/progress.svg";
import { Step, ScrollStep } from "../intro";
import Section from "components/section";
import eye from "./Eye_05.gif";
// import eye2 from "./Eye_06.gif";
import { Link } from "react-router-dom";
import introStyles from "components/teaser/intro/intro.module.scss";

import render1 from "./render_1.gif";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
gsap.registerPlugin(ScrollTrigger);


// const Future = ({ theme }) => {
//     return (
//         <Step step={"future"} theme={theme}>
//             <Copy
//                 heading={"A Future Imagined."}
//                 text={
//                     <>
//                         <p>
//                             Generator Z was an ideas lab that launched in 2020 for teens and
//                             afterschool providers to reimagine the future of afterschool in
//                             Southeast Michigan and Western New York. Teens are the experts of
//                             their own lives and afterschool providers are the source of
//                             afterschool opportunities. Together, they drive the brightest
//                             possible future.
//                         </p>
//                         <p>
//                             One thousand teens across both regions became Generators, sharing their stories and insights to generate the change they cared to see. Over 200 afterschool providers learned from Generators, turning insights into ideas. Nearly 100 received grants to bring these ideas to life.
//                         </p>
//                         <p>
//                             Generator Z concluded in 2022, leaving behind a legacy of content and a case study for centering youth voice.
//                         </p>
//                         <div className={introStyles.buttons}>
//                             <div>
//                                 <div className={styles.buttonHeadline}>Read over 1,000 stories.</div>
//                                 <Link className={introStyles.button} to={"/stories"}>
//                                     Generator Stories
//                                 </Link>
//                             </div>
//                             <div>
//                                 <div className={styles.buttonHeadline}>
//                                     Learn from 93 grants.
//                                 </div>
//                                 <Link className={introStyles.button} to={"/afterschool-ideas"}>
//                                     Afterschool Grants
//                                 </Link>
//                             </div>
//                         </div>
//                         <div className={styles.providerSearch}>
//                             <Link to="/provider-directory">
//                                 Find an afterschool provider near you!
//                             </Link>
//                         </div>
//                     </>
//                 }
//             />

//             {/* <img src={eye2} className={styles.gif} alt={"eye"} /> */}
//         </Step>
//     );
// };

const TwentyTwenty = ({ theme }) => {
    return (
        <Step step={"future"} theme={theme}>
            <Copy
                heading="It all started in 2020."
                text={
                    <>
                        <p>
                            Pandemic, politics, protests, natural disasters, injustice, vaccines, variants and more marked the start of the decade with everyone affected, teens included.
                        </p>

                        <p>
                            When schools shut down in the shadow of the pandemic, so did the many places, spaces, and activities surrounding school. This included afterschool, the unique space and time between school and life where you make friends, learn new things, understand yourself better, and explore. With so much uncertainty and no clear answers, Generator Z was born.
                        </p>
                        <div className={introStyles.buttons}>
                            <Link className={introStyles.button} to={"/stories"}>
                                Generator Stories
                            </Link>
                        </div>

                    </>
                }
            />
        </Step>
    );
};

const Different = ({ theme }) => {
    return (
        <ScrollStep
            heading={"A different kind of grantmaking initiative."}
            text={
                <>
                    <p>
                        Grantmaking is one of the ways afterschool providers get the dollars to do their work — but young people are rarely involved. Generator Z asked, “why?” and set out to flip the script.
                    </p>
                    <p>
                        In this model, teens took the lead over traditional grantmakers to ultimately direct millions of dollars in grants in ways that mattered to them. And they earned $1 million for their valuable expertise! These teens, known as Generators, earned $1,000 each for their participation. Their efforts enabled afterschool providers to hear from a diverse range of teens, experiment with new ideas, and receive $10,000 to $100,000 in grant funding for afterschool innovation through a simple and transparent application process. In 2021, over 200 providers with Generator-inspired afterschool ideas were selected as finalists and nearly 100 received grants totaling $4 million.
                    </p>
                    <p>
                        This made Generator Z an unprecedented one of a kind grantmaking initiative.
                    </p>
                </>
            }
            theme={theme}
            gif={eye}
            shapes={false}
        />
    );
};

const BeforeSlides = ({ theme }) => {
    const ref = useRef();
    const collageRef = useRef();
    const zRef = useRef();

    useEffect(() => {
        const tl = gsap.timeline({
            duration: 3,
            scrollTrigger: {
                trigger: ref.current,
                start: "top top",
                end: "bottom center",
                scrub: 2,
                //snap: 1 / 3,
            },
        });
        const collage = gsap.fromTo(
            collageRef.current,
            {
                duration: 1,
                xPercent: 0,
            },
            { xPercent: 100 }
        );

        const z = gsap
            .timeline()
            .to(zRef.current, { rotate: 180 })
            .to(zRef.current, { xPercent: -100 });

        tl.add(collage, 0);
        tl.add(z, 0);

        return () => {
            tl && tl.scrollTrigger.kill();
        };
    }, []);

    return (
        <div ref={ref}>
            <StaticGraphics collageRef={collageRef} zRef={zRef} />
            {/* <Future theme={theme} /> */}
            <TwentyTwenty theme={theme} />
            <Different theme={theme} />
        </div>
    );
};

const howItWorksSteps = [
    {
        key: "intro",
    },
    {
        key: "step1",
        text: "1,000 Generators shared their personal stories and ideas for afterschool.",
        // cta: {
        //   type: "link",
        //   text: "Apply Now",
        //   to: "/apply",
        // },
        number: 1,
    },
    {
        key: "step2",
        text: "Afterschool providers browsed teen stories for inspiration. Generators advised them and offered feedback along the way.",
        // cta: {
        //   type: "link",
        //   text: "Apply Now",
        //   to: "/apply",
        // },
        number: 2,
    },
    {
        key: "step3",
        text: "Ideas turned into reality and Generators received $1,000 over two years.",
        // cta: {
        //   type: "link",
        //   text: "Apply Now",
        //   to: "/apply",
        // },
        number: 3,
    },
];

const ForTeens = () => {
    const theme = "lilac";
    // const history = useHistory();
    return (
        <Section id={"ForTeens"} theme={theme}>
            <Intro
                className={styles.element}
                children={
                    <>
                        <BeforeSlides theme={theme} />

                        <div className={styles.howItWorkedWrap}>
                            <img className={styles.render1} src={render1} alt="" />
                            <HowItWorks
                                heading="Here's how it worked: Teens led the way."
                                buttonText="Generator Stories"
                                buttonLink="/stories"
                                steps={howItWorksSteps}
                                shape={howItWorksShape}
                                progressShape={progressShape}
                                anchorPrefix="ForTeens"
                                backgroundColor={theme}
                            />
                        </div>
                        {/* <Convince
                            heading={<> The Power of Sharing</>}
                            image={{
                                src: girl,
                                alt: "Girl looking very happy",
                            }}
                            z={{
                                src: convinceZ,
                                alt: "The letter Z in the style of an @ symbol",
                            }}
                            button={{
                                onClick: () => {
                                    history.push("/stories");
                                },
                                text: "Generator Stories",
                            }}
                            // button2={{
                            //   onClick: () => {
                            //     history.push("/provider/apply");
                            //   },
                            //   text: "Apply for a Grant",
                            // }}
                            shapes={<ConvinceShapes />}
                        /> */}
                    </>
                }
            />
        </Section>
    );
};

export default ForTeens;

const StaticGraphics = ({ collageRef, zRef }) => {
    return (
        <>
            <div className={styles.z} ref={zRef}>
                <img src={z} alt="Abstract Z shape" />
                {/* <img src={eye} width={400} /> */}
            </div>
            <div className={styles.collage} ref={collageRef}>
                <img
                    src={people}
                    alt="Abstract collage of Gen Z teens and colorful shapes"
                />
            </div>
        </>
    );
};
