import React, { useEffect } from "react";
import styles from "./success.module.scss";
import { ThemeProvider } from "../../teaser/theme-context";
import ColumnLayout from "layouts/columns";
import Header from "components/stories/stories-form/header";

const Success = ({ heading, body }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className={styles.element}>
      <ThemeProvider value={"pale-peach"}>
        <ColumnLayout banner={false}>
          <Header heading={heading} body={body} hideCollage={true} />
        </ColumnLayout>
      </ThemeProvider>
    </div>
  );
};

export default Success;
