import { counties } from "components/stories/counties";

const filterOpts = [
  {
    col: "Grantees",
    heading: "Grantees",
    type: "checkboxes",
    options: [{ label: "2021 Grantee", value: "1" }],
  },
  {
    col: "Completion Level",
    heading: "Completion Level",
    type: "checkboxes",
    options: [
      { label: "0% Complete", value: "0" },
      { label: "1%-25% Complete", value: "25" },
      { label: "26%-50% Complete", value: "50" },
      { label: "51%-75% Complete", value: "75" },
      { label: "76%-100% Complete", value: "100" },
    ],
  },
  {
    col: "Theme",
    heading: "Theme",
    type: "checkboxes",
    options: [
      { value: "Mental, Social & Emotional Health" },
      { value: "Identity & Acceptance" },
      { value: "Growth & Wellness" },
      { value: "Friends & Community" },
      { value: "Excellence in Learning" },
      { value: "Jobs & Careers" },
      { value: "Creative Arts & Culture" },
      { value: "Fun & Games" },
      { value: "Play & Sports" },
      { value: "Life Skills & Balance" },
    ],
  },
  {
    col: "Region",
    heading: "Region",
    type: "checkboxes",
    options: [
      { value: "Michigan", label: "Southeast Michigan" },
      { value: "New York", label: "Western New York" },
    ],
  },
  {
    col: "CountyRegion",
    heading: "Michigan Counties",
    type: "checkboxes",
    options: counties["Michigan"].map((val) => {
      return {
        label: val.name,
        value: val.name + "-" + val.state,
        stateCondition: val.state,
      };
    }),
  },
  {
    col: "CountyRegion",
    heading: "New York Counties",
    type: "checkboxes",
    options: counties["New York"].map((val) => {
      return {
        label: val.name,
        value: val.name + "-" + val.state,
        stateCondition: val.state,
      };
    }),
  },
  {
    col: "Type",
    heading: "Type of Provider",
    type: "checkboxes",
    options: [
      {
        label: "Local Organization",
        value: "an existing independent local organization",
      },
      {
        label: "National Organization",
        value: "an existing local branch of a national organization ",
      },
      {
        label: "School or Higher Ed",
        value: "an existing school (public or non-profit)",
      },
      {
        label: "Government",
        value: "an existing program housed in local government ",
      },
    ],
  },
  {
    col: "Cost",
    heading: "Cost of Idea",
    type: "checkboxes",
    options: [
      { label: "$10,000 - $24,999", value: "[10000,24999]" },
      { label: "$25,000 - $49,999", value: "[25000,49999]" },
      { label: "$50,000 - $74,999", value: "[50000,74999]" },
      { label: "$75,000 - $100,000", value: "[75000,100000]" },
    ],
  },
  {
    col: "Search",
    heading: "Name of Provider",
    type: "text",
  },
];

export default filterOpts;
