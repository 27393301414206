import React from "react";
import TransformedVideo from "components/stories/transformed-video";
import { FilestackUpload } from "components/form/filestack-upload";
import styles from "./video-upload.module.scss";
import Upload from "components/form/upload";

const VideoUpload = ({ ...props }) => {
  return (
    <Upload
      media={
        <div className={styles.video}>
          <FilestackUpload
            {...props}
            accept={["video/*"]}
            Preview={TransformedVideo}
            buttonText={"Add Video"}
          />
        </div>
      }
      filename={props.value && props.value.filename}
    ></Upload>
  );
};

export default VideoUpload;
