import React, { useState } from "react";
import ColumnLayout from "layouts/columns";
import Header from "components/stories/stories-form/header";
import UserLookup, { useUserLookup } from "components/user-lookup";
import ProviderLookup from "components/provider-lookup";
import Select from "components/form/select";
import TextArea from "components/form/textarea";
import { useForm } from "react-hook-form";
// import useFormPersist from "react-hook-form-persist";
import errorMessages from "components/form/errorMessages";
import CheckboxSet from "components/form/checkbox-set";
import { ThemeProvider } from "components/teaser/theme-context";
import styles from "./confirmation-form.module.scss";
import formLayout from "components/form/form-layout.module.scss";
import Text from "components/form/text";
import Subsection from "components/stories/stories-form/subsection";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { Redirect } from "react-router-dom";

import useStory from "components/stories/story/useStory";

const qs = require("qs");

const statuses = {
  SUCCESS: "success",
  LOADING: "loading",
  ERROR: "error",
};
const workshopDates = `Tuesday, January 18 from 5 - 6 PM
  Thursday, January 20 from 5 - 6 PM
  Friday, January 21 from 5 - 6 PM
  Saturday, January 22 from 4 - 5 PM
  Monday, January 24 from 4 - 5 PM
  Friday, January 28 from 5 - 6 PM
  Wednesday, February 2 from 4 - 5 PM
  Friday, February 4 from 5 - 6 PM`.split("\n");

const ConfirmationForm = ({ location }) => {
  const [status, setStatus] = useState(null);

  const querystring = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    encodeValuesOnly: true,
  });
  const email = querystring.email;
  const { loading, error, data } = useStory(null, null, email);

  if (status === statuses.SUCCESS) {
    return <Redirect to="/generator-confirmed-2" />;
  }
  if (loading) return <>Loading..</>;
  if (error) return <>Error loading page. </>;

  return (
    <ThemeProvider value={`peach`}>
      <ColumnLayout>
        <Header
          heading={<>Generator Confirmation Page</>}
          body={
            <>
              <h3>You MUST be a Generator to participate and get paid.</h3>
            </>
          }
        />
        {status === statuses.SUCCESS ? (
          <>Sending</>
        ) : (
          <>
            {email && data?.error ? (
              <>Generator not found with email: {email}</>
            ) : (
              <Form
                defaultValues={{ Email: email }}
                setStatus={setStatus}
                status={status}
                user={data?.fields}
                hasEmail={email}
              ></Form>
            )}
          </>
        )}
      </ColumnLayout>
    </ThemeProvider>
  );
};

const Form = ({ status, setStatus, defaultValues, user, hasEmail }) => {
  const { register, handleSubmit, control, watch, errors } = useForm({
    defaultValues,
  });
  const recaptchaRef = React.useRef();

  const { data, setTerm } = useUserLookup();

  console.log(defaultValues, user);

  // useFormPersist(
  //   "gen-confirm",
  //   { watch, setValue },
  //   {
  //     exclude: ["Email"],
  //     //storage: window.localStorage,
  //   }
  // );

  // const onSubmitWithReCAPTCHA = async (data) => {
  //   const token = 1; //await recaptchaRef.current.executeAsync();
  //   console.log("start submit");
  //   if (token) {
  //     setStatus(statuses.LOADING);
  //   }
  // };

  const submitForm = (data) => {
    console.log("submit form");
    axios
      .post(
        `${
          process.env.REACT_APP_EXPRESS ? process.env.REACT_APP_EXPRESS : ""
        }/submit-confirmation-2`,
        data

        // { headers: { "Content-Type": "multipart/form-data" } }
      )

      .then((res) => {
        console.log(res);
        setStatus(statuses.SUCCESS);
      })
      .catch((err) => {
        console.log(err.message);
        setStatus(statuses.ERROR);
      });
  };

  const watched = watch(["Providers", "User"]);

  return (
    <div className={styles.element}>
      <form onSubmit={handleSubmit(submitForm)}>
        <Subsection
          fields={
            <>
              <h4>Tell us who you are.</h4>
              <div className={formLayout.half}>
                <Text
                  name="First Name"
                  label="First Name"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />
                <Text
                  name="Last Name"
                  label="Last Name"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />
              </div>
              <div className={formLayout.full}>
                {/* 2 */}{" "}
                <Text
                  name="Email"
                  type="email"
                  label="Email (make sure to use the same email address you signed up with)"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />
                <br />
                <Text
                  name="Updated Email"
                  type="email"
                  label="Updated Email (only fill this out if you have a new preferred email address)"
                  forwardRef={register()}
                  errors={errors}
                />
              </div>

              {hasEmail ? (
                <>
                  <input
                    type="hidden"
                    value={user["Screen Name"]}
                    name="Screen Name"
                    ref={register()}
                    readOnly
                  />
                  <input
                    type="hidden"
                    value={user["Survey ID"]}
                    name="Generator ID"
                    ref={register()}
                    readOnly
                  />
                  <input
                    type="hidden"
                    value={user["State"]}
                    name="Region"
                    ref={register()}
                    readOnly
                  />
                </>
              ) : (
                <>
                  <h4>Identify your generator story:</h4>{" "}
                  <UserLookup
                    showStoryName={true}
                    name="User"
                    forwardRef={register({
                      required: errorMessages.required,
                    })}
                    errors={errors}
                    data={data}
                    setTerm={setTerm}
                  />
                  {watched.User && watched.User.length > 0 && (
                    <>
                      <input
                        type="hidden"
                        value={JSON.parse(watched.User)[0].screenname}
                        name="Screen Name"
                        ref={register()}
                        readOnly
                      />
                      <input
                        type="hidden"
                        value={JSON.parse(watched.User)[0].id}
                        name="Generator ID"
                        ref={register()}
                        readOnly
                      />
                      <input
                        type="hidden"
                        value={JSON.parse(watched.User)[0].region}
                        name="Region"
                        ref={register()}
                        readOnly
                      />
                    </>
                  )}
                </>
              )}
            </>
          }
        ></Subsection>
        <Subsection
          fields={
            <>
              <Select
                name="Workshop Attended"
                control={control}
                defaultValue=""
                placeholder="Choose the workshop you attended"
                label="You are required to participate in one workshop:"
                options={workshopDates}
                forwardRef={register({
                  required: errorMessages.required,
                })}
                errors={errors}
              />
            </>
          }
        />
        <Subsection
          fields={
            <>
              <h4>HOW DID YOU FILTER GRANTEE AFTERSCHOOL PROGRESS</h4>
              <CheckboxSet
                name="Filters Used"
                options={[
                  {
                    value: "Grantee",
                    label: "Grantee",
                    checked: true,
                    disabled: true,
                  },
                  { value: "Progress", label: "Progress" },
                  { value: "Afterschool Theme", label: "Afterschool Theme" },
                  { value: "Region", label: "Region" },
                  { value: "County", label: "County" },
                  { value: "Type of Provider", label: "Type of Provider" },
                  { value: "Cost of Idea", label: "Cost of Idea" },
                ]}
                generateRef={() =>
                  register({ required: errorMessages.required })
                }
                errors={errors}
              />
            </>
          }
        />
        <Subsection
          fields={
            <>
              <h4>
                SELECT THE GRANTEE AFTERSCHOOL PROGRESS THAT YOU COMMENTED ON
                (MINIMUM OF 3)
              </h4>

              <ProviderLookup
                showStoryName={true}
                name="Providers"
                forwardRef={register({
                  required: errorMessages.required,
                  validate: (value) => {
                    if (!value) {
                      return "Please choose a valid idea";
                    }
                    return JSON.parse(value).length < 3
                      ? "Please select at least 3 ideas"
                      : true;
                  },
                })}
                errors={errors}
                data={data}
                setTerm={setTerm}
              />
              {watched && watched.Providers && (
                <>
                  <input
                    type="hidden"
                    ref={register()}
                    readOnly
                    name={`Ideas`}
                    value={JSON.parse(watched.Providers)
                      .map((idea, index) => idea["id"])
                      .join(",")}
                    className={styles.hidden}
                  />
                </>
              )}
            </>
          }
        />
        <Subsection
          fields={
            <>
              <h4>Why did you choose this group?</h4>
              <TextArea
                name="Reason"
                placeholder="Start typing here"
                forwardRef={register({
                  required: errorMessages.required,
                  minLength: {
                    value: 50,
                    message: errorMessages.minLength(50),
                  },
                })}
                minLength={50}
                maxLength={500}
                errors={errors}
                required={{
                  message: "TEXT IS REQUIRED, MINIMUM 50 CHARACTERS*",
                }}
              />
            </>
          }
        />
        <Subsection
          fields={
            <>
              <h4>
                SINCE GENERATOR Z, HAVE YOU DISCOVERED NEW OR DIFFERENT
                AFTERSCHOOL OPPORTUNITIES? TELL US ABOUT IT.
              </h4>
              <TextArea
                name="New Opportunities"
                placeholder="Start typing here"
                forwardRef={register({
                  required: errorMessages.required,
                  minLength: {
                    value: 50,
                    message: errorMessages.minLength(50),
                  },
                })}
                minLength={50}
                maxLength={500}
                errors={errors}
                required={{
                  message: "TEXT IS REQUIRED, MINIMUM 50 CHARACTERS*",
                }}
              />
            </>
          }
        />
        <Subsection
          fields={
            <>
              <h4>
                WHAT DID YOU LIKE ABOUT BEING A GENERATOR? IS THERE ANYTHING YOU
                WOULD CHANGE?
              </h4>
              <TextArea
                name="Like and Change"
                placeholder="Start typing here"
                forwardRef={register({
                  required: errorMessages.required,
                  minLength: {
                    value: 50,
                    message: errorMessages.minLength(500),
                  },
                })}
                minLength={50}
                maxLength={500}
                errors={errors}
                required={{
                  message: "TEXT IS REQUIRED, MINIMUM 50 CHARACTERS*",
                }}
              />
            </>
          }
        />
        <Subsection
          heading="(Optional) Generator Z book"
          fields={
            <>
              <p>
                We’re working on a Generator Z book that will be gifted to all
                Generators! We would love your help collecting some additional
                information on how you feel in 2022. Answer these few questions
                and share your best mailing address with us.
              </p>
              <h4>
                Describe the biggest change in your life since your Generator Z
                story in 2020. What do you wish you could say to your younger
                self?
              </h4>
              <TextArea
                name="Life Change"
                placeholder="Start typing here"
                forwardRef={register()}
                minLength={0}
                maxLength={1000}
                errors={errors}
              />

              <h4>How did you find joy over these last two years?</h4>
              <TextArea
                name="Finding Joy"
                placeholder="Start typing here"
                forwardRef={register()}
                minLength={0}
                maxLength={1000}
                errors={errors}
              />
              <h4>
                As part of Generation Z, what is the most important message you
                have for other teens or adults?
              </h4>
              <TextArea
                name="Your Message"
                placeholder="Start typing here"
                forwardRef={register()}
                minLength={0}
                maxLength={1000}
                errors={errors}
              />
              <h4>Your Mailing Address</h4>
              <Text
                name="Mailing Address"
                label="Address"
                placeholder="123 Generator Way, Apt 1Z"
                forwardRef={register()}
                // minLength={0}
                // maxLength={1000}
                errors={errors}
              />
              <Text
                name="Mailing City"
                label="City"
                placeholder="Detroit"
                forwardRef={register()}
                // minLength={0}
                // maxLength={1000}
                errors={errors}
              />
              <Text
                name="Mailing State"
                label="State"
                placeholder="MI"
                forwardRef={register()}
                maxLength={2}
                minLength={2}
                // maxLength={1000}
                errors={errors}
              />
              <Text
                name="Mailing Zip"
                label="Zip Code"
                placeholder="48127"
                forwardRef={register()}
                minLength={5}
                maxLength={5}
                errors={errors}
              />
            </>
          }
        />
        <Subsection
          fields={
            <>
              <h2>Finalize</h2>
              <CheckboxSet
                name="Final Confirm"
                options={[
                  {
                    label: (
                      <>
                        I confirm that my work is complete. I understand that I
                        am not eligible for future opportunities if Generator Z
                        finds that my work was not complete.
                      </>
                    ),
                    value: "Confirmed",
                  },
                ]}
                generateRef={() =>
                  register({ required: errorMessages.required })
                }
                errors={errors}
              />
            </>
          }
        />
        <Subsection
          fields={
            <>
              {!status || !status.LOADING ? (
                <button className={formLayout.submit} type="submit">
                  Submit
                </button>
              ) : (
                "Submitting"
              )}
            </>
          }
        />
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey="6LeYG-UZAAAAAGscT5cfnHrGve9DmCRSzUNAefdu"
        />
      </form>
    </div>
  );
};

export default ConfirmationForm;
