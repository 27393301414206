import React from "react";
import Success from "components/form/success";

const PaymentFormSuccess = () => {
  return (
    <Success
      heading="Success!"
      body={
        <p>
          Success! Welcome on board, Generator. Now it’s on to the next step of
          creating your story. Use the unique link in your acceptance email to
          access your storytelling page. We hope to see you at one of our many{" "}
          <a
            href="https://www.eventbrite.com/e/generator-z-storytelling-workshops-tickets-129894962329"
            target="_blank"
            rel="noopener noreferrer"
          >
            workshops
          </a>{" "}
          this month and on the{" "}
          <a
            href="https://generatorz.slack.com/join/shared_invite/zt-kofsg21i-hmkJoiqOnpUSw_KRYfvxfA#/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Generator Z Slack channel!
          </a>{" "}
          Reach out to us at{" "}
          <a
            href="mailto:info@generator-z.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            info@generator-z.org
          </a>{" "}
          with any questions.
        </p>
      }
    />
  );
};

export default PaymentFormSuccess;
