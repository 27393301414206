import React, { useRef, useEffect } from "react";
import styles from "./locales.module.scss";
import yellow from "./yellow.svg";
import tan from "./tan.svg";
import red from "./red.svg";
import purple from "./purple.svg";
import green from "./green.svg";
import SVG from "react-inlinesvg";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

const data = [
  {
    locale: "City",
    quote: `"It's easy to find on a map and hard to forget."`,
    aside: (
      <>
        Teens who live in <span>cities</span> responded, from Detroit to Buffalo
        and Rochester to Ann Arbor.
      </>
    ),
  },
  {
    locale: "Suburb",
    quote: `"It's like the little cousin of a city."`,
    aside: (
      <>
        Teens who live in the <span>suburbs</span> responded, from Mt. Clemens
        to Williamsville and North Tonawanda to Port Huron.
      </>
    ),
  },
  {
    locale: "Town",
    quote: `“I'm likely going to bump into someone I know.”`,
    aside: (
      <>
        Teens who live in <span>towns</span> responded, from Ortonville to
        Batavia and Jamestown to Dundee.
      </>
    ),
  },
  {
    locale: "Rural",
    quote: `"I can find more open space than people."`,
    aside: (
      <>
        Teens who live in <span>rural areas</span> responded, from Lake Orion to
        Houghton and Ellicottville to Oceola Township.
      </>
    ),
  },
];

const shapes = {
  red,
  yellow,
  purple,
  tan,
  green,
};

const Locales = () => {
  const ref = useRef();
  const shapesRef = useRef();

  const redRef = useRef();
  const yellowRef = useRef();
  const purpleRef = useRef();
  const tanRef = useRef();
  const greenRef = useRef();
  const shapeRefs = {
    red: redRef,
    yellow: yellowRef,
    purple: purpleRef,
    tan: tanRef,
    green: greenRef,
  };

  useEffect(() => {
    const tl = gsap.timeline(
      {
        scrollTrigger: {
          trigger: ref.current,
          start: "top top",
          end: "bottom bottom",
          pin: shapesRef.current,
          scrub: 1,
        },
      },
      []
    );
    const red = gsap.to(redRef.current, {
      scale: 0.5,
      yPercent: 20,
      xPercent: -15,
    });
    const yellow = gsap.to(yellowRef.current, {
      scale: 0.8,
      xPercent: 50,
    });
    const purple = gsap.to(purpleRef.current, {
      xPercent: -50,
    });
    const tan = gsap.to(tanRef.current, {
      yPercent: -40,
    });
    const green = gsap.to(greenRef.current, {
      yPercent: 100,
    });
    tl.add(red);
    tl.add(yellow, 0);
    tl.add(purple, 0);
    tl.add(tan, 0);
    tl.add(green, 0);

    return () => {
      tl && tl.scrollTrigger.kill();
    };
  }, []);

  return (
    <div className={styles.element} ref={ref}>
      <div className={styles.shapes} ref={shapesRef}>
        {Object.keys(shapes).map((key) => {
          return (
            <div key={key} className={styles.shape} ref={shapeRefs[key]}>
              <SVG src={shapes[key]} alt="" />
            </div>
          );
        })}
      </div>
      <div className={styles.body}>
        {data.map(({ locale, aside }) => {
          return (
            <div key={locale} className={styles.message}>
              {aside}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Locales;
