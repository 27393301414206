import React, { useRef, useEffect, useState } from "react";
import styles from "./ready.module.scss";

// import img1 from "./01.png";
// import img2 from "./02.png";
// import img3 from "./03.png";
// import img4 from "./04.png";
// import img5 from "./05.png";
// import img6 from "./06.png";
// import img7 from "./07.png";
// import img8 from "./08.png";
// import img9 from "./09.png";
// import img10 from "./10.png";

import img1 from "./img1.png";
import img2 from "./img2.png";
import img3 from "./img3.png";
import img4 from "./img4.png";
import img5 from "./img5.png";
import img6 from "./img6.png";
import img7 from "./img7.png";
import img8 from "./img8.png";
import img9 from "./img9.png";
import img10 from "./img10.png";
import img11 from "./img11.png";
import img12 from "./img12.png";
import img13 from "./img13.png";
import img14 from "./img14.png";
import img15 from "./img15.png";
import img16 from "./img16.png";
import gif3 from './render_elements_3.gif'
import gif9 from './render_elements_9.gif'
import gif0 from './render_elements.gif'
import gif7 from './render_elements_7.gif'
import gif2 from './render_elements_2.gif'
import gif5 from './render_elements_5.gif'
import gif1 from './render_elements_1.gif'
import gif00 from './render.gif'

import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
gsap.registerPlugin(ScrollTrigger);

// const images = [
//     { src: img1, style: { marginLeft: "auto" } },
//     { src: img2, x: 0, y: -20 },
//     { src: img3, x: 0, y: 0 },
//     { src: img4, x: 0, y: 0 },
//     { src: img5, x: 0, y: 0 },
//     { src: img6, x: 0, y: 0 },
//     { src: img7, x: 0, y: 0 },
//     { src: img8, x: 0, y: 0 },
//     { src: img9, x: 0, y: 0 },
//     { src: img10, x: 0, y: 0 },
// ];

const images = [
    { src: img1, style: { marginLeft: "auto", marginRight: "0" } },
    { src: gif3, style: { marginLeft: "0", marginRight: "auto" } },
    { src: img2, style: { marginLeft: "0", marginRight: "auto" } },
    { src: gif9, style: { marginLeft: "auto", marginRight: "0", marginTop: "-40vh", marginBottom: "-20vh" } },
    { src: img3, style: { marginLeft: "10%", marginRight: "auto", marginTop: "20vh" } },
    { src: img4, style: { marginLeft: "auto", marginRight: "0" } },
    { src: img5, style: { marginLeft: "0", marginRight: "auto" } },
    { src: gif0, style: { marginLeft: "auto", marginRight: "0" } },
    { src: img6, style: { marginLeft: "25%", marginRight: "auto" } },
    { src: gif7, style: { marginLeft: "auto", marginRight: "15%" } },
    { src: img7, style: { marginLeft: "auto", marginRight: "15%" } },
    { src: gif2, style: { marginLeft: "10%", marginRight: "auto" } },
    { src: img8, style: { marginLeft: "10%", marginRight: "auto" } },
    { src: gif5, style: { marginLeft: "auto", marginRight: "5%" } },
    { src: img9, style: { marginLeft: "auto", marginRight: "0" } },
    { src: gif1, style: { marginLeft: "5%", marginRight: "auto" } },
    { src: img10, style: { marginLeft: "auto", marginRight: "10%" } },
    { src: gif00, style: { marginLeft: "10%", marginRight: "auto" } },
]

const Ready = ({ setNavGrid }) => {
    const ref = useRef();
    const headingRef = useRef();

    useEffect(() => {
        const t1 = ScrollTrigger.create({
            trigger: ref.current,
            start: "top center",
            end: "top center",
            onEnter: () => {
                setNavGrid(null);
            },
            onEnterBack: () => {
                //setNavGrid("four");
            },
        });

        const t2 = ScrollTrigger.create({
            trigger: ref.current,
            start: "top top",
            end: "bottom bottom",
            pin: headingRef.current,
        });

        return () => {
            t1.kill();
            t2.kill();
        };
    }, [setNavGrid]);

    return (
        <div className={styles.element} ref={ref}>
            <h2 className={styles.heading} ref={headingRef}>
                Thank You Generators!
            </h2>
            {images.map((props, index) => (
                <Image key={props.src} i={index} {...props} />
            ))}
        </div>
    );
};

export default Ready;

const Image = ({ src, style, i }) => {
    const ref = useRef();
    const [loaded, setLoaded] = useState(false);
    const imageRef = useRef();

    useEffect(() => {
        if (loaded) {
            const t = gsap.to(ref.current, {
                duration: 1,
                ease: "none",
                scrollTrigger: {
                    trigger: ref.current,
                    start: "top center",
                    end: "top center",
                    scrub: 2,
                    toggleActions: "play pause resume reverse",
                },
                autoAlpha: 1,
                yPercent: 0,
            });

            return () => {
                t.scrollTrigger.kill();
            };
        }
    }, [loaded]);

    return (
        <div
            ref={ref}
            style={{ visibility: "hidden", position: "relative", ...style }}
            className={styles.imageWrap}
        >
            <img
                ref={imageRef}
                className={`${styles.image} ${i % 3 === 0 ? styles.odd : ""}`}
                key={src}
                src={src}
                alt=""
                onLoad={() => setLoaded(true)}
            />
        </div>
    );
};
