import React, { useEffect, useRef, useState } from "react";
import styles from "./splash.module.scss";
import Intro from "components/teaser/intro";
import people from "./splash-v3.png";
import youtubeThumbnail from "./youtube.jpg"

import { Step } from "../intro";
import Section from "components/section";

import { Link } from "react-router-dom";
import introStyles from "components/teaser/intro/intro.module.scss";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import VideoModal from "components/videoModal";
gsap.registerPlugin(ScrollTrigger);

const Future = ({ theme }) => {

    const [showModal, setShowModal] = useState(false)

    return (
        <Step step={"future"} theme={theme}>

            <VideoModal showModal={showModal} setShowModal={setShowModal} />

            <div className={styles.splashInner}>
                <div className={styles.left}>
                    <h2 className={styles.heading}>A Future Imagined.</h2>

                    <div className={styles.contentWrap}>
                        <p>
                            Generator Z was an ideas lab that launched in 2020 for teens and
                            afterschool providers to reimagine the future of afterschool in
                            Southeast Michigan and Western New York. Teens are the experts of
                            their own lives and afterschool providers are the source of
                            afterschool opportunities. Together, they drive the brightest
                            possible future.
                        </p>
                        <p>
                            One thousand teens across both regions became Generators, sharing their stories and insights to generate the change they cared to see. Over 200 afterschool providers learned from Generators, turning insights into ideas. Nearly 100 received grants to bring these ideas to life.
                        </p>
                        <p>
                            Generator Z concluded in 2022, leaving behind a legacy of content and a case study for centering youth voice.
                        </p>
                    </div>

                    <div className={styles.buttonWrap}>
                        <Link className={styles.button} to={"/stories"}>
                            Generator Stories
                        </Link>

                        <Link className={styles.button} to={"/afterschool-ideas"}>
                            Afterschool Grants
                        </Link>
                        <a className={`${styles.button} ${styles.white}`} href="https://www.ralphcwilsonjrfoundation.org/wp-content/flipbooks/Gen-Z_Evaluation_Report_February_2024/index.html#page=1" target="_blank">
                            Evaluation Report
                        </a>
                        <a className={`${styles.button} ${styles.white}`} href="/z-to-z">
                            Z to Z Book
                        </a>
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.imageWrap}>
                        <img onClick={() => setShowModal(true)} src={youtubeThumbnail} alt="youtube" />
                    </div>
                </div>
            </div>
        </Step>
    );
};

const BeforeSlides = ({ theme }) => {
    const ref = useRef();
    const collageRef = useRef();
    const zRef = useRef();

    useEffect(() => {
        const tl = gsap.timeline({
            duration: 3,
            scrollTrigger: {
                trigger: ref.current,
                start: "top top",
                end: "bottom center",
                scrub: 2,
                //snap: 1 / 3,
            },
        });
        const collage = gsap.fromTo(
            collageRef.current,
            {
                duration: 1,
                xPercent: 0,
            },
            { xPercent: 100 }
        );

        const z = gsap
            .timeline()
            .to(zRef.current, { rotate: 180 })
            .to(zRef.current, { xPercent: -100 });

        tl.add(collage, 0);
        tl.add(z, 0);

        return () => {
            tl && tl.scrollTrigger.kill();
        };
    }, []);

    return (
        <div ref={ref}>
            <div className={styles.splash}>
                <Future theme={theme} />
                <StaticGraphics collageRef={collageRef} zRef={zRef} />
            </div>
        </div>
    );
};

const Splash = () => {
    const theme = "peach";

    return (
        <Section id={"ModelToRemember"} theme={theme}>
            <Intro
                className={styles.element}
                children={
                    <BeforeSlides theme={theme} />
                }
            />
        </Section>
    );
};

export default Splash;

const StaticGraphics = ({ collageRef, zRef }) => {
    return (
        <>
            {/* <div className={styles.z} ref={zRef}>
                <img src={z} alt="Abstract Z shape" />
                <img src={eye} width={400} />
            </div> */}
            <div className={styles.collage} ref={collageRef}>
                <img
                    src={people}
                    alt="Abstract collage of Gen Z teens and colorful shapes"
                />
            </div>
        </>
    );
};
