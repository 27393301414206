import React, { useContext, useEffect, useState, useMemo } from "react";
import FilterContext from "components/stories/filter-context";
import ProviderTile from "components/tile";
import TileContent from "../tile-content";
import styles from "components/stories/story/more-stories/more-stories.module.scss";

const colors = {
  white: "fff",
  black: "1b1a1f",
  blue: "523dff",
  purple: "ab1eba",
  red: "ff3f44",
  peach: "f9dacb",
  pink: "fe8f9b",
  lilac: "b4b5e4",
  green: "00fab0",
  yellow: "fefd38",
  magenta: "ab1eba",
};
const MoreProviders = ({ currentId, themeColor, heading }) => {
  const { filteredProviders: preFilteredProviders } = useContext(FilterContext);
  const [providers, setProviders] = useState([]);
  const filteredProviders = useMemo(() => {
    return (
      preFilteredProviders &&
      preFilteredProviders.filter(
        (d) => d.fields["Application ID"].indexOf("-6month") === -1
      )
    );
  }, [preFilteredProviders]);

  useEffect(() => {
    console.log("Load New", currentId);
    let newProviders = [];
    const keys = filteredProviders ? Object.keys(filteredProviders) : null;
    if (keys && keys.length > 1) {
      //get 3 random numbers\

      let arr = [];
      while (arr.length < (keys.length > 3 ? 3 : keys.length)) {
        var r = Math.floor(Math.random() * keys.length) + 1;
        if (arr.indexOf(r) === -1) arr.push(r);
      }

      let backfill = [];
      let index = 0;
      keys.some((key) => {
        const foundIndex = arr.indexOf(index);
        const curr = filteredProviders[key].id === currentId;

        if (foundIndex > -1) {
          arr.splice(foundIndex, 1);
          if (!curr) {
            newProviders.push(filteredProviders[key]);
          }
          if (newProviders.length === 3) {
            return true;
          }
        } else if (!curr) {
          backfill.push(filteredProviders[key]);
        }

        index++;

        return false;
      });
      while (
        newProviders.length < (keys.length < 4 ? keys.length - 1 : 3) &&
        backfill.length > 0
      ) {
        // console.log("backfilling", backfill);
        const backfillRand = Math.floor(Math.random() * backfill.length - 1);
        const backfilled = backfill.splice(backfillRand, 1);
        newProviders.push(backfilled[0]);
      }

      setProviders(newProviders);
    }
  }, [filteredProviders, currentId]);

  return (
    <>
      {providers.length > 0 && (
        <div className={styles.element}>
          <h4>{heading ? heading : "More Ideas"}</h4>
          <ul>
            {providers.map((provider, index) => {
              const fields = provider.fields;
              return (
                <ProviderTile
                  key={index}
                  id={provider.id}
                  fields={fields}
                  show={true}
                  color={colors[themeColor]}
                  section="providers"
                >
                  <TileContent fields={fields} inheritColors={true} />
                </ProviderTile>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default MoreProviders;
