import React, { useEffect } from "react";
import styles from "./student-application.module.scss";

const Success = () => {
  useEffect(() => {
    typeof window !== "undefined" &&
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className={styles.success}>
      <h1 className={styles.heading}>Submission Confirmed</h1>
      <h2 className={styles.title}>Amazing, you did it!</h2>
      <p className={styles.copy}>
        Thank you for taking the time to apply to become a Generator. We
        will be in touch with you soon.
        <br />
        <br />
        Thanks, 
        <br />
        Generator Z Team
      </p>
    </div>
  );
};

export default Success;
