import React, { useState, useEffect } from "react";
import styles from "./media-builder.module.scss";
import VideoUpload from "components/form/video-upload";
import ImageUpload from "components/form/image-upload";
import AudioUpload from "components/form/audio-upload";
import DocumentUpload from "components/form/document-upload";
import { v4 as uid } from "uuid";
import { TextareaInput as Textarea } from "components/form/textarea";
import SVG from "react-inlinesvg";
import x from "./x.svg";
import dragHandle from "./drag-handle.svg";
import { useStorageReducer } from "react-storage-hooks";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Requirement from "components/form/requirement";
import { ErrorMessage } from "@hookform/error-message";

import { getTypeHandles } from "components/stories/stories-form";
const API_KEY = process.env.REACT_APP_FILESTACK;

const reducer = (state, action) => {
  switch (action.type) {
    case "addImage":
      return {
        ...state,
        blocks: [...state.blocks, { type: "image", id: uid() }],
      };
    case "addVideo":
      return {
        ...state,
        blocks: [...state.blocks, { type: "video", id: uid() }],
      };

    case "addAudio":
      return {
        ...state,
        blocks: [...state.blocks, { type: "audio", id: uid() }],
      };

    case "addText":
      return {
        ...state,
        blocks: [...state.blocks, { type: "text", id: uid() }],
      };

    case "addDocument":
      return {
        ...state,
        blocks: [...state.blocks, { type: "document", id: uid() }],
      };

    case "setValue":
      return {
        ...state,
        blocks: [...state.blocks].map((props) =>
          props.id === action.id ? { ...props, value: action.value } : props
        ),
      };
    case "remove":
      return {
        ...state,
        blocks: state.blocks.filter(({ id }) => id !== action.id),
      };

    case "reorder":
      return {
        ...state,
        blocks: action.value,
      };

    default:
      throw new Error(`unknown action ${action.type}`);
  }
};

const initialState = { blocks: [] };

const useMedia = (id) => {
  const [state, dispatch] = useStorageReducer(
    window.localStorage,
    `media-block--${id}`,
    reducer,
    initialState
  );

  const handlers = {
    text: () => dispatch({ type: "addText" }),
    image: () => dispatch({ type: "addImage" }),
    video: () => dispatch({ type: "addVideo" }),
    audio: () => dispatch({ type: "addAudio" }),
    document: () => dispatch({ type: "addDocument" }),
  };
  const remove = (id) => dispatch({ type: "remove", id });
  const setValue = (id) => (value) => dispatch({ type: "setValue", value, id });
  const reorder = (blocks) => dispatch({ type: "reorder", value: blocks });
  return {
    state,
    handlers,
    setValue,
    remove,
    reorder,
  };
};

const MediaBuilder = ({
  forwardRef,
  errors,
  name,
  types = ["audio", "video", "text", "image", "document"],
  limit = 0, //no limit
  heading = "Add Content",
  required,
  extensions,
  urlOnly,
}) => {
  const { state, handlers, setValue, remove, reorder } = useMedia(name);
  const typeHandles = getTypeHandles(types);
  const [url, setUrl] = useState("");

  //const url = state.blocks.length === 1 ? state.blocks[0].url : "none";

  useEffect(() => {
    if (urlOnly) {
      setUrl(
        state.blocks.length > 0 && state.blocks[0].value
          ? state.blocks[0].value.url.replace(
              "filestackcontent.com/",
              `filestackcontent.com/${API_KEY}/`
            )
          : ""
      );
    }

    return () => {
      //cleanup
    };
  }, [state.blocks, urlOnly]);

  return (
    <div className={styles.element}>
      <input
        type="text"
        ref={forwardRef}
        name={name}
        readOnly
        value={urlOnly === true ? url : JSON.stringify(state.blocks)}
        className={styles.hidden}
      />
      <Items
        uid={name}
        items={state.blocks}
        setValue={setValue}
        remove={remove}
        reorder={reorder}
        limit={limit}
        types={types}
        extensions={extensions}
      />

      {(!limit ||
        !state.blocks ||
        (state.blocks && state.blocks.length < limit)) && (
        <>
          <div className={styles.heading}>{heading}</div>
          <Buttons handlers={handlers} types={typeHandles} />
        </>
      )}
      {required && (
        <Requirement
          fulfilled={required.validate(state.blocks)}
          message={required.message}
        />
      )}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="error">{message}</div>}
      />
    </div>
  );
};

const Items = ({
  items,
  setValue,
  remove,
  uid,
  reorder,
  limit,
  types,
  extensions,
}) => {
  if (!items) {
    return null;
  }
  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.draggableId &&
      destination.index === source.index
    ) {
      return;
    }
    const newItems = [...items];
    newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, items[source.index]);
    reorder(newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={uid}>
        {(provided) => {
          return (
            <List innerRef={provided.innerRef} {...provided.droppableProps}>
              {items.map(({ type, id, value }, index) => {
                return (
                  <Item
                    extensions={extensions}
                    key={id}
                    id={id}
                    index={index}
                    value={value}
                    setValue={setValue}
                    remove={remove}
                    type={type}
                    fieldProps={types.find(
                      ({ type: findType }) => findType === type
                    )}
                    isDraggable={items.length > 1 && (!limit || limit > 1)}
                  />
                );
              })}
              {provided.placeholder}
            </List>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

const List = ({ children, innerRef }) => {
  return (
    <ul className={styles.items} ref={innerRef}>
      {children}
    </ul>
  );
};

const Item = ({
  value,
  setValue,
  remove,
  id,
  index,
  type,
  isDraggable,
  fieldProps,
  extensions,
}) => {
  const Comp = fields[type];

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => {
        return (
          <div
            className={styles.item}
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <Comp
              {...fieldProps}
              handle={value}
              value={value}
              setValue={setValue(id)}
              extensions={extensions}
            />
            <button
              className={styles.itemRemove}
              type="button"
              onClick={() =>
                window.confirm("Are you sure you want to delete this block?") &&
                remove(id)
              }
            >
              <SVG src={x} />
            </button>
            <div
              className={styles.dragHandle}
              {...provided.dragHandleProps}
              style={{ display: isDraggable ? "" : "none" }}
            >
              <SVG src={dragHandle} />
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

const buttons = {
  video: {
    label: "Video",
  },
  audio: {
    label: "Audio",
  },
  image: {
    label: "Image",
  },
  text: {
    label: "Text",
  },
  document: {
    label: "Document",
  },
};
const Buttons = ({ handlers, types, limit }) => {
  return (
    <ul className={styles.buttons}>
      {types.map((type) => {
        const { label } = buttons[type];

        return (
          <li key={type} className={styles.buttonsItem}>
            <Button label={label} onClick={() => handlers[type]()} />
          </li>
        );
      })}
    </ul>
  );
};

const Button = ({ onClick, label }) => {
  return (
    <div>
      <button className={styles.button} type="button" onClick={onClick}>
        {label}
      </button>
    </div>
  );
};

export default MediaBuilder;

const fields = {
  image: (props) => (
    <ImageUpload label="Image Upload" immediate={true} {...props} />
  ),
  video: (props) => (
    <VideoUpload label="Video Upload" immediate={true} {...props} />
  ),
  audio: (props) => (
    <AudioUpload label="Audio Upload" immediate={true} {...props} />
  ),
  document: (props) => (
    <DocumentUpload label="Document Upload" immediate={true} {...props} />
  ),
  text: (props) => <Textarea {...props} />,
};
