import React, { useEffect, useRef, useState } from "react";
import styles from "./regions.module.scss";
import Map from "./map";
import useMatchMedia from "hooks/useMatchMedia";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
gsap.registerPlugin(ScrollTrigger);

const steps = [
  {
    heading: "2 Regions",
    content:
      "Western New York and Southeast Michigan are the core regions that the Ralph C. Wilson, Jr. Foundation funds and the focus geographies of Teen Opinions Count and Generator Z.",
    step: "fadeIn",
  },
  {
    heading: "16 Counties",
    content:
      "The counties in Southeast Michigan are: Wayne, Oakland, Macomb, Livingston, Monroe, St. Clair, and Washtenaw. The counties in Western New York are: Allegany, Cattaraugus, Chautauqua, Erie, Genesee, Monroe, Niagara, Orleans, and Wyoming.",
    step: "counties",
  },
  {
    heading: "4 Residencies",
    content:
      "Teens, across regions and counties, live in a variety of places from city to suburb and town to rural areas. Any teen in any part of the country can relate to these places and that is why data is sortable by residency.",
    step: "counties",
  },
];

const Regions = () => {
  const [step, setStep] = useState(steps[0].step);

  const ref = useRef();
  const mapRef = useRef();

  const desktop = useMatchMedia(`(min-width: 1200px)`);

  useEffect(() => {
    const tween = gsap.to(ref.current, {
      duration: 1,
      scrollTrigger: {
        trigger: ref.current,
        start: "top center",
        end: "bottom center",
        toggleActions: "play pause resume reverse",
      },
      autoAlpha: 1,
    });
    const t = ScrollTrigger.create({
      trigger: ref.current,
      pin: mapRef.current,
      start: "top top",
      end: "bottom bottom",
      //markers: true,
      pinSpacing: false,
    });
    return () => {
      tween.scrollTrigger.kill();
      t.kill();
    };
  }, []);

  return (
    <div ref={ref} className={styles.element}>
      <div ref={mapRef}>
        <Map step={step} desktop={desktop} />
      </div>
      <div className={styles.steps}>
        {steps.map((props, i) => {
          return <Step key={props.step + i} {...props} setStep={setStep} />;
        })}
      </div>
    </div>
  );
};

export default Regions;

const Step = ({ heading, content, step, setStep }) => {
  const ref = useRef();
  useEffect(() => {
    const t = ScrollTrigger.create({
      onEnter: () => setStep(step),
      onEnterBack: () => setStep(step),
      start: "top center",
      end: "bottom center",
      trigger: ref.current,
    });
    return () => t.kill();
  }, [step, setStep]);
  return (
    <div className={styles.step} ref={ref}>
      <div className={styles.stepInner}>
        <h3 className={styles.stepHeading}>{heading}</h3>
        <p className={styles.text}>{content}</p>
      </div>
    </div>
  );
};
