import React, { useState, useEffect } from "react";
import { poll } from "helpers";
import { FILESTACK_CDN } from "const";
import Loader from "components/stories/loader";
import styles from "./transformed-video.module.scss";

const API_KEY = process.env.REACT_APP_FILESTACK;

const pollForTransform = ({ handle, width = 800 }) => {
  const call = async () => {
    const response = await fetch(
      `${FILESTACK_CDN}/${API_KEY}/video_convert=width:${width},preset:h264/${handle}`
    );
    return response.json();
  };
  return poll({
    fn: call,
    validate: (res) => res.status !== "pending",
    interval: 5000,
    maxAttempts: 100,
  });
};

const TransformedVideo = ({ handle, width = 800 }) => {
  const [result, setResult] = useState();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (handle) {
      let mounted = true;

      setProcessing(true);
      pollForTransform({ handle, width })
        .then((result) => {
          mounted && setResult(result);
          setProcessing(false);
        })
        .catch((err) => {
          console.error(err);
          setError(true);
          setProcessing(false);
        });
      return () => (mounted = false);
    }
  }, [handle, width]);

  if (!handle) {
    return null;
  }
  if (error) {
    return "Error";
  }
  if (processing) {
    return <Loader />;
  }
  return result ? (
    <video className={styles.video} src={result.data.url} controls />
  ) : null;
};

export default TransformedVideo;
