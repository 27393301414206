import React from "react";
import styles from "./prototypes.module.scss";
import Demographics from "blocks/demographics";
// import Regions from "blocks/regions";
// import Themes from "blocks/themes";
//import Ideas from "blocks/ideas";
//import ForOrgs from "components/teaser/for-orgs";
//import Locales from "components/teaser/data/locales";
//import Story from "components/teaser/story";
//import Map from "blocks/regions";
import { ThemeProvider } from "components/teaser/theme-context";

const Prototypes = () => {
  return (
    <ThemeProvider>
      <div className={styles.element}>
        {/* <Ideas /> */}
        <Demographics />
        {/* <Map /> */}
        {/* <Story /> */}
      </div>
    </ThemeProvider>
  );
};

export default Prototypes;
