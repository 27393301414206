const questions = {
  joy: [
    {
      value: "Question 1",
      label: "What is one of your favorite things in the world to do? ",
      prompt:
        "“Take us there” and show us what you love and why? Is it a place? A person? An activity? Bring it to life for us and tell us why it’s so special to you.",
    },
    {
      value: "Question 2",
      label:
        "What’s the song (or movie, book, poem, or piece of art) that means everything to you?",
      prompt:
        "Make sure you identify what it is and tell us why it’s so meaningful to you.",
    },
  ],
  challenge: [
    {
      value: "Question 1",
      label: "What was the hardest thing you’ve ever done? ",
      prompt:
        "How did you get through it? What kinds of help or tools would have made it better? What advice would you give someone in a similar situation?",
    },
    {
      value: "Question 2",
      label: "What is something you could use help with right now?",
      prompt:
        "Be specific. If you could ask for anything and money wasn’t an object, what would it be? How might it affect your life if you got the help you needed?",
    },
  ],
};

export default questions;
