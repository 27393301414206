import React from "react";
import styles from "../modal/modal.module.scss";
import closeIcon from "components/nav/IconClose.svg";
import SVG from "react-inlinesvg";
import { CSSTransition } from "react-transition-group";
import ScrollLock from "react-scrolllock";

const VideoModal = ({ showModal, setShowModal }) => {

    return (
        <>
            <CSSTransition in={showModal} appear={true} unmountOnExit timeout={100}>
                <ScrollLock>
                    <div className={styles.outer} onMouseDown={() => setShowModal(false)}>
                        <div
                            className={styles.element}
                            onMouseDown={(e) => e.stopPropagation()}
                        >
                            <div className={styles.border}>
                                <button
                                    onClick={() => setShowModal(false)}
                                    className={styles.closeModal}
                                >
                                    <SVG src={closeIcon} alt="Close Modal"></SVG>
                                </button>
                                <div className={styles.inner}>
                                    <div className={styles.embedContainer}>
                                        <iframe
                                            width="560"
                                            height="315"
                                            src="https://www.youtube.com/embed/vpNLkdmvIUE?si=L_qNxaJxLr7Q4Q1Z"
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ScrollLock>
            </CSSTransition>
        </>
    );
};

export default VideoModal;