import React from "react";
import styles from "./audio.module.scss";
import { FILESTACK_CDN } from "const";

const Audio = ({ handle }) => {
  if (!handle) {
    return null;
  }
  return (
    <div className={styles.element}>
      <audio src={`${FILESTACK_CDN}/${handle}`} controls />
    </div>
  );
};

export default Audio;
