import React, { useContext } from "react";
import { ErrorMessage } from "@hookform/error-message";
import ThemeContext from "components/teaser/theme-context";
import styles from "./z-picker.module.scss";
import SVG from "react-inlinesvg";

function ZColorPicker({ options, errors, name, generateRef, current }) {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={styles.element}>
      <div className={styles.grid}>
        {options.map((option, i) => (
          <label
            key={`${option}-${i}`}
            //eslint-disable-next-line eqeqeq
            className={
              current === option.id
                ? `${styles.activeLabel} fg--${theme}`
                : styles.label
            }
          >
            <input
              className={styles.input}
              name={name}
              value={option.id}
              type="radio"
              ref={generateRef()}
            />
            <div className={`${styles.indicator}`}>
              <SVG src={option.icon}></SVG>
            </div>
            <div></div>
          </label>
        ))}
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="error">{message}</div>}
      />
    </div>
  );
}

export default ZColorPicker;
