import React from "react";
import { OutboundLink } from "react-ga";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faTiktok,
  faSnapchatGhost,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Social = ({ fields }) => {
  const removeAt = (handle) => handle.replace("@", "");

  return (
    <>
      {fields["Twitter"] && (
        <OutboundLink
          rel="noopner noreferrer"
          target="_blank"
          to={
            fields["Twitter"].indexOf("http:") === 0
              ? fields["Twitter"]
              : `https://twitter.com/${removeAt(fields["Twitter"])}`
          }
          eventLabel="twitter"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </OutboundLink>
      )}
      {fields["Facebook"] && (
        <OutboundLink
          rel="noopner noreferrer"
          target="_blank"
          to={
            fields["Facebook"].indexOf("http:") === 0
              ? fields["Facebook"]
              : `https://facebook.com/${removeAt(fields["Facebook"])}`
          }
          eventLabel="facebook"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </OutboundLink>
      )}
      {fields["Instagram"] && (
        <OutboundLink
          rel="noopner noreferrer"
          target="_blank"
          to={
            fields["Instagram"].indexOf("http:") === 0
              ? fields["Instagram"]
              : `https://instagram.com/${removeAt(fields["Instagram"])}`
          }
          eventLabel="instagram"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </OutboundLink>
      )}
      {fields["TikTok"] && (
        <OutboundLink
          rel="noopner noreferrer"
          target="_blank"
          to={
            fields["TikTok"].indexOf("http:") === 0
              ? fields["TikTok"]
              : `https://tiktok.com/${fields["TikTok"]}`
          }
          eventLabel="tiktok"
        >
          <FontAwesomeIcon icon={faTiktok} />
        </OutboundLink>
      )}
      {fields["SnapChat"] && (
        <OutboundLink
          rel="noopner noreferrer"
          target="_blank"
          to={
            fields["SnapChat"].indexOf("http:") === 0
              ? fields["SnapChat"]
              : `https://snapchat.com/add/${removeAt(fields["SnapChat"])}`
          }
          eventLabel="snapchat"
        >
          <FontAwesomeIcon icon={faSnapchatGhost} />
        </OutboundLink>
      )}
      {fields["LinkedIn"] && (
        <OutboundLink
          rel="noopner noreferrer"
          target="_blank"
          to={
            fields["LinkedIn"].indexOf("http:") === 0
              ? fields["LinkedIn"]
              : `https://linkedin.com/company/${removeAt(fields["LinkedIn"])}`
          }
          eventLabel="linkedin"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </OutboundLink>
      )}
    </>
  );
};

export default Social;
