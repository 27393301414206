import React, { useState, useRef, useCallback, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import usePosts from "components/usePosts";
import styles from "components/story-lookup/story-lookup.module.scss";
import providerStyles from "./provider-lookup.module.scss";

import { ErrorMessage } from "@hookform/error-message";
import { useStorageState } from "react-storage-hooks";

const searchFilter = [
  { col: "Display Name", heading: "Autocomplete", type: "text" },
];
// const reducer = (state, action) => {
//   // return ...state, blocks:[...state.blocks]
// };
// const useLookup = (id) => {
//   const [state, dispatch] = useStorageReducer(
//     window.location,
//     `lookup--${id}`,
//     reducter,
//     initialState
//   );
// };

const Lookup = ({ forwardRef, errors, setValue, name }) => {
  //   const [filters, setFilters] = useState(initFilters);
  const [search, setSearch] = useState("");
  const [textValue, setTextValue] = useState("");
  const [storedVal, setStoredVal] = useStorageState(
    localStorage,
    `${name}-val`,
    []
  );
  const {
    loading,

    data,
    //updating
  } = usePosts("/api/providers", search);

  if (loading) {
    return <>loading</>;
  }

  const handleChange = (e) => {
    const value = e.target.value;

    //if (value.length > 0) checkedItems[item][0] = [value.toLowerCase(), true];
    //else checkedItems[item][0] = [value.toLowerCase(), false];
    if (value.length === 0) {
      setSearch([{}]);
    } else
      setSearch([{ col: "Title Search", v: [[value.toLowerCase()], true] }]);
  };
  const addStory = (e, fields) => {
    const allStories = [...storedVal, fields];
    // setStories(allStories);
    setStoredVal(allStories);
    setTextValue("");
    setSearch([{}]);
    e.target.blur();
    setTimeout(() => triggerInput(name), 100);
  };
  const removeStory = (story) => {
    const newStories = storedVal.filter(
      (s) => s["Application ID"] !== story["Application ID"]
    );
    // setStories(newStories);
    setStoredVal(newStories);
    setTimeout(() => triggerInput(name), 100);
  };
  return (
    <div>
      {searchFilter.map((item, index) => {
        return (
          <div key={`col-${index}`} className={styles.autocompleter}>
            <Text
              onTypingEnd={handleChange}
              //   name={item.col}

              value={textValue}
              setValue={setTextValue}
            ></Text>

            <div className={styles.dropdown} tabIndex={-1}>
              {data &&
                data.map(({ fields, id }, index) => {
                  if (
                    storedVal.filter(
                      (story) =>
                        story["Application ID"] === fields["Application ID"]
                    ).length > 0
                  ) {
                    return "";
                  }
                  return (
                    <div key={`data-${index}`} tabIndex={0}>
                      <div
                        className={providerStyles.item}
                        tabIndex={0}
                        value={JSON.stringify({
                          id: fields["Application ID"],
                          slug: fields["Slug"],
                          title: fields["Title"],
                          org: fields["Org"],
                        })}
                        onClick={(e) => addStory(e, fields)}
                      >
                        <div className={providerStyles.title}>
                          {fields["Title"]}
                        </div>
                        <div className={providerStyles.org}>
                          {fields["Organization"]}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
      <div className={providerStyles.providers}>
        {storedVal.length > 0 ? (
          <>
            <h4>Selected Ideas</h4>
            <div className={providerStyles.providersInner}>
              {storedVal.map((provider, index) => (
                <div
                  key={`story-${index}`}
                  className={providerStyles.selectedProvider}
                >
                  <Link
                    to={`/afterschool-ideas/${provider["Slug"]}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className={providerStyles.title}>
                      {provider["Title"]}
                    </div>{" "}
                    <div className={providerStyles.org}>
                      {provider["Organization"]}
                    </div>
                  </Link>

                  <div
                    onClick={() => removeStory(provider)}
                    className={providerStyles.removeBtn}
                  >
                    remove
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>Add ideas by typing a name in the field above</>
        )}
        <input
          type="text"
          ref={forwardRef}
          id={name}
          readOnly
          name={name}
          value={JSON.stringify(
            storedVal.map((story) => {
              return { id: story["Application ID"], slug: story["Slug"] };
            })
          )}
        />
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="error"> {message}</div>}
      />
    </div>
  );
};

export default Lookup;
function triggerInput(enteredName, enteredValue) {
  const input = document.getElementById(enteredName);

  // const lastValue = input.value;

  // input.value = enteredValue;

  const event = new Event("input", { bubbles: true });

  // const tracker = input._valueTracker;

  // if (tracker) {

  //   tracker.setValue(lastValue);

  // }

  input.dispatchEvent(event);
}

const Text = ({
  type = "text",
  onTypingEnd,
  name,
  value,
  setValue,
  placeholder = "Type a Name",
}) => {
  const firstUpdate = useRef(true);
  const ref = useRef();
  const [inputData, setInputData] = useState();

  const [send, setSend] = useState(false);

  const typingDetect = useCallback(
    (e) => {
      setValue(e.target.value);
      clearTimeout(ref.current);

      setInputData({ ...e });
      const sendData = () => {
        setSend(true);
      };
      ref.current = setTimeout(() => {
        sendData();
      }, 750);
    },
    [setValue]
  );

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (send) onTypingEnd(inputData);
    setSend(false);
  }, [inputData, send, onTypingEnd]);

  return (
    <input
      type={type}
      autoComplete="off"
      name={name}
      onChange={typingDetect}
      value={value}
      placeholder={placeholder}
    />
  );
};
