import React, { useState } from "react";
import styles from "./student-application.module.scss";
import formLayout from "components/form/form-layout.module.scss";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Text from "components/form/text";
import RadioSet from "components/form/radio-set";
import CheckboxSet from "components/form/checkbox-set";
import errorMessages from "components/form/errorMessages";
import Question from "./question";
import axios from "axios";
import Email from "components/form/email";
import Select from "components/form/select";
import Link from "components/link";
import Tooltip from "components/tooltip";
import shape from "svgs/purple-z.svg";
import "./form.scss";
import RadioIconSet from "components/form/radio-icon-set";
import { useLocation } from "react-router-dom";
import themeData from "blocks/themes/data";
import { themes } from "components/teaser/theme-context";

import Success from "./success";
import { useEffect } from "react";
import { style } from "d3-selection";

import ColumnLayout from "layouts";
import { ThemeProvider } from "../teaser/theme-context";

const defaultValues = {
  firstName: "Dylan",
  lastName: "Viola",
  email: "notarealemail@hyperakt.com",
  word1: "aasdf",
  word2: "oipuqwe",
  word3: "fdsawefwaweff",
  genderIdentity: "2",
  genderSpecify: "me",
  birthday: "1987-11-19",
  ethnicity: ["6", "9", "13", "14"],
  ethnicitySpecify: "another ethnicity",
  usAncestry: ["2", "7"],
  city: "Brooklyn",
  state: "1",
  zip: "11232",
  liveWith: ["6", "11"],
  liveWithSpecify: "mooshy",
  liveWithCount: "6",
  timesMoved: "7",
  schoolTransportation: "3",
  afterschoolProgramCurrent: "0",
  afterschoolProgramBefore: "1",
  afterschoolTheme: "5",

  impact: ["12", "18", "20"],
  impactSpecify: "Specified Impact",
  emailSpecify: "1",
  //receiveTextAgreement: null,
};

const transportationVals = [
  "Walking",
  "Bike",
  "Scooter",
  "School Bus",
  "Public Transportation",
  "Someone driving you",
  "You driving yourself",
];

// const stateOptions = usStates.map(({ name, abbreviation }, i) => ({
//   value: i,
//   label: name,
// }));

const statuses = {
  SUCCESS: "success",
  LOADING: "loading",
  ERROR: "error",
};

const StudentApplication = () => {
  const [status, setStatus] = useState(null);
  const theme = themes[5];
  useEffect(() => {
    if (status === statuses.SUCCESS) {
      if (typeof window !== "undefined") {
        window.snaptr("track", "SIGN_UP");
        window.fbq("track", "Lead");
        window.gtag("event", "conversion", {
          send_to: "AW-481265390/NzLSCJCK0uYBEO6NvuUB",
        });
      }
    }
  }, [status]);

  return (
    <ThemeProvider value={theme}>
      <ColumnLayout shape={shape} styles={styles}>
        {status === statuses.SUCCESS ? (
          <Success />
        ) : (
          <Form status={status} setStatus={setStatus} />
        )}
      </ColumnLayout>
    </ThemeProvider>
  );
};

export default StudentApplication;

const Form = ({ status, setStatus }) => {
  const search = useLocation().search;
  const defaultForm = search === "?fill" ? { defaultValues } : undefined;
  const { register, handleSubmit, errors, watch, control } = useForm(
    defaultForm
  );
  const onSubmit = (data) => {
    console.log("submit", data);
    setStatus(statuses.LOADING);
    axios
      .post(
        `${
          process.env.REACT_APP_EXPRESS ? process.env.REACT_APP_EXPRESS : ""
        }/submit-student`,
        data
      )
      .then((res) => {
        console.log(res);
        setStatus(statuses.SUCCESS);
      })
      .catch((err) => {
        console.log(err.message);
        setStatus(statuses.ERROR);
      });
  };

  const watched = watch([
    "genderIdentity",
    "impact",
    "afterschoolTheme",
    "ethnicity",
    "liveWith",
    "cellphone",
  ]);

  return (
    <>
      <h1 className={styles.heading}>Apply to become a Generator</h1>

      <h2 className={styles.title}>What’s your story?</h2>
      <p className={style.copy}>
        Did you know that there are over half-a-million teens in Southeast
        Michigan and Western New York? Our goal is to move 1,000 teens to the
        front to influence afterschool organizations in future opportunities for
        young people. We are looking for real young people living real lives
        from all sorts of backgrounds. The stronger the representation and
        diversity, the more valuable the insight.&nbsp;
        <strong>All we ask is that you be you!</strong>
      </p>

      <h4
        className={styles.tooltipnote}
        data-for={"geographicalnote"}
        data-tip={
          "You must be between 13 to 18 years of age and live in Southeast Michigan (Wayne, Oakland, Macomb, Livingston, Monroe, St. Clair, Washtenaw counties) or Western New York (Allegany, Cattaraugus, Chautauqua, Erie, Genesee, Monroe, Niagara, Orleans, Wyoming counties). Only one application per person. Multiple applications will result in disqualification. Visit the FAQ page for more information."
        }
      >
        A Note on Eligibility*
      </h4>

      <Tooltip
        color="blue"
        copy="You must be between 13 to 18 years of age and live in Southeast Michigan (Wayne, Oakland, Macomb, Livingston, Monroe, St. Clair, Washtenaw counties) or Western New York (Allegany, Cattaraugus, Chautauqua, Erie, Genesee, Monroe, Niagara, Orleans, Wyoming counties). Only one application per person. Multiple applications will result in disqualification. Visit the FAQ page for more information."
        id="geographicalnote"
      />

      <h2 className={styles.title}>
        Quick &amp; Easy: 15 questions in 5 minutes
      </h2>
      <p className={style.copy}>
        The great news is that if you’ve made it here, you are half-way to
        completing the application form. Keep going. Fifteen multiple choice
        questions should take you 5-minutes. We will let you know if you’ve been
        selected for Generator Z later this month. 
      </p>

      <h2 className={styles.title}>
        Before You Begin: An email and cell phone
      </h2>
      <p>
        We will let you know our decision through email. An email is required. A
        cell phone is encouraged. If you do not have an email address, we
        recommend that you{" "}
        <Link
          url="https://accounts.google.com/signup/v2/webcreateaccount?hl=en&flowName=GlifWebSignIn&flowEntry=SignUp"
          text="sign-up for your own gmail here"
        />
        . Please note that multiple people (siblings or friends, for instance)
        cannot share the same email address to apply. If you do not have a cell
        phone, pick a person you trust (maybe a parent, guardian, grandparent,
        sibling, or friend) who will agree to receive text messages.
      </p>

      <h4
        className={styles.tooltipnote}
        data-for={"emailnote"}
        data-tip={
          "If you have the choice between a school email and a personal email, we recommend using a personal email so it's easier for us to contact you."
        }
      >
        A Note on Email*
      </h4>

      <Tooltip
        color="green"
        copy="If you have the choice between a school email and a personal email, we recommend using a personal email so it's easier for us to contact you."
        id="emailnote"
      />

      <h3 className={`${styles.title} ${styles.deadline}`}>
        Applications Close on November 20th
      </h3>

      <form
        className={styles.element}
        onSubmit={handleSubmit(onSubmit)}
        id="Form"
      >
        <Question question="What is your name?">
          <fieldset className={formLayout.half}>
            <Text
              name={"firstName"}
              label="First Name"
              forwardRef={register({
                required: errorMessages.required,
              })}
              errors={errors}
            />
            <Text
              name={"lastName"}
              label="Last Name"
              forwardRef={register({
                required: errorMessages.required,
              })}
              errors={errors}
            />
          </fieldset>
        </Question>
        <Question question="Imagine that someone who knows you well is asked to describe you in three words. <br/>What are those words?">
          <fieldset className={formLayout.thirds}>
            <Text
              name={"word1"}
              label=""
              forwardRef={register({
                required: errorMessages.required,
              })}
              errors={errors}
            />
            <Text
              name={"word2"}
              label=""
              forwardRef={register({
                required: errorMessages.required,
              })}
              errors={errors}
            />
            <Text
              name={"word3"}
              label=""
              forwardRef={register({
                required: errorMessages.required,
              })}
              errors={errors}
            />
          </fieldset>
        </Question>
        <Question question="What are your gender pronouns?">
          <RadioSet
            name="genderIdentity"
            options={[
              "He/Him/His",
              "She/Her/Hers",
              "They/Them/Theirs",
              "Other",
            ]}
            generateRef={() => register({ required: errorMessages.required })}
            errors={errors}
          />
          {watched.genderIdentity && watched.genderIdentity === "3" && (
            <Text
              className={styles.indentText}
              name={"genderSpecify"}
              forwardRef={register({
                required: errorMessages.required,
              })}
              errors={errors}
            />
          )}
        </Question>
        <Question question="When is your birthday?">
          <input
            className={styles.inputDate}
            type="date"
            name="birthday"
            aria-invalid={errors["birthday"] ? "true" : "false"}
            ref={register({ required: errorMessages.required })}
          />

          <ErrorMessage
            errors={errors}
            name={"birthday"}
            render={({ message }) => <div className="error">{message}</div>}
          />
        </Question>
        <Question question="How do you identify? (Select all that apply)">
          <CheckboxSet
            name={"ethnicity"}
            options={[
              "Black or African-American",
              "African",
              "Afro-Carribean",
              "North African",
              "Latinx or Hispanic",
              "Middle Eastern or Arab",
              "White or Caucasian",
              "European",
              "Asian",
              "East Asian",
              "Southeast Asian",
              "South Asian",
              "Native American or Alaskan Native",
              "Native Hawaiian or Other Pacific Islander",
              "Something Else / Specify",
              "Unknown",
            ]}
            generateRef={() => register({ required: errorMessages.required })}
            errors={errors}
          />
          {watched.ethnicity && watched.ethnicity.includes("14") && (
            <Text
              className={styles.indentText}
              name={"ethnicitySpecify"}
              forwardRef={register({
                required: errorMessages.required,
              })}
              errors={errors}
            />
          )}
        </Question>
        <Question
          question="Where does your American story begin? (Select all that apply)"
          helper="Select all that apply"
        >
          <CheckboxSet
            name={"usAncestry"}
            options={[
              "First Generation: I was born in the U.S.",
              "Second Generation: A parent was born in the U.S ",
              "Third Generation: A grandparent was born in the U.S",
              "Fourth Generation: A great-grandparent was born in the U.S.",
              "Fifth Generation and beyond",
              "I am a descendant of enslaved peoples",
              "I am Native American",
              "I am an immigrant",
              "I am a refugee",
              "I am not sure",
            ]}
            generateRef={() => register({ required: errorMessages.required })}
            errors={errors}
          />
        </Question>
        <Question question="What is your city / state / zip code?">
          <fieldset>
            <Text
              name={"city"}
              label="City"
              forwardRef={register({
                required: errorMessages.required,
              })}
              errors={errors}
            />
            <div className={styles.pad}>
              <RadioSet
                name="state"
                options={["Western New York", "Southeast Michigan"]}
                generateRef={() =>
                  register({ required: errorMessages.required })
                }
                errors={errors}
              />
            </div>
            <Text
              name={"zip"}
              label="Zip Code"
              forwardRef={register({
                required: errorMessages.required,
              })}
              errors={errors}
            />
          </fieldset>
        </Question>
        <Question
          question="Who do you live with? (Select all that apply)"
          helper="Select all that apply"
        >
          <CheckboxSet
            name={"liveWith"}
            options={[
              "Mom",
              "Another Mom or Stepmom",
              "Dad",
              "Another Dad or Stepdad",
              "Grandma",
              "Grandpa",
              "Uncle",
              "Aunt",
              "Foster Family",
              "Sibling",
              "Friend",
              "Other",
            ]}
            generateRef={() => register({ required: errorMessages.required })}
            errors={errors}
          />
          {/* {watched.liveWith && watched.liveWith.includes("11") && (
            <Text
              className={styles.text}
              name={"liveWithSpecify"}
              label="Who else do you live with?"
              forwardRef={register({
                required: errorMessages.required,
              })}
              errors={errors}
            />
          )} */}
        </Question>

        <Question question="">
          <Select
            control={control}
            name={"liveWithCount"}
            label="How many people, including yourself, live with you?"
            options={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10+"]}
            forwardRef={register({ required: errorMessages.required })}
            errors={errors}
          />
        </Question>

        <Question question="">
          <Select
            control={control}
            name={"timesMoved"}
            label="How many times have you moved in the last three years?"
            options={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10+"]}
            onChange={(val) => {
              console.log(val);
            }}
            forwardRef={register({ required: errorMessages.required })}
            errors={errors}
          />
        </Question>

        <Question question="Before Covid-19, how did you mostly get around?">
          <RadioSet
            name="schoolTransportation"
            options={transportationVals}
            generateRef={() => register({ required: errorMessages.required })}
            errors={errors}
          />
        </Question>

        <Question
          question="Do you currently participate in an afterschool program?"
          tooltip=""
        >
          <RadioSet
            name="afterschoolProgramCurrent"
            options={["Yes", "No"]}
            generateRef={() => register({ required: errorMessages.required })}
            errors={errors}
          />
        </Question>

        <Question question="Did you participate in an afterschool program before covid-19?">
          <RadioSet
            name="afterschoolProgramBefore"
            options={["Yes", "No"]}
            generateRef={() => register({ required: errorMessages.required })}
            errors={errors}
          />
        </Question>

        <Question question="What afterschool theme (and related ideas) will you address if you are selected as a Generator? (Select Only One)">
          <RadioIconSet
            name="afterschoolTheme"
            current={watched.afterschoolTheme}
            options={themeData}
            generateRef={() => register({ required: errorMessages.required })}
            errors={errors}
          />
        </Question>

        <Question question="OPTIONAL: We understand that young people are real people. Generator Z is a safe space to share your story. If you choose to answer this last question, your information will be kept private: Have you, your family, or your friends been impacted by any of the following? If so, select all that apply. If not, select none.">
          <CheckboxSet
            name={"impact"}
            options={[
              "Abuse",
              "Alcohol",
              "Assault",
              "Bullying",
              "Covid-19",
              "Death",
              "Domestic Violence",
              "Drugs",
              "Financial Challenges",
              "Homelessness",
              "Homicide",
              "Immigration Issues",
              "Incarceration",
              "Job Loss",
              "LGBTQ+ Discrimination",
              "Mental Health",
              "Racial Injustice",
              "Separation or Divorce",
              "Violence",
              "None",
              "Other",
            ]}
            generateRef={() => register()}
            errors={errors}
          />
          {/* {watched.impact && watched.impact.includes("20") && (
            <Text
              className={styles.indentText}
              name={"impactSpecify"}
              forwardRef={register({
                required: errorMessages.required,
              })}
              errors={errors}
            />
          )} */}
        </Question>
        <Question>
          <h2 className={styles.title}>How do we reach you?</h2>
          <p>
            Provide both forms of contact information if you can! An email is
            required. A cell phone is recommended.
          </p>
          <p className={styles.warning}>
            Each person must use a unique email address to submit.
          </p>
          <fieldset className={formLayout.half}>
            <Email
              name={"email"}
              label="Email"
              forwardRef={register({
                required: errorMessages.required,
              })}
              errors={errors}
            />
            <div>
              <label className={styles.label}>
                Who does this email belong to?
              </label>
              <RadioSet
                name="emailSpecify"
                options={["Me", "Someone else"]}
                generateRef={() =>
                  register({ required: errorMessages.required })
                }
                errors={errors}
              />
            </div>
          </fieldset>

          <fieldset className={`${formLayout.half} pad`}>
            <Text
              name={"cellphone"}
              label="cell phone"
              type="tel"
              forwardRef={register}
              errors={errors}
            />
            <div>
              <label className={styles.label}>
                Who does this cell phone belong to?
              </label>
              <RadioSet
                name="cellphoneSpecify"
                options={["Me", "Someone else"]}
                generateRef={() => register({ trigger: errorMessages.trigger })}
                errors={errors}
              />
            </div>
          </fieldset>
          {watched.cellphone && (
            <div className={formLayout.half}>
              <div />
              <CheckboxSet
                name="receiveTextAgreement"
                options={[
                  "I agree to receive text messages about my application and Generator Z opportunities",
                ]}
                generateRef={() => register()}
                errors={errors}
              />
            </div>
          )}
        </Question>

        <div className={styles.fieldset}>
          <div className={styles.submitMessage}>
            {status === statuses.ERROR && (
              <p className={styles.error}>
                A server error occured. Please try again in a few moments. If
                you continue experiencing issues, please email us at{" "}
                <a
                  href="mailto:info@generator-z.org"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  info@generator-z.org
                </a>
                .
              </p>
            )}
            {status === statuses.LOADING && <p>Sending...</p>}
          </div>
          <button className={formLayout.submit} type="submit">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};
