import React from "react";
import ReactDropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./dropdown.scss";
import { Controller } from "react-hook-form";

const Dropdown = ({ control, name, value, defaultValue, ...props }) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{ required: "This field is required" }}
        render={({ onChange, value, name, updateField }) => (
          <ReactDropdown
            className="Dropdown"
            {...props}
            name={name}
            value={value}
            onChange={(e) => {
              onChange(e.value);
            }}
          />
        )}
      />
    </>
  );
};

export default Dropdown;
