export const counties = {
  Michigan: [
    { name: "Wayne", state: "Michigan" },
    { name: "Oakland", state: "Michigan" },
    { name: "Macomb", state: "Michigan" },
    { name: "Livingston", state: "Michigan" },
    { name: "Monroe", state: "Michigan" },
    { name: "St. Clair", state: "Michigan" },
    { name: "Washtenaw", state: "Michigan" },
  ],

  "New York": [
    { name: "Allegany", state: "New York" },
    { name: "Cattaraugus", state: "New York" },
    { name: "Chautauqua", state: "New York" },
    { name: "Erie", state: "New York" },
    { name: "Genesee", state: "New York" },
    { name: "Monroe", state: "New York" },
    { name: "Niagara", state: "New York" },
    { name: "Orleans", state: "New York" },
    { name: "Wyoming", state: "New York" },
  ],
};
