import React, { useContext, useState, useEffect } from "react";
import Logo from "components/logo";
import styles from "./provider-directory.module.scss";
import Footer from "components/footer";
import Social from "components/providers/provider/social";
import NavOverlay from "components/nav/overlay";

import usePosts from "components/usePosts";
import GoogleMapReact from "google-map-react";
import ThemeContext from "components/teaser/theme-context";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import NotFound from "components/not-found";
import { OutboundLink } from "react-ga";
import { normalizeURL } from "helpers";

const Marker = ({ text, index, clickEvent, selected, section, grantee }) => {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <HashLink
      className={`${styles.marker} ${
        selected === `place-${index}` ? styles.selected : ""
      } ${grantee ? styles.granteeMarker : ""} `}
      onClick={clickEvent}
      smooth
      to={`/provider-directory/${section}#place-${index}`}
      scroll={(el) => scrollWithOffset(el)}
    >
      <span>{text}</span>
    </HashLink>
  );
};

const MapWrap = ({ section }, props) => {
  const [region, setRegion] = useState(
    section === "new-york"
      ? "New York"
      : section === "michigan"
      ? "Michigan"
      : null
  );
  const [filters, setFilters] = useState([{ col: "Region", v: [region] }]);
  const { loading, error, data } = usePosts(`/api/providers`, filters);

  const [places, setPlaces] = useState([]);
  const [selected, setSelected] = useState(null);

  const { theme } = useContext(ThemeContext);

  // const onInfoWindowClose = () => {};

  // const mapLoaded = (mapProps, map) => {
  //   map.setOptions({ styles: mapStyle });
  // };
  useEffect(() => {
    setRegion(
      section === "new-york"
        ? "New York"
        : section === "michigan"
        ? "Michigan"
        : null
    );
    setPlaces([]);
  }, [region, section]);
  useEffect(() => {
    setFilters([{ col: "Region", v: [region] }]);
  }, [region]);

  useEffect(() => {
    if (data) {
      const points = processPoints(data);
      setPlaces(points);
    }
  }, [data]);

  const processPoints = (points) => {
    return points.map((place, index) => ({
      title: place.fields.Organization,
      lat: +place.fields.Lat,
      lng: +place.fields.Lng,
      index: index,

      ...place.fields,
    }));
  };

  if (loading) {
    return <></>;
  }
  if (!region) {
    return (
      <>
        <NotFound />
      </>
    );
  }

  if (loading) {
    return null;
  }
  // if (updating) {
  //   return <>updating</>;
  // }
  if (error) {
    return <>error</>;
  }

  return (
    <div className={styles.element}>
      <div className={styles.wrapper}></div>
      <div className={styles.border}></div>
      <div className={styles.mobileLogo}>
        <Logo theme={theme}></Logo>
      </div>

      <div className={styles.places}>
        <div className={styles.logo}>
          <Logo theme={theme}></Logo>
          <div className={styles.toggle}>
            <Link
              to={`/provider-directory/${
                section === "new-york" ? "michigan" : "new-york"
              }`}
              // onClick={() => {
              //   setPlaces([]);
              // }}
            >
              {section === "new-york" ? "Michigan" : "New York"}
            </Link>
          </div>
        </div>
        <h1>
          {section === "new-york"
            ? "Western New York"
            : section === "michigan"
            ? "Southeastern Michigan"
            : ""}
        </h1>
        <p>
          Make your way to an afterschool provider virtually or physically!
          Please note that, at times, providers are headquartered in a different
          location from where they serve teens. Check websites and social media
          for full details.
        </p>
        <ul>
          {/* {places.map((place, index) => (
            <li
              key={index}
              onClick={() => {
                highlightMarker(place.title);
              }}
            >
              <h3>{place.title}</h3>
              <div className={styles.email}>{place.email}</div>
              <div className={styles.twitter}>{place.twitter}</div>
            </li>
          ))} */}
          {places.map((place, index) => {
            return (
              <li
                key={`place-${index}`}
                id={`place-${index}`}
                className={`${
                  selected === `place-${index}` ? styles.itemSelected : ""
                } ${styles.listItem}`}
              >
                <button onClick={() => setSelected(`place-${index}`)}>
                  <h3 className={`${place.Grantees ? styles.granteeWrap : ""}`}>
                    <span>{index + 1}.</span> {place.title}
                  </h3>
                  {place.Grantees ? (
                    <div className={styles.grantee}>
                      <span role="img" aria-label="sparkle">
                        ✨
                      </span>{" "}
                      2021 Grantee
                    </div>
                  ) : (
                    ""
                  )}
                </button>
                <div className={styles.location}>
                  {place.City},{" "}
                  {place.Region === "Southeast Michigan"
                    ? "Michigan"
                    : "New York"}
                </div>
                <div className={styles.link}>
                  <OutboundLink
                    eventLabel="website"
                    to={normalizeURL(place["Website"])}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Website
                  </OutboundLink>
                </div>
                <div className={styles.directions}>
                  <OutboundLink
                    eventLabel="directions"
                    target="_blank"
                    rel="noopener noreferrer"
                    to={`https://www.google.pl/maps/dir//${place.title}/@${place.lat},${place.lng}`}
                  >
                    Get Directions
                  </OutboundLink>
                </div>
                <div className={styles.social}>
                  <Social fields={place} />
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      <div className={styles.map}>
        {places && (
          <Map
            places={places}
            setClicked={setSelected}
            selected={selected}
            section={section}
          />
        )}
      </div>

      <div className={styles.footer}>
        <Footer noPad={true}></Footer>
      </div>
      <NavOverlay />
    </div>
  );
};

const Map = ({ places, setClicked, selected, section }) => {
  // const [selectedProp, setSelectedProp] = useState();
  // const highlightMarker = (id) => {
  //   setSelectedProp({
  //     selectedPlace: { name: id },
  //     // activeMarker: marker,
  //     // showingInfoWindow: true,
  //   });
  // };
  const markerClick = (place) => {
    setClicked(`place-${place.index}`);
    // setSelectedProp({
    //   selectedPlace: props,
    //   activeMarker: marker,
    //   showingInfoWindow: true,
    // });
    // console.log(props);
  };

  const getMapBounds = (map, maps, locations) => {
    const bounds = new maps.LatLngBounds();

    locations.forEach((location) => {
      bounds.extend(new maps.LatLng(location.lat, location.lng));
    });

    return bounds;
  };

  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  const apiIsLoaded = (map, maps, locations) => {
    if (map) {
      const bounds = getMapBounds(map, maps, locations);
      map.fitBounds(bounds);
      bindResizeListener(map, maps, bounds);
    }
  };
  return (
    <>
      {places && (
        <GoogleMapReact
          key={`${places.length}`}
          // ref=()
          onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, places)}
          options={{ styles: mapStyle, fullscreenControl: false }}
          bootstrapURLKeys={{
            key: "AIzaSyBiSccZn76xZV1qayhuUOKvPRLrXzEUoFo",
          }}
          defaultCenter={{ lat: 30.27009214378759, lng: -97.72696668854319 }}
          defaultZoom={8}
        >
          {places.map((place, index) => {
            //const isSelected = selectedProp?.selectedPlace.name === place.title;
            //console.log("Marker", fields.Lat, fields.Lng);
            return (
              <Marker
                section={section}
                clickEvent={() => markerClick(place)}
                key={`point-${index}`}
                name={place.title}
                index={index}
                selected={selected}
                lat={place.lat}
                lng={place.lng}
                text={`${index + 1}`}
                grantee={place.Grantees}
              />
            );
          })}
        </GoogleMapReact>
      )}
    </>
  );
};

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyBiSccZn76xZV1qayhuUOKvPRLrXzEUoFo",
// })(MapWrap);
export default MapWrap;

const mapStyle = [
  {
    featureType: "all",
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: 36,
      },
      {
        color: "#000000",
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#000000",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 17,
      },
    ],
  },
];
