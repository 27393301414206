import React from "react";
import styles from "./upload.module.scss";

const Upload = ({ media, filename, type }) => {
  return (
    <div className={`${styles.element} ${styles[type]}`}>
      <div>{media}</div>
      <div className={styles.filename}>{filename}</div>
    </div>
  );
};

export default Upload;
