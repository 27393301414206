import React from "react";
import Success from "components/form/success";
import { OutboundLink } from "react-ga";

const PaymentFormSuccess = () => {
  return (
    <Success
      heading="Success!"
      body={
        <p>
          Thank you for confirming your work! If you have any questions, please
          contact{" "}
          <OutboundLink eventLabel="email" to="mailto:info@generator.org">
            info@generator-z.org
          </OutboundLink>
          .
        </p>
      }
    />
  );
};

export default PaymentFormSuccess;
