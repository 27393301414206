import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Prototypes from "components/prototypes";
import ProgramGuidelines from "components/program-guidelines";
import FAQ from "components/faq";
import ContactUs from "components/contact-us";
import Teaser from "components/teaser";
import ScrollMemory from "react-router-scroll-memory";
import Stories from "components/stories/stories-directory";
import Story from "components/stories/story";
import StoriesForm from "components/stories/stories-form";
import { IDNotFound } from "components/id-check";
import StoriesFormSuccess from "components/stories/stories-form-success";
import PaymentFormSuccess from "components/payment-form/payment-form-success";
import Resources from "components/resources";
import PaymentForm from "components/payment-form";
import TermsAndConditions from "components/terms-and-conditions";
import Providers from "components/providers";
import Provider from "components/providers/provider";
import ProviderDirectory from "components/providers/provider-directory";
import ProviderDirectoryLanding from "components/providers/provider-directory/provider-directory-landing";
import ConfirmationForm from "components/confirmation-form/confirmation-form-copy";
import GeneratorConfirmed from "components/confirmation-form/success";
import GeneratorConfirmed2 from "components/confirmation-form/success-copy";

import ProviderApplication from "components/provider-application";
import Team from "components/team";
import StudentApplication from "components/student-application";
import { FilterProvider } from "components/stories/filter-context";
import ProviderSixMonthProgress from "components/provider-six-month-progress";
import ProviderFinalProgress from "components/provider-final-progress";
import ProviderCompletion from "components/provider-completion";
import ZtoZ from "components/z-to-z";

const App = () => {
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === "") {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace("#", "");
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash, key]); // do this on route change
    return (
        <>
            <ScrollMemory />
            <FilterProvider>
                <Switch>
                    {/* <Route path="/" component={Page} /> */}
                    <Route path="/prototypes" component={Prototypes} />
                    <Route path="/program-guidelines" component={ProgramGuidelines} />
                    <Route path="/resources" component={Resources} />
                    <Route path="/faq" component={FAQ} />
                    <Route path="/contact-us" component={ContactUs} />
                    <Route path="/stories/add/success" component={StoriesFormSuccess} />
                    <Route path="/payment-info/success" component={PaymentFormSuccess} />
                    <Route path="/payment-info/:surveyid" component={PaymentForm} />
                    {/* <Route path="/student-app/" component={StudentApplication} /> */}
                    <Route
                        path="/internal/stories/add/:surveyid"
                        component={StoriesForm}
                    />
                    <Route
                        path="/internal/provider/apply"
                        component={ProviderApplication}
                    />
                    <Route
                        path="/internal/generator/apply"
                        component={StudentApplication}
                    />
                    <Route
                        path="/internal/generator/story"
                        component={StudentApplication}
                    />
                    <Route
                        path="/provider/final-completion-report"
                        component={ProviderCompletion}
                    />
                    <Route
                        path="/provider/final-progress-report"
                        component={ProviderFinalProgress}
                    />
                    <Route
                        path="/provider/6-month-progress-report"
                        component={ProviderSixMonthProgress}
                    />
                    <Route
                        path="/internal/provider/6-month-progress-report"
                        component={ProviderSixMonthProgress}
                    />
                    <Route
                        path="/provider-directory/:slug"
                        component={ProviderDirectory}
                    />
                    <Route
                        path="/provider-directory"
                        component={ProviderDirectoryLanding}
                    />
                    <Route path="/stories/add/" component={IDNotFound} />
                    <Route path="/afterschool-ideas/:slug/6-month" component={Provider} />
                    <Route path="/afterschool-ideas/:slug" component={Provider} />
                    <Route path="/afterschool-ideas" component={Providers} />
                    <Route path="/payment-info" component={IDNotFound} />
                    <Route path="/stories/review/:storyid" component={Story} />
                    <Route path="/stories/:slug" component={Story} />
                    <Route path="/stories" component={Stories} />
                    <Route path="/confirm" component={ConfirmationForm} />
                    <Route
                        path="/generator-confirmed-2"
                        component={GeneratorConfirmed2}
                    />
                    <Route path="/generator-confirmed" component={GeneratorConfirmed} />
                    <Route path="/terms-and-conditions" component={TermsAndConditions} />
                    <Route path="/team" component={Team} />
                    <Route path="/z-to-z" component={ZtoZ} />
                    <Route path="/" component={Teaser} />
                </Switch>
            </FilterProvider>
        </>
    );
};

export default App;
