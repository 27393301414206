import React, { useRef, useEffect, useState, useContext } from "react";
import styles from "./story.module.scss";
import Themes from "./themes";
import Storytellers from "./storytellers";
import Roundtable from "./roundtable";
import Ready from "./ready";
import Section from "components/section";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import ThemeContext from "../theme-context";
gsap.registerPlugin(ScrollTrigger);

const Story = () => {
    const ref = useRef();
    const { setNavGrid } = useContext(ThemeContext);
    useEffect(() => {
        const t = ScrollTrigger.create({
            trigger: ref.current,
            start: "top center",
            end: "bottom center",
            onLeaveBack: () => {
                setNavGrid(null);
            },
        });
        return () => t.kill();
    }, [setNavGrid]);
    return (
        <Section
            id="TheFoundation"
            theme="black"
            className={styles.element}
            forwardRef={ref}
            onEnter={() => console.log("RESET HEAD")}
        >
            <div className={styles.inner}>
                <HeaderSteps setNavGrid={setNavGrid} />
                <Ready setNavGrid={setNavGrid} />
            </div>
        </Section>
    );
};

export default Story;

const content = {
    kickstarted: {
        heading: "Kickstarted by data",
        text: "Before Generator Z, we asked 10,000 teens across Southeast Michigan and Western New York what they wanted when they were not in school. We worked all that data down to 10 themes! Teens and afterschool providers selected one of these ten themes when they applied to Generator Z. You can filter <a href='/stories'>Generator Stories</a> and <a href='/afterschool-ideas'>Afterschool Grants</a> by any of these themes.",
    },
    shaped: {
        heading: "Shaped by collaborative design",
        text: "A multigenerational roundtable of teens and adults collaboratively designed Generator Z. They  came together through multiple workshops to shape the vision and validate the direction. Check out this video. And check out our <a href='/team'>Team</a> page for the agencies and individuals that implemented the initiative.",
    },
};

const HeaderSteps = ({ setNavGrid }) => {
    const ref = useRef();
    const headerRef = useRef();
    useEffect(() => {
        let t;
        ScrollTrigger.matchMedia({
            "(min-width:0px)": () => {
                t = gsap.to(ref.current, {
                    duration: 1,
                    scrollTrigger: {
                        trigger: ref.current,
                        start: "top top",
                        end: "bottom bottom",
                        toggleActions: "play pause resume reverse",
                        pin: headerRef.current,
                    },
                    autoAlpha: 1,
                });
                return () => {
                    t && t.scrollTrigger.kill();
                    ScrollTrigger.clearMatchMedia();
                };
            },
        });
    }, []);

    const [headerData, setHeaderData] = useState(content.shaped);

    return (
        <div className={styles.headerSteps} ref={ref}>
            <div className={styles.header}>
                <style>.pin-spacer:width:0 !important;</style>
                <Header forwardRef={headerRef} {...headerData} />
            </div>
            <div className={styles.body}>
                {/* <Themes onEnter={() => setHeaderData(content.kickstarted)} /> */}
                <Storytellers />
                <Roundtable
                    onEnter={() => {
                        setHeaderData(content.shaped);
                    }}
                    onLeaveBack={() => {
                        setHeaderData(content.shaped);
                    }} />
            </div>
        </div>
    );
};
const Header = ({ forwardRef, heading, text }) => {
    return (
        <div className={styles.headerContent} ref={forwardRef}>
            <h2>{heading}</h2>
            <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
    );
};
