import React from "react";
import styles from "./how-it-works.module.scss";
import Steps from "./steps";

const HowItWorks = ({
    heading,
    buttonLink,
    buttonText,
    steps,
    index,
    shape,
    progressShape,
    anchorPrefix,
    backgroundColor,
}) => {
    return (
        <div className={styles.element}>
            <Steps
                heading={heading}
                buttonLink={buttonLink}
                buttonText={buttonText}
                steps={steps}
                index={index}
                shape={shape}
                progressIcon={progressShape}
                anchorPrefix={anchorPrefix}
                backgroundColor={backgroundColor}
            />
        </div>
    );
};

export default HowItWorks;
