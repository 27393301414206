import React from "react";
import Logo from "components/logo";
import Footer from "components/footer";
import styles from "./contact-us.module.scss";
import layout from "scss/layout.module.scss";
import NavOverlay from "components/nav/overlay";
import SVG from "react-inlinesvg";
import shape from "./shape.svg";
import { ThemeProvider } from "components/teaser/theme-context";

const ContactUs = () => {
    const theme = "green";
    return (
        <ThemeProvider value={theme}>
            <div className={`${styles.element} ${theme}`}>
                <Logo theme={theme} />{" "}
                <div className={layout.columns}>
                    <div className={layout.column_inner}>
                        <div className={layout.sidebar}>
                            <div className={layout.shape}>
                                <SVG src={shape}></SVG>
                            </div>
                        </div>
                        <section className={`${layout.content} ${styles.content}`}>
                            <div className={layout.inner}>
                                <h1 className={layout.heading}>Contact Us</h1>
                                <h3 className={styles.sectionTitle}>
                                    If you are interested in learning more about Generator Z, please contact:
                                </h3>
                                <p>
                                    Email:{" "}
                                    <a
                                        href="mailto:info@ralphcwilsonjrfoundation.org
"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        info@ralphcwilsonjrfoundation.org

                                    </a>
                                    <br />
                                    Call:{" "}
                                    <a
                                        href="tel:313.885.1895"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        313.885.1895
                                    </a>
                                </p>
                                {/* <h3 className={styles.sectionTitle}>
                                    For Press Inquiries Contact:
                                </h3>
                                <p>
                                    Amani Olu
                                    <br /> Olu & Company
                                    <br />{" "}
                                    <a href="mailto:amani@olucompany.com">amani@olucompany.com</a>
                                </p> */}
                            </div>
                        </section>
                    </div>
                </div>
                <Footer></Footer>
                <NavOverlay />
            </div>
        </ThemeProvider>
    );
};

export default ContactUs;
