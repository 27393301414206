import { useState, useEffect } from "react";
import axios from "axios";
const qs = require("qs");

const usePosts = (apiRoute, filters) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    setUpdating(true);
    axios
      .get(apiRoute, {
        params: { filters },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
        setUpdating(false);
      })
      .catch((err) => {
        console.log("Error Tracked", err);
        setLoading(false);
        setError(true);
      });
    //React Hook useEffect has a missing dependency: 'filtered'. Either include it or remove the dependency array
  }, [filters, apiRoute]);

  return { data, loading, error, updating };
};

export default usePosts;
