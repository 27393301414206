import React from "react";
import styles from "./theme.module.scss";

const Theme = ({
  icon,
  heading,
  subheading,
  plainText,
  items,
  collapsed,
  active,
  onClick,
}) => {
  return (
    <button
      className={`${styles.theme} ${collapsed && styles.collapsed} ${
        active && styles.active
      }`}
      onClick={onClick}
    >
      <div className={styles.header}>
        <div className={styles.icon}>
          <img src={icon} alt={`icon for ${heading}`} />
        </div>
        <div className={styles.heading}>{heading}</div>
      </div>
      {collapsed ? null : active ? (
        <Items items={items} subheading={subheading} />
      ) : (
        <div className={styles.inner}>
          <div className={styles.description}>{plainText}</div>
        </div>
      )}
    </button>
  );
};

export default Theme;

const Items = ({ items, subheading }) => {
  return (
    <div className={styles.itemsWrapper}>
      <div className={styles.itemHeading}>{subheading}</div>
      <div className={styles.items}>
        {items.map(({ id, heading, plainText }) => {
          return (
            <div key={id} className={styles.item}>
              <div className={styles.itemHeading}>{heading}</div>
              <div>{plainText}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
