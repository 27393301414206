import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import styles from "./radio-set.module.scss";

const RadioSet = ({ name, options, generateRef, errors }) => {
  return (
    <fieldset
      className={options.length > 2 ? styles.element : styles.elementSm}
    >
      {options.map((option, i) => (
        <div className={styles.labelWrap} key={`option-${i}`}>
          <label className={styles.label}>
            <input
              className={styles.input}
              name={name}
              value={option.value ? option.value : i}
              type="checkbox"
              ref={generateRef()}
              checked={option.checked}
              disabled={option.disabled}
            />
            <span className={styles.checkbox} />
            {option.label ? option.label : option}
          </label>
        </div>
      ))}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="error">{message}</div>}
      />
    </fieldset>
  );
};

export default RadioSet;
