import React, { useState, useEffect, useRef, useContext } from "react";
import ColumnLayout from "layouts/columns";
import styles from "./team.module.scss";
import { ThemeProvider } from "components/teaser/theme-context";
import layout from "scss/layout.module.scss";
import { HashLink } from "react-router-hash-link";
import YouTube from "react-youtube";

// import TileContent from "../story/tileContent";

import Masonry from "react-masonry-component";
import { useHistory } from "react-router-dom";
import Filters from "components/filters";
import FilterContext from "components/stories/filter-context";

import SVG from "react-inlinesvg";
// import expand from "./expand.svg";
import filterOpts from "./filter-opts";
import { processFilters } from "helpers";
import usePosts from "components/usePosts";
import expand from "components/stories/stories-directory/expand.svg";

import image1 from "./team-pics/Abir Ali_Head Shot - Abir Ali.jpg";
import image2 from "./team-pics/IMG_20200719_1157114 - Addie Glickstein.jpg";
import image3 from "./team-pics/image - Aidan Bradfield-MacKenzie.jpg";
import image4 from "./team-pics/EFC56AC1-CCAC-470F-BB7C-4C7B3C895683.jpg";
import image5 from "./team-pics/7CDC4F17-4441-48D4-A4ED-ABCC599734F2 - Akhila Mullapudi.jpg";
import image6 from "./team-pics/Alexis_Vondran_Headshot - Alexis Vondran.jpg";
import image7 from "./team-pics/alr - Allison Rabent.jpg";
import image8 from "./team-pics/8F6D25B4-8C93-4724-9E52-6D9FB996F667 - Amani Olu.jpg";
import image9 from "./team-pics/Amber Slichta Headshot - Amber Slichta.jpg";
import image10 from "./team-pics/CD9021AE-6C78-4AD6-9BBF-7203D48D9D53 - Ambiance Wright.jpg";
import image11 from "./team-pics/DCA7DF71-81E9-411D-9E98-51437584CE28 - Amy Sacka.jpg";
import image12 from "./team-pics/ANDREA - Andrea Minor.jpg";
import image13 from "./team-pics/ANDREA_CU_GLASSES_SMILE - andrea williams.jpg";
import image14 from "./team-pics/andy_working-CMYK_headshot - Andrew Kopietz.jpg";
import image15 from "./team-pics/Ann_Nguyen-1-e1565015816783-768x961 - Ann Thúy Nguyễn.jpg";
import image16 from "./team-pics/IMG_4704 - Avery Eenigenburg.jpg";
import image17 from "./team-pics/E28EEAE5-CA96-4D84-8A00-D10C31BEA5A2 - Ayesha N.jpg";
import image18 from "./team-pics/Benjamin_Gluck - Erin Thompson.jpg";
import image19 from "./team-pics/5881E08B-B5EC-41F1-90C4-A4DDFD3BF492 - Bridget Christian.jpg";
import image20 from "./team-pics/Caelan_Soma - Erin Thompson.jpg";
import image21 from "./team-pics/Caitlin Bowen - Caitlin Bowen.jpg";
import image22 from "./team-pics/gray poncho selfie - Carla stough huffman.jpg";
import image23 from "./team-pics/Photo_20191002_141039 - Carly Strachan.jpg";
import image24 from "./team-pics/olucompany-000165020011 - Carrie Paveglio.jpg";
import image25 from "./team-pics/ECA6F60D-4D93-4997-BB21-E0BE42191F94_1_105_c - Catherine Pink.jpg";
import image26 from "./team-pics/62775945874__EDF49B80-5465-4CD9-93A8-E6CA2268C146 - Erin Thompson.jpg";
import image27 from "./team-pics/Christa Foschio-Bebak - Christa Foschio-Bebak.jpg";
import image28 from "./team-pics/Clare Hong Pic - Clare Hong.jpg";
import image29 from "./team-pics/Daniel Headshot - Daniel Robertson.jpg";
import image30 from "./team-pics/David_Colligan - Jesse Friedman.jpg";
import image31 from "./team-pics/Daycia 2 - Daycia McClam.jpg";
import image32 from "./team-pics/Deroy - Jenna Shapiro.jpg";
import image33 from "./team-pics/Dylan.jpg";
import image34 from "./team-pics/ETinklenberg_Headshot_2021 - Emily Tinklenberg.jpg";
import image35 from "./team-pics/Eric.jpg";
import image36 from "./team-pics/IMG_1660 - Erin Thompson.jpg";
import image37 from "./team-pics/rb_Eugene_Driker_030703_14 - Jesse Friedman.jpg";
import image38 from "./team-pics/Gianni_Morales - Erin Thompson.jpg";
import image39 from "./team-pics/738EB521-F770-42B5-85B3-2B3F99DC87BF - Hannah Gabelnick.jpg";
import image40 from "./team-pics/Heidi Milch - heidi milch.jpg";
import image41 from "./team-pics/Iza.jpg";
import image42 from "./team-pics/IMG_3605 - Janelle Greene-Todd.jpg";
import image43 from "./team-pics/Jarrod - Jarrod Mayes.jpg";
import image44 from "./team-pics/Littmann headshot - Jesse Friedman.jpg";
import image45 from "./team-pics/Mazurkiewicz - Jesse Friedman.jpg";
import image46 from "./team-pics/IMG_7078 - Jesse Friedman.jpg";
import image47 from "./team-pics/John_Weiss - Erin Thompson.jpg";
import image48 from "./team-pics/55B4C2B8-B918-4B88-948E-B5A73A76F678.jpg";
import image49 from "./team-pics/Judy_Paulsen - Erin Thompson.jpg";
import image50 from "./team-pics/Kawanza Humphrey Headshot - Jesse Friedman.jpg";
import image51 from "./team-pics/Pic - Kejuan Harmon.jpg";
import image52 from "./team-pics/962BAC2B-7971-4B0D-94EF-B68A69D46866 - Kennedy Jeanette.jpg";
import image53 from "./team-pics/Reem Winter Gear - ROC MAPP.jpg";
import image54 from "./team-pics/IMG_2019 - King Bethel.jpg";
import image55 from "./team-pics/10-3457 - Kyle Norris.jpg";
import image56 from "./team-pics/new pic - Laura Jo Hess.jpg";
import image57 from "./team-pics/IMG_4306 - Lauren Jones.jpg";
import image58 from "./team-pics/Lenzi in Bloom - Lenzi Petty.jpg";
import image59 from "./team-pics/C572F6AC-E48C-44E7-B7FD-84D603DC4903 - Lilianna Angel Reyes.jpg";
import image60 from "./team-pics/emser - Logan Emser.jpg";
import image61 from "./team-pics/EEA4D207-87DA-437F-B8BF-C634373E5AD0 - Malachi Mitchell.jpg";
import image62 from "./team-pics/1615135507844 - Malia Xie.jpg";
import image63 from "./team-pics/C57D3940-B5D2-4E3D-97FF-B71EC8966A0B - Manar Zoulfikar.jpg";
import image64 from "./team-pics/IMG-2240 - Margaret Zimmer.jpg";
import image65 from "./team-pics/M_Owen.jpg";
import image66 from "./team-pics/M_Wilson.jpg";
import image67 from "./team-pics/matinga selfie - Erin Thompson.jpg";
import image68 from "./team-pics/Melissa-Milch - Melissa Milch.jpg";
import image69 from "./team-pics/Michael_Martin - Erin Thompson.jpg";
import image70 from "./team-pics/Naisha_Ortiz - Erin Thompson.jpg";
import image71 from "./team-pics/CE6F0BBA-E20C-41F7-8FCC-2E0E64FE0F97 - natasha miller.jpg";
import image72 from "./team-pics/2018_05_03_DetroitHeadshots1326 - Nathaniel Mullen.jpg";
import image73 from "./team-pics/NMCGENZ - nichole christian.jpg";
import image74 from "./team-pics/LI pic - Olivia Vaden.jpg";
import image75 from "./team-pics/91845926-5533-410F-A1F0-74BFD9F29F2E - Randall Bruder.jpg";
import image76 from "./team-pics/Reid_Hiltunen - Erin Thompson.jpg";
import image77 from "./team-pics/IMG_6318 copy - Sarah Hallacher.jpg";
import image78 from "./team-pics/56157785_10219572422361124_5843080672930955264_o - Scott Hoch.jpg";
import image79 from "./team-pics/Shawn_Wilson - Erin Thompson.jpg";
import image80 from "./team-pics/SheidaV (1) - Sheida Varshabi.jpg";
import image81 from "./team-pics/Screenshot_20200623-102401_Facebook - Sofia Marra.jpg";
import image82 from "./team-pics/EC1E3DC7-E6A8-4D91-A0D2-66A206A3F309 - Sonya Harris.jpg";
import image83 from "./team-pics/Stefania_Ramirez - Erin Thompson.jpg";
import image84 from "./team-pics/Sruthi.jpg";
import image85 from "./team-pics/Suma Karaman Rosen_crop - Suma Rosen.jpg";
import image86 from "./team-pics/IMG_2342 - Tess Watson.jpg";
import image87 from "./team-pics/TStaplesHeadshot - Trevor Staples.jpg";
import image88 from "./team-pics/Frank Fountain Photo[1] - Jesse Friedman.jpg";
import image89 from "./team-pics/AFE2AC6A-DA57-4BFA-B800-1E4CB86FDF7F - Xavier Lamar.jpg";
import image90 from "./team-pics/ZLee.jpg";

const qs = require("qs");
const imagesLoadedOptions = { background: ".bg-el" };
const masonryOptions = {
    transitionDuration: "0.2s",
    stagger: 10,
    gutter: 20,
};
const financials = [
    { heading: "GRANT DOLLARS", amount: "$4,000,000 ($10,000 - $100,000/GRANT)" },
    {
        heading: "GENERATOR STIPENDS",
        amount: "$1,000,000 ($1,000/TEEN)",
    },
    { heading: "CO-DESIGN FACILITATION & SUPPORT", amount: "$10,000" },
    { heading: "TEEN & ADULT CO-DESIGNERS", amount: "$16,000 ($500/DESIGNER)" },
    {
        heading: "BRAND, IDENTITY, WEBSITE & GRAPHIC DESIGN",
        amount: "$250,000",
    },
    { heading: "PUBLIC RELATIONS & SOCIAL MEDIA", amount: "$60,000" },
    { heading: "DIRECT MARKETING TO TEENS", amount: "$40,000" },
    {
        heading: "TEEN WORKSHOPS & INFO SESSIONS",
        amount: " $1,500/WORKSHOP OR SESSION",
    },
    {
        heading: "COMMUNITY REVIEW TEAM STIPENDS",
        amount: "$14,000 ($1,000/REVIEWER)",
    },
];
const images = [
    { src: image1 },
    { src: image2 },
    { src: image3 },
    { src: image4 },
    { src: image5 },
    { src: image6 },
    { src: image7 },
    { src: image8 },
    { src: image9 },
    { src: image10 },
    { src: image11 },
    { src: image12 },
    { src: image13 },
    { src: image14 },
    { src: image15 },
    { src: image16 },
    { src: image17 },
    { src: image18 },
    { src: image19 },
    { src: image20 },
    { src: image21 },
    { src: image22 },
    { src: image23 },
    { src: image24 },
    { src: image25 },
    { src: image26 },
    { src: image27 },
    { src: image28 },
    { src: image29 },
    { src: image30 },
    { src: image31 },
    { src: image32 },
    { src: image33 },
    { src: image34 },
    { src: image35 },
    { src: image36 },
    { src: image37 },
    { src: image38 },
    { src: image39 },
    { src: image40 },
    { src: image41 },
    { src: image42 },
    { src: image43 },
    { src: image44 },
    { src: image45 },
    { src: image46 },
    { src: image47 },
    { src: image48 },
    { src: image49 },
    { src: image50 },
    { src: image51 },
    { src: image52 },
    { src: image53 },
    { src: image54 },
    { src: image55 },
    { src: image56 },
    { src: image57 },
    { src: image58 },
    { src: image59 },
    { src: image60 },
    { src: image61 },
    { src: image62 },
    { src: image63 },
    { src: image64 },
    { src: image65 },
    { src: image66 },
    { src: image67 },
    { src: image68 },
    { src: image69 },
    { src: image70 },
    { src: image71 },
    { src: image72 },
    { src: image73 },
    { src: image74 },
    { src: image75 },
    { src: image76 },
    { src: image77 },
    { src: image78 },
    { src: image79 },
    { src: image80 },
    { src: image81 },
    { src: image82 },
    { src: image83 },
    { src: image84 },
    { src: image85 },
    { src: image86 },
    { src: image87 },
    { src: image88 },
    { src: image89 },
    { src: image90 },
];
const TeamWrap = ({ location }) => {
    const theme = "white";
    const initialSettings = qs.parse(location.search, {
        ignoreQueryPrefix: true,
        encodeValuesOnly: true,
    });
    let checkedItems = {
        Generation: new Map(),
        Region: new Map(),
        Roles: new Map(),
    };
    Object.keys(initialSettings).forEach((objkey) => {
        const obj = initialSettings[objkey];
        const col = obj.col;

        if (col === undefined) {
            //do nothing
        } else if (col === "Display Name") {
            checkedItems[col] = [[obj.v[0], true]];
        } else {
            obj.v.map((value) => {
                checkedItems[col].set(value, true);
                return null;
            });
        }
    });
    return (
        <ThemeProvider value={theme}>
            <TeamGrid initCheckedItems={checkedItems} />
        </ThemeProvider>
    );
};

const TeamGrid = ({ initCheckedItems }) => {
    // const [showPage, setShowPage] = useState(1);
    // const [infiniteLoading, setInfiniteLoading] = useState(false);
    const [checkedItems, setCheckedItems] = useState({ ...initCheckedItems });
    const initFilters = processFilters(initCheckedItems);
    const [filters, setFilters] = useState(initFilters);
    const [showFilters, setShowFilters] = useState(false);
    const { setFilteredStories, setFilterStoriesQS } = useContext(FilterContext);
    const history = useHistory();
    const filtersRef = useRef(null);

    const { loading, error, data, updating } = usePosts(`/api/team`, filters);

    useEffect(() => {
        setFilteredStories(data);
    });
    const toggleFilters = () => {
        setShowFilters(!showFilters);
        filtersRef.current.scrollIntoView();
    };
    // const loadMore = () => {
    //   setInfiniteLoading(true);

    //   setShowPage(showPage + 1);
    //   window.setTimeout(function () {
    //     setInfiniteLoading(false);
    //   }, 1000);
    // };

    const handleChange = (e) => {
        const type = e.target.type;
        const item = e.target.name;
        const value = e.target.value;

        // enable to reset the count on any interaction
        //setShowPage(1);

        if (type === "text") {
            //if (checkedItems[item] === undefined) checkedItems[item] = [];
            if (value.length > 0) checkedItems[item][0] = [value.toLowerCase(), true];
            else checkedItems[item][0] = [value.toLowerCase(), false];
        } else {
            const isChecked = e.target.checked;
            checkedItems[item].set(value, isChecked);
        }
        setCheckedItems({ ...checkedItems });
        //console.log("checkedItems", checkedItems, e.target.checked);
    };

    useEffect(() => {
        //map out filter
        const newFilter = processFilters(checkedItems);
        setFilters(newFilter);
        const filterStr = qs.stringify(newFilter, { encodeValuesOnly: true });
        setFilterStoriesQS(filterStr);

        history.push({
            search: newFilter.length > 0 ? `?${filterStr}` : "",
        });

        // return {
        //   // cleanup
        // };
    }, [checkedItems, history, setFilterStoriesQS]);

    return (
        <ColumnLayout
            railContent={
                <>
                    <Filters
                        handleChange={handleChange}
                        checkedItems={checkedItems}
                        mobileShowFilters={showFilters}
                        filterOpts={filterOpts}
                        introText={
                            "Filter by the role each individual played on the project."
                        }
                    />
                    {showFilters && (
                        <div className={styles.filterToggle}>
                            <button onClick={toggleFilters} className={styles.closeFilters}>
                                Filter Team
                            </button>
                        </div>
                    )}
                </>
            }
        >
            <div className={styles.element}>
                <div className={styles.intro}>
                    <div className={`${styles.heading}`}>
                        <h1 className={layout.heading}>Generator Z Team</h1>
                        <h3>
                            Behind the scenes, Generator Z was fueled by a cross-disciplinary
                            and multi-generational team.
                        </h3>
                        <p>
                            Nearly 100 contributors, from Southeast Michigan, Western New
                            York, and other parts of the country pushed beyond traditional
                            modes of operation to build something no one individual on the
                            team had built before. Thought-partners and allies from Gen Z,
                            millennial, Gen X, baby boomer, and silent generations organized
                            around their shared experience of being a teen now and in the past
                            to advocate for youth voice today. Together, they developed,
                            designed, launched, and ran Generator Z.
                        </p>
                        <p>
                            Curious about the direct investment in people power?{" "}
                            <HashLink to="/team/#investment">
                                Scroll to the bottom of the page.{" "}
                            </HashLink>
                        </p>
                    </div>
                </div>
                <hr />
                <div className={styles.youtubeEmbed}>
                    <YouTube
                        //videoId={`Ykk2nCwwzY0`} // defaults -> null
                        videoId="T_ptUwA2gnw"
                        //   id={string} // defaults -> null
                        className={styles.videoWrap} // defaults -> null
                    //   containerClassName={string} // defaults -> ''
                    //   opts={obj} // defaults -> {}
                    //   onReady={func} // defaults -> noop
                    //   onPlay={func} // defaults -> noop
                    //   onPause={func} // defaults -> noop
                    //   onEnd={func} // defaults -> noop
                    //   onError={func} // defaults -> noop
                    //   onStateChange={func} // defaults -> noop
                    //   onPlaybackRateChange={func} // defaults -> noop
                    //   onPlaybackQualityChange={func} // defaults -> noop
                    />
                </div>
                <hr />
                <div className={styles.filterToggle}>
                    <button onClick={toggleFilters} ref={filtersRef}>
                        Filter Afterschool Providers{" "}
                        <span>
                            <SVG src={expand}></SVG>
                        </span>
                    </button>
                </div>
                <section className={styles.grid}>
                    <h1>Contributors</h1>
                    {loading ? (
                        "Loading Team"
                    ) : error ? (
                        "Error"
                    ) : data ? (
                        <div className={updating ? "stories--updating" : "stories--loaded"}>
                            <div className={styles.results}>
                                <span>{data.length} </span> results
                                {updating ? " -- updating --" : ""}
                            </div>
                            <Masonry
                                options={masonryOptions}
                                updateOnEachImageLoad={true}
                                imagesLoadedOptions={imagesLoadedOptions}
                            >
                                {data &&
                                    data.map(({ fields, id }, index) => {
                                        const imagesrc = images[fields.ID - 1]?.src;

                                        return (
                                            <div key={id} className={styles.tile}>
                                                <div className={styles.pic}>
                                                    {imagesrc ? (
                                                        <img src={imagesrc} alt={`${fields.Name}`} />
                                                    ) : (
                                                        <div className={styles.placeholder} />
                                                    )}
                                                </div>
                                                <div className={styles.info}>
                                                    <h3>{fields.Name}</h3>

                                                    <div className={styles.meta}>
                                                        {fields.Pronouns}
                                                        <br />
                                                        {fields.Work}
                                                        <br />
                                                        {fields.Location}
                                                        <br />
                                                        {fields.Generation}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </Masonry>
                        </div>
                    ) : null}
                </section>
                <hr />
                <section>
                    <div className={styles.intro} id={"investment"}>
                        <h1>Investment</h1>
                        <p>
                            It takes money to move money with intention. Generator Z invested
                            directly in the people who matter most - teens and organizations.
                            From trust-building and communications to insight and direction,
                            here are some important numbers to consider:
                        </p>
                    </div>
                    <hr />
                    {financials.map(({ heading, amount }, index) => (
                        <div className={styles.finresult} key={`fin-${index}`}>
                            <h4>{heading}</h4>
                            <div className={styles.amount}>{amount}</div>
                        </div>
                    ))}
                    <hr />
                    <div className={styles.intro}>
                        <p>
                            Please note that initiative research and design, production
                            management and administration, and grants administration vary and
                            are not included in these direct numbers.
                        </p>
                    </div>
                </section>
            </div>
        </ColumnLayout>
    );
};

export default TeamWrap;
