import { useState, useEffect } from "react";
import axios from "axios";

const useStory = (slug, id = null, email = null) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  // console.log("HERE");
  useEffect(() => {
    setLoading(slug);
    axios
      .get(`/api/story`, {
        params: { slug, id, email },
      })
      .then((res, err) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
        setError(true);
      });
  }, [id, slug, email]);

  return { data, loading, error };
};

export default useStory;
