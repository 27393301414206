import React from "react";
import styles from "./six-month.module.scss";
import questions from "../questions";
import SVG from "react-inlinesvg";
import quote from "components/providers/provider/GZ_quote.svg";
import Media from "components/stories/story/media";
import { useAnchorTrigger } from "components/providers/provider/provider";

const SixMonth = (data) => {
  const ref = useAnchorTrigger({
    setCurrentAnchor: data.setCurrentAnchor,
    anchorId: "sixmonth",
  });
  if (!Object.keys(data).length) {
    return null;
  }
  if (data.error) {
    return "An Error occured";
  }

  const { ideaData } = data;
  return (
    <section className={styles.element} id="sixmonth" ref={ref}>
      <h2>
        <span role="img" aria-label="Update">
          📎
        </span>{" "}
        Progress at 6 months
      </h2>

      <section>
        <h3 className={styles.heading}>How far along are you?</h3>
        <div>
          Our afterschool idea is {data["Completion Level"]}% complete at the
          6-month mark.
        </div>
      </section>
      {data &&
        Object.values(questions).map(
          ({ key, question, publicQuestion, type, show }) => {
            if (show === false) {
              return null;
            }
            switch (type) {
              case "quote":
                return (
                  <div className={`${styles.pullquote} bg--accent`} key={key}>
                    <div className={` bg--accent`}>
                      <SVG
                        src={quote}
                        className={`${styles.quote} ${styles.startquote}  svg--bg-color`}
                      />
                      <SVG
                        src={quote}
                        className={`${styles.quote} ${styles.endquote}  svg--bg-color`}
                      />
                      <h3 className={styles.heading}>{data[key]}</h3>
                      {data["Quote Attribution First Name"] && (
                        <div>
                          – {data["Quote Attribution First Name"]}{" "}
                          {data["Quote Attribution Last Initial"]}
                          {data["Quote Attribution Age"] && (
                            <>, age {data["Quote Attribution Age"]}</>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              case "quoteAttribution":
                return null;
              case "video":
              case "image":
                return (
                  <Media blocks={JSON.parse(data[key])} key={key} slider />
                );
              default:
                return (
                  <React.Fragment key={key}>
                    {key === "Defining Success" && (
                      <section>
                        <h3 className={styles.heading}>
                          At the time you applied, you said success was...
                        </h3>
                        <div>
                          {
                            ideaData[
                              "If your idea is selected for a grant, how do you define success?"
                            ]
                          }
                        </div>
                      </section>
                    )}
                    <section>
                      <h3 className={styles.heading}>
                        {publicQuestion || question}
                      </h3>
                      <div>{data[key]}</div>
                    </section>
                  </React.Fragment>
                );
            }
          }
        )}
    </section>
  );
};

export default SixMonth;
