import React, { useContext } from "react";
import { primaryNavItems } from "./nav-items";
import styles from "./quicknav.module.scss";
import { HashLink } from "react-router-hash-link";
import ThemeContext from "components/teaser/theme-context";
import SVG from "react-inlinesvg";

import DD from "./IconDD.svg";

const QuickNav = () => {
    const { theme, sectionId } = useContext(ThemeContext);

    return (
        <div className={`${styles.element} bg--${theme}`}>
            <div className={styles.current}>
                {primaryNavItems
                    .filter(({ url }) => {
                        return url === `/#${sectionId}`
                    })
                    .map(({ title }) => (
                        <React.Fragment key={title}>{title}</React.Fragment>
                    ))}
                <span>
                    <SVG src={DD}></SVG>
                </span>
            </div>
            <ul>
                {primaryNavItems.map((item, index) => {
                    return (
                        <li key={index}>
                            <HashLink to={item.url}>{item.title}</HashLink>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default QuickNav;
