import React from "react";
import Locales from "./locales";
import Action from "./action";
import Themes from "blocks/themes";
import Ideas from "blocks/ideas";
import Demographics from "blocks/demographics";
import Regions from "blocks/regions";
import Section from "components/section";
import ThemeSection from "components/teaser/theme-section";

const Data = ({ setNavTheme, showModal }) => {
    return (
        <Section id="TheFoundation">
            <ThemeSection theme="white">
                {/* <Regions /> */}
                {/* <Locales /> */}
                {/* <Demographics /> */}
            </ThemeSection>
            <ThemeSection theme="black">
                <Themes setNavTheme={setNavTheme} />
                {/* <Ideas /> */}
                {/* <Action setNavTheme={setNavTheme} showModal={showModal} /> */}
            </ThemeSection>
        </Section>
    );
};

export default Data;
