import React, { useState } from "react";
import ColumnLayout from "layouts/columns";
import axios from "axios";
import styles from "./payment-form.module.scss";
import { useForm } from "react-hook-form";
import errorMessages from "components/form/errorMessages";
import Header from "components/stories/stories-form/header";
import Section from "components/stories/stories-form/section";
import Subsection from "components/stories/stories-form/subsection";
import Text from "components/form/text";
import Select from "components/form/select";
import formLayout from "components/form/form-layout.module.scss";
import CheckboxSet from "components/form/checkbox-set";
import { ThemeProvider } from "components/teaser/theme-context";
import { useParams, Redirect } from "react-router-dom";
import useIDCheck, { IDNotFound } from "components/id-check";
import ReCAPTCHA from "react-google-recaptcha";

const statuses = {
  SUCCESS: "success",
  LOADING: "loading",
  ERROR: "error",
};

const PaymentForm = () => {
  let defaultValues = {};
  const { surveyid } = useParams();
  const { loading, error, data } = useIDCheck(surveyid);

  if (loading) {
    return <>Loading</>;
  }
  if (error) {
    return <IDNotFound />;
  }
  if (data) {
    const user = data[0].fields;
    defaultValues = {
      //   ...initialValues,
      "Survey ID": user["Applicant System ID"],
      "First Name": user["Applicant First Name"],
      "Last Name": user["Applicant Last Name"],
      Email: user["Applicant Email Address"],
      "Airtable ID": data[0].id,
    };
    if (user["Payment Info"]) {
      return (
        <ThemeProvider value={`peach`}>
          <ColumnLayout banner={true}>
            <Header
              heading={<>You've already submitted payment info</>}
              body={
                <>
                  <p>
                    It looks like you already submitted your payment info. If
                    you think this is a mistake, or you need to change your
                    information, please reach out to us at{" "}
                    <a
                      href="mailto:info@generator-z.org."
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      info@generator-z.org.
                    </a>{" "}
                  </p>
                </>
              }
            />
          </ColumnLayout>
        </ThemeProvider>
      );
    }
  }

  return (
    <ThemeProvider value={`peach`}>
      <ColumnLayout banner={true}>
        <Header
          heading={<>Yes, I'll be a Generator.</>}
          body={
            <>
              <p>
                Congratulations! You are one of 1,000 teens selected to
                represent Southeast Michigan and Western New York. Before you
                begin your story, you must accept your position as a Generator.
                Confirm your name and email address, provide your preferred form
                of payment, accept the Terms & Conditions, and hit submit.
              </p>
              {/* <p>
                <strong>
                  You must accept your position by Friday, November 27th at
                  11:59pm.
                </strong>{" "}
                If you miss this deadline, your spot as a Generator could go to
                someone else.
              </p> */}
            </>
          }
        />
        <Form defaultValues={defaultValues}></Form>
      </ColumnLayout>
    </ThemeProvider>
  );
};

export default PaymentForm;

const Form = ({ defaultValues }) => {
  const { register, handleSubmit, control, watch, errors } = useForm({
    defaultValues,
  });

  const recaptchaRef = React.useRef();
  const watched = watch([
    "Email",
    "Payment Handle",
    "Payment Type",
    "Payment Address",
    "Payment Email",
  ]);
  const [status, setStatus] = useState(null);
  const onSubmitWithReCAPTCHA = async (data) => {
    const token = await recaptchaRef.current.executeAsync();
    if (token) {
      // apply to form data
      console.log("submit", data);
      setStatus(statuses.LOADING);
      axios
        .post(`/submit-payment`, data)
        .then((res) => {
          console.log(res);
          setStatus(statuses.SUCCESS);
        })
        .catch((err) => {
          console.log(err.message);
          setStatus(statuses.ERROR);
        });
    }
  };

  if (status === statuses.SUCCESS) {
    return <Redirect to="/payment-info/success" />;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmitWithReCAPTCHA)}
      className={styles.element}
    >
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LeYG-UZAAAAAGscT5cfnHrGve9DmCRSzUNAefdu"
      />
      <Section heading={`Set up your profile`}>
        <Subsection
          //   instructions={<h4>What is your name? (First/Last)</h4>}
          fields={
            <>
              <div className={formLayout.half}>
                <Text
                  name="First Name"
                  label="First Name"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                  readonly
                />
                <Text
                  name="Last Name"
                  label="Last Name"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                  readonly
                />
              </div>
              <div className={formLayout.half}>
                {/* 2 */}{" "}
                <Text
                  readonly
                  name="Email"
                  type="email"
                  label="Email"
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />
              </div>
              <div className={formLayout.half}>
                <Select
                  name="Payment Type"
                  control={control}
                  defaultValue=""
                  label="Payment Type"
                  options={[
                    "Venmo",
                    "Paypal",
                    "CashApp",
                    "Electronic Check",
                    "Mailed Check",
                  ]}
                  forwardRef={register({
                    required: errorMessages.required,
                  })}
                  errors={errors}
                />
              </div>

              {["Venmo", "CashApp", "Paypal"].indexOf(watched["Payment Type"]) >
                -1 && (
                <div className={formLayout.half}>
                  <Text
                    name="Payment Handle"
                    type="text"
                    label="Payment Handle"
                    forwardRef={register({
                      required: errorMessages.required,
                    })}
                    errors={errors}
                  />
                  <Text
                    name="Payment Handle Confirm"
                    type="text"
                    label="Enter Payment Handle Again to Confirm"
                    forwardRef={register({
                      required: errorMessages.required,
                      validate: {
                        value: (value) => {
                          if (value !== watched["Payment Handle"]) {
                            return "Payment handles must match";
                          }
                          return true;
                        },
                      },
                    })}
                    errors={errors}
                  />
                </div>
              )}
              {["Mailed Check"].indexOf(watched["Payment Type"]) > -1 && (
                <>
                  <div className={formLayout.full}>
                    <Text
                      name="Payment Address"
                      type="text"
                      label="Payment Address"
                      forwardRef={register({
                        required: errorMessages.required,
                      })}
                      errors={errors}
                    />
                  </div>
                  <div className={formLayout.half}>
                    <Text
                      name="Payment City"
                      label="City"
                      type="text"
                      forwardRef={register({
                        required: errorMessages.required,
                      })}
                      errors={errors}
                    ></Text>
                    <Select
                      name="Payment State"
                      label="State"
                      options={["Michigan", "New York"]}
                      control={control}
                      type="text"
                      forwardRef={register({
                        required: errorMessages.required,
                      })}
                      errors={errors}
                      defaultValue=""
                    />
                    <Text
                      name="Payment Zipcode"
                      type="text"
                      label="Zipcode"
                      forwardRef={register({
                        required: errorMessages.required,
                        pattern: {
                          value: /\d{5}(?:[-\s]\d{4})?$/i,
                          message: "Invalid Zipcode",
                        },
                      })}
                      errors={errors}
                    ></Text>
                  </div>
                  {/* <div className={formLayout.full}>
                    <Text
                      name="Payment Address Confirm"
                      type="text"
                      label="Payment Address"
                      forwardRef={register({
                        required: errorMessages.required,
                        validate: {
                          value: (value) => {
                            if (value !== watched["Payment Address"]) {
                              return "Payment addresses must match";
                            }
                            return true;
                          },
                        },
                      })}
                      errors={errors}
                    />
                  </div> */}
                </>
              )}
              {watched["Payment Type"] === "Electronic Check" && (
                <>
                  <div className={styles.echeck}>
                    INSTRUCTIONS FOR YOUR ELECTRONIC CHECK WILL BE SENT TO THE
                    EMAIL YOU ENTER BELOW
                  </div>
                  <div className={formLayout.half}>
                    <Text
                      name="Payment Email"
                      type="email"
                      label="Payment Email"
                      forwardRef={register({
                        required: errorMessages.required,
                      })}
                      errors={errors}
                    />
                    <Text
                      name="Payment Email Confirm"
                      type="email"
                      label="Confirm Payment Email"
                      forwardRef={register({
                        required: errorMessages.required,
                        validate: {
                          value: (value) => {
                            if (value !== watched["Payment Email"]) {
                              return "Payment email fields must match";
                            }
                            return true;
                          },
                        },
                      })}
                      errors={errors}
                    />
                  </div>
                </>
              )}
              <br></br>
              <div className={formLayout.half}>
                <CheckboxSet
                  name="Terms and Conditions"
                  options={[
                    <>
                      I agree to{" "}
                      <a
                        href="/terms-and-conditions"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Terms & Conditions*
                      </a>
                    </>,
                  ]}
                  generateRef={() =>
                    register({ required: errorMessages.required })
                  }
                  errors={errors}
                />
                <input
                  className={styles.verified}
                  type="checkbox"
                  name="I am verified"
                  ref={register()}
                />
                <div className={styles.questions}>
                  <span>
                    Questions?{" "}
                    <span role="img" aria-label="mailbox">
                      📬{" "}
                    </span>
                    &nbsp;
                    <a href="mailto:info@generator-z.org">Contact Us</a>
                  </span>
                </div>
                <input
                  type="hidden"
                  name="Survey ID"
                  value={defaultValues["Survey ID"]}
                  ref={register}
                ></input>
                <input type="hidden" name="Airtable ID" ref={register}></input>
              </div>

              {status === statuses.LOADING ? (
                "Sending Info"
              ) : (
                <button className={formLayout.submit} type="submit">
                  Submit
                </button>
              )}
            </>
          }
        ></Subsection>
      </Section>
    </form>
  );
};
