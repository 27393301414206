import React, { useState } from "react";
import styles from "./themes.module.scss";
import themes from "./data";
import ThemeRow from "./theme-row";
import useColumns from "./useColumns";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
gsap.registerPlugin(ScrollTrigger);

const heading = "Kickstarted by data";
const text = (
    <p>Before Generator Z, we asked 10,000 teens across Southeast Michigan and Western New York what they wanted when they were not in school. We worked all that data down to 10 themes! Teens and afterschool providers selected one of these ten themes when they applied to Generator Z. You can filter <a href='/stories'>Generator Stories</a> and <a href='/afterschool-ideas'>Afterschool Grants</a> by any of these themes.</p>
);

const Themes = () => {
    const columns = useColumns();

    const rows = chunk(columns, themes);
    const [active, setActive] = useState(null);
    const [maxHeight, setMaxHeight] = useState();

    return (
        <div className={styles.container}>
            <div className={styles.body}>
                <h2 className={styles.heading}>{heading}</h2>
                <div className={styles.text}>{text}</div>
            </div>
            <div className={styles.shadowThemes}>
                {rows.map((row, i) => (
                    <ThemeRow
                        key={`cols:${columns}_${i}`}
                        themes={row}
                        active={active}
                        setActive={setActive}
                        animate={false}
                        max-height={maxHeight}
                        setMaxHeight={setMaxHeight}
                    />
                ))}
            </div>
            <div className={styles.themes}>
                {rows.map((row, i) => (
                    <ThemeRow
                        key={`cols:${columns}_${i}`}
                        themes={row}
                        active={active}
                        setActive={setActive}
                        maxHeight={maxHeight}
                    />
                ))}
            </div>
        </div>
    );
};

export default Themes;

function chunk(chunkSize, items) {
    const chunked = [];
    let i = 0;
    while (i < items.length) {
        chunked.push(items.slice(i, i + chunkSize));
        i = i + chunkSize;
    }
    return chunked;
}
