import React from "react";
import styles from "./email.module.scss";
import { ErrorMessage } from "@hookform/error-message";

const Email = ({ label, name, forwardRef, className, errors }) => {
  return (
    <div className={className}>
      <label>
        <span className={styles.label}>{label}</span>
        <input
          className={styles.input}
          type="email"
          name={name}
          ref={forwardRef}
          aria-invalid={errors[name] ? "true" : "false"}
        />
      </label>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="error">{message}</div>}
      />
    </div>
  );
};

export default Email;
