import React from "react";
import { FilestackUpload } from "components/form/filestack-upload";
import { FILESTACK_CDN } from "const";
import Upload from "components/form/upload";
const ImageUpload = ({ ...props }) => {
  return (
    <FilestackUpload
      {...props}
      accept={["image/*"]}
      Preview={ImagePreview}
      buttonText="Add Image"
    />
  );
};

export default ImageUpload;

const ImagePreview = ({ handle, filename }) => {
  if (!handle) {
    return null;
  }
  return (
    <Upload
      filename={filename}
      media={
        <img src={`${FILESTACK_CDN}/${handle}`} width={100} alt={"TODO: alt"} />
      }
    />
  );
};
