import React, { useState, useContext } from "react";
import styles from "./nav.module.scss";
import ScrollLock from "react-scrolllock";
import { CSSTransition } from "react-transition-group";
import SVG from "react-inlinesvg";
import navLogo from "components/logo/LogoNav.svg";
import logoMobile from "components/logo/LogoMobile.svg";
import closeIcon from "./IconClose.svg";
import openIcon from "./IconOpen.svg";
import fb from "./LogoFacebook.svg";
//import twitter from "./LogoTwitter.svg";
import instagram from "./LogoInstagram.svg";
import { primaryNavItems } from "./nav-items";
import ThemeContext from "components/teaser/theme-context";
import { HashLink } from "react-router-hash-link";

const NavOverlay = () => {
    const [showNav, setShowNav] = useState(false);
    const closeNav = () => {
        setShowNav(false);
    };
    const { theme } = useContext(ThemeContext);
    return (
        <>
            <button
                className={`${styles.navTrigger} bg--${theme} nav-trigger`}
                onClick={() => setShowNav(true)}
            >
                <SVG src={openIcon} alt="Open Menu"></SVG>
            </button>

            <CSSTransition in={showNav} appear={true} unmountOnExit timeout={100}>
                <ScrollLock>
                    <div className={styles.overlay}>
                        <nav className={styles.menu}>
                            <div className={styles.navLogo}>
                                <SVG src={navLogo}></SVG>
                            </div>
                            <div className={styles.navLogoMobile}>
                                <SVG src={logoMobile}></SVG>
                            </div>

                            <div className={styles.primaryLinks}>
                                <ul>
                                    {primaryNavItems.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <HashLink onClick={closeNav} to={item.url}>
                                                    {item.title}
                                                </HashLink>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className={styles.secondaryLinks}>
                                <ul className={styles.secondaryPages}>
                                    <li>
                                        <HashLink onClick={closeNav} to={`/stories`}>
                                            Generator Stories
                                        </HashLink>
                                    </li>
                                    <li>
                                        <HashLink onClick={closeNav} to={`/afterschool-ideas`}>
                                            Afterschool Ideas
                                        </HashLink>
                                    </li>
                                    <li>
                                        <HashLink onClick={closeNav} to={`/provider-directory`}>
                                            Provider Directory
                                        </HashLink>
                                    </li>
                                    {/* <li>
                    <HashLink onClick={closeNav} to={`/provider/apply`}>
                      Apply for a Grant
                    </HashLink>
                  </li> */}
                                    <li>
                                        <HashLink onClick={closeNav} to={`/program-guidelines`}>
                                            Program Guidelines
                                        </HashLink>
                                    </li>
                                    <li>
                                        <HashLink onClick={closeNav} to={`/faq`}>
                                            FAQ
                                        </HashLink>
                                    </li>
                                    <li>
                                        <HashLink onClick={closeNav} to={`/terms-and-conditions`}>
                                            Generator Terms
                                        </HashLink>
                                    </li>
                                    <li>
                                        <HashLink onClick={closeNav} to={`/team`}>
                                            Team
                                        </HashLink>
                                    </li>

                                    {/* <li>
                    <a
                      href="mailto:info@generator-z.org"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Newsletter Signup
                    </a>
                  </li> */}
                                    <li>
                                        <HashLink onClick={closeNav} to="/contact-us">
                                            Contact Us
                                        </HashLink>
                                    </li>
                                </ul>
                                <ul className={styles.socialLinks}>
                                    <li>
                                        <a
                                            href="https://www.facebook.com/GeneratorZFuture/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <SVG src={fb} alt="Facebook"></SVG>
                                        </a>
                                    </li>
                                    {/* <li>
                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                      <SVG src={twitter} alt="Twitter"></SVG>
                    </a>
                  </li> */}
                                    <li>
                                        <a
                                            href="https://instagram.com/generatorzfuture?igshid=19vepje9amurd"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <SVG src={instagram} alt="Instagram"></SVG>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                        <button
                            className={styles.navCloseTrigger}
                            onClick={() => setShowNav(false)}
                        >
                            <SVG src={closeIcon} alt="Close Menu"></SVG>
                        </button>
                    </div>
                </ScrollLock>
            </CSSTransition>
        </>
    );
};

export default NavOverlay;
