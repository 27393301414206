import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import styles from "./color-picker.module.scss";

import SVG from "react-inlinesvg";
import swatch from "./swatch.svg";


function ThemeColorPicker({ options, errors, name, generateRef }) {
  return (
    <div className={styles.element}>
      {options.map((option, i) => (
        <label
          key={option}
        >
          <input
            className={styles.input}
            name={name}
            value={option}
            type="radio"
            ref={generateRef()}
          />
          <div className={`${styles.indicator} swatch-svg`}>
            <span className={`bg--${option} ${styles.colorOpt}`}>
              <SVG src={swatch}></SVG>
            </span>
          </div>
          <div></div>
        </label>
      ))}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="error">{message}</div>}
      />
    </div>
  );
}

export default ThemeColorPicker;
