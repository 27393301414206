import React, { useEffect, useRef, useState } from "react";
import styles from "./demographics.module.scss";
import Nodes from "./nodes";
import groups from "./dummy.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { scaleLinear } from "d3-scale";
import useMatchMedia from "hooks/useMatchMedia";

import gsap from "gsap";
gsap.registerPlugin(ScrollTrigger);

const steps = Object.keys(groups);
const Demographics = () => {
  const desktop = useMatchMedia(`(min-width: 1200px)`);
  return desktop ? <Desktop /> : <Mobile />;
};

export default Demographics;

const Mobile = () => {
  const intro = groups[steps[0]];
  return (
    <div className={styles.mobile}>
      <h2 className={styles.mobileTitle}>{intro.heading}</h2>
      <div>{intro.text}</div>
      <ul className={styles.mobileSteps}>
        {steps.slice(1).map((group) => {
          return <MobileStep key={group} {...groups[group]} />;
        })}
      </ul>
    </div>
  );
};

const MobileStep = ({ heading, text, groups, useLabelQuantityOnMobile }) => {
  return (
    <li className={styles.mobileStep}>
      <div className={styles.mobileBody}>
        <div className={styles.mobileHeading}>{heading}</div>
        {/* {legend && (
          <div className={styles.legend}>
            <span></span> = {legend}
          </div>
        )} */}
        {text && <div className={styles.mobileText}>{text}</div>}
      </div>
      <ul className={styles.mobileGroups}>
        {groups.map(
          ({ key, quantity, labelQuantity, label, sublabel, shape }) => {
            return (
              <li key={key} className={styles.mobileGroup}>
                <img src={shape} alt="" />
                <Blob
                  value={useLabelQuantityOnMobile ? labelQuantity : quantity}
                  shrink={!!shape}
                />
                <div>
                  <div className={styles.mobileGroupValue}>
                    {labelQuantity ? labelQuantity : quantity}%
                  </div>
                  <div className={styles.mobileGroupLabel}>{label}</div>
                  <div className={styles.mobileGroupSublabel}>{sublabel}</div>
                </div>
              </li>
            );
          }
        )}
      </ul>
    </li>
  );
};

const scale = scaleLinear().domain([0, 100]).range([0, 7853.75]);

const Blob = ({ value, shrink }) => {
  const area = scale(value);
  const r = Math.sqrt(area / Math.PI);
  return (
    <svg
      viewBox={`0 0 100 100`}
      style={{ transform: `scale(${shrink ? 0.25 : 1})` }}
    >
      <circle r={r} cy={50} cx={50} fill="#523dff" />
    </svg>
  );
};

const Desktop = () => {
  const [active, setActive] = useState(false);
  const [step, setStep] = useState(steps[0]);
  const ref = useRef();
  const nodesRef = useRef();

  useEffect(() => {
    const t = ScrollTrigger.create({
      trigger: ref.current,
      start: "top top",
      end: "bottom bottom",
      pin: nodesRef.current,
      onEnter: () => setActive(true),
      onLeave: () => setActive(false),
      onEnterBack: () => setActive(true),
      onLeaveBack: () => setActive(false),
      onUpdate: (self) => {
        const index = Math.min(
          steps.length - 1,
          Math.floor(self.progress * (steps.length - 0.5))
        );
        setStep(steps[index]);
      },
    });
    return () => t.kill();
  }, []);
  return (
    <section className={styles.element} ref={ref}>
      <Steps />
      <div className={styles.nodes} ref={nodesRef}>
        {active && <Nodes steps={steps} currentStep={step} />}
      </div>
      <div style={{ height: "50vh" }} />
    </section>
  );
};

const Steps = () => {
  return (
    <ul className={styles.steps}>
      {steps.map((group) => {
        return <Step key={group} {...groups[group]} />;
      })}
    </ul>
  );
};

const Step = ({ text, heading, legend }) => {
  return (
    <li className={styles.step}>
      <div className={styles.body}>
        <div className={styles.heading}>{heading}</div>
        {legend && (
          <div className={styles.legend}>
            <span></span> = {legend}
          </div>
        )}
        {text && <div className={styles.text}>{text}</div>}
      </div>
    </li>
  );
};
