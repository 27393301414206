import ColumnLayout from "layouts/columns";
import styles from "./z-to-z.module.scss";
import { ThemeProvider } from "components/teaser/theme-context";
import layout from "scss/layout.module.scss";

const ZtoZ = () => {
    const theme = "white";

    return (
        <ThemeProvider value={theme}>
            <ColumnLayout>
                <div className={styles.element}>
                    <div className={styles.intro}>
                        <div className={`${styles.heading}`}>
                            <h1 className={layout.heading}>Z to Z Book</h1>
                            <p>
                                The magic of a time-limited grantmaking initiative, Generator Z, was transformed into a time-capsule of a book, Z to Z: Conversations of a Generation. It is an extraordinary living history of what it meant to be a teen during an extraordinary time. Starting this spring, Z to Z will be available for check-out at libraries across Southeast Michigan and Western New York as a gesture to honor and memorialize the power of youth voice.
                            </p>
                        </div>
                    </div>
                    <hr />
                    <div className={styles.videoWrap}>
                        <video controls>
                            <source src="/video/gen-z-book.mp4" type="video/mp4" />
                            Your browser doesn't support HTML video.
                        </video>
                    </div>
                </div>
            </ColumnLayout>
        </ThemeProvider>
    );
};

export default ZtoZ;
