import React from "react";
import styles from "./providers.module.scss";

function Badge({ color, inheritColors }) {
  return (
    <div className={`${styles.badge} badge`}>
      <div className={`${styles.badgeInner}`}>
        <span className={inheritColors ? "" : `fg--${color}`}>
          2021
          <br />
          Grantee
        </span>
        <svg
          className={inheritColors ? "fg--accent" : "fg--accent"}
          width="170"
          height="162"
          viewBox="0 0 170 162"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M86.7348 35.2L109.663 0.755083L112.889 39.7457L154.213 33.3436L132.903 63.9895L169.152 80.1215L127.237 95.7519L153.207 130.266L113.156 118.805L110.369 161.76L83.7078 131.308L57.8593 159.045L49.7476 124.845L15.3027 129.721L28.7506 109.517L40.0278 86.9528L0 79.8445L35.5075 64.302L14.4986 27.9904L53.1767 37.8808L58.0333 0L71.6602 18.0527L86.7348 35.2Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  );
}

export default Badge;
