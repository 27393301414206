import React, { useEffect, useState } from "react";
import styles from "./provider-six-month-progress.module.scss";
import { ThemeProvider } from "../teaser/theme-context";
import ColumnLayout from "layouts";
import Header from "components/stories/stories-form/header";
import { Link } from "react-router-dom";
import Section from "components/stories/stories-form/section";
import Subsection from "components/stories/stories-form/subsection";
import Text from "components/form/text";
import TextArea from "components/form/textarea";
import MediaBuilder from "components/form/media-builder";
import errorMessages from "components/form/errorMessages";
import { useForm } from "react-hook-form";
import formLayout from "components/form/form-layout.module.scss";
import { statuses } from "components/stories/stories-form/stories-form";
import axios from "axios";
import questions from "./questions";
import Select from "components/form/select";
import usePosts from "components/usePosts";
import { Document } from "components/provider-application/provider-application";
import RadioSet from "components/form/radio-set/radio-set";
import Range from "components/form/range";

const ProviderSixMonthProgress = () => {
  return (
    <ThemeProvider value="provider-peach">
      <ColumnLayout>
        <Header heading={heading} body={body} />
        <Form />
      </ColumnLayout>
    </ThemeProvider>
  );
};

export default ProviderSixMonthProgress;

const heading = "Grantee Progress: Month 6";
const body = (
  <>
    <p>
      Generator Z is a one-year grant initiative to reimagine afterschool.
      Afterschool providers, like yourselves, are accountable to teens -- the
      most important audience by far. As a grantee, please share your progress
      at this 6-month mark. Remember to speak to Generators directly. Generators
      will be reviewing your work and providing feedback.
    </p>
    <p>
      Before you get started, read through your original application. It is
      housed in <Link to="/afterschool-ideas">Afterschool Ideas</Link> and will
      automatically link to the reporting form once you identify yourself below.
      It is also attached to your grant agreement. Start there before answering
      the following questions. Keep it handy for reference.
    </p>
    <p>
      Please note that your answers will not automatically save until you
      complete and submit the form so plan accordingly. If you have questions,
      email{" "}
      <a
        href="mailto:info@generator-z.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        info@generator-z.org
      </a>
      . The deadline for reporting is Monday, December 20, 2021 at 11:59 PM.
    </p>
  </>
);

const SUCCESS_MESSAGE = (
  <>
    <p>Congratulations, your progress on Generator Z has been submitted!</p>
    <p>
      Please note that you will not receive a separate confirmation email. This
      message serves as confirmation. If you would like, take a screenshot for
      your records. Your updates will be published on-line in late December.
      Generators will begin reviewing your progress in early 2022 so stay tuned!
    </p>
  </>
);

const reg = {
  text: {
    required: errorMessages.required,
    minLength: {
      value: 5,
      message: errorMessages.minLength(5),
    },
  },
  text250: {
    required: errorMessages.required,
    minLength: {
      value: 50,
      message: errorMessages.minLength(50),
    },
  },
  text1800: {
    required: errorMessages.required,
    minLength: {
      value: 500,
      message: errorMessages.minLength(500),
    },
  },
  text3000: {
    required: errorMessages.required,
    minLength: {
      value: 1000,
      message: errorMessages.minLength(1000),
    },
  },
};
const defaultValues = {
  "Joys and Challenges":
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  "Defining Success":
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  "Teen Experience Quote":
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

  "Staff Learnings":
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  "Org Change":
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  slug: null,
};

const AlreadySubmitted = () => {
  return (
    <p>
      An 6 month update has already been submitted for this Afterschool Idea. If
      you feel you've received this message in error, or would like to change
      something, please email us at{" "}
      <a href={`mailto: info@generator-z.org`}>info@generator-z.org</a>.
    </p>
  );
};

const Form = () => {
  const { register, handleSubmit, errors, control, watch } = useForm({
    defaultValues: module.hot ? defaultValues : { slug: null },
  });
  const [status, setStatus] = useState(null);

  const project = watch(questions.which.key);

  const [alreadySubmitted, setAlreadySubmitted] = useState(null);
  useEffect(() => {
    if (project) {
      setAlreadySubmitted(null);
      axios
        .post(`/check-six-month-progress`, {
          slug: project,
        })
        .then((res) => {
          setAlreadySubmitted(res.data);
        });
    }
  }, [project]);

  const submit = (data) => {
    setStatus(statuses.LOADING);
    axios
      .post(`/submit-six-month-progress`, data)
      .then((res) => {
        console.log(res);
        if (res.data === "Success") {
          setStatus(statuses.SUCCESS);
        } else if (res.data === "Duplicate") {
          setStatus("Duplicate");
        }
      })
      .catch((err) => {
        console.log(err.message);
        setStatus(statuses.ERROR);
      });
  };

  const { error, data } = usePosts(`/api/providers/slugs`);
  if (error) {
    console.error(error);
  }
  const whichProjectOptions = data
    ? data
        .map(({ fields }) => {
          return {
            label: (
              <>
                <div>{fields["Revised Title"]}</div>
                <div style={{ fontSize: "80%" }}>
                  {fields["Name of Organization or Program"]}
                </div>
              </>
            ),
            sortLabel: fields["Revised Title"],
            value: fields["Slug (from Finalists)"][0],
          };
        })
        .sort((a, b) => {
          if (a.sortLabel < b.sortLabel) {
            return -1;
          }
          if (b.sortLabel < a.sortLabel) {
            return 1;
          }
          return 0;
        })
    : [];

  const thisProject =
    project &&
    data &&
    data.find(({ fields }) => fields["Slug (from Finalists)"][0] === project);

  return (
    <form onSubmit={handleSubmit(submit)} className={styles.form}>
      <Section heading={"Identify Yourself"}>
        <Subsection
          fields={
            <>
              <div
                className={!thisProject ? styles.projectSelectNoProject : ""}
              >
                <label className={styles.label}>
                  {/* <p>{questions.which.question}</p> */}
                  <Select
                    name={questions.which.key}
                    forwardRef={register()}
                    label={questions.which.question}
                    control={control}
                    errors={errors}
                    options={whichProjectOptions}
                  />
                  {/* <TextArea
                  name={questions.which.key}
                  placeholder="Start typing here"
                  forwardRef={register(reg.text250)}
                  minLength={50}
                  maxLength={1000}
                  errors={errors}
                  required={{
                    message: "TEXT IS REQUIRED. MINIMUM 50/MAXIMUM 1,000 CHARACTERS",
                  }}
                /> */}
                </label>
              </div>
              {thisProject && (
                <Link
                  target="_blank"
                  to={`/afterschool-ideas/${thisProject.fields["Slug (from Finalists)"][0]}`}
                >
                  {thisProject.fields["Revised Title"]} idea page
                </Link>
              )}
            </>
          }
        />
      </Section>
      {alreadySubmitted ? (
        <AlreadySubmitted />
      ) : (
        thisProject &&
        alreadySubmitted !== null && (
          <>
            <Section
              heading={"Share your progress. This section will be published."}
            >
              <Subsection
                // tip={
                //   <>
                //     <p>
                //       {" "}
                //       We encourage you to check your eligibility as soon as possible.
                //       It only takes a few minutes.{" "}
                //     </p>
                //   </>
                // }
                fields={
                  <>
                    <label className={styles.label}>
                      <p>{questions.completion.question}</p>
                      <Range
                        min={0}
                        max={100}
                        step={1}
                        name={questions.completion.key}
                        control={control}
                      />
                    </label>
                    <label className={styles.label}>
                      <p>{questions.joy.question}</p>
                      <TextArea
                        name={questions.joy.key}
                        placeholder="Start typing here"
                        forwardRef={register(reg.text250)}
                        minLength={50}
                        maxLength={1000}
                        errors={errors}
                        required={{
                          message:
                            "TEXT IS REQUIRED. MINIMUM 50/MAXIMUM 1,000 CHARACTERS",
                        }}
                      />
                    </label>
                  </>
                }
              />
              <Subsection
                tip={
                  "We asked you to be 'courageous' with your application. That meant: Be your best organizational self. You are the ones to define success. Your actionable idea should reinforce your passion for serving and supporting young people now and in the future."
                }
                fields={
                  <>
                    <label className={styles.label}>
                      <p>{questions.success.question}</p>

                      {thisProject &&
                        thisProject.fields[
                          "If your idea is selected for a grant, how do you define success? (from Finalists)"
                        ][0] && (
                          <div
                            className={`${styles.referenceAnswer} bg--accent`}
                          >
                            <p>
                              {
                                thisProject.fields[
                                  "If your idea is selected for a grant, how do you define success? (from Finalists)"
                                ][0]
                              }
                            </p>
                          </div>
                        )}
                      <p>
                        Walk us through your progress here. Has your definition
                        of success remained the same or has it changed? Explain.
                      </p>
                      <TextArea
                        name={questions.success.key}
                        placeholder="Start typing here"
                        forwardRef={register(reg.text250)}
                        minLength={50}
                        maxLength={1000}
                        errors={errors}
                        required={{
                          message:
                            "TEXT IS REQUIRED. MINIMUM 50/MAXIMUM 1,000 CHARACTERS",
                        }}
                      />
                    </label>
                  </>
                }
              />
              <Subsection
                tip="This person is not expected to be a Generator, although they might be. This person is expected to be a teen that is directly involved with your organization and your Generator Z idea."
                fields={
                  <>
                    <label className={styles.label}>
                      <p>{questions.quote.question}</p>
                      <TextArea
                        name={questions.quote.key}
                        placeholder="Start typing here"
                        forwardRef={register()}
                        minLength={50}
                        maxLength={400}
                        errors={errors}
                        required={{
                          message:
                            "TEXT IS REQUIRED. MINIMUM 50/MAXIMUM 400 CHARACTERS",
                        }}
                      />
                    </label>
                    <fieldset className={styles.attribution}>
                      <legend>Who gave you this quote?</legend>
                      <label className={styles.label}>
                        <p>{questions.quoteAttributionFirst.question}</p>
                        <Text
                          name={questions.quoteAttributionFirst.key}
                          forwardRef={register()}
                          errors={errors}
                        />
                      </label>
                      <label className={styles.label}>
                        <p>{questions.quoteAttributionLast.question}</p>
                        <Text
                          name={questions.quoteAttributionLast.key}
                          forwardRef={register()}
                          errors={errors}
                          maxLength={1}
                          size={4}
                        />
                      </label>
                      <label className={styles.label}>
                        <p>{questions.quoteAttributionAge.question}</p>
                        <Text
                          name={questions.quoteAttributionAge.key}
                          forwardRef={register()}
                          errors={errors}
                          maxLength={2}
                          size={4}
                        />
                      </label>
                    </fieldset>
                  </>
                }
              />
              <Subsection
                fields={
                  <>
                    <label className={styles.label}>
                      <p>{questions.progress.question}</p>
                      <MediaBuilder
                        types={["image"]}
                        limit={3}
                        heading="Image upload (3 max)"
                        errors={errors}
                        name={questions.progress.key}
                        forwardRef={register({
                          required: errorMessages.required,
                          validate: (value) => {
                            return (
                              JSON.parse(value).length > 0 ||
                              "You must add an image"
                            );
                          },
                        })}
                      />
                    </label>

                    <MediaBuilder
                      types={["video"]}
                      limit={1}
                      errors={errors}
                      heading="Optional (90 seconds or less)"
                      name="Progress Video"
                      forwardRef={register()}
                    />
                  </>
                }
              />
            </Section>
            <Section heading="BEHIND THE SCENES: This section will NOT BE published.">
              <Subsection
                fields={
                  <>
                    <label className={styles.label}>
                      <p>{questions.learning.question}</p>
                      <TextArea
                        name={questions.learning.key}
                        placeholder="Start typing here"
                        forwardRef={register(reg.text250)}
                        minLength={50}
                        maxLength={1000}
                        errors={errors}
                        required={{
                          message:
                            "TEXT IS REQUIRED. MINIMUM 50/MAXIMUM 1,000 CHARACTERS",
                        }}
                      />
                    </label>

                    <label className={styles.label}>
                      <p>{questions.changes.question}</p>
                      <TextArea
                        name={questions.changes.key}
                        placeholder="Start typing here"
                        forwardRef={register(reg.text250)}
                        minLength={50}
                        maxLength={1000}
                        errors={errors}
                        required={{
                          message:
                            "TEXT IS REQUIRED. MINIMUM 50/MAXIMUM 1,000 CHARACTERS",
                        }}
                      />
                    </label>
                    <label className={styles.label}>
                      <p>{questions.experienceRating.question}</p>
                      <RadioSet
                        generateRef={register}
                        options={[
                          { value: 1, label: "1 (terrible)" },
                          2,
                          3,
                          4,
                          5,
                          6,
                          7,
                          8,
                          9,
                          { value: 10, label: "10 (awesome)" },
                        ]}
                        errors={errors}
                        layout="line"
                        name={questions.experienceRating.key}
                      />
                    </label>
                  </>
                }
              />
            </Section>
            <Section heading="Financials. This section will not be published.">
              <Subsection
                fields={
                  <>
                    <p>
                      An excel file was emailed to you on June 2, 2021 with the
                      subject line: Generator Z- Budget Reporting Template -
                      *SAVE* Fill in your expenses to date, explain any changes
                      in the notes section, and upload your completed form as an
                      excel file here. Keep this updated file on hand. You will
                      be asked to add to it at the conclusion of the grant.{" "}
                    </p>
                    <Document
                      heading={""}
                      name={"File Upload"}
                      errors={errors}
                      register={register({
                        required: errorMessages.required,
                        validate: (value) => {
                          return value !== "" || "You must add an document";
                        },
                      })}
                      // extensions={[".pdf",]}
                      required={{
                        message: "One document required",
                        validate: (blocks) =>
                          blocks && blocks.length > 0 && blocks[0].value,
                      }}
                    />
                  </>
                }
              />
            </Section>
            <button
              className={formLayout.submit}
              type="submit"
              disabled={
                status === statuses.LOADING || status === statuses.SUCCESS
              }
            >
              {status === statuses.LOADING
                ? "Sending"
                : status === statuses.SUCCESS
                ? "Success!"
                : "Submit"}
            </button>
            {status === statuses.ERROR && "An error occured while submitting"}
            {status === statuses.SUCCESS && (
              <div className={styles.successMessage}>{SUCCESS_MESSAGE}</div>
            )}
            {status === "Duplicate" && <AlreadySubmitted />}
          </>
        )
      )}
    </form>
  );
};
