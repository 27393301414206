import React, { useState, useEffect } from "react";
import styles from "./textarea.module.scss";
import { ErrorMessage } from "@hookform/error-message";
import mergeRefs from "react-merge-refs";
import { useRef } from "react";
// import Requirement from "components/form/requirement";
const Textarea = ({ placeholder, errors, name, ...rest }) => {
  return (
    <div className={styles.element}>
      <TextareaInput {...rest} name={name} />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="error">{message}</div>}
      />
    </div>
  );
};

export default Textarea;

export const TextareaInput = ({
  name,
  forwardRef,
  minLength = 570,
  maxLength = 1700,
  placeholder,
  value,
  setValue,
  // required,
}) => {
  const ref = useRef();
  const [textLength, setTextLength] = useState(0);
  useEffect(() => {
    setTextLength(ref ? ref.current.value.length : 0);
    return () => {};
  }, [ref]);

  // const fulfilled = ref.current && ref.current.value.length > minLength;
  return (
    <>
      <div className={styles.textWrap}>
        <textarea
          className={styles.textarea}
          name={name}
          ref={mergeRefs([ref, forwardRef])}
          minLength={minLength}
          maxLength={maxLength}
          placeholder={placeholder}
          defaultValue={value}
          onChange={(e) => {
            setTextLength(e.target.value.length);
            setValue && setValue(e.target.value);
          }}
        />

        <Counter count={textLength} min={minLength} max={maxLength} />
      </div>
      {/* {required && (
        <Requirement fulfilled={fulfilled} message={required.message} />
      )} */}
    </>
  );
};

const Counter = ({ count, min, max }) => {
  return (
    <div className={styles.charCounter}>
      <span
        className={`${styles.currentCount} ${count < min ? "min-not-met" : ""}`}
      >
        {count}
      </span>
      /{max}
    </div>
  );
};
