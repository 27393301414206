import z1 from "./z1.svg";
import z2 from "./z2.svg";
import z3 from "./z3.svg";
import z4 from "./z4.svg";
import z5 from "./z5.svg";
import z6 from "./z6.svg";
import z7 from "./z7.svg";
import z8 from "./z8.svg";
import z9 from "./z9.svg";
import z10 from "./z10.svg";
import z11 from "./z11.svg";
import z12 from "./z12.svg";
import z13 from "./z13.svg";
import z14 from "./z14.svg";
import z15 from "./z15.svg";
import z16 from "./z16.svg";
import z17 from "./z17.svg";
import z18 from "./z18.svg";
import z19 from "./z19.svg";
import z20 from "./z20.svg";
import z21 from "./z21.svg";
import z22 from "./z22.svg";
import z24 from "./z24.svg";
import z23 from "./z23.svg";
import z25 from "./z25.svg";
import z26 from "./z26.svg";
import z27 from "./z27.svg";
import z28 from "./z28.svg";
import z29 from "./z29.svg";
import z30 from "./z30.svg";

const zData = [
  { icon: z1, id: "z1" },
  { icon: z2, id: "z2" },
  { icon: z3, id: "z3" },
  { icon: z4, id: "z4" },
  { icon: z5, id: "z5" },
  { icon: z6, id: "z6" },
  { icon: z7, id: "z7" },
  { icon: z8, id: "z8" },
  { icon: z9, id: "z9" },
  { icon: z10, id: "z10" },
  { icon: z11, id: "z11" },
  { icon: z12, id: "z12" },
  { icon: z13, id: "z13" },
  { icon: z14, id: "z14" },
  { icon: z15, id: "z15" },
  { icon: z16, id: "z16" },
  { icon: z17, id: "z17" },
  { icon: z18, id: "z18" },
  { icon: z19, id: "z19" },
  { icon: z20, id: "z20" },
  { icon: z21, id: "z21" },
  { icon: z22, id: "z22" },
  { icon: z23, id: "z23" },
  { icon: z24, id: "z24" },
  { icon: z25, id: "z25" },
  { icon: z26, id: "z26" },
  { icon: z27, id: "z27" },
  { icon: z28, id: "z28" },
  { icon: z29, id: "z29" },
  { icon: z30, id: "z30" },
];

export default zData;
