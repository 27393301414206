import React, { useEffect, useState } from "react";
import Grid from "./Grid";
import defaultItems from "./dummy";
import "./CityIndexScroller.scss";
import { locales, demographics } from "./constants";
//import Dropdown from "components/dropdown";
import styles from "./ideas-switcher.module.scss";
import { fills } from "./useLayouts";
import { useTransition, a } from "react-spring";
import Buttons from "./locale-buttons";

const demographicCategories = Object.keys(demographics);

const heading = "45 ideas";
const text = (
  <>
    <p>
      Teens share common ground across themes. When it comes to the details,
      though, different groups of teens give priority to different ideas.{" "}
      <strong>
        Select a residency and demographic to better understand the popularity
        of ideas!
      </strong>
    </p>
  </>
);

export default function IdeasSwitcher({
  data,
  cityIndexScroller = defaultItems,
  explainer,
}) {
  const items = cityIndexScroller.reduce((r, d) => {
    return [...r, ...d.items.map((item) => ({ ...item, category: d.label }))];
  }, []);
  const initialIndex = items[0].column;
  const [isMobile, setIsMobile] = useState();

  useEffect(() => {
    const media = window.matchMedia(`(min-width: 800px)`);
    media.onchange = onChange;
    onChange();
    function onChange() {
      setIsMobile(!media.matches);
    }
  }, []);

  const [active, setActive] = useState(null);

  const initialLocale = locales[0];

  const [locale, setLocale] = useState(initialLocale);
  const [demographicCategory, setDemographicCategory] = useState(
    demographicCategories[0]
  );
  const [demographic, setDemographic] = useState([]);

  useEffect(() => {
    setDemographic(demographics[demographicCategory][0]);
  }, [demographicCategory]);

  const props = {
    active,
    setActive,
    data,
    initialIndex,
    initialLocale,
    locale,
    setLocale,
    demographicCategory,
    setDemographicCategory,
    demographic,
    setDemographic,
  };

  return isMobile ? <Mobile {...props} /> : <Desktop {...props} />;
}

const Mobile = ({
  locale,
  setLocale,
  demographicCategory,
  setDemographicCategory,
  demographic,
  setDemographic,
  active,
  setActive,
  data,
  initialIndex,
  initialLocale,
}) => {
  const transitions = useTransition(active, null, {
    from: {
      position: "absolute",
      opacity: 0,
    },
    enter: { opacity: 1, position: "static" },
    leave: { opacity: 0, position: "absolute", width: "100%" },
  });
  const [showOver] = useState(true);
  return (
    <section className={styles.elementMobile}>
      <div className={styles.headerContent}>
        <h2 className={styles.heading}>{heading}</h2>
        <div className={styles.body}>{text}</div>
      </div>
      <div className={styles.header}>
        <Filters
          locales={locales}
          locale={locale}
          setLocale={setLocale}
          demographic={demographic}
          setDemographic={setDemographic}
          demographicCategory={demographicCategory}
          setDemographicCategory={setDemographicCategory}
        />
        <div className={styles.switchContainer}>
          {transitions.map(({ item, key, props }) =>
            item && item.Idea ? (
              <a.div style={props} key={key}>
                <Key />

                <Sidebar
                  {...item}
                  locale={locale}
                  reset={() => setActive(null)}
                />
              </a.div>
            ) : (
              <a.div style={props} key={key}>
                <Key />

                {data && (
                  <MobileGrid
                    showOver={showOver}
                    data={data}
                    locale={locale}
                    index={demographic}
                    zoomIndex={`${initialIndex} - ${initialLocale}`}
                    setActive={setActive}
                  />

                  // <CitySwitcher
                  //   data={data}
                  //   locale={locale}
                  //   index={demographic}
                  //   zoomIndex={`${initialIndex} - ${initialLocale}`}
                  //   setActive={setActive}
                  // />
                )}
              </a.div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

const MobileGrid = ({ data, locale, index, setActive }) => {
  if (!data) {
    return null;
  }
  const over = data.filter((d) => +d[`${index} - ${locale}`] >= 75);
  const under = data.filter((d) => +d[`${index} - ${locale}`] < 75);
  return (
    <div className={styles.mobileGrid}>
      <List items={over} className={styles.listOver} setActive={setActive} />
      <List items={under} className={styles.listUnder} setActive={setActive} />
    </div>
  );
};

const List = ({ className, items, setActive }) => {
  return (
    <ol className={className}>
      {items.map((props) => (
        <li key={props.Idea} onClick={() => setActive(props)}>
          <button>{props.Idea}</button>
        </li>
      ))}
    </ol>
  );
};

const Desktop = ({
  locale,
  setLocale,
  demographicCategory,
  setDemographicCategory,
  demographic,
  setDemographic,
  active,
  setActive,
  data,
  initialIndex,
  initialLocale,
}) => {
  const transitions = useTransition(active, null, {
    from: {
      position: "absolute",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      opacity: 0,
    },
    enter: { opacity: 1, position: "static" },
    leave: { opacity: 0, position: "absolute" },
  });
  return (
    <section className={styles.elementDesktop}>
      <div className={styles.header}>
        {transitions.map(({ item, key, props }) =>
          item && item.Idea ? (
            <a.div style={props} key={key}>
              <Sidebar
                {...item}
                locale={locale}
                reset={() => setActive(null)}
              />
            </a.div>
          ) : (
            <a.div style={props} key={key}>
              <div className={styles.headerContent}>
                <h2 className={styles.heading}>{heading}</h2>
                <div className={styles.body}>{text}</div>
              </div>
            </a.div>
          )
        )}
        {/* {
          active && active.Idea ? (
            <Sidebar
              {...active}
              locale={locale}
              reset={() => setActive(null)}
            />
          ) : (
            <div className={styles.headerContent}>
              <h2 className={styles.heading}>{heading}</h2>
              <div className={styles.body}>{text}</div>
            </div>
          ) // <div className={styles.sidebar}></div>
        } */}
      </div>
      {data ? (
        <div className="CityIndexSwitcher">
          <CitySwitcher
            controls={
              <Filters
                locales={locales}
                locale={locale}
                setLocale={setLocale}
                demographic={demographic}
                setDemographic={setDemographic}
                demographicCategory={demographicCategory}
                setDemographicCategory={setDemographicCategory}
              />
            }
            gridKey={<Key />}
            data={data}
            locale={locale}
            index={demographic}
            zoomIndex={`${initialIndex} - ${initialLocale}`}
            setActive={setActive}
          />
        </div>
      ) : (
        "Loading"
      )}
    </section>
  );
};

const Filters = ({
  locales,
  locale,
  setLocale,
  demographicCategory,
  setDemographicCategory,
  demographic,
  setDemographic,
}) => {
  return (
    <fieldset className={styles.fieldset}>
      <div className={styles.filter}>
        <Buttons
          values={locales}
          set={setLocale}
          currentValue={locale}
          firstAll
        />
        <Buttons
          values={demographicCategories}
          set={setDemographicCategory}
          currentValue={demographicCategory}
          firstAll
        />
        {/* <label>
          Demographic
          <Dropdown
            options={demographicCategories}
            value={demographicCategory}
            onChange={({ value }) => {
              setDemographicCategory(value);
            }}
          />
        </label> */}
        {demographics[demographicCategory].length > 1 && (
          <Buttons
            values={demographics[demographicCategory]}
            set={setDemographic}
            currentValue={demographic}
          />
          // <Dropdown
          //   options={demographics[demographicCategory]}
          //   value={demographic}
          //   onChange={({ value }) => setDemographic(value)}
          // />
        )}
      </div>
    </fieldset>
  );
};

const Key = () => {
  return (
    <div className={styles.key}>
      <div>How many teens identified this idea?</div>
      <ul>
        <li
          style={{
            backgroundColor: fills.over.bg,
            color: fills.over.text,
          }}
        >
          over 75%
        </li>
        <li
          style={{
            backgroundColor: fills.under.bg,
            color: fills.under.text,
          }}
        >
          less than 75%
        </li>
      </ul>
    </div>
  );
};

const CitySwitcher = ({
  data,
  locale,
  index,
  zoomIndex,
  controls,
  gridKey,
  setActive,
}) => {
  return (
    <div className={styles.main}>
      {controls || gridKey ? (
        <div className={styles.legend}>
          {controls}
          {gridKey}
        </div>
      ) : null}
      <div className={styles.tiles}>
        <Grid
          onSetActive={setActive}
          data={data}
          currentLocale={locale}
          currentIndex={index}
          zoomIndex={zoomIndex}
        />
      </div>
    </div>
  );
};

const Sidebar = ({ locale, reset, ...data }) => {
  return (
    <div className={styles.sidebar} onClick={reset}>
      <button onClick={reset} className={styles.sidebarClose}>
        Close
      </button>
      <div className={styles.sidebarHeading}>{data.Idea}</div>
      <div className={styles.sidebarText}>{data.Description}</div>
      <div>{locale === "All" ? "All Residencies" : locale}</div>
      <ul className={styles.sidebarItems}>
        <SidebarItem
          label="Gender"
          keys={["Female", "Male", "Trans"]}
          locale={locale}
          data={data}
        />
        <SidebarItem
          label="Race"
          keys={["Black", "Hispanic", "Mixed Race", "White"]}
          locale={locale}
          data={data}
        />
        <SidebarItem
          label="Age"
          keys={["Middle School", "High School"]}
          locale={locale}
          data={data}
        />
        <SidebarItem
          label="Afterschool Status"
          keys={["Afterschool Enrolled", "No Afterschool"]}
          locale={locale}
          data={data}
        />
      </ul>
    </div>
  );
};

const SidebarItem = ({ label, locale, data, keys }) => {
  return (
    <li className={styles.sidebarItem}>
      <div className={styles.sidebarItemLabel}>{label}</div>
      <ul className={styles.sidebarItemList}>
        {keys.map((key) => {
          const value = data[`${key} - ${locale}`];
          return (
            <li
              key={key}
              className={
                value >= 75
                  ? styles.sidebarItemListItemOver
                  : styles.sidebarItemListItemUnder
              }
            >
              {key.replace("Trans", "Trans/NB")}
            </li>
          );
        })}
      </ul>
    </li>
  );
};
