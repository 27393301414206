import React, { useRef, useEffect, useState } from "react";
import styles from "./intro.module.scss";
import { Link } from "react-router-dom";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
gsap.registerPlugin(ScrollTrigger);

const Intro = ({ className = "", children }) => {
    return <div className={`${styles.element} ${className}`}>{children}</div>;
};

export const Steps = ({ steps, setStep }) => {
    return (
        <div className={styles.steps}>
            {steps.map(({ key, ...props }) => {
                return <Step {...props} step={key} setStep={setStep} key={key} />;
            })}
        </div>
    );
};

export default Intro;

export const Copy = ({ heading, text, link, button }) => {
    return (
        <div className={styles.currentStepInner}>
            <h2 className={styles.heading}>{heading}</h2>
            <div className={styles.body}>{text}</div>
            {link && (
                <Link className={styles.link} to={link.to}>
                    {link.text}
                </Link>
            )}
            {button && (
                <button className={styles.button} onClick={button.onClick}>
                    {button.text}
                </button>
            )}
            {/* {button2 && (
        <button className={styles.button} onClick={button2.onClick}>
          {button2.text}
        </button>
      )} */}
        </div>
    );
};

export const Step = ({ children, shapes, theme }) => {
    const [className, setClassName] = useState("");

    useEffect(() => {
        const bp0 = "(min-width: 0px) and (max-width: 1200px)";
        const bp1 = "(min-width: 1201px)";
        ScrollTrigger.matchMedia({
            [bp0]: () => setClassName(`bg--${theme}`),
            [bp1]: () => setClassName(""),
        });

        return () => {
            ScrollTrigger.clearMatchMedia([bp0, bp1]);
        };
    }, [theme]);

    return (
        <>
            <div className={`${styles.step} ${className}`}>{children}</div>
            {shapes}
        </>
    );
};

export const ScrollStep = ({ shapes, heading, text, gif, theme, ...rest }) => {
    const ref = useRef();
    const copyRef = useRef();
    useEffect(() => {
        let t;
        ScrollTrigger.matchMedia({
            "(min-width: 1200px)": () => {
                t = ScrollTrigger.create({
                    trigger: ref.current,
                    start: "top bottom",
                    end: "bottom bottom",
                    pin: copyRef.current,
                    pinSpacing: false,
                    //markers: true,
                });
            },
        });
        return () => {
            t && t.kill();
            ScrollTrigger.clearMatchMedia();
        };
    }, []);

    return (
        <div className={styles.scrollStep}>
            <div ref={copyRef}>
                <Step theme={theme}>
                    <Copy heading={heading} text={text} {...rest} />
                    {gif ? (
                        <img src={gif} className={styles.gif} alt={"gif of eye"} />
                    ) : null}
                </Step>
            </div>
            <div ref={ref}>{shapes && shapes}</div>
        </div>
    );
};
