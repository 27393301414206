import React from "react";
import styles from "./prompt.module.scss";

const withPrompt = (Component) => ({ prompts, watch, ...props }) => {
  const watchComp = watch(props.name);
  return (
    <div>
      <div className={`${styles.label} fg--accent`}>Pick one question to answer*</div>
      <Component {...props}></Component>

      {watchComp && (
        <div className={styles.element}>
          <p>
            {prompts.filter((prompt) => prompt.value === watchComp)[0].prompt}
          </p>
        </div>
      )}
    </div>
  );
};

export default withPrompt;
