import React from "react";
import { FilestackUpload } from "components/form/filestack-upload";
//import { FILESTACK_CDN } from "const";
import Upload from "components/form/upload";

const DocumentUpload = ({ extensions, ...props }) => {
  return (
    <FilestackUpload
      {...props}
      accept={extensions}
      Preview={DocumentPreview}
      buttonText={"Add Document"}
    />
  );
};

const DocumentPreview = ({ handle, filename }) => {
  return <Upload filename={filename} />;
};

export default DocumentUpload;
