import React from "react";

import { ThemeProvider } from "components/teaser/theme-context";
import MapWrap from "./map";

const ProviderDirectory = ({ match }) => {
  return (
    <ThemeProvider value={"black"}>
      <div className="bg--black">
        <MapWrap section={match.params.slug}></MapWrap>
      </div>
    </ThemeProvider>
  );
};

export default ProviderDirectory;
