export const poll = async ({ fn, validate, interval, maxAttempts }) => {
  let attempts = 0;

  const executePoll = async (resolve, reject) => {
    const result = await fn();
    attempts++;

    if (validate(result)) {
      return resolve(result);
    } else if (maxAttempts && attempts === maxAttempts) {
      return reject(new Error("Exceeded max attempts"));
    } else {
      setTimeout(executePoll, interval, resolve, reject);
    }
  };

  return new Promise(executePoll);
};

export const screenname = (user) => {
  const sn = user["Screen Name"];
  return sn ? sn : toTitleCase(user["Display Name"]);
};

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const processFilters = (checkedItems) => {
  let newFilter = [];

  Object.keys(checkedItems).forEach((objkey) => {
    const keyArr = [...checkedItems[objkey]];

    if (keyArr.length > 0) {
      const values = keyArr
        .filter(([key, value]) => value === true)
        .map(([k, v]) => k);

      if (values.length > 0)
        newFilter.push({
          col: objkey,
          v: values,
        });
      //}
    }
  });
  return newFilter;
};

export const normalizeURL = (url) => {
  //url.replace("http://, https://");

  if (url.indexOf("http") === -1) {
    url = "http://" + url;
  }
  return url;
};
export const noProtocol = (url) => {
  return url.replace("http://", "").replace("https://", "").replace(/\/$/, "");
};
