import styles from "./section.module.scss";
import React from "react";

const Section = ({ heading, children }) => {
  return (
    <section className={styles.element}>
      <header className={`bg--accent`}>
        <span className={styles.inner}>{heading}</span>
      </header>
      <div className={styles.inner}>{children}</div>
    </section>
  );
};

export default Section;
