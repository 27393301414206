import React from "react";
import ReactFilestack from "filestack-react";
import { useState } from "react";
import { ErrorMessage } from "@hookform/error-message";

const FilestackUploadStandalone = ({
  label,
  name,
  forwardRef,
  errors,
  accept,
  Preview,
  apikey,
}) => {
  const [value, setValue] = useState("");

  return (
    <div>
      <div>{label}</div>
      <FilestackUpload
        accept={accept}
        value={value}
        setValue={setValue}
        Preview={Preview}
        apikey={apikey}
      />

      <input
        type={"text"}
        disabled
        value={value}
        name={name}
        ref={forwardRef}
        aria-invalid={errors[name] ? "true" : "false"}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="error">{message}</div>}
      />
    </div>
  );
};

export const FilestackUpload = ({
  accept,
  value,
  setValue,
  Preview,
  immediate = false,
  buttonText = "Add File",
  apikey = null,
}) => {
  const filestackProps = {
    apikey: apikey ? apikey : process.env.REACT_APP_FILESTACK,
    onSuccess: ({ filesUploaded, filesFailed }) => {
      if (filesUploaded[0]) {
        console.log(filesUploaded);
        setValue(filesUploaded[0]);
      }
    },
    actionOptions: {
      maxFiles: 1,
      accept,
      fromSources: [
        "local_file_system",
        "facebook",
        "instagram",
        "googledrive",
        "dropbox",
        "picasa",
      ],
    },
  };

  return (
    <div>
      {!value && (
        <ReactFilestack
          {...filestackProps}
          componentDisplayMode={{
            type: immediate ? "immediate" : "button",
          }}
        />
      )}
      {value && value.handle ? (
        <Preview {...value} />
      ) : (
        <ReactFilestack
          {...filestackProps}
          componentDisplayMode={{
            type: "button",
            customText: buttonText,
          }}
        />
      )}
    </div>
  );
};

export default FilestackUploadStandalone;
