import React, { useEffect, useContext } from "react";
import styles from "./story.module.scss";

//import TransformedVideo from "components/transformed-video";
import Media from "./media";
import ColumnLayout from "layouts/columns";
import ThemeContext, { ThemeProvider } from "components/teaser/theme-context";
import questions from "../questions";
import zData from "components/nav/z/z-data";
import SVG from "react-inlinesvg";
import themeData from "blocks/themes/data";
import { screenname } from "helpers";
import PostsNav from "components/posts-nav";
import MoreStories from "./more-stories";
import useStory from "./useStory";
import ResourceBar from "components/stories/resourceBar/resource-bar";
import Loading from "components/loading";
import { Link } from "react-router-dom";

const Story = ({ match }) => {
  if (match.params.slug) {
    return <StoryWrap slug={match.params.slug} />;
  } else {
    return <Review id={match.params.storyid} />;
  }
};
const NotFound = ({ text = "Not Found" }) => {
  return <p className={styles.notFound}>{text}</p>;
};
const StoryWrap = ({ slug }) => {
  const { loading, error, data } = useStory(slug);
  let fields = null;
  let theme = null;
  const notFoundText = (
    <div>
      <p>{`We couldnt find a page for ${slug}`}</p>
      <p>
        Go back to <Link to="/stories">stories</Link>, go{" "}
        <Link to="/">home</Link>, or{" "}
        <a href="mailto:info@generator-z.org">contact us</a>
      </p>
    </div>
  );
  if (loading) return <Loading text={`Loading ${slug}`} />;
  if (error) {
    return <NotFound text={notFoundText} />;
  }
  if (loading) {
    console.log("Loading ", slug);
  }
  if (data) {
    if (data.error) {
      return (
        <>
          {slug.indexOf("rec") === 0 ? (
            <>
              Review Links have moved:{" "}
              <a href={`/stories/review/${slug}`}>
                https://staging.generator-z.org/stories/review/{slug}
              </a>
            </>
          ) : (
            <NotFound text={notFoundText} />
          )}
        </>
      );
    }
    fields = data.fields;
    theme = fields["Theme"];
  }

  return (
    <ThemeProvider value={theme}>
      {data && (
        <>
          <ResourceBar />
          <StoryInner fields={fields} theme={theme} id={data.id}></StoryInner>
        </>
      )}
    </ThemeProvider>
  );
};
const Review = ({ id }) => {
  const { loading, error, data } = useStory(null, id);
  let fields = null;
  let theme = null;
  if (loading) return <>loading</>;
  if (error) {
    return <>Page not found</>;
  }

  if (data) {
    fields = data.fields;
    theme = fields["Theme"];
  }
  return (
    <ThemeProvider value={theme}>
      {data && (
        <StoryInner fields={fields} theme={theme} id={data.id}></StoryInner>
      )}
    </ThemeProvider>
  );
};

const StoryInner = ({ fields, theme, id }) => {
  const { setTheme } = useContext(ThemeContext);

  useEffect(() => {
    setTheme(theme);
  }, [theme, setTheme]);

  const joyQuestion = questions.joy.filter(
    ({ value }) => value === fields["Joy Question"]
  )[0].label;
  const challengeQuestion = questions.challenge.filter(
    ({ value }) => value === fields["Challenge Question"]
  )[0].label;

  const themeIcon = themeData.filter(
    ({ id }) => id === fields["Afterschool Theme"]
  )[0];
  const Z = zData.filter((th) => th.id === fields["Z Style"])[0];

  return (
    <ColumnLayout
      railContent={<PostsNav sect={"stories"} recId={id} theme={theme} />}
    >
      <div className={styles.element}>
        {/* <TransformedVideo handle={data.fields.Video} /> */}

        <div className={styles.heading}>
          <div>
            {fields["Title"] ? (
              <>
                <h2>{fields["Title"]}</h2>
                <h3>{fields["Title Description"]}</h3>
              </>
            ) : (
              <>
                <h3>{fields["Sum It Up Response"]}</h3>
              </>
            )}
          </div>
          <div className={`${styles.shape} story--accent`}>
            <SVG src={Z.icon}></SVG>
          </div>
        </div>

        <div className={styles.sentence}>
          <span>
            <SVG src={themeIcon.icon} className="story--accent" />
          </span>
          <p>
            {/* <strong>{fields["Identity"]}</strong> */}
            <strong>{screenname(fields)}</strong> is a {fields["Age"]} year old
            from{" "}
            {fields["Region"] === "New York"
              ? "Western New York"
              : "Southeast Michigan"}{" "}
            focusing on <strong>{fields["Afterschool Theme"]}</strong> as an
            afterschool theme.
          </p>
        </div>

        <div className={styles.imageLeft}>
          {!fields["Your Story - Stock Image"] && (
            <div className={styles.image}>
              <Media blocks={JSON.parse(fields["Your Story Image"])} />
            </div>
          )}

          <div className={styles.text}>
            <div className={styles.sectHeading}>
              <h3>Your Story, Short & Sweet </h3>
            </div>
            <p>{fields["Your Story Response"]}</p>
          </div>
        </div>

        <div className={`${styles.staggeredSection} ${styles.mediaRight}`}>
          <div className={styles.sectHeading}>
            <h3>{joyQuestion}</h3>
          </div>
          <div className={styles.mediaBlock}>
            <Media blocks={JSON.parse(fields["Joy Content"])} />
          </div>
        </div>
        <div className={`${styles.staggeredSection} ${styles.mediaLeft}`}>
          <div className={styles.sectHeading}>
            <h3>{challengeQuestion}</h3>
          </div>
          <div className={styles.mediaBlock}>
            <Media blocks={JSON.parse(fields["Challenge Content"])} />
          </div>
        </div>

        <div className={`${styles.fiftyFifty}`}>
          <div className={styles.text}>
            <h3>It's 3 pm, What’s up</h3>

            <p>{fields["3pm Response"]}</p>
          </div>
          {!fields["3pm - Stock Image"] && (
            <div className={styles.image}>
              <Media blocks={JSON.parse(fields["3pm Content"])} />
            </div>
          )}
        </div>
        <div className={styles.wideImageText}>
          <div className={styles.text}>
            <h3>
              When you applied to be a Generator and picked a theme, why did you
              choose it?
            </h3>
            <p>{fields["Afterschool Response"]}</p>
          </div>
          {!fields["Afterschool - Stock Image"] && (
            <div className={styles.image}>
              <Media blocks={JSON.parse(fields["Afterschool Image"])} />
            </div>
          )}
        </div>
        <div className={`${styles.fullWidth}`}>
          <div className={styles.sectHeading}>
            <h3>
              Imagine that you have a magic wand that can make the best
              afterschool experience ever that relates to your afterschool
              theme&mdash;what is it?
            </h3>
          </div>
          <div className={styles.mediaBlock}>
            <Media blocks={JSON.parse(fields["Afterschool Advice Content"])} />
          </div>
        </div>
        <div className={`${styles.fullWidth}`}>
          <div className={styles.sectHeading}>
            <h3>
              Imagine that you are an inventor that finds clever ways to solve
              problems. How does your afterschool dream happen during a
              pandemic?{" "}
            </h3>
          </div>
          <div className={styles.mediaBlock}>
            <Media blocks={JSON.parse(fields["Creative Solution Content"])} />
          </div>
        </div>
        <MoreStories currentId={id} themeColor={theme}></MoreStories>
        {/*
          

          

          <h3>Your Big Afterschool Dream</h3>
          <Media blocks={JSON.parse(fields["Afterschool Advice Content"])} />

          <h3>Your Creative Solution Content</h3>
          <Media blocks={JSON.parse(fields["Creative Solution Content"])} /> */}
      </div>{" "}
    </ColumnLayout>
  );
};

export default Story;
