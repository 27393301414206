import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSpring, useSprings, config } from "react-spring";
import useLayouts from "./useLayouts";
import AsyncComp from "components/AsyncComp";
import { Waypoint } from "react-waypoint";
import useMeasure from "react-use-measure";

function wrapText(context, text, x, y, maxWidth, lineHeight, center = true) {
  var words = text.split(" ");
  var line = "";
  var lines = [];

  for (var n = 0; n < words.length; n++) {
    var testLine = line + words[n] + " ";
    var metrics = context.measureText(testLine);
    var testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      lines.push({ line, x, y });
      line = words[n] + " ";
      y += lineHeight;
    } else {
      line = testLine;
    }
  }
  lines.push({ line, x, y });

  const yOff = (lineHeight * (lines.length - 1)) / 2;
  lines.forEach(({ line, x, y }, i) => {
    const yOffset = center ? -yOff : 0;
    context.fillText(line, x, y + yOffset);
  });

  return lines.length;
}

const dpr = typeof window !== "undefined" ? window.devicePixelRatio : 1;

const Grid = ({
  data,
  currentIndex,
  currentLocale,
  zoomIndex,
  onSetActive,
}) => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });
  const layout = useLayouts(dimensions, data);
  const gridItems = layout
    ? layout[`${currentIndex} - ${currentLocale}__${zoomIndex}`]
    : null;
  const [containerRef, { width, height }] = useMeasure();

  useEffect(() => {
    setDimensions({ width, height });
  }, [width, height]);

  // useEffect(() => {
  //   let timer;

  //   const onResize = () => {
  //     setDimensions(bounds);
  //   };

  //   set();
  //   window.addEventListener("resize", onResize);

  //   return () => {
  //     window.removeEventListener("resize", onResize);
  //     clearTimeout(timer);
  //   };
  // }, []);

  return (
    <div className="Grid" ref={containerRef} style={{ position: "relative" }}>
      {gridItems && (
        <Canvas
          raw={data}
          items={gridItems}
          canvasWidth={Math.floor(dimensions.width)}
          canvasHeight={Math.floor(dimensions.height)}
          keyPad={currentIndex === "all"}
          onSetActive={onSetActive}
        />
      )}
    </div>
  );
};

const AsyncGrid = (props) => {
  return (
    <AsyncComp onSetActive={props.onSetActive}>
      <Grid {...props} />
    </AsyncComp>
  );
};

export default AsyncGrid;

const Canvas = ({
  items,
  raw,
  canvasWidth,
  canvasHeight,
  keyPad,
  onSetActive,
}) => {
  const hovered = useRef();
  const [clicked, setClicked] = useState(null);
  const ttvisible = useRef(false);

  const [springs, set] = useSprings(items.length, (index) => {
    const { x, y, width, height, textOpacity } = items[index];
    return {
      from: {
        x,
        y,
        width,
        height,
        textOpacity,
        opacity: 1,
      },
      config: config.slow,
    };
  });
  const [animate, setAnimate] = useState(false);

  const [ttProps, setTtprops] = useSpring(() => ({
    opacity: 0,
  }));

  useEffect(() => {
    set((index) => {
      const { x, y, width, height, textOpacity } = items[index];

      return {
        x,
        y,
        width,
        height,
        textOpacity,
        config: config.slow,
        // delay: index
      };
    });
  }, [items, set]);

  // useEffect(() => {
  //   set((index) => {
  //     const { x, y, width, height, textOpacity } = items[index];

  //     if (clicked && clicked.label === items[index].label) {
  //       return {
  //         x,
  //         y: height,
  //         width,
  //         height: canvasHeight - 2 * height,
  //         textOpacity,
  //         config: config.slow,
  //         // delay: index
  //       };
  //     }
  //     return {
  //       x,
  //       y,
  //       width,
  //       height,
  //       textOpacity,
  //       config: config.slow,
  //       // delay: index
  //     };
  //   });
  // }, [clicked, canvasHeight, items, set]);

  const ref = useRef();
  const mouse = useRef();

  const getHoveredItem = useCallback(
    (mx, my) => {
      for (let i = 0; i < items.length; i++) {
        const { x, y, width, height } = items[i];
        if (mx >= x && mx < x + width && my >= y && my < y + height) {
          return items[i];
        }
      }
      return null;
    },
    [items]
  );

  useEffect(() => {
    if (ref.current) {
      const setFromEvent = (e) => {
        const { x, y, width, height } = ref.current.getBoundingClientRect();

        //mouse coords relative to canvas
        const mx = e.clientX - x;
        const my = e.clientY - y;

        mouse.current = {
          x: mx,
          y: my,
        };

        let visible = false;
        if (mx >= 0 && mx < width && my >= 0 && my < height) {
          //check if mouse is inside canvas
          const hoveredItem = getHoveredItem(mx, my);
          hovered.current = hoveredItem;
          if (hoveredItem) {
            visible = true;
          }
        } else {
          hovered.current = null;
        }
        ttvisible.current = visible;
        setTtprops({
          opacity: visible ? 1 : 0,
        });
      };

      const onScroll = () => {
        setTtprops({
          opacity: 0,
        });
      };
      const onClick = () => {
        setClicked(hovered.current);
        if (
          clicked &&
          hovered.current &&
          clicked.Idea === hovered.current.Idea
        ) {
          onSetActive(null);
          setClicked(null);
        } else {
          if (hovered.current) {
            onSetActive({ ...hovered.current });
          }
        }
      };
      const leave = () => {
        hovered.current = null;
      };

      const canvas = ref.current;

      canvas.addEventListener("mousemove", setFromEvent);
      canvas.addEventListener("mouseleave", leave);
      canvas.addEventListener("scroll", onScroll);
      canvas.addEventListener("click", onClick);

      return () => {
        canvas.removeEventListener("mousemove", setFromEvent);
        canvas.removeEventListener("scroll", onScroll);
        canvas.removeEventListener("click", onClick);
        canvas.removeEventListener("mouseleave", leave);
      };
    }
  }, [clicked, items, setTtprops, getHoveredItem, onSetActive]);

  useEffect(() => {
    if (ref.current && animate) {
      const width = canvasWidth;
      const height = canvasHeight;
      const fontSize = 10;
      //const tooltipFontsize = fontSize * 1.2;
      //const lineHeight = 2;
      // const ttwidth = 250;
      // const ttheight = fontSize * lineHeight;
      // const keywidth = 50;
      // const keyheight = height < 600 && !keyPad ? 20 : 25;
      // const keyx = width - 5 * keywidth;
      // const keystart = "Agreed Less";
      // const keyend = "Agreed More";
      // const textOffsetY = keyPad ? 5 : 0;
      // const textOffsetX = keyPad ? 2.5 : 2;

      const c = ref.current.getContext("2d", { alpha: true });
      c.setTransform(dpr, 0, 0, dpr, 0, 0);

      let raf;

      const update = () => {
        c.clearRect(0, 0, width, height);

        c.font = `${fontSize}px Good Sans, sans-serif`;

        // //Key
        // [1, 2, 3, 4, 5].forEach((grade, i) => {
        //   c.fillStyle = colors[grade];
        //   c.fillRect(keyx + keywidth * i, 0, keywidth, keyheight);
        //   c.fillStyle = "#000";
        //   c.fillText(
        //     i + 1,
        //     keyx - keywidth / textOffsetX + keywidth * (i + 1),
        //     keyheight / 2 + textOffsetY
        //   );
        // });

        // c.fillStyle = "#000";
        // c.textAlign = "start";
        // c.fillText(keystart, keyx, keyheight + (9 + textOffsetY));
        // c.textAlign = "end";
        // c.fillText(keyend, width, keyheight + (9 + textOffsetY));

        // c.font = `500 ${fontSize}px ApercuPro`;
        // c.fillText("KEY", keyx - fontSize, keyheight - 10);

        // c.font = `${fontSize}px ApercuWeWorkMono-Regular`;

        //Tiles
        const renderTile = ({ x, y, width, height }, i) => {
          const { label, isCurrent, fill, textFill } = items[i];
          c.save();
          c.beginPath();
          // c.fillStyle =
          //   ttvisible.current === false || hovered.current.label === label
          //     ? fill
          //     : fill_tint;
          c.fillStyle =
            (hovered.current && hovered.current.label === label) ||
            (clicked && clicked.label === label)
              ? "#fff"
              : fill;

          c.rect(x.value, y.value, width.value, height.value);

          // c.ellipse(
          //   x.value + width.value / 2,
          //   y.value + height.value / 2,
          //   width.value / 2,
          //   height.value / 2,
          //   0,
          //   0,
          //   2 * Math.PI
          // );

          c.fill();
          c.lineWidth = 1;
          c.strokeStyle = "#1b1a1f";
          c.stroke();

          if (isCurrent) {
            c.textAlign = "center";
            c.textBaseline = "middle";
            c.fillStyle = textFill;

            wrapText(
              c,
              label,
              x.value + width.value / 2,
              y.value + height.value / 2,
              width.value * 0.8,
              fontSize * 1.4 //line-height
            );
          }
          c.restore();
        };

        // if (clicked) {
        //   let currentIndex;
        //   springs.forEach((props, i) => {
        //     const { label } = items[i];
        //     if (clicked.label === label) {
        //       currentIndex = i;
        //     } else {
        //       renderTile(props, i);
        //     }
        //   });
        //   renderTile(springs[currentIndex], currentIndex);
        // } else {
        springs.forEach(renderTile);
        //}

        //Tooltip
        // if (hovered.current) {
        //   let { x, y } = mouse.current;
        //   y -= ttheight / 2;
        //   if (x + ttwidth > width) {
        //     x -= ttwidth;
        //   }
        //   if (y < 2) {
        //     y = 2;
        //   } else if (y + ttheight > height) {
        //     y = height - ttheight - 2;
        //   }

        //   const opacity = ttProps.opacity.value;
        //   c.save();
        //   c.beginPath();

        //   const lineCount = wrapText(
        //     c,
        //     hovered.current.Theme || "TODO: theme",
        //     x + 10,
        //     y + 2 * fontSize,
        //     ttwidth,
        //     fontSize * 1.1, //line-height
        //     false
        //   );

        //   c.globalAlpha = opacity;
        //   c.fillStyle = "#ffffff";
        //   c.strokeStyle = "#000000";
        //   c.rect(
        //     x,
        //     y + 2 * fontSize - 10,
        //     ttwidth + 20,
        //     lineCount * fontSize + 20
        //   );
        //   c.stroke();
        //   c.fill();
        //   c.fillStyle = "#000000";

        //   c.textBaseline = "top";
        //   wrapText(
        //     c,
        //     hovered.current.Theme || "TODO: theme",
        //     x + 10,
        //     y + 2 * fontSize,
        //     ttwidth,
        //     fontSize * 1.1, //line-height
        //     false
        //   );

        //   c.restore();
        // }

        raf = requestAnimationFrame(update);
      };
      raf = requestAnimationFrame(update);

      return () => {
        cancelAnimationFrame(raf);
      };
    }
  }, [
    animate,
    springs,
    raw,
    items,
    ttProps,
    canvasWidth,
    canvasHeight,
    keyPad,
    clicked,
  ]);

  return (
    <Waypoint
      onEnter={() => {
        setAnimate(true);
      }}
      onLeave={() => {
        setAnimate(false);
      }}
    >
      <canvas width={canvasWidth * dpr} height={canvasHeight * dpr} ref={ref} />
    </Waypoint>
  );
};
