import React from "react";
import styles from "./image.module.scss";
import { FILESTACK_CDN } from "const";

const API_KEY = process.env.REACT_APP_FILESTACK;

const Image = ({ handle, width, smart_crop, color = "1b1a1f" }) => {
  if (!handle) {
    return null;
  }
  const transform = width
    ? `${API_KEY}${
        smart_crop
          ? "/resize=height:800/smart_crop=width:" +
            width +
            ",height:" +
            width +
            `,mode:face,fill_color:${color}`
          : "/resize=width:2000"
      }`
    : "";

  return (
    <img
      src={`${FILESTACK_CDN}/${transform}/${handle}`}
      alt="desc"
      className={`${styles.element} .image-el`}
    />
  );
};

export default Image;
