import React from "react";
import styles from "./action.module.scss";
import shape1 from "./shape1.svg";
import shape2 from "./shape2.svg";
import shape3 from "./shape3.svg";
import SVG from "react-inlinesvg";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { useContext } from "react";
import ModalContext from "components/teaser/modal-context";
gsap.registerPlugin(ScrollTrigger);

const heading = "Moving to Action";
const text = (showModal) => (
  <>
    <p>
      Teen Opinions Count lays a framework to fuel action. We know that the
      world is always changing, now more than ever. Generator Z slides in to
      take afterschool to the next level. Help reimagine the future of
      afterschool and direct the change that you want to see!{" "}
      <button className={styles.cta} onClick={showModal}>
        <strong>Sign up for Generator Z today!</strong>
      </button>
    </p>
  </>
);

const shapes = [shape3, shape2, shape1];

const Action = () => {
  const { setShowModal } = useContext(ModalContext);
  return (
    <div className={styles.element}>
      <div className={styles.shapes}>
        {shapes.map((shape, i) => (
          <SVG key={shape} src={shape} className={styles[`shape${i}`]} alt="" />
        ))}
      </div>
      <div className={styles.body}>
        <h2 className={styles.heading}>{heading}</h2>
        <div className={styles.text}>{text(() => setShowModal(true))}</div>
      </div>
    </div>
  );
};

export default Action;
