import React from "react";
import styles from "./text.module.scss";
import { ErrorMessage } from "@hookform/error-message";

const Text = ({
  label,
  name,
  type = "text",
  forwardRef,
  errors,
  className,
  pattern,
  readonly,
  placeholder,
  prefix,
  min,
  maxLength,
  size,
  required,
}) => {
  return (
    <div className={className} style={{ position: "relative" }}>
      <label>
        <span className={styles.label}>{label}</span>
        <div className={styles.fieldWrap}>
          {prefix && <span className={styles.prefix}>{prefix}</span>}
          <input
            tabIndex={0}
            className={styles.input}
            type={type}
            name={name}
            ref={forwardRef}
            aria-invalid={errors[name] ? "true" : "false"}
            pattern={pattern}
            readOnly={readonly}
            placeholder={placeholder}
            min={min}
            maxLength={maxLength}
            size={size}
          />
        </div>
      </label>

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="error">{message}</div>}
      />
    </div>
  );
};

export default Text;
