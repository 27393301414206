import { useEffect, useState } from "react";

const breakpoints = {
  1: 0,
  2: 600,
  4: 1200,
  5: 1400,
};
const useColumns = () => {
  const [columns, setColumns] = useState(1);

  useEffect(() => {
    const onResize = () => {
      const width = window.innerWidth;
      let columns;
      for (let key in breakpoints) {
        if (width > breakpoints[key]) {
          columns = key;
        }
      }
      setColumns(+columns);
    };
    window.addEventListener("resize", onResize);
    onResize();

    return () => window.removeEventListener("resize", onResize);
  }, []);

  return columns;
};

export default useColumns;
