import fun from "./fun.svg";
import play from "./play.svg";
import arts from "./arts.svg";
import identity from "./identity.svg";
import learning from "./learning.svg";
import mental from "./mental.svg";
import jobs from "./jobs.svg";
import friends from "./friends.svg";
import growth from "./growth.svg";
import ready from "./ready.svg";

const data = [
    {
        id: "Mental, Social & Emotional Health",
        icon: mental,
        heading: "Mental, Social & Emotional Health",
        subheading: "87% of teens interested",
        plainText:
            "Valuing my mental health and navigating the stress of being a teen with support",
        items: [
            {
                id: "Mental",
                heading: "Mental Health",
                plainText: "affordable or free mental health guidance and support",
            },
            {
                id: "Social",
                heading: "Social & Emotional Support from Adults & Peers",
                plainText:
                    "learning life skills to deal with the stress and anxiety of being a teen with support from people who care",
            },
        ],
    },
    {
        id: "Identity & Acceptance",
        icon: identity,
        heading: "Identity & Acceptance",
        subheading: "93% of teens interested",
        plainText:
            "Feeling safe, included, supported, and appreciated for being me without judgement or bullying",
        items: [
            {
                id: "Individual",
                heading: "Individuality & Identity",
                plainText:
                    "to be valued, safe, secure, included, and appreciated for being me",
            },
            {
                id: "LGBTQ+",
                heading: "LGBTQ+",
                plainText:
                    "the safety and support to explore my gender identity and sexuality",
            },
            {
                id: "Race",
                heading: "Race",
                plainText: "the safety and support to explore my racial experiences",
            },
            {
                id: "Acceptance",
                heading: "Acceptance",
                plainText: "to safely be who I am without judgement or bullying",
            },
        ],
    },
    {
        id: "Growth & Wellness",
        icon: growth,
        heading: "Growth & Wellness",
        subheading: "93% of teens interested",
        plainText:
            "Caring for myself through activities that keep me healthy and stress free to feel my best",
        items: [
            {
                id: "Health",
                heading: "Health",
                plainText:
                    "guidance and support on healthy living including diet exercise",
            },
            {
                id: "outdoor",
                heading: "Outdoor Activities & Appreciation",
                plainText: "activities and exercise rooted in nature",
            },
            {
                id: "Gardening & Farming",
                heading: "Gardening & Farming",
                plainText: "learning to grow through gardening and agriculture",
            },
            {
                id: "Animals",
                heading: "Animals",
                plainText: "caring for and interacting with animals",
            },
        ],
    },
    {
        id: "Friends & Community",
        icon: friends,
        heading: "Friends & Community",
        subheading: "94% of teens interested",
        plainText:
            "Connecting to friends, building relationships, and working together to support one another",
        items: [
            {
                id: "Friends & Socializing",
                heading: "Friends & Socializing",
                plainText: "time to hang out with my friends and make new friends",
            },
            {
                id: "Community",
                heading: "Community",
                plainText: "making a positive impact in a community that supports me",
            },
            {
                id: "Teamwork & Impact",
                heading: "Teamwork & Impact",
                plainText: "working with other teens to make a real difference",
            },
        ],
    },
    {
        id: "Excellence in Learning",
        icon: learning,
        heading: "Excellence in Learning ",
        subheading: "94% of teens interested",
        plainText:
            "Finding workspaces, peers, and mentors to help me learn new things and prepare for the future",
        items: [
            {
                id: "Homework & Workspaces",
                heading: "Homework & Workspaces",
                plainText: "help with homework and places to study",
            },
            {
                id: "Computer & Internet Access",
                heading: "Computer & Internet Access",
                plainText:
                    "access to computers and the internet to do my work and learn new things",
            },
            {
                id: "Mentoring",
                heading: "Mentoring",
                plainText: "finding a mentor or becoming a mentor",
            },
            {
                id: "Enrichment Classes",
                heading: "Enrichment Classes",
                plainText: "classes outside of school on a variety of subjects",
            },
            {
                id: "STEM",
                heading: "STEM",
                plainText:
                    "lessons and activities in science, technology, engineering, and math",
            },
            {
                id: "College",
                heading: "College",
                plainText: "activities and support to prepare me for College",
            },
        ],
    },
    {
        id: "Jobs & Careers",
        icon: jobs,
        heading: "Jobs & Careers",
        subheading: "93% of teens interested",
        plainText:
            "Exploring  job opportunities, hands-on-training, and career paths to be great at what I want to do ",
        items: [
            {
                id: "Job Search Assistance",
                heading: "Job Search Assistance",
                plainText: "accessing information to help find a job",
            },
            {
                id: "Job Opportunities",
                heading: "Job Opportunities",
                plainText: "securing a job that is compatible with my schedule",
            },
            {
                id: "Internships",
                heading: "Internships",
                plainText:
                    "exploring professional interests and options through internships",
            },
            {
                id: "Career Skills & Training",
                heading: "Career Skills & Training",
                plainText: "building job skills to be prepared and successful",
            },
            {
                id: "Hands-on Learning",
                heading: "Hands-on Learning",
                plainText: "opportunities for hands-on learning and activities",
            },
            {
                id: "Small Business & Entrepreneurship",
                heading: "Small Business & Entrepreneurship",
                plainText: "learning how to start and run my own business",
            },
        ],
    },
    {
        id: "Creative Arts & Culture",
        icon: arts,
        heading: "Creative Arts & Culture",
        subheading: "94% of teens interested",
        plainText:
            "Exploring creativity and culture to express myself, craft my art, and celebrate others ",
        items: [
            {
                id: "Visual Arts",
                heading: "Visual Arts",
                plainText: "exploring creative arts and showcasing my art",
            },
            {
                id: "Reading & Writing",
                heading: "Reading & Writing",
                plainText: "spaces to read and opportunities to be a better writer",
            },
            {
                id: "Drama & Theater",
                heading: "Drama & Theater",
                plainText: "spaces and support for performing arts and drama",
            },
            {
                id: "Music Education",
                heading: "Music Education",
                plainText: "learning how to play an instrument and make music",
            },
            {
                id: "Live Music & Concerts",
                heading: "Live Music & Concerts",
                plainText: "spaces for live music and opportunities for musicians",
            },
            {
                id: "Language & Culture",
                heading: "Language & Culture",
                plainText: "learning new languages and cultures",
            },
        ],
    },
    {
        id: "Fun & Games",
        heading: "Fun & Games",
        icon: fun,
        subheading: "94% of teens interested",
        plainText:
            "Enjoying activities, games, and gaming that connects me to my friends and community",
        items: [
            {
                id: "Accessible Activities",
                heading: "Accessible Activities",
                plainText: "affordable and interesting activities and trips",
            },
            {
                id: "Fun, Games, & Activity Centers",
                heading: "Fun, Games, & Activity Centers",
                plainText:
                    "a place with games and activities for me, my friends, and my family",
            },
            {
                id: "Gaming",
                heading: "Gaming",
                plainText:
                    "playing video games and competing with friends and other teens",
            },
            {
                id: "Food Options",
                heading: "Food Options",
                plainText: "places to eat and hang out with my friends",
            },
            {
                id: "Summer Camp",
                heading: "Summer Camp",
                plainText: "a camp for teens during summer break",
            },
        ],
    },
    {
        id: "Play & Sports",
        icon: play,
        heading: "Play & Sports",
        subheading: "94% of teens interested",
        plainText:
            "Moving around physically by myself or with others on the court, in the field, on ice, or in water",
        items: [
            {
                id: "Play & Fitness",
                heading: "Play & Fitness",
                plainText:
                    "non-competitive physical activity, play, and sports year round",
            },
            {
                id: "Sports Facilities",
                heading: "Sports Facilities",
                plainText: "places for me and my team to practice and play sports",
            },
            {
                id: "Parks with Basketball",
                heading: "Parks with Basketball",
                plainText: "traditional parks with playgrounds and basketball courts",
            },
            {
                id: "Sports Equipment",
                heading: "Sports Equipment",
                plainText: "affordable or free high-quality sports gear",
            },
            {
                id: "Skate Facilities & Skate Parks",
                heading: "Skate Facilities & Skate Parks",
                plainText: "places for ice skating, roller skating, and skateboarding",
            },
            {
                id: "Swim Facilities & Water Activities",
                heading: "Swim Facilities & Water Activities",
                plainText: "places to swim and have fun in the water",
            },
        ],
    },
    {
        id: "Life Skills & Balance",
        icon: ready,
        heading: "Life Skills & Balance",
        subheading: "86% of teens interested",
        plainText:
            "Figuring out how to balance my responsibilities at home, school, and work",
        items: [
            {
                id: "Practical Life Skills",
                heading: "Practical Life Skills",
                plainText:
                    "learning practical skills to become self-sufficient and prepared for the future",
            },
            {
                id: "Relaxing",
                heading: "Relaxing",
                plainText: "downtime to relax outside of school and homework",
            },
            {
                id: "Balance",
                heading: "Balance",
                plainText:
                    "learning to balance school, job, extracurricular activities, and rest",
            },
        ],
    },
];

export default data;
