import { useEffect, useState } from "react";

const useMatchMedia = (query) => {
  const [matches, setMatches] = useState();
  useEffect(() => {
    if (typeof window !== undefined && window.matchMedia) {
      const media = window.matchMedia(query);
      const onChange = () => setMatches(media.matches);
      if (typeof media.addEventListener !== "undefined") {
        media.addEventListener("change", onChange);
      } else {
        media.addListener(onChange); //ios safari still needs this.  throws undefined for addEventListener
      }
      onChange();
      return () => {
        if (typeof media.removeEventListener !== "undefined") {
          media.removeEventListener("change", onChange);
        } else {
          media.removeListener(onChange);
        }
      };
    }
  }, [query]);
  return matches;
};

export default useMatchMedia;
