import React from "react";
import { Link } from "react-router-dom";
import styles from "./story-tile.module.scss";
import SVG from "react-inlinesvg";
import themeData from "blocks/themes/data";
import useStory from "components/stories/story/useStory";
import TileContent from "components/stories/story/tileContent";
import Image from "components/stories/image";

const StoryTile = ({ id, show, fields, color, children, section }) => {
  const coverImage = getCoverImage(fields, color);

  return (
    <li
      key={id}
      className={`${styles.element}  ${show ? styles.show : styles.hide} tile`}
    >
      <Link
        to={`/${section === "stories" ? section : "afterschool-ideas"}/${
          fields.Slug
        }`}
      >
        {coverImage ? (
          <div className={styles.imageWrap}>{coverImage}</div>
        ) : (
          <></>
        )}
        <div className={styles.info}>{children}</div>
      </Link>
    </li>
  );
};

export const StoryTileLoad = (props) => {
  const { loading, data, error } = useStory(props.slug, null);
  if (loading) {
    return <>loading...</>;
  }
  if (error) {
    return <>{console.log(error)}</>;
  }
  if (data)
    return (
      <StoryTile fields={data.fields} {...props} section={"stories"}>
        <TileContent fields={data.fields} />
      </StoryTile>
    );
};

// cover image helper
const getCoverImage = (fields, color) => {
  if (!fields["Your Story - Stock Image"]) {
    // console.log(
    //   "checking story image|",
    //   fields["Slug"],
    //   "|",
    //   fields["Survey ID"]
    // );
    const hasImage =
      fields["Your Story Image"] &&
      checkImageJson(fields["Your Story Image"], color);
    if (hasImage) return hasImage;
  }
  if (!fields["3pm - Stock Image"]) {
    // console.log("checking 3pm image");
    const hasImage =
      fields["3pm Content"] && checkImageJson(fields["3pm Content"]);
    if (hasImage) return hasImage;
  }
  if (!fields["Afterschool - Stock Image"]) {
    // console.log("checking afterschool image");
    const hasImage =
      fields["Afterschool Image"] &&
      checkImageJson(fields["Afterschool Image"]);
    if (hasImage) return hasImage;
  }

  const themeIcon = themeData.filter(
    ({ id }) => id === fields["Afterschool Theme"]
  )[0];

  return themeIcon ? <SVG src={themeIcon.icon}></SVG> : <>no image</>;
};

const checkImageJson = (image, color) => {
  try {
    image = image ? JSON.parse(image) : null;
  } catch (e) {
    console.error("image JSON issue", image);
  }
  if (image && image[0] && image[0].value) {
    return (
      <Image
        handle={image[0].value.handle}
        width={601}
        smart_crop={true}
        color={color}
      />
    );
  }
  return false;
};

export default StoryTile;
