import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import styles from "./radio-icon-set.module.scss";
import SVG from "react-inlinesvg";

const RadioIconSet = ({ name, options, generateRef, errors, current }) => {
  return (
    <div className={styles.element}>
      {options.map((option, i) => (
        <label
          key={option.heading}
          //eslint-disable-next-line eqeqeq
          className={current == i ? styles.activeLabel : styles.label}
        >
          <input
            className={styles.input}
            name={name}
            value={i}
            type="radio"
            ref={generateRef()}
          />
          <div className={styles.indicator}>
            <span className={styles.radio} />
            <SVG
              className={styles.icon}
              src={option.icon}
              alt={`icon for ${option.name}`}
            />
          </div>
          <div>
            <div className={styles.heading}>{option.heading}</div>
            <p className={styles.text}>{option.plainText}</p>
            <div className={styles.items}>
              IDEAS: {option.items.map(({ heading }) => heading).join(", ")}
            </div>
          </div>
        </label>
      ))}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="error">{message}</div>}
      />
    </div>
  );
};

export default RadioIconSet;
