import React, { useEffect, useState, useRef } from "react";
import styles from "./steps.module.scss";
import { Link } from "react-router-dom";
import introStyles from "components/teaser/intro/intro.module.scss";
// import ui from "scss/ui.module.scss";
import { useSpring, a } from "react-spring";
import useMeasure from "react-use-measure";

import SVG from "react-inlinesvg";
import mergeRefs from "react-merge-refs";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
gsap.registerPlugin(ScrollTrigger);

const Steps = ({
    heading,
    buttonLink,
    buttonText,
    steps,
    shape,
    progressIcon,
    anchorPrefix,
    backgroundColor,
}) => {
    const [step, setStep] = useState(0);

    // const props = useSpring({
    //   gridTemplateColumns: step > 0 ? "1fr 66.66%" : "3fr 0%",
    // });

    const ref = useRef();
    const innerRef = useRef();
    const stepsRef = useRef();
    const [progress, setProgress] = useState(0);
    const tl = useRef();
    const [containerRef, { height: containerHeight }] = useMeasure();

    const progressProps = useSpring({
        transform: `translateY(${progress * containerHeight}px)`,
    });

    useEffect(() => {
        ScrollTrigger.saveStyles([innerRef.current]);
        ScrollTrigger.matchMedia({
            "(min-width: 1200px)": () => {
                tl.current = gsap
                    .timeline({
                        duration: 4.5,
                        scrollTrigger: {
                            trigger: ref.current,
                            start: "top top",
                            scrub: true,
                            end: () => "+=" + window.innerHeight,
                            pin: true,
                            onUpdate: (self) => {
                                const step = Math.max(
                                    0,
                                    Math.min(3, Math.floor(self.progress * 4.5))
                                );
                                setStep(step);
                                setProgress(self.progress);
                            },
                        },
                    })
                    //.to(stepsRef.current, { yPercent: -66.66, duration: 3 }, 1.5)
                    .to(
                        innerRef.current,
                        {
                            width: "66.6666%",
                            duration: 1,
                        },
                        0
                    );
            },
        });

        return () => {
            tl.current && tl.current.scrollTrigger.kill();

            ScrollTrigger.clearMatchMedia();
        };
    }, []);

    const numberSteps = steps.filter((step) => step.number !== undefined);

    return (
        <div
            className={`${styles.element} ${backgroundColor}`}
            ref={mergeRefs([containerRef, ref])}
        >
            <div className={styles.inner}>

                <div className={styles.header}>
                    <div>
                        {heading &&
                            <h2>{heading}</h2>
                        }
                        {(buttonLink && buttonText) &&
                            <Link className={introStyles.button} to={buttonLink}>
                                {buttonText}
                            </Link>
                        }
                    </div>
                    <img alt="" src={shape} className={styles.headerShape} />
                </div>
                <div className={styles.body} ref={innerRef}>
                    <ol className={styles.numbers}>
                        {numberSteps.map(({ number }) => (
                            <li
                                key={number}
                                className={
                                    number === step ? styles.activeStepNumber : styles.stepNumber
                                }
                            >
                                {number}
                            </li>
                        ))}
                    </ol>
                    <ul className={styles.steps} ref={stepsRef}>
                        {numberSteps.map((props, i) => {
                            return (
                                <Step
                                    key={props.key}
                                    {...props}
                                    active={props.number === step}
                                    anchor={`${anchorPrefix}step${i}`}
                                    anchorPrefix={anchorPrefix}
                                />
                            );
                        })}
                    </ul>
                </div>
                <a.div className={styles.progress} style={progressProps}>
                    <SVG src={progressIcon} />
                </a.div>
            </div>
        </div>
    );
};

export default Steps;

const Step = ({
    number,
    text,
    onSelect,
    active,
    cta,
    anchor,
    anchorPrefix,
}) => {
    //const [ref, { height }] = useMeasure();
    // const props = useSpring({
    //   height: active ? `${height}px` : "0px",
    // });

    const inner = (
        <div className={styles.stepInner}>
            <div className={styles.stepNumber}>{number}</div>
            {/* <div className={styles.stepNumber}>
        {active ? (
          number
        ) : (
          <a href={`#${anchorPrefix}step${number}`}>{number}</a>
        )}
      </div> */}
            {/* <a.div style={props}> */}
            <div className={styles.stepBodyDetail}>
                <div className={styles.stepBody}>{text}</div>
            </div>
            {/* </a.div> */}
        </div>
    );

    return (
        <a.li
            //ref={containerRef}
            className={`${styles.step} ${styles.activeStep}`}
            id={anchor}
        >
            {inner}
            {/* {active ? (
        inner
      ) : (
        <div className={styles.stepButton} onClick={onSelect}>
          {inner}
        </div>
      )} */}
        </a.li>
    );
};

// const Progress = ({ value, max, height = 4, width = 100 }) => {
//   return (
//     <svg viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
//       <rect x={0} y={0} width={width} height={height} fill={"lightgray"} />
//       <rect
//         x={0}
//         y={0}
//         width={width * (value / max)}
//         height={height}
//         fill={"black"}
//       />
//     </svg>
//   );
// };
