import React from "react";
import { OutboundLink } from "react-ga";
import styles from "./banner.module.scss";
function Banner() {
  return (
    <div className={styles.element}>
      <div className={styles.content}>
        Click{" "}
        <OutboundLink
          to="https://www.eventbrite.com/e/generator-z-additional-qa-session-tickets-141869378129"
          eventLabel="info_session"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </OutboundLink>{" "}
        to sign up for a virtual information session
      </div>
    </div>
  );
}

export default Banner;
