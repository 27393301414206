const roles = [
  "Brand Identity",
  "Community Review Team",
  "Data Specialist",
  "Expert Consultant",
  "Foundation Staff",
  "Foundation Trustee",
  "Generator",
  "Generator Stories Copy Editor",
  "Generator Stories Publishing Team",
  "Generator Workshop Facilitator",
  "Generator Workshop Mentor/Coach",
  "Grants Administrator",
  "Graphic Design",
  "Illustration & Animation",
  "Info Session Facilitator",
  "Lead Program Manager",
  "Marketing",
  "Outreach Lead",
  "PR & Social Media",
  "Program Design & Direction",
  "Project Manager",
  "Roundtable (Co-Design) Facilitator",
  "Roundtable (Co-Design) Participant",
  "Video Production",
  "Website Development",
];

const filterOpts = [
  {
    col: "Generation",
    heading: "Generation",
    type: "checkboxes",
    options: [
      { value: "Gen Z" },
      { value: "Millennial" },
      { value: "Gen X" },
      { value: "Baby Boomer" },
      { value: "Silent Generation" },
    ],
  },
  {
    col: "Region",
    heading: "Region",
    type: "checkboxes",
    options: [
      { value: "Southeast Michigan", label: "Southeast Michigan" },
      { value: "Western New York", label: "Western New York" },
      { value: "Other", label: "Other" },
    ],
  },
  {
    col: "Roles",
    heading: "Role",
    type: "checkboxes",
    options: roles.map((value) => {
      if (value === "Generator") {
        return { value: "Generator~", label: "Generator" };
      }
      return {
        value: value,
      };
    }),
  },
];

export default filterOpts;
