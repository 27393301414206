import React, { useContext, useEffect, useState } from "react";
import FilterContext from "components/stories/filter-context";
import StoryTile from "components/tile";
import TileContent from "../tileContent";
import styles from "./more-stories.module.scss";

const MoreStories = ({ currentId, themeColor, heading }) => {
  const { filteredStories } = useContext(FilterContext);
  const [stories, setStories] = useState([]);

  useEffect(() => {
    let newStories = [];
    const keys = filteredStories ? Object.keys(filteredStories) : null;
    if (keys && keys.length > 1) {
      //get 3 random numbers\

      let arr = [];
      while (arr.length < (keys.length > 3 ? 3 : keys.length)) {
        var r = Math.floor(Math.random() * keys.length) + 1;
        if (arr.indexOf(r) === -1) arr.push(r);
      }

      let backfill = [];
      let index = 0;
      keys.some((key) => {
        const foundIndex = arr.indexOf(index);
        const curr = filteredStories[key].id === currentId;

        if (foundIndex > -1) {
          arr.splice(foundIndex, 1);
          if (!curr) {
            newStories.push(filteredStories[key]);
          }
          if (newStories.length === 3) {
            return true;
          }
        } else if (!curr) {
          backfill.push(filteredStories[key]);
        }

        index++;

        return false;
      });
      while (
        newStories.length < (keys.length < 4 ? keys.length - 1 : 3) &&
        backfill.length > 0
      ) {
        // console.log("backfilling", backfill);
        const backfillRand = Math.floor(Math.random() * backfill.length - 1);
        const backfilled = backfill.splice(backfillRand, 1);
        newStories.push(backfilled[0]);
      }

      setStories(newStories);
    }
  }, [filteredStories, currentId]);
  const colors = {
    white: "fff",
    black: "1b1a1f",
    blue: "523dff",
    purple: "ab1eba",
    red: "ff3f44",
    peach: "f9dacb",
    pink: "fe8f9b",
    lilac: "b4b5e4",
    green: "00fab0",
    yellow: "fefd38",
    magenta: "ab1eba",
  };
  return (
    <>
      {stories.length > 0 && (
        <div className={`${styles.element} more-stories`}>
          <h4>{heading ? heading : "More Stories"}</h4>
          <ul>
            {stories.map((story, index) => (
              <StoryTile
                key={index}
                id={story.id}
                fields={story.fields}
                show={true}
                color={colors[themeColor]}
                section="stories"
              >
                <TileContent fields={story.fields} />
              </StoryTile>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default MoreStories;
