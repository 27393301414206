import React, { useEffect, useRef, useContext } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ThemeContext from "components/teaser/theme-context";

const ThemeSection = ({ theme, children, className = "" }) => {
  const { setTheme } = useContext(ThemeContext);
  const ref = useRef();

  useEffect(() => {
    const t = ScrollTrigger.create({
      trigger: ref.current,
      start: "top 30px",
      end: "bottom 30px",
      onEnter: () => setTheme(theme),
      onEnterBack: () => setTheme(theme),
    });
    return () => t.kill();
  }, [setTheme, theme]);
  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
};

export default ThemeSection;
