import React from "react";
import styles from "./foundation.module.scss";
import Section from "components/section";
import shape from "./shape.svg";
import gif6 from './render_elements_6.gif'

const heading = "Supported by RCWJRF";
const link = {
    text: "Learn More",
    href: "https://www.ralphcwilsonjrfoundation.org/",
};
const text = (
    <>
        <p>
            Generator Z was supported by the <a href={link.href}>Ralph C. Wilson, Jr. Foundation</a>,
            committed to improving the quality of life for all people, of all
            ages, in Southeast Michigan and Western New York, in ways that matter to them.
        </p>
    </>
);
const Foundation = () => {
    return (
        <Section id="TheFoundation" theme="yellow">
            <div className={styles.element}>
                <div className={styles.body}>
                    <img className={styles.gif6} src={gif6} alt="" />
                    <h2 className={styles.heading}>{heading}</h2>
                    <div className={styles.text}>{text}</div>
                    <a
                        className={styles.link}
                        href={link.href}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {link.text}
                    </a>
                </div>
                <img className={styles.shape} src={shape} alt="" />
            </div>
        </Section>
    );
};

export default Foundation;
