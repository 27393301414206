import React from "react";
import ThemeSection from "components/teaser/theme-section";
import { useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useContext } from "react";
import ThemeContext from "components/teaser/theme-context";

const Section = ({ id, theme, children, className, forwardRef }) => {
  const inner = forwardRef ? <div ref={forwardRef}>{children}</div> : children;
  const ref = useRef();

  const { setSectionId } = useContext(ThemeContext);

  useEffect(() => {
    const onEnter = () => {
      setSectionId(id);
    };
    const t = ScrollTrigger.create({
      trigger: ref.current,
      onEnter: onEnter,
      onEnterBack: onEnter,
      start: "top center",
      end: "bottom  center",
    });
    return () => {
      t.kill();
    };
  }, [id, setSectionId]);

  return (
    <section id={id} ref={ref}>
      {theme ? (
        <ThemeSection theme={theme} className={className}>
          {inner}
        </ThemeSection>
      ) : (
        inner
      )}
    </section>
  );
};

export default Section;
