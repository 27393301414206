import { useReducer } from "react";
import { locales as allLocales } from "./constants";

export const fills = {
  over: { bg: "#FF3F44", text: "#1B1A1F" },
  under: { bg: "#B4B5E4", text: "#1B1A1F" },
};

const indexReducer = (state, action) => {
  switch (action.type) {
    case "SAVE_LAYOUT":
      return {
        ...state,
        layouts: {
          ...state.layouts,
          [action.key]: action.value,
        },
      };
    default:
      throw new Error(`Unknown action type ${action.type}`);
  }
};

const indexInitialState = {
  layouts: {},
};

function sortBy(sortKey) {
  return (a, b) => {
    const aVal = +a[sortKey];
    const bVal = +b[sortKey];
    if (aVal > bVal) {
      return -1;
    }
    if (aVal < bVal) {
      return 1;
    }
    return 0;
  };
}

export const useIndexLayouts = (dimensions, data) => {
  const [state, dispatch] = useReducer(indexReducer, indexInitialState);
  const key = getDimKey(dimensions);
  const layout = state.layouts[key];

  if (
    typeof layout === "undefined" &&
    dimensions.width > 0 &&
    dimensions.height > 0
  ) {
    // dispatch({
    //   type: "REQUESTED_LAYOUT",
    //   key
    // });
    dispatch({
      type: "SAVE_LAYOUT",
      key,
      value: getLayouts(data, dimensions),
    });
  }

  return layout;
};

export default useIndexLayouts;

const groups = [
  "Everyone",
  "Middle School",
  "High School",
  "Trans",
  "Female",
  "Male",
  "Black",
  "Hispanic",
  "Mixed Race",
  "White",
];

const getDimKey = ({ width, height, reverseScale = false }) =>
  `${width}_${height}${reverseScale ? "_reverse" : ""}`;

const getLayouts = (data, { width, height }, locales = allLocales) => {
  const rows = 9;
  const cols = 5;
  const itemWidth = width / cols;
  const itemHeight = height / rows;

  const layouts = {};

  groups.forEach((sortGroup) => {
    locales.forEach((sortLocale) => {
      const sortKey = `${sortGroup} - ${sortLocale}`;
      const sorted = data.sort(sortBy(sortKey)).map((d, i) => {
        return {
          ...d,
          sortRank: i,
        };
      });
      groups.forEach((groupB) => {
        locales.forEach((localeB) => {
          const dataKey = `${groupB} - ${localeB}`;
          const layoutKey = `${dataKey}__${sortKey}`;

          layouts[layoutKey] = sorted
            .sort(sortBy(dataKey))
            // .map((d) => ({
            //   value: +d[dataKey],
            // }))
            .map((d, indexRank) => {
              const x = itemWidth * (indexRank % cols) + 1;
              const y = itemHeight * Math.floor(indexRank / cols);
              const theme = +d[dataKey] >= 75 ? fills.over : fills.under;
              return {
                ...d,
                label: d["Idea"],
                x,
                y,
                fill: theme.bg,
                textFill: theme.text,
                fill_tint: "#232323",
                textOpacity: 1,
                isCurrent: true,
                width: itemWidth,
                height: itemHeight,
              };
            })
            .sort(sortBy(sortKey));
        });
      });
    });
  });
  return layouts;
};

// const getLayouts = (data, { width, height }, reverseScale = false) => {
//   const fives = (index) => {
//     return data.reduce((r, d) => {
//       if (+d[index] === 5 || d[index] === 4) {
//         r++;
//       }
//       return r;
//     }, 0);
//   };

//   const byIndex = indices
//     .sort((a, b) => {
//       return fives(a) > fives(b) ? -1 : fives(a) < fives(b) ? 1 : 0;
//     })
//     .reduce((r, index) => {
//       r[index] = dataByIndex(data, index, reverseScale ? "desc" : "asc");
//       return r;
//     }, {});

//   const all = Object.values(byIndex).reduce((r, d) => {
//     return [...r, ...d];
//   }, []);

//   return indices.reduce(
//     (r, indexA) => {
//       indices.forEach((indexB) => {
//         r[`${indexA}${indexB}`] = getLayout(
//           all,
//           indices,
//           indexA,
//           indexB,
//           width,
//           height
//         );
//       });
//       return r;
//     },
//     {
//       allall: getLayout(all, indices, "all", "all", width, height),
//     }
//   );
// };

// const dataByIndex = (data, currentIndex, indexOrder) => {
//   const result = data.map((d, i) => ({
//     ...d,
//     ...indices.map((ind) => ({ [ind]: +d[ind] })),
//     itemKey: `${currentIndex}-${d[keys.label]}`,
//     index: currentIndex,
//     value: +d[currentIndex],
//   }));
//   return sortAlphaScore(result, currentIndex, indexOrder);
// };

// const getLayout = (items, indices, currentIndex, zoomIndex, width, height) => {
//   const PADDING = 3;
//   const columns = currentIndex === "all" ? 50 : 5;
//   const itemWidth = width / 50;
//   const itemHeight = height / 10;
//   const itemWidthZoomed = width / 5;
//   const itemHeightZoomed = height / 11;

//   let allColumnCount = -1;
//   let allRowCount = 1;
//   let zoomColumnCount = -1;

//   return items.map((item, i) => {
//     if (allColumnCount + 1 >= columns) {
//       allColumnCount = 0;
//       allRowCount++;
//     } else {
//       allColumnCount++;
//     }

//     const isCurrent = item.index === zoomIndex;
//     const indexCurrent = indices.indexOf(zoomIndex);

//     if (isCurrent) {
//       if (zoomColumnCount + 1 >= columns) {
//         zoomColumnCount = 0;
//       } else {
//         zoomColumnCount++;
//       }
//     }

//     const indexRank =
//       currentIndex !== "all"
//         ? items.find(
//             (d) =>
//               d[keys.label] === item[keys.label] && d.index === currentIndex
//           ).indexRank
//         : null;

//     const x =
//       currentIndex === "all" || !isCurrent
//         ? itemWidth * (i % 50) + 1
//         : itemWidthZoomed * (indexRank % columns) + 1;

//     const y =
//       currentIndex === "all"
//         ? itemHeight * allRowCount + (5 * PADDING) / 2
//         : isCurrent
//         ? itemHeightZoomed * Math.floor(1 + indexRank / columns)
//         : indices.indexOf(item.index) < indexCurrent
//         ? -itemHeight
//         : height;

//     return {
//       isCurrent,
//       label: item[keys.label],
//       x: x,
//       y: y,
//       textOpacity: isCurrent ? 1 : 0,
//       width: isCurrent ? itemWidthZoomed : itemWidth,
//       height: isCurrent ? itemHeightZoomed : itemHeight - PADDING * 5,
//       fill: isCurrent ? colors[item[currentIndex]] : colors[item.value],
//       fill_tint: isCurrent
//         ? colors[`${item[currentIndex]}_tint`]
//         : colors[`${item.value}_tint`],
//     };
//   });
// };

// const sortAlphaScore = (data, index = "value", indexOrder = "asc") => {
//   return orderBy(
//     data,
//     [index, keys.label],
//     [indexOrder, "asc"]
//   ).map((d, i) => ({ ...d, indexRank: i }));
// };
