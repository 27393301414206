import React from "react";
import styles from "./student-application.module.scss";

const Question = ({ question, children }) => {
  return <div className={styles.fieldset}>
    <div className={styles.label} dangerouslySetInnerHTML={{ __html: question }}></div>
    <div>{children}</div>
  </div>
}

export default Question