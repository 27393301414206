import React, { useEffect, useState } from "react";
import styles from "./provider-six-month-progress.module.scss";
import { ThemeProvider } from "../teaser/theme-context";
import ColumnLayout from "layouts";
import Header from "components/stories/stories-form/header";
import { Link } from "react-router-dom";
import Section from "components/stories/stories-form/section";
import Subsection from "components/stories/stories-form/subsection";
import Text from "components/form/text";
import TextArea from "components/form/textarea";
import MediaBuilder from "components/form/media-builder";
import errorMessages from "components/form/errorMessages";
import { useForm } from "react-hook-form";
import formLayout from "components/form/form-layout.module.scss";
import { statuses } from "components/stories/stories-form/stories-form";
import axios from "axios";
import questions from "./questions";
import Select from "components/form/select";
import usePosts from "components/usePosts";
import { Document } from "components/provider-application/provider-application";
import RadioSet from "components/form/radio-set/radio-set";
import Range from "components/form/range";
const REQUIRED_MESSAGE = "Required";

const ProviderSixMonthProgress = () => {
  return (
    <ThemeProvider value="provider-peach">
      <ColumnLayout>
        <Header heading={heading} body={body} />
        <Form />
      </ColumnLayout>
    </ThemeProvider>
  );
};

export default ProviderSixMonthProgress;

const heading = "Grantee Conclusion";
const body = (
  <>
    <p>
      Generator Z is a one-year grant opportunity where afterschool providers
      are accountable to teens, the most important audience by far. With your
      idea now fully implemented, please share your overall progress.{" "}
      <strong>Remember to speak to teens directly.</strong>
    </p>
    <p>
      Before you get started, read through your original application, your
      progress at six-months, and the feedback from Generators. All are housed
      on your <Link to="/afterschool-ideas">Afterschool Ideas</Link> page. Your
      original application is also attached to your grant agreement. Start there
      before answering the following questions.
    </p>
    <p>
      Please note that your answers will not automatically save until you
      complete and submit the form so plan accordingly. If you have questions,
      email{" "}
      <a
        href="mailto:info@generator-z.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        info@generator-z.org
      </a>
      . The deadline for reporting is Monday, August 15, 2022 at 11:59 PM.
    </p>
  </>
);

const SUCCESS_MESSAGE = (
  <>
    <p>Congratulations, your progress on Generator Z has been submitted!</p>
    <p>
      Please note that you will not receive a separate confirmation email. This
      message serves as confirmation. If you would like, take a screenshot for
      your records. Your updates will be published on-line in late December.
      Generators will begin reviewing your progress in early 2022 so stay tuned!
    </p>
    Congratulations, your final progress on Generator Z has been submitted!
    Please note that you will <strong>not</strong> receive a separate
    confirmation email. <strong>This message serves as confirmation.</strong> If
    you would like, take a screenshot for your records. Your report will be
    published on-line in early fall. Thank you for all that you do!
  </>
);

const defaultValues = {
  "Joys and Challenges":
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  "Defining Success":
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  "Teen Experience Quote":
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

  "Staff Learnings":
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  "Org Change":
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  slug: null,
};

const AlreadySubmitted = () => {
  return (
    <p>
      An Final update has already been submitted for this Afterschool Idea. If
      you feel you've received this message in error, or would like to change
      something, please email us at{" "}
      <a href={`mailto: info@generator-z.org`}>info@generator-z.org</a>.
    </p>
  );
};

const Form = () => {
  const { register, handleSubmit, errors, control, watch } = useForm({
    defaultValues: module.hot ? defaultValues : { slug: null },
  });
  const [status, setStatus] = useState(null);

  const project = watch(questions.which.key);
  const completionLevel = watch(questions.isItComplete.key);
  // const isIncomplete = completionLevel && completionLevel < 100;
  const isIncomplete = completionLevel === "no";
  console.log(completionLevel);

  const [alreadySubmitted, setAlreadySubmitted] = useState(null);
  useEffect(() => {
    if (project) {
      setAlreadySubmitted(null);
      axios
        .post(`/check-final-progress`, {
          slug: project,
        })
        .then((res) => {
          setAlreadySubmitted(res.data);
        });
    }
  }, [project]);

  const submit = (data) => {
    setStatus(statuses.LOADING);
    axios
      .post(`/submit-final-progress`, data)
      .then((res) => {
        console.log(res);
        if (res.data === "Success") {
          setStatus(statuses.SUCCESS);
        } else if (res.data === "Duplicate") {
          setStatus("Duplicate");
        }
      })
      .catch((err) => {
        console.log(err.message);
        setStatus(statuses.ERROR);
      });
  };

  const { error, data } = usePosts(`/api/providers/slugs`);
  if (error) {
    console.error(error);
  }
  const whichProjectOptions = data
    ? data
        .map(({ fields }) => {
          return {
            label: (
              <>
                <div>{fields["Revised Title"]}</div>
                <div style={{ fontSize: "80%" }}>
                  {fields["Name of Organization or Program"]}
                </div>
              </>
            ),
            sortLabel: fields["Revised Title"],
            value: fields["Slug (from Finalists)"][0],
          };
        })
        .sort((a, b) => {
          if (a.sortLabel < b.sortLabel) {
            return -1;
          }
          if (b.sortLabel < a.sortLabel) {
            return 1;
          }
          return 0;
        })
    : [];

  const thisProject =
    project &&
    data &&
    data.find(({ fields }) => fields["Slug (from Finalists)"][0] === project);

  return (
    <form onSubmit={handleSubmit(submit)} className={styles.form}>
      <Section heading={"Identify Yourself"}>
        <Subsection
          fields={
            <>
              <div
                className={!thisProject ? styles.projectSelectNoProject : ""}
              >
                <label className={styles.label}>
                  <Select
                    name={questions.which.key}
                    forwardRef={register({ required: REQUIRED_MESSAGE })}
                    label={questions.which.question}
                    control={control}
                    errors={errors}
                    options={whichProjectOptions}
                  />
                </label>
              </div>
              {thisProject && (
                <Link
                  target="_blank"
                  to={`/afterschool-ideas/${thisProject.fields["Slug (from Finalists)"][0]}`}
                >
                  {thisProject.fields["Revised Title"]} idea page
                </Link>
              )}
            </>
          }
        />
      </Section>
      {alreadySubmitted ? (
        <AlreadySubmitted />
      ) : (
        thisProject &&
        alreadySubmitted !== null && (
          <>
            <Section
              heading={"Share your progress. This section will be published."}
            >
              <Subsection
                fields={
                  <>
                    <label className={styles.label}>
                      <p>{questions.isItComplete.question}</p>
                      <RadioSet
                        generateRef={() =>
                          register({
                            required: REQUIRED_MESSAGE,
                            validate: (value) => {
                              console.log(value);
                              return !!value || REQUIRED_MESSAGE;
                            },
                          })
                        }
                        options={[
                          { label: "100% Complete", value: "yes" },
                          { label: "Not quite there yet", value: "no" },
                        ]}
                        errors={errors}
                        layout="line"
                        name={questions.isItComplete.key}
                        required
                      />
                    </label>
                    {isIncomplete && (
                      <Subsection
                        tip={
                          "Please note that you will be required to resubmit this form and financials at 100% completion."
                        }
                        fields={
                          <>
                            <label className={styles.label}>
                              <p>{questions.completion.question}</p>
                              <Range
                                min={0}
                                max={100}
                                step={1}
                                name={questions.completion.key}
                                control={control}
                              />
                            </label>
                            <label>
                              <p>{questions.whatHappened.question}</p>
                              <TextArea
                                name={questions.whatHappened.key}
                                placeholder="Start typing here"
                                forwardRef={register}
                                minLength={50}
                                maxLength={2500}
                                errors={errors}
                              />
                            </label>

                            <fieldset className={styles.attribution}>
                              <legend>
                                Who should Generator Z contact to discuss your
                                status?
                              </legend>
                              <label className={styles.label}>
                                <p>{questions.contactName.question}</p>
                                <Text
                                  name={questions.contactName.key}
                                  forwardRef={register()}
                                  errors={errors}
                                />
                              </label>
                              <label className={styles.label}>
                                <p>{questions.contactRole.question}</p>
                                <Text
                                  name={questions.contactRole.key}
                                  forwardRef={register()}
                                  errors={errors}
                                />
                              </label>
                              <label className={styles.label}>
                                <p>{questions.contactEmail.question}</p>
                                <Text
                                  name={questions.contactEmail.key}
                                  forwardRef={register()}
                                  errors={errors}
                                  type="email"
                                />
                              </label>
                              <label className={styles.label}>
                                <p>{questions.contactPhone.question}</p>
                                <Text
                                  name={questions.contactPhone.key}
                                  forwardRef={register()}
                                  errors={errors}
                                  type="tel"
                                />
                              </label>
                            </fieldset>
                          </>
                        }
                      />
                    )}
                    <label className={styles.label}>
                      <p>{questions.moment.question}</p>
                      <TextArea
                        name={questions.moment.key}
                        placeholder="Start typing here"
                        forwardRef={register}
                        minLength={50}
                        maxLength={1250}
                        errors={errors}
                        required={{
                          message:
                            "TEXT IS REQUIRED. MINIMUM 50/MAXIMUM 1,250 CHARACTERS",
                        }}
                      />
                    </label>
                    <label className={styles.label}>
                      <p>{questions.how.question}</p>
                      <TextArea
                        name={questions.how.key}
                        placeholder="Start typing here"
                        forwardRef={register}
                        minLength={50}
                        maxLength={1250}
                        errors={errors}
                        required={{
                          message:
                            "TEXT IS REQUIRED. MINIMUM 50/MAXIMUM 1,250 CHARACTERS",
                        }}
                      />
                    </label>
                  </>
                }
              />
              <Subsection
                tip="This person is not expected to be a Generator, although they might be. This person is expected to be a teen that is directly involved with your organization and your Generator Z idea."
                fields={
                  <>
                    <label className={styles.label}>
                      <p>{questions.quote.question}</p>
                      <TextArea
                        name={questions.quote.key}
                        placeholder="Start typing here"
                        forwardRef={register({ required: REQUIRED_MESSAGE })}
                        minLength={50}
                        maxLength={500}
                        errors={errors}
                        required={{
                          message:
                            "TEXT IS REQUIRED. MINIMUM 50/MAXIMUM 500 CHARACTERS",
                        }}
                      />
                    </label>
                    <fieldset className={styles.attribution}>
                      <legend>Who gave you this quote?</legend>
                      <label className={styles.label}>
                        <p>{questions.quoteAttributionFirst.question}</p>
                        <Text
                          name={questions.quoteAttributionFirst.key}
                          forwardRef={register({ required: true })}
                          errors={errors}
                          required
                        />
                      </label>
                      <label className={styles.label}>
                        <p>{questions.quoteAttributionLast.question}</p>
                        <Text
                          name={questions.quoteAttributionLast.key}
                          forwardRef={register({ required: true })}
                          errors={errors}
                          maxLength={1}
                          size={4}
                          required
                        />
                      </label>
                      <label className={styles.label}>
                        <p>{questions.quoteAttributionAge.question}</p>
                        <Text
                          name={questions.quoteAttributionAge.key}
                          forwardRef={register()}
                          errors={errors}
                          maxLength={2}
                          size={4}
                          required
                        />
                      </label>
                    </fieldset>
                  </>
                }
              />
              <Subsection
                fields={
                  <>
                    <label className={styles.label}>
                      <p>{questions.influence.question}</p>
                      <TextArea
                        name={questions.influence.key}
                        placeholder="Start typing here"
                        forwardRef={register}
                        minLength={50}
                        maxLength={1250}
                        errors={errors}
                        required={{
                          message:
                            "TEXT IS REQUIRED. MINIMUM 50/MAXIMUM 1,250 CHARACTERS",
                        }}
                      />
                    </label>
                    <label className={styles.label}>
                      <p>{questions.advice.question}</p>
                      <TextArea
                        name={questions.advice.key}
                        placeholder="Start typing here"
                        forwardRef={register}
                        minLength={50}
                        maxLength={1250}
                        errors={errors}
                        required={{
                          message:
                            "TEXT IS REQUIRED. MINIMUM 50/MAXIMUM 1,250 CHARACTERS",
                        }}
                      />
                    </label>
                  </>
                }
              />
              <Subsection
                fields={
                  <>
                    <label className={styles.label}>
                      <p>{questions.progress.question}</p>
                      <MediaBuilder
                        types={["image"]}
                        limit={3}
                        heading="Image upload (3 max)"
                        errors={errors}
                        name={questions.progress.key}
                        forwardRef={register({
                          required: errorMessages.required,
                          validate: (value) => {
                            return (
                              JSON.parse(value).length > 0 ||
                              "You must add an image"
                            );
                          },
                        })}
                      />
                    </label>

                    <MediaBuilder
                      types={["video"]}
                      limit={1}
                      errors={errors}
                      heading="Optional (90 seconds or less)"
                      name="Progress Video"
                      forwardRef={register()}
                    />
                  </>
                }
              />
            </Section>
            <Section heading="BEHIND THE SCENES: This section will NOT BE published.">
              <Subsection
                fields={
                  <>
                    <label className={styles.label}>
                      <p>{questions.future.question}</p>
                      <TextArea
                        name={questions.future.key}
                        placeholder="Start typing here"
                        forwardRef={register}
                        minLength={50}
                        maxLength={1250}
                        errors={errors}
                        required={{
                          message:
                            "TEXT IS REQUIRED. MINIMUM 50/MAXIMUM 1,250 CHARACTERS",
                        }}
                      />
                    </label>
                    <label className={styles.label}>
                      <p>{questions.generatorParticipation.question}</p>
                      <RadioSet
                        generateRef={register}
                        options={[
                          { label: "yes", value: "yes" },
                          { label: "not sure", value: "not sure" },
                          { label: "no", value: "no" },
                        ]}
                        errors={errors}
                        layout="line"
                        name={questions.generatorParticipation.key}
                      />
                    </label>
                    <label className={styles.label}>
                      <p>{questions.generatorParticipationDetails.question}</p>
                      <TextArea
                        name={questions.generatorParticipationDetails.key}
                        placeholder="Start typing here"
                        forwardRef={register}
                        minLength={50}
                        maxLength={1250}
                        errors={errors}
                      />
                    </label>
                    <label className={styles.label}>
                      <p>{questions.learning.question}</p>
                      <TextArea
                        name={questions.learning.key}
                        placeholder="Start typing here"
                        forwardRef={register}
                        minLength={50}
                        maxLength={1250}
                        errors={errors}
                        required={{
                          message:
                            "TEXT IS REQUIRED. MINIMUM 50/MAXIMUM 1,250 CHARACTERS",
                        }}
                      />
                    </label>

                    <label className={styles.label}>
                      <p>{questions.changes.question}</p>
                      <TextArea
                        name={questions.changes.key}
                        placeholder="Start typing here"
                        forwardRef={register}
                        minLength={50}
                        maxLength={1250}
                        errors={errors}
                        required={{
                          message:
                            "TEXT IS REQUIRED. MINIMUM 50/MAXIMUM 1,250 CHARACTERS",
                        }}
                      />
                    </label>
                    <label className={styles.label}>
                      <p>{questions.experienceRating.question}</p>
                      <RadioSet
                        generateRef={register}
                        options={[
                          { value: 1, label: "1 (terrible)" },
                          2,
                          3,
                          4,
                          5,
                          6,
                          7,
                          8,
                          9,
                          { value: 10, label: "10 (awesome)" },
                        ]}
                        errors={errors}
                        layout="line"
                        name={questions.experienceRating.key}
                      />
                    </label>
                  </>
                }
              />
            </Section>
            <Section heading="Financials. This section will not be published.">
              <Subsection
                fields={
                  <>
                    <p>
                      Complete the excel file that was submitted during your
                      progress report. Fill in your final expenses, explain any
                      changes in the notes section, and upload your completed
                      form as an excel file here.
                    </p>
                    <Document
                      heading={""}
                      name={"File Upload"}
                      errors={errors}
                      register={register({
                        required: errorMessages.required,
                        validate: (value) => {
                          return value !== "" || "You must add an document";
                        },
                      })}
                      // extensions={[".pdf",]}
                      required={{
                        message: "One document required",
                        validate: (blocks) =>
                          blocks && blocks.length > 0 && blocks[0].value,
                      }}
                    />
                  </>
                }
              />
            </Section>
            <button
              className={formLayout.submit}
              type="submit"
              disabled={
                status === statuses.LOADING || status === statuses.SUCCESS
              }
            >
              {status === statuses.LOADING
                ? "Sending"
                : status === statuses.SUCCESS
                ? "Success!"
                : "Submit"}
            </button>
            {status === statuses.ERROR && "An error occured while submitting"}
            {status === statuses.SUCCESS && (
              <div className={styles.successMessage}>{SUCCESS_MESSAGE}</div>
            )}
            {status === "Duplicate" && <AlreadySubmitted />}
          </>
        )
      )}
    </form>
  );
};
