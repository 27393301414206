import React from "react";
import styles from "./text.module.scss";

const Text = ({ value }) => {
  return (
    <p
      className={styles.element}
      dangerouslySetInnerHTML={{ __html: value }}
    ></p>
  );
};

export default Text;
