import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import styles from "./stories-directory.module.scss";
import layout from "scss/layout.module.scss";
import usePosts from "components/usePosts";

import ColumnLayout from "layouts/columns";
import { ThemeProvider } from "components/teaser/theme-context";
import TileContent from "../story/tileContent";
import StoryTile from "../../tile";
import Masonry from "react-masonry-component";
import { Waypoint } from "react-waypoint";
import { useHistory } from "react-router-dom";
import Filters from "../../filters";
import FilterContext from "components/stories/filter-context";
import ResourceBar from "components/stories/resourceBar/resource-bar";
import SVG from "react-inlinesvg";
import expand from "./expand.svg";
import filterOpts from "./filter-opts";
import { processFilters } from "helpers";

// import { Link } from "react-router-dom";

// import { counties as miCounties } from "components/stories/counties-mi";

const qs = require("qs");

const imagesLoadedOptions = { background: ".bg-el" };
const masonryOptions = {
  transitionDuration: "0.2s",
  stagger: 10,
  gutter: 20,
};

const defaultCheckedItems = {
  "Afterschool Theme": new Map(),
  Age: new Map(),
  State: new Map(),
  County: new Map(),
  "Display Name": [],

  "Gender Identity": new Map(),
  "Afterschool Program": new Map(),
  "Identity Match": new Map(),
};

const StoriesDirectory = ({ location }) => {
  const firstUpdate = useRef(true);
  let checkedItems = {
    "Afterschool Theme": new Map(),
    Age: new Map(),
    State: new Map(),
    County: new Map(),
    "Display Name": [],

    "Gender Identity": new Map(),
    "Afterschool Program": new Map(),
    "Identity Match": new Map(),
  };

  /* set initial query */
  const initialSettings = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    encodeValuesOnly: true,
  });

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  });

  Object.keys(initialSettings).forEach((objkey) => {
    const obj = initialSettings[objkey];
    const col = obj.col;

    if (col === undefined) {
      //do nothing
    } else if (col === "Display Name") {
      checkedItems[col] = [[obj.v[0], true]];
    } else {
      console.log("col", col);
      obj.v.map((value) => {
        checkedItems[col].set(value, true);
        return null;
      });
    }
  });

  return (
    <>
      {checkedItems && (
        <Stories location={location} initCheckedItems={checkedItems} />
      )}
    </>
  );
};

const Stories = ({ initCheckedItems }) => {
  const [showPage, setShowPage] = useState(1);
  const [infiniteLoading, setInfiniteLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState({ ...initCheckedItems });
  const initFilters = processFilters(initCheckedItems);
  const [filters, setFilters] = useState(initFilters);
  const [showFilters, setShowFilters] = useState(false);
  const { setFilteredStories, setFilterStoriesQS } = useContext(FilterContext);
  const history = useHistory();
  const filtersRef = useRef(null);

  const { loading, error, data, updating } = usePosts(`/api/stories`, filters);

  useEffect(() => {
    setFilteredStories(data);
  });
  const toggleFilters = () => {
    setShowFilters(!showFilters);
    filtersRef.current.scrollIntoView();
  };
  const loadMore = () => {
    setInfiniteLoading(true);

    setShowPage(showPage + 1);
    window.setTimeout(function () {
      setInfiniteLoading(false);
    }, 1000);
  };

  const clearFilters = () => {
    setCheckedItems(defaultCheckedItems);
  };

  const handleChange = (e) => {
    const type = e.target.type;
    const item = e.target.name;
    const value = e.target.value;

    // enable to reset the count on any interaction
    setShowPage(1);

    if (type === "text") {
      //if (checkedItems[item] === undefined) checkedItems[item] = [];
      if (value.length > 0) checkedItems[item][0] = [value.toLowerCase(), true];
      else checkedItems[item][0] = [value.toLowerCase(), false];
    } else {
      const isChecked = e.target.checked;
      checkedItems[item].set(value, isChecked);
    }
    setCheckedItems({ ...checkedItems });
    //console.log("checkedItems", checkedItems, e.target.checked);
  };

  useEffect(() => {
    //map out filter
    const newFilter = processFilters(checkedItems);
    setFilters(newFilter);
    const filterStr = qs.stringify(newFilter, { encodeValuesOnly: true });
    setFilterStoriesQS(filterStr);

    history.push({
      search: newFilter.length > 0 ? `?${filterStr}` : "",
    });

    // return {
    //   // cleanup
    // };
  }, [checkedItems, history, setFilterStoriesQS]);

  return (
    <ThemeProvider value={"black"}>
      <ResourceBar />
      <ColumnLayout
        railContent={
          <>
            <Filters
              handleChange={handleChange}
              checkedItems={checkedItems}
              mobileShowFilters={showFilters}
              filterOpts={filterOpts}
              introText={
                "Filter through Generator stories in all the ways that matter to you."
              }
              clear={clearFilters}
            />
            {showFilters && (
              <div className={styles.filterToggle}>
                <button onClick={toggleFilters} className={styles.closeFilters}>
                  Filter Stories
                </button>
              </div>
            )}
          </>
        }
      >
        <div className={styles.intro}>
          <h1 className={layout.heading}>Generator Stories</h1>
          <p>
            Over 1,000 teens in Southeast Michigan and Western New York were
            asked a big question:{" "}
            <strong>
              “How do you reimagine the future of afterschool in a changing
              world?”
            </strong>{" "}
            Young people answered this call by channeling the role of
            storyteller, director, architect, author, guide, muse, collaborator,
            consultant, expert (we can go on). These <strong>Generators</strong>{" "}
            stepped up to offer their personal experience and insight.
          </p>
          <p>
            The act of sharing can shape the world in unexpected ways. It can
            impact friends, family, young people, not-so-young people, leaders,
            policy-makers, and -- in the case of Generator Z --{" "}
            <strong>afterschool providers</strong> who build opportunities for
            teens outside of school. Whoever you are, we invite you to read
            through to understand the lives and afterschool dreams of young
            people.
          </p>
          {/* <p>
            <strong>
              If you are an afterschool provider{" "}
              <Link
                to={`/provider/apply`}
                target="_blank"
                rel="noopener noreferrer"
              >
                applying
              </Link>{" "}
              to Generator Z, we encourage you to note the stories that catch
              your attention for use in your application.
            </strong>
          </p> */}
        </div>
        <div className={styles.filterToggle}>
          <button onClick={toggleFilters} ref={filtersRef}>
            Filter Stories{" "}
            <span>
              <SVG src={expand}></SVG>
            </span>
          </button>
        </div>
        <div className={styles.element}>
          {loading ? (
            "Loading Stories"
          ) : error ? (
            "Error"
          ) : data ? (
            <div className={updating ? "stories--updating" : "stories--loaded"}>
              <div className={styles.results}>
                <span>{data.length} </span> results
                {updating ? " -- updating --" : ""}
              </div>
              <Masonry
                options={masonryOptions}
                updateOnEachImageLoad={true}
                imagesLoadedOptions={imagesLoadedOptions}
              >
                {data &&
                  data.map(({ fields, id }, index) => {
                    //console.log("loading story", id);

                    return fields["Survey ID"] && index < showPage * 15 ? (
                      <StoryTile
                        key={id}
                        id={id}
                        fields={fields}
                        show={index < showPage * 15 ? true : false}
                        section={`stories`}
                      >
                        <TileContent fields={fields} />
                      </StoryTile>
                    ) : null;
                  })}
              </Masonry>
            </div>
          ) : null}
          {data && showPage < Math.ceil(data.length / 15) && (
            /*showPage < Math.ceil(data.length / 15) &&*/ <>
              {!infiniteLoading && (
                <Waypoint onEnter={loadMore}>
                  <div className={styles.infinite}>Loading More Stories</div>
                </Waypoint>
              )}
            </>
          )}
        </div>
      </ColumnLayout>
    </ThemeProvider>
  );
};

export default StoriesDirectory;

// const StoryPage = ({ filters, page }) => {
//   const { loading, error, data, updating } = usePosts(filters, page);
//   return (
//     <>
//       {data &&
//         data.map(({ fields, id }, index) => {
//           return index < page * 15 ? (
//             <StoryTile
//               id={id}
//               fields={fields}
//               show={index < page * 15 ? true : false}
//             ></StoryTile>
//           ) : null;
//         })}
//     </>
//   );
// };
