import React, { useContext } from "react";
import ReactSlider from "react-slider";
import styles from "./range.module.scss";
import ThemeContext from "components/teaser/theme-context";
import { Controller } from "react-hook-form";
const Range = ({ label, min = 0, max = 100, step = 1, control, name }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={0}
      render={({ onChange, onBlur, value, name, ref }) => {
        return (
          <label>
            <div>{label}</div>
            <ReactSlider
              min={min}
              max={max}
              step={step}
              name={name}
              className={styles.slider}
              onChange={onChange}
              thumbClassName={styles.thumb}
              trackClassName={styles.track}
              renderThumb={(props, state) => (
                <div {...props} className={`${props.className} bg--${theme}`}>
                  {state.valueNow}%
                </div>
              )}
            />
          </label>
        );
      }}
    />
  );
};

export default Range;
