import React, {
    useState,
    useEffect,
    useContext,
    useRef,
    useLayoutEffect,
    useMemo,
} from "react";
import { ThemeProvider } from "components/teaser/theme-context";
import usePosts from "components/usePosts";
import ColumnLayout from "layouts/columns";
import Masonry from "react-masonry-component";
import { Waypoint } from "react-waypoint";
import { useHistory, Link } from "react-router-dom";
import layout from "scss/layout.module.scss";
import styles from "./providers.module.scss";
import Tile from "components/tile";
import TileContent from "./tile-content";
import Filters from "components/filters";
import filterOpts from "./filter-opts";
import FilterContext from "components/stories/filter-context";
import { processFilters } from "helpers";
import SVG from "react-inlinesvg";
import expand from "components/stories/stories-directory/expand.svg";
const qs = require("qs");

const imagesLoadedOptions = { background: ".bg-el" };
const masonryOptions = {
    transitionDuration: "0.2s",
    stagger: 10,
    gutter: 20,
};

const ProvidersParent = ({ location }) => {
    const firstUpdate = useRef(true);

    const checkedItems = {
        Theme: new Map(),
        Age: new Map(),
        Region: new Map(),
        CountyRegion: new Map(),
        Search: [],
        Type: new Map(),
        Cost: new Map(),
        Grantees: new Map(),
        "Completion Level": new Map(),
    };
    const blankCheckedItems = {
        Theme: new Map(),
        Age: new Map(),
        Region: new Map(),
        CountyRegion: new Map(),
        Search: [],
        Type: new Map(),
        Cost: new Map(),
        Grantees: new Map(),
        "Completion Level": new Map(),
    };

    /* set initial query */
    const initialSettings = qs.parse(location.search, {
        ignoreQueryPrefix: true,
        encodeValuesOnly: true,
    });

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
    });

    Object.keys(initialSettings).forEach((objkey) => {
        const obj = initialSettings[objkey];
        const col = obj.col;

        if (col === undefined) {
            //do nothing
        } else if (col === "Search") {
            checkedItems[col] = [[obj.v[0], true]];
        } else {
            obj.v.map((value) => {
                checkedItems[col].set(value, true);
                return null;
            });
        }
    });

    return (
        <>
            {checkedItems && (
                <Providers
                    location={location}
                    initCheckedItems={checkedItems}
                    blankCheckedItems={blankCheckedItems}
                />
            )}
        </>
    );
};

function Providers({ initCheckedItems, blankCheckedItems }) {
    const [showPage, setShowPage] = useState(1);
    const [infiniteLoading, setInfiniteLoading] = useState(false);
    const [checkedItems, setCheckedItems] = useState({ ...initCheckedItems });
    const initFilters = processFilters(initCheckedItems);
    const [filters, setFilters] = useState(initFilters);
    const [showFilters, setShowFilters] = useState(false);
    const { setFilteredProviders, setFilterProvidersQS } =
        useContext(FilterContext);
    const history = useHistory();
    const filtersRef = useRef(null);
    const clearFilters = () => setCheckedItems(blankCheckedItems);

    const { loading, error, data, updating } = usePosts(
        `/api/providers`,
        filters
    );
    const dataWithout6Month = useMemo(
        () =>
            data &&
            data.filter((d) => d.fields["Application ID"].indexOf("-6month") === -1),
        [data]
    );
    const toggleFilters = () => {
        setShowFilters(!showFilters);
        filtersRef.current.scrollIntoView();
    };
    const loadMore = () => {
        setInfiniteLoading(true);

        setShowPage(showPage + 1);
        window.setTimeout(function () {
            setInfiniteLoading(false);
        }, 1000);
    };
    useEffect(() => {
        setFilteredProviders(dataWithout6Month);
    }, [dataWithout6Month, setFilteredProviders]);
    const handleChange = (e) => {
        const type = e.target.type;
        const item = e.target.name;
        const value = e.target.value;

        // enable to reset the count on any interaction
        setShowPage(1);

        if (type === "text") {
            //if (checkedItems[item] === undefined) checkedItems[item] = [];
            if (value.length > 0) checkedItems[item][0] = [value.toLowerCase(), true];
            else checkedItems[item][0] = [value.toLowerCase(), false];
        } else {
            const isChecked = e.target.checked;
            checkedItems[item].set(value, isChecked);
        }
        setCheckedItems({ ...checkedItems });
        //console.log("checkedItems", checkedItems, e.target.checked);
    };

    useEffect(() => {
        //map out filter
        const newFilter = processFilters(checkedItems);
        setFilters(newFilter);
        const filterStr = qs.stringify(newFilter, { encodeValuesOnly: true });
        setFilterProvidersQS(filterStr);

        history.push({
            search: newFilter.length > 0 ? `?${filterStr}` : "",
        });

        // return {
        //   // cleanup
        // };
    }, [checkedItems, history, setFilterProvidersQS]);

    return (
        <ThemeProvider value={"black"}>
            <ColumnLayout
                railContent={
                    <>
                        <Filters
                            handleChange={handleChange}
                            checkedItems={checkedItems}
                            mobileShowFilters={showFilters}
                            filterOpts={filterOpts}
                            clear={clearFilters}
                            introText="Filter through afterschool ideas in all the ways that matter to you."
                        />
                        {showFilters && (
                            <div className={styles.filterToggle}>
                                <button onClick={toggleFilters} className={styles.closeFilters}>
                                    Filter Providers
                                </button>
                            </div>
                        )}
                    </>
                }
            >
                <div className={styles.intro}>
                    <h1 className={layout.heading}>Afterschool Ideas</h1>
                    <p>
                        Over 1,000 teens in Southeast Michigan and Western New York were
                        asked a big question, “How do you reimagine the future of
                        afterschool in a changing world?” They stepped up, loud and clear,
                        with their personal <Link to={"/stories/"}>Generator Stories</Link>.
                    </p>{" "}
                    <p>
                        Afterschool providers - responsible for creating opportunities for young people - spent time with these stories and pitched afterschool ideas inspired by Generators. 128 finalist ideas were shortlisted and, of those, 93 were selected for grants totaling over $4 million. All ideas are published here for record.  We invite you to read through applications, grantee reporting, and Generator reactions!
                    </p>
                    <p>
                        All finalist and grantee Generator Z afterschool providers are also listed in our{" "}
                        <Link to={"/provider-directory"}>Provider Directory</Link>.{" "}
                    </p>
                </div>
                <div className={styles.filterToggle}>
                    <button onClick={toggleFilters} ref={filtersRef}>
                        Filter Afterschool Providers{" "}
                        <span>
                            <SVG src={expand}></SVG>
                        </span>
                    </button>
                </div>
                <div className={styles.element}>
                    {loading ? (
                        "Loading Providers"
                    ) : error ? (
                        "Error"
                    ) : dataWithout6Month ? (
                        <div className={updating ? "stories--updating" : "stories--loaded"}>
                            <div className={styles.results}>
                                <span>{dataWithout6Month.length} </span> results
                                {updating ? " -- updating --" : ""}
                            </div>

                            <Masonry
                                options={masonryOptions}
                                updateOnEachImageLoad={true}
                                imagesLoadedOptions={imagesLoadedOptions}
                            >
                                {dataWithout6Month &&
                                    dataWithout6Month.map(({ fields, id }, index) => {
                                        if (fields["Name"] === "") {
                                            return <>BLANK</>;
                                        }
                                        return index < showPage * 15 ? (
                                            <Tile
                                                key={id}
                                                id={id}
                                                fields={fields}
                                                show={index < showPage * 15 ? true : false}
                                                section={`providers`}
                                            >
                                                <TileContent fields={fields}></TileContent>
                                            </Tile>
                                        ) : null;
                                    })}
                                {/* <StoryPage filters={filters} page={1}></StoryPage>
                <StoryPage filters={filters} page={2}></StoryPage> */}
                            </Masonry>
                        </div>
                    ) : null}

                    {dataWithout6Month &&
                        showPage < Math.ceil(dataWithout6Month.length / 15) && (
                            <>
                                {!infiniteLoading && (
                                    <Waypoint onEnter={loadMore}>
                                        <div className={styles.infinite}>Loading More Stories</div>
                                    </Waypoint>
                                )}
                            </>
                        )}
                </div>
            </ColumnLayout>
        </ThemeProvider>
    );
}

export default ProvidersParent;
