import React from "react";
import styles from "./loader.module.scss";

const Loader = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      className={styles.element}
    >
      <path d="M24 13C24 6.92487 19.0751 2 13 2" />
    </svg>
  );
};

export default Loader;
