import ReactDOM from "react-dom";
import { forceSimulation, forceX, forceY, forceCollide } from "d3-force";
import { select } from "d3-selection";
import groups from "./dummy.js";

const DEFAULT_STRENGTH = 0.1;
const collisionRadius = 40;

const FORCE = () => {
  const nsp = {};
  const initForce = ({ nodes, width, height }) => {
    nsp.width = width;
    nsp.height = height;
    nsp.sim = forceSimulation(nodes).alphaTarget(0.75);
  };
  const updateNode = (selection) => {
    if (selection.datum().x) {
      selection
        .attr("transform", (d) => {
          return "translate(" + d.x + "," + d.y + ")";
        })
        .attr("cx", function (d) {
          return (d.x = Math.max(30, Math.min(nsp.width - 30, d.x)));
        })
        .attr("cy", function (d) {
          return (d.y = Math.max(30, Math.min(nsp.height - 30, d.y)));
        });
    }
  };

  const updateGraph = (selection) => {
    selection.selectAll(".node").call(updateNode);
  };
  const tick = (that) => {
    that.d3Graph = select(ReactDOM.findDOMNode(that));
    nsp.sim.on("tick", () => {
      that.d3Graph.call(updateGraph);
    });
  };

  nsp.updateFill = (key) => (selection) => {
    selection.select("path").attr("fill", (d) => {
      return d[key] && d[key].fill ? "#B4B5E4" : "#523dff";
    });
  };

  nsp.destroy = () => {
    nsp.sim.stop();
    nsp.sim.nodes([]);
  };

  const reheat = () => {
    nsp.sim.alpha(1);
  };

  Object.keys(groups).forEach((group) => {
    nsp[group] = makeGroups({ key: group, ...groups[group] });
  });

  function makeGroups({ key, strength = DEFAULT_STRENGTH, zoomOut }) {
    return () => {
      const xy = nsp.xy(key);
      nsp.sim.force("x", forceX((d) => xy(d).x).strength(strength * 2));
      nsp.sim.force("y", forceY((d) => xy(d).y).strength(strength * 2.5));
      nsp.sim.force(
        "collide",
        forceCollide([
          zoomOut ? collisionRadius / 4 : collisionRadius,
        ]).strength(DEFAULT_STRENGTH * 0.5)
      );
      reheat();
    };
  }

  function getValues(key) {
    return groups[key].groups ? groups[key].groups.map(({ key }) => key) : null;
  }

  nsp.maxColumns = 4;

  nsp.xy = (key) => (d) => {
    const values = getValues(key);

    if (!values) {
      return { x: nsp.width / 2, y: nsp.height / 2 };
    }
    const value = d[key].value;
    if (value) {
      const sequentialColumn = values.indexOf(value);
      const isLastRow =
        values.length > nsp.maxColumns &&
        sequentialColumn >= values.length - (values.length % nsp.maxColumns);
      const maxColumns = Math.min(nsp.maxColumns, values.length);
      const utilizedColumns = isLastRow
        ? values.length % nsp.maxColumns
        : maxColumns;
      const column =
        (((isLastRow ? sequentialColumn - 1 : sequentialColumn) %
          utilizedColumns) +
          0.5) /
        utilizedColumns;

      const rows = Math.ceil(values.length / maxColumns);
      const row = Math.ceil((sequentialColumn + 1) / maxColumns) / (rows + 1);
      const x = nsp.width * column;
      const y =
        rows === 1
          ? nsp.height * 0.2 + nsp.height * 0.8 * row + (row > 0.5 ? 100 : 0)
          : nsp.height * row + (row > 0.5 ? 100 : 0);
      return { x, y };
    } else {
      console.warn(`missing value for ${key}`, d);
      //return -1000;
    }
  };
  // nsp.y = (key) => (d) => {
  //   const values = getValues(key);

  //   if (!values) {
  //     return nsp.width / 2;
  //   }
  //   const value = d[key].value;
  // };

  nsp.updateNode = updateNode;
  nsp.updateGraph = updateGraph;
  nsp.initForce = initForce;
  nsp.tick = tick;

  return nsp;
};

export default FORCE;
