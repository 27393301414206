import React from "react";
import styles from "./requirement.module.scss";
import check from "./check.svg";
import SVG from "react-inlinesvg";

const Requirement = ({ fulfilled, message }) => {
  return (
    <div className={`${styles.required} fg--accent`}>
      <div
        className={
          fulfilled ? styles.requiredMarkerFilled : styles.requiredMarker
        }
      >
        {fulfilled && (
          <span className={styles.check}>
            <SVG src={check}></SVG>
          </span>
        )}
      </div>{" "}
      {message}
    </div>
  );
};

export default Requirement;
