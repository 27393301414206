import React from "react";
import { ThemeProvider, themes } from "components/teaser/theme-context";
import layout from "scss/layout.module.scss";
import styles from "./terms-and-conditions.module.scss";
import ColumnLayout from "layouts";

function TermsAndConditions() {
  const theme = themes[5];
  return (
    <ThemeProvider value={theme}>
      <ColumnLayout>
        <div className={styles.element}>
          <h1 className={layout.heading}>Terms and Conditions</h1>
          <div>
            <h4>By filling out this form I am confirming:</h4>
            <ol>
              <li>
                I agree that by using the Generator Z website (the “Service”), I
                will abide by the terms and conditions below (the “Terms”).
              </li>
              <li>
                I have read and understood the Terms and agree to be bound by
                the Terms. Generator Z, Lakeshore Connections, Inc. and the
                Ralph C. Wilson, Jr. Foundation may amend the Terms or
                supplement the Terms with rules, regulations, or policies by
                putting the amendment or supplement on the Generator Z website.
                If I use the Service after those amendments or supplements are
                put on the website, then I am agreeing to be bound by those
                amendments and supplements.
              </li>
              <li>
                I shared my accurate birth date on my application and I was
                between 13 and 18 years old at some point in time between
                November 1, 2020 - November 20, 2020 and qualified to be a
                Generator.
              </li>
              <li>
                I currently live in a qualified county in the State of Michigan
                or the State of New York for at least 6 months of the year.
                (Michigan: Wayne, Oakland, Macomb, Livingston, Monroe, St.
                Clair, Washtenaw) (New York: Allegany, Cattaraugus, Chautauqua,
                Erie County, Genesee, Monroe, Niagara, Orleans, Wyoming)
              </li>
              <li>
                I understand that Generator Z, Lakeshore Connections, Inc. and
                the Ralph C. Wilson, Jr. Foundation will retain my personal data
                and information that I submit but my birthdate, last name,
                payment information, and personal address will be kept private
                by Generator Z, Lakeshore Connections, Inc. and the Ralph C.
                Wilson, Jr. Foundation.
              </li>
              <li>
                I understand that Generator Z, Lakeshore Connections, Inc. and
                the Ralph C. Wilson, Jr. Foundation will use the email and cell
                phone I provide to communicate Generator Z information and other
                afterschool opportunities. My data will not be shared or sold by
                Generator Z, Lakeshore Connections, Inc. and the Ralph C.
                Wilson, Jr. Foundation.
              </li>
              <li>
                The information I provide on this website may be saved even if I
                do not complete and submit a full application.
              </li>
              <li>
                I give Generator Z, Lakeshore Connections, Inc. and the Ralph C.
                Wilson, Jr. Foundation permission to use, without charge, my
                written story, any audio or visual material that is part of my
                written story, my image, my first name, first initial of my last
                name, and my state of residency.
              </li>
              <li>
                My story is my own original writing that has not been adapted or
                copied from another source. This means that it is not someone
                else’s original work or copyrighted material. All other ideas,
                suggestions, or other non-personal information that I submit in
                using the Service will be owned by Generator Z, Lakeshore
                Connections, Inc. and the Ralph C. Wilson, Jr. Foundation.
              </li>
              <li>
                I will comply with all laws applicable to my use of the Service.
                Generator Z, Lakeshore Connections, Inc. and the Ralph C.
                Wilson, Jr. Foundation will also comply with all laws applicable
                to the Service.
              </li>
              <li>
                This is my one and only application submission to Generator Z.
              </li>
              <li>
                If I am selected as one of 1000 Generators, I will be eligible
                to receive a stipend of up to $1,000 which is broken down into
                three payments over the course of one year: At the end of Step
                1, I will be paid $500 for submitting my story; at the end of
                Step 2, I will be paid $250 for submitting feedback on
                afterschool grant applications; and at the end of Step 3, I will
                be paid a final $250 for submitting feedback on afterschool
                grant progress reports.
              </li>
              <li>
                My stipend payments will come through only after I complete each
                stage of the program by the stated deadline on the website.
              </li>
              <li>
                The images I submit, the words I use, and the story I tell will
                follow Generator Z internet etiquette which means that I will
                make sure that my personal story does not include harmful or
                offensive words or images.
              </li>
              <li>
                I am willing to receive feedback from Generator Z to revise my
                story without harmful or offensive words or images. If I do not
                want to make revisions, or if in Generator Z’s opinion and sole
                discretion, my submission cannot be edited so that it is no
                longer harmful or offensive, I could be disqualified from
                participating in Generator Z moving forward and not eligible to
                receive future stipend payments.
              </li>
              <li>
                If I qualify for the stipend, I will accept my payment by the
                provided payment information.
              </li>
              <li>
                I understand that payment stipends are no more than $500 in one
                calendar year. If I do not accept my payment for Step 1 by
                December 31, 2020 I may be required to provide a W-9 to
                Lakeshore Connections, Inc. and be issued a 1099 by Lakeshore
                Connections, Inc. for tax purposes.
              </li>
            </ol>
          </div>
        </div>
      </ColumnLayout>
    </ThemeProvider>
  );
}

export default TermsAndConditions;
