import React, { useEffect, useContext } from "react";
import styles from "./teaser.module.scss";
import Nav from "../nav";
import { Link } from "react-router-dom";

import ForOrgs from "./for-orgs";
import ForTeens from "./for-teens";
import Splash from "./splash/splash";
import Story from "./story";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SVG from "react-inlinesvg";
import NavOverlay from "components/nav/overlay";
import QuickNav from "components/nav/quick-nav";
import Modal from "components/modal";

import VideoModal from "components/videoModal";

import Footer from "components/footer";
import Foundation from "./foundation";
import Data from "./data";
import Logo from "components/logo";
import Banner from "components/banner";
import arrow from "./newsletter-arrow.svg";

import ThemeContext, { ThemeProvider } from "./theme-context";
import ModalContext, { ModalProvider } from "./modal-context";

const Teaser = () => {
    useEffect(() => {
        document.querySelectorAll("img").forEach((img) => {
            if (img.complete) {
                ScrollTrigger.refresh(true);
            } else {
                img.addEventListener("load", (imgLoaded) =>
                    ScrollTrigger.refresh(true)
                );
            }
        });
    }, []);

    return (
        <ThemeProvider>
            <ModalProvider>
                {/* <Banner /> */}
                <div className={`${styles.element}`}>
                    <div className={`${styles.logoWrap} home`}>
                        <Logo />
                    </div>
                    <QuickNav />
                    <Nav />

                    <div className={styles.mainWrap} style={{ position: "relative" }}>
                        <NewsletterButton />
                        <Modal />

                        {/* <VideoModal /> */}

                        <Splash />

                        <ForTeens />
                        <ForOrgs />
                        <Data />
                        <Story />
                        <Foundation />
                        <Footer></Footer>
                    </div>
                    <div className={`home`}>
                        <NavOverlay />
                    </div>
                </div>
            </ModalProvider>
        </ThemeProvider>
    );
};

export default Teaser;

const NewsletterButton = () => {
    const { theme } = useContext(ThemeContext);
    const { setShowModal } = useContext(ModalContext);
    return (
        // <button
        //     onClick={() => setShowModal(true)}
        //     className={`${styles.trigger} ${theme}`}
        // >
        //     <span className={styles.triggerText}>Sign up for updates</span>
        //     <span>
        //         <SVG src={arrow}></SVG>
        //     </span>
        // </button>
        <Link
            to="/contact-us"
            className={`${styles.trigger} ${theme}`}
        >
            <span className={styles.triggerText}>Contact Us</span>
            <span>
                <SVG src={arrow}></SVG>
            </span>
        </Link>
    );
};
