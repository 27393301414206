export const themeColors = [
  { theme: "Creative Arts & Culture", colorScheme: "provider-lilac" },
  { theme: "Friends & Community", colorScheme: "provider-pink" },
  { theme: "Life Skills & Balance", colorScheme: "provider-yellow" },
  { theme: "Play & Sports", colorScheme: "provider-black" },
  { theme: "Fun & Games", colorScheme: "provider-green" },
  { theme: "Growth & Wellness", colorScheme: "provider-peach" },
  { theme: "Excellence in Learning", colorScheme: "provider-purple" },
  { theme: "Jobs & Careers", colorScheme: "provider-black-b" },
  { theme: "Identity & Acceptance", colorScheme: "provider-blue" },
  { theme: "Mental, Social & Emotional Health", colorScheme: "provider-white" },
];
