import React from "react";
import styles from "./footer.module.scss";

import SVG from "react-inlinesvg";
import fb from "../nav/LogoFacebook.svg";
//import twitter from "./LogoTwitter.svg";
import instagram from "../nav/LogoInstagram.svg";

const Footer = ({ noPad }) => {
    return (
        <div className={`${styles.element} ${noPad ? styles.noPad : ""}`}>
            <div className={styles.inner}>
                <div className={styles.copyright}>
                    &copy;Generator Z 2024 ALL RIGHTS RESERVED
                </div>
                <div className={styles.links}>
                    <ul className={styles.socialLinks}>
                        <li>
                            <a
                                href="https://www.facebook.com/GeneratorZFuture/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <SVG src={fb} alt="Facebook"></SVG>
                            </a>
                        </li>
                        {/* <li>
                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                      <SVG src={twitter} alt="Twitter"></SVG>
                    </a>
                  </li> */}
                        <li>
                            <a
                                href="https://instagram.com/generatorzfuture?igshid=19vepje9amurd"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <SVG src={instagram} alt="Instagram"></SVG>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Footer;
