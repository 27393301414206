import michiganparticipation from "./Residency_Blobs/SEMI.svg";
import nyparticipation from "./Residency_Blobs/WNY.svg";
import city from "./Residency_Blobs/GZ_city.svg";
import rural from "./Residency_Blobs/GZ_rural.svg";
import suburb from "./Residency_Blobs/GZ_suburb.svg";
import towns from "./Residency_Blobs/GZ_town.svg";

const groups = {
  all: {
    heading: "10,000 teens ",
    legend: "100 teens",
    text:
      "Young people who participated in Teen Opinions Count represented both regions across residencies. Outside of geography, teens were diverse in their gender identity, race and ethnicity, grade level, and afterschool participation. ",
    groups: [
      {
        key: "all",
        quantity: 100,
        label: "All teens from SE Michigan and Western NY",
      },
    ],
  },
  region: {
    heading: "From two regions",
    groups: [
      {
        key: "mi",
        quantity: 66,
        label: "teens from SE Michigan",
        sublabel: "7,135 teens",
      },
      {
        key: "ny",
        quantity: 34,
        label: "teens from Western NY",
        sublabel: "3,751 teens",
      },
    ],
  },
  participationregion: {
    heading: "Teen participation in both regions",
    zoomOut: true,
    bumpLabels: true,
    groups: [
      {
        key: "mi",
        quantity: 66,
        labelQuantity: 2,
        label: "of teens in SE Michigan",
        sublabel: "Of 338,926 total teens, 7,135 participated in TOC",
        shape: michiganparticipation,
      },
      {
        key: "ny",
        quantity: 34,
        labelQuantity: 2,
        label: "of teens in Western NY",
        sublabel: "Of 159,931 total teens, 3,751 participated in TOC",
        shape: nyparticipation,
      },
    ],
  },
  residency: {
    heading: "Teens by residency",
    groups: [
      {
        key: "cities",
        quantity: 55,
        label: "from cities",
      },
      {
        key: "suburbs",
        quantity: 18,
        label: "from suburbs",
      },
      {
        key: "rural",
        quantity: 15,
        label: "from rural areas",
      },
      {
        key: "town",
        quantity: 12,
        label: "from towns",
      },
    ],
  },
  participationresidency: {
    heading: "Teen participation by residency",
    zoomOut: true,
    groups: [
      {
        key: "cities",
        quantity: 55,
        labelQuantity: 5,
        label: "of teens in cities",
        sublabel: "Of 129,361 total teens, 5,921 participated in TOC",
        shape: city,
      },
      {
        key: "town",
        quantity: 12,
        labelQuantity: 5,
        label: "of teens in towns",
        sublabel: "Of 25,187 total teens, 1,316 participated in TOC",
        shape: towns,
      },
      {
        key: "rural",
        quantity: 15,
        labelQuantity: 3,
        label: "of teens in rural areas",
        sublabel: "Of 52,092 total teens, 1,651 participated in TOC",
        shape: rural,
      },
      {
        key: "suburbs",
        quantity: 18,
        labelQuantity: 1,
        label: "of teens in suburbs",
        sublabel: "Of 292,217 total teens, 1,998 participated in TOC",
        shape: suburb,
      },
    ],
  },
  michigan: {
    heading: "SE Michigan teens by residency",
    groups: [
      {
        key: "cities",
        quantity: 75,
        label: "from cities",
      },
      {
        key: "suburbs",
        quantity: 17,
        label: "from suburbs",
      },
      {
        key: "rural",
        quantity: 5,
        label: "from rural areas",
      },
      {
        key: "town",
        quantity: 3,
        label: "from towns",
      },
    ],
  },
  newyork: {
    heading: "Western NY teens by residency",
    groups: [
      {
        key: "rural",
        quantity: 34,
        label: "from rural areas",
      },
      {
        key: "town",
        quantity: 29,
        label: "from towns",
      },
      {
        key: "suburbs",
        quantity: 22,
        label: "from suburbs",
      },
      {
        key: "cities",
        quantity: 15,
        label: "from cities",
      },
    ],
  },
  gender: {
    heading: "Teens by gender identity",
    groups: [
      {
        key: "female",
        quantity: 52,
        label: "female",
      },
      {
        key: "male",
        quantity: 45,
        label: "male",
      },
      {
        key: "trans",
        quantity: 3,
        label: "trans/non-binary",
      },
    ],
  },
  race: {
    heading: "Teens by race",
    groups: [
      {
        key: "black",
        quantity: 39,
        label: "Black",
      },
      {
        key: "white",
        quantity: 39,
        label: "White",
      },
      {
        key: "hispanic",
        quantity: 12,
        label: "Hispanic",
      },
      {
        key: "mixed",
        quantity: 10,
        label: "Mixed Race",
      },
    ],
  },
  grade: {
    heading: "Teens by grade level",
    //strength: 0.4,
    groups: [
      {
        key: "9th grade",
        quantity: 24,
        label: "9th graders",
      },
      {
        key: "10th grade",
        quantity: 18,
        label: "10th graders",
      },
      {
        key: "11th grade",
        quantity: 18, //17
        label: "11th graders",
      },
      {
        key: "12th grade",
        quantity: 13,
        label: "12th graders",
      },
      {
        key: "8th grade",
        quantity: 13,
        label: "8th graders",
      },
      {
        key: "7th grade",
        quantity: 11,
        label: "7th graders",
      },
      {
        key: "6th grade",
        quantity: 3,
        label: "6th graders",
      },
    ],
  },
  afterschool: {
    heading: "Teens by afterschool participation",
    legend: "100 teens participated in afterschool programs",
    useLabelQuantityOnMobile: true,
    groups: [
      {
        key: "rural",
        quantity: 15,
        labelQuantity: 64,
        label: "of teens in rural areas",
        fill: 10,
      },
      {
        key: "town",
        quantity: 12,
        labelQuantity: 59,
        label: "of teens in towns",
        fill: 7,
      },
      {
        key: "suburbs",
        quantity: 18,
        labelQuantity: 53,
        label: "of teens in suburbs",
        fill: 10,
      },
      {
        key: "cities",
        quantity: 55,
        labelQuantity: 48,
        label: "of teens in cities",
        fill: 26,
      },
    ],
  },
};

export default groups;
