import React, { useContext } from "react";
import styles from "./guidelines.module.scss";
import layout from "scss/layout.module.scss";
import shape from "./shape.svg";
import ColumnLayout from "layouts";
import Modal from "components/modal";
import { Link } from "react-router-dom";

import ModalContext, { ModalProvider } from "components/teaser/modal-context";
import { ThemeProvider, themes } from "components/teaser/theme-context";

const ProgramGuidelines = () => {
    const theme = themes[5];

    return (
        <ThemeProvider value={theme}>
            <ModalProvider>
                <ColumnLayout shape={shape} initTheme={theme}>
                    <Guidelines />
                </ColumnLayout>
            </ModalProvider>
        </ThemeProvider>
    );
};

export default ProgramGuidelines;

const Guidelines = () => {
    const { setShowModal } = useContext(ModalContext);

    const showModal = () => {
        console.log("showModal");
        setShowModal(true);
    };
    return (
        <div className={styles.element}>
            <Modal />
            <h1 className={layout.heading}>Program Guidelines</h1>
            <h2>The Generator Z Initiative is now closed. We continue to share our Program Guidelines as a source of learning.</h2>
            <br />

            {content.map((sect, index) => (
                <React.Fragment key={index}>
                    <h3 className={layout.sectionTitle}>{sect.heading}</h3>
                    {sect.text(showModal)}
                </React.Fragment>
            ))}
        </div>
    );
};

export const countiesList = (
    <ul>
        <li>
            <strong>Southeast Michigan counties:</strong> Wayne, Oakland, Macomb,
            Livingston, Monroe, St. Clair, Washtenaw
        </li>
        <li>
            <strong>Western New York counties:</strong> Allegany, Cattaraugus,
            Chautauqua, Erie, Genesee, Monroe, Niagara, Orleans, Wyoming
        </li>
    </ul>
);

const content = [
    {
        heading: `Why Are Teens Important?`,
        text: (showModal) => (
            <>
                <p>
                    If you are a teen, you make up the bulk of Gen Z. You are of a
                    generation that is more racially and ethnically diverse than any other
                    generation before you. You were born into a world virtually connected
                    through social media and smartphones. You are fluid in how you
                    identify yourself. You have a considerable amount of lived experience.
                    You are important in defining the future but you are rarely involved
                    in decision-making with the adults around you. We want this to change.
                </p>
                <p>
                    Generator Z believes that young people are leaders right{" "}
                    <strong>now</strong>.
                </p>
            </>
        ),
    },
    {
        heading: `What was Generator Z?`,
        text: (showModal) => (
            <>
                <p>
                    Generator Z was an ideas lab that reimagined the future of afterschool in a changing world. It is a philanthropic initiative that flipped the script on traditional grantmaking by inviting Gen Z to lead the conversation on afterschool and inviting afterschool providers to turn teen insight and ideas into action. Generator Z was a program for teens and afterschool providers in Southeast Michigan and Western New York.
                </p>
                <p>
                    Over two years, $1 million in stipends went directly to teens for their participation, along with $4 million in grants to afterschool providers, to fuel the development of new afterschool opportunities driven by and for young people. Generator Z was supported by the Ralph C. Wilson Jr. Foundation and powered by Lakeshore Connections.
                </p>
            </>
        ),
    },
    {
        heading: `Who were the Generators?`,
        text: (showModal) => (
            <>
                <p>
                    Southeast Michigan and Western New York are home to over
                    half-a-million teens. Generator Z selected 1,000 eligible teens from
                    these regions to be ‘Generators’ and share their experience and
                    insights on the future of afterschool with the world and,
                    specifically, with afterschool providers. Generators were between 13
                    and 18 years of age at the time of the application window (November 1
                    - November 20, 2020). Generators live in one of sixteen counties in
                    Southeast Michigan or Western New York:
                </p>
                {countiesList}
                {/* <p>
                    Generator stories are housed on the Generator Stories page. This
                    collective voice and vision is now ready to inspire afterschool
                    providers who work everyday to create opportunities for young people.{" "}
                </p> */}
                {/* 
                <h3 className={styles.applicationEnd}>
                    The grant application period is closed. Please visit our finalists and
                    grantees on our <Link to="/afterschool-ideas">Afterschool Ideas</Link>{" "}
                    page.
                </h3> */}
            </>
        ),
    },
    {
        heading: `How were Generators valued?`,
        text: (showModal) => (
            <>
                <p>
                    Generator impact was huge! They were responsible for three important steps and they got paid at each step in the process:
                </p>
                <ul>
                    <li>In step one, you submitted and we published your personal afterschool story. You received a $500 stipend. (2020)</li>
                    <li>In step two, you reacted to the ideas in grant applications and provided your feedback online. You received a $250 stipend. (2021)</li>
                    <li>In step three, you reacted to grantee progress and provided your feedback online. You received a $250 stipend. (2022)</li>

                </ul>
                <p>We offered the following payment options:</p>
                <ul>
                    <li>
                        Digital:{" "}
                        <a
                            href="https://cash.app/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            CashApp
                        </a>
                        ,{" "}
                        <a
                            href="https://venmo.com/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Venmo
                        </a>
                        ,{" "}
                        <a
                            href="https://www.paypal.com/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            PayPal
                        </a>
                    </li>
                    <li>Electronic: E-Check</li>
                    <li>Physical: Mailed Check</li>
                </ul>
            </>
        ),
    },
    // {
    //   heading: `How do I apply?`,
    //   text: (
    //     <>
    //       <p>
    //         It’s quick and easy! The first step is to complete the intake
    //         application on this site. We ask 15 questions that should take you no
    //         more than 5-minutes. An email is required to complete the application
    //         and a cell phone number is encouraged. Please note that multiple
    //         applicants (siblings or friends, for instance) cannot share the same
    //         email address to apply. <strong>You may apply only once</strong>.
    //         Multiple applications will result in disqualification.
    //       </p>
    //       <p>
    //         The intake application period is from November 1 - November 20, 2020.
    //         Applications will close at 11:59 pm on November 20th.
    //       </p>
    //     </>
    //   ),
    // },
    // {
    //   heading: `Who will be selected?`,
    //   text: (
    //     <p>
    //       Generator Z will select 1,000 teens who represent Southeast Michigan and
    //       Western New York. We are looking for young people living real lives from
    //       all sorts of backgrounds. Being your genuine self is your best shot at
    //       selection. We will filter through all of the applications received after
    //       the application deadline closes to select 1,000 teens for Generator Z.
    //       You will be contacted, by email and text, with our decision in late
    //       November. If you do not hear from us by December 1st, please email us at{" "}
    //       <a href="mailto:info@generator-z.org">info@generator-z.org</a>. Messages
    //       received after 6 pm Monday through Friday or on weekends will be
    //       answered during business hours.
    //     </p>
    //   ),
    // },
    {
        heading: `Who were the Afterschool Providers?`,
        text: (showModal) => (
            <>
                <p>
                    Generator Z defined an afterschool provider as a structured program or
                    experience that functions outside of school hours that provides young
                    people the time and space to explore their interests. We recognize
                    that the afterschool providers that serve teens come in all shapes and
                    sizes. Some are dedicated exclusively to teens while others are hosted
                    through faith-based, school, government, arts, or community
                    organizations. Some providers are large and known, others are small
                    and not so known.
                </p>

                <p>
                    Afterschool providers serving teens in Southeast Michigan and Western New York received $3 million in grants.
                    Generator Z saw <u>teen-inspired</u> ideas to reimagine the future of afterschool during a time of change brought to life by afterschool providers across the regions.
                </p>

                <p>Applications are closed at this time, but see what impacted our decisions and the grant application process below.</p>
            </>
        ),
    },
    {
        heading: `Afterschool Provider Eligibility`,
        text: (showModal) => (
            <>
                <p>
                    Afterschool providers were required to meet{" "}
                    <strong>
                        <u>all</u>
                    </strong>{" "}
                    of the following eligibility requirements to apply for a Generator Z
                    grant.
                </p>
                <ol>
                    <li>
                        Operate out of one of 16 counties in Southeast Michigan or
                        Western New York:
                        {countiesList}
                    </li>
                    <li>
                        Serve young people from these regions who are between the
                        ages of 13 and 18.
                    </li>
                    <li>
                        Be an existing (a) registered 501(c)(3) organization; (b)
                        partnered with a 501(c)(3) fiduciary organization; (c) a school; or
                        (d) a unit of local government
                    </li>
                    <li>Have an active Board of Directors.</li>
                    <li>
                        Be able to share either (a) a filed 2019 Form 990; or (b) a
                        2019 financial statement.{" "}
                    </li>
                </ol>
            </>
        ),
    },
    {
        heading: `Grant Amount Guidelines`,
        text: (showModal) => (
            <>
                <p>Grant awards ranged from $10,000 to $100,000:</p>
                <ul>
                    <li>
                        Eligible afterschool providers were able to apply with an idea that was {" "}
                        <strong>no more than half</strong> of their organizational budget
                        (in 2019) up to a $100,000 maximum amount. For instance, if you had
                        a $300,000 budget, you could only apply for up to $100,000.
                    </li>
                    <li>
                        Grant awards began at $10,000 and applied to all organizational
                        budgets, even if less than $20,000. For instance, if you had a
                        $15,000 organizational budget (in 2019), you could still apply with a
                        $10,000 idea.
                    </li>
                </ul>
                <p>
                    Generator Z awarded $4 million through a range of grant sizes
                    between $10,000 and $100,000. More consideration was given to
                    small and medium grants and less to large grants in order to support a
                    balanced and diverse mix of afterschool providers across both regions.
                    Ultimately, grant awards depended on a strong inspired idea that
                    was actionable in 12-months so we paid close attention to realistic financial requests.
                    (It often takes substantial time, capacity, and effort
                    to move big dollars.)
                </p>
            </>
        ),
    },
    {
        heading: `The Application`,
        text: (showModal) => (
            <>
                <p>
                    The Generator Z Afterschool Provider Application was an online process.
                    The application was organized into three sections: (1) eligibility; (2)
                    essentials; and (3) inspired idea:
                </p>
                <ol>
                    <li>
                        <strong>Eligibility:</strong> If you answered ‘yes’ to{" "}
                        <strong>all</strong> questions, you were eligible to apply and able to continue
                        to the remaining sections of the application. If you answered ‘no’ to any of the questions,
                        you were not eligible to apply and could not submit an application to Generator Z.
                    </li>
                    <li>
                        <strong>Essentials:</strong> You were asked for more information
                        as it pertains to eligibility. You received options to submit
                        materials early.{" "}
                    </li>
                    <li>
                        <strong>Inspired Idea:</strong> You showed us and teens your magic. Filtered
                        and read through Generator Stories that were relevant to what you did
                        or wanted to do. You thought about what teens shared and how it
                        inspired you as a provider. Because we were (and still are) living in uncharted times we
                        encouraged you to experiment and define your own success. Generator Z
                        looked for actionable ideas that were clearly inspired by
                        Generators and could be implemented in one-year.{" "}
                    </li>
                </ol>
                <p>
                    Afterschool providers could submit <strong>only one</strong>{" "}
                    application. Multiple applications resulted in disqualification.{" "}
                </p>
                <p>
                    Applications opened on Monday, January 25, 2021 and closed at 11:59 pm on
                    Monday, Feb. 22, 2021. Generator Z did not accept late applications.{" "}
                </p>
            </>
        ),
    },
    {
        heading: `Building A Strong Application`,
        text: (showModal) => (
            <>
                <p>
                    The strongest Afterschool Provider Applications created afterschool opportunities for teens in 2021 that were inspired by Generator insight and actionable by organizations. Generator Z looked for applications that embodied the following qualities at that time:
                </p>
                <ol>
                    <li>
                        <strong>Relevant:</strong>{" "}
                        <em>Centralize young people at this time.</em> Generators are sharing their insight on their lives and afterschool
                        dreams during immense uncertainty and change. Place them and the
                        world they are navigating front and center.
                    </li>
                    <li>
                        <strong>Experimental:</strong>{" "}
                        <em>Try something without restriction.</em> New models, prototypes, pilots, and policies are encouraged to meet
                        the demands of teens and a changing world. Formulate an approach
                        without fear of penalty or failure.
                    </li>
                    <li>
                        <strong>Courageous:</strong>{" "}
                        <em>Be your best organizational self.</em> You are the ones to
                        define success. Your actionable idea should reinforce your passion
                        for serving and supporting young people now and in the future.
                    </li>
                    <li>
                        <strong>Collaborative:</strong>
                        <em> Work with teens.</em> You hold the most meaningful relationship
                        with young people. Tell us how you will work with teens that you
                        know to make your proposed idea come to life.
                    </li>
                    <li>
                        <strong>Understandable:</strong>
                        <em> Teens are your most important audience.</em> They will read
                        your application and share their feedback. Speak in a language that
                        meets them where they are.
                    </li>
                    <li>
                        <strong>Fundable:</strong>
                        <em> Bigger isn’t always better.</em> A $10,000 idea is just as
                        exciting as a $100,000 idea. Impact comes in all shapes and sizes.
                        Make sure that your idea can happen in twelve months.
                    </li>
                </ol>
                <p>
                    Generator Z Info-Sessions were held during the active application process.
                </p>
            </>
        ),
    },
    {
        heading: `After Applying`,
        text: (showModal) => (
            <>
                <p>
                    <strong>If you were expecting a traditional grant application process, this isn’t it.</strong> Generator Z was designed to be a transparent platform that flipped what is often a mysterious
                    process inside-out. Whether awarded a grant or not, applications were published alongside
                    Generator stories. Why? Because we believe that Generators and other young people deserve
                    to understand their influence. Afterschool providers are ultimately accountable to the teens
                    they serve. In this case, Generator Z applicants were accountable to the Generators that
                    kickstarted this effort. We also believe that there is incredible value in peer-to-peer
                    learning. Great ideas can inspire other great ideas.
                </p>
                <p>
                    Submitted applications went through a review process. Generators weighed in on applications and a group of teens and adult allies shortlisted grantees with the strongest applications (see the previous section, Building A Strong Application). Finalists and potential grantees were reviewed by the Generator Z team. Final grantees were confirmed by Lakeshore Connections and the Ralph C. Wilson Jr. Foundation.
                </p>
            </>
        ),
    },
    {
        heading: `Selected Finalists`,
        text: (showModal) => (
            <>
                <p>
                    Generator Z notified applicants of their status midway through the review process via email. Declined did <strong><em>not</em></strong> advance further. Finalists advanced to the next round.
                </p>
                <p>
                    A 2019 Form 990 or a 2019 financial statement was required of all finalists with the option to submit these documents early at the time of application. This was highly encouraged but not required until the finalist stage. Generator Z may also asked for more detailed grant budget information as needed.
                </p>
            </>
        ),
    },
    {
        heading: `Selected Grantees`,
        text: (showModal) => (
            <>
                <p>
                    The Generator Z grant agreement process was administered through
                    Lakeshore Connections.{" "}
                </p>
                <p>
                    As a grantee, Lakeshore Connections required a certificate of liability insurance as a term of the grant agreement. If there was no liability insurance, grantees were encouraged to budget at the time of application. Lakeshore Connections guided potential grantees who needed further assistance during the grant agreement process.
                </p>
                <p>
                    Grantees had 12-months to implement their idea and spend their grant award. Public reporting to Generators on the Generator Z website was required midway through progress (month 6) and at the completion of implementation (month 13).
                </p>
            </>
        ),
    },
    // {
    //     heading: `You Keep Getting Paid. In A Few Ways.`,
    //     text: (showModal) => (
    //         <>
    //             <p>
    //                 There is a value to what you do. Generator Z pays Generators a stipend
    //                 at each step in the process. Here are the details:
    //             </p>
    //             <ul>
    //                 <li>$ 500 stipend for published stories (Step 1)</li>{" "}
    //                 <li>$ 250 stipend for feedback on provider applications (Step 2)</li>{" "}
    //                 <li>$ 250 stipend for feedback on provider progress (Step 3)</li>
    //             </ul>
    //             <p>We offered the following payment options:</p>
    //             <ul>
    //                 <li>
    //                     Digital:{" "}
    //                     <a
    //                         href="https://cash.app/"
    //                         rel="noopener noreferrer"
    //                         target="_blank"
    //                     >
    //                         CashApp
    //                     </a>
    //                     ,{" "}
    //                     <a
    //                         href="https://venmo.com/"
    //                         rel="noopener noreferrer"
    //                         target="_blank"
    //                     >
    //                         Venmo
    //                     </a>
    //                     ,{" "}
    //                     <a
    //                         href="https://www.paypal.com/"
    //                         rel="noopener noreferrer"
    //                         target="_blank"
    //                     >
    //                         PayPal
    //                     </a>
    //                 </li>
    //                 <li>Electronic: E-Check</li>
    //                 <li>Physical: Mailed Check</li>
    //             </ul>
    //         </>
    //     ),
    // },
];
