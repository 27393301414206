import React, { useRef, useEffect } from "react";
import styles from "./for-orgs.module.scss";
import Intro from "components/teaser/intro";
import HowItWorks from "components/teaser/how-it-works";
// import Convince from "components/teaser/intro/convince";
// import Shapes from "components/teaser/scroll-shapes";
// import one from "./sohappy.svg";
// import convincingGuy from "./convincingguy.png";
// import convinceZ from "./z.svg";
// import convinceblob from "./convinceblob.svg";
import howItWorksShape from "./howitworksshape.svg";
import progressShape from "../how-it-works/progress-2.svg";
import Section from "components/section";
import orgZ from "components/nav/z/org_z.svg";
// import shape from "./afterschoolshape.svg";
import gsap from "gsap";
// import { useHistory } from "react-router-dom";
// import eye from "./Eye_13.gif";
import { useContext } from "react";
import ModalContext from "components/teaser/modal-context";

import render4 from './render_elements_4.gif'

const ForOrgs = () => {
    const { setShowModal } = useContext(ModalContext);
    const howItWorksSteps = [
        {
            key: "intro",
        },
        {
            key: "step1",
            text: "Providers read through a treasure trove of Generator stories.",
            cta: {
                type: "button",
                text: "Stay Connected",
                onClick: () => setShowModal(true),
            },
            number: 1,
        },
        {
            key: "step2",
            text: "They applied for grant dollars with a proposal for an afterschool experience inspired and informed by Generator stories.",
            number: 2,
            cta: {
                type: "button",
                text: "Stay Connected",
                onClick: () => setShowModal(true),
            },
        },
        {
            key: "step3",
            text: "Selected providers received grants and reported back to Generators (the real experts) on progress and challenges.",
            cta: {
                type: "button",
                text: "Stay Connected",
                onClick: () => setShowModal(true),
            },
            number: 3,
        },
    ];

    // const Future = () => {
    //     const history = useHistory();
    //     return (
    //         <ScrollStep
    //             heading={"Hey, afterschool providers..."}
    //             text={
    //                 <>
    //                     <h2>Here’s the deal: </h2>
    //                     <p>
    //                         Generator Z is a grantmaking initiative that moves $4 million to
    //                         afterschool providers. It is a chance to hear from a diverse range
    //                         of teens, experiment with new ideas, and receive $10,000 to
    //                         $100,000 in grant funding for afterschool innovation through a
    //                         simple, transparent application process. In 2021, over 200
    //                         providers with generator-inspired afterschool ideas were selected
    //                         as finalists. Nearly 100 received grants. Read the ideas. Meet the
    //                         grantees. Follow along as ideas turn to action.
    //                     </p>
    //                 </>
    //             }
    //             button={{
    //                 text: "Afterschool Ideas",
    //                 onClick: () => {
    //                     history.push("/afterschool-ideas");
    //                 },
    //             }}
    //             shapes={false}
    //             gif={eye}
    //         />
    //     );
    // };

    const ref = useRef();
    const shapeRef = useRef();

    useEffect(() => {
        const tl = gsap.timeline({
            duration: 3,
            scrollTrigger: {
                trigger: ref.current,
                start: "top center",
                end: "bottom top",
                scrub: 2,
                //snap: 1 / 3,
            },
        });

        const z = gsap
            .timeline()
            .fromTo(shapeRef.current, { xPercent: -100 }, { xPercent: 20 })
            .to(shapeRef.current, { xPercent: -100 });

        tl.add(z, 0);

        return () => {
            tl && tl.scrollTrigger.kill();
        };
    }, []);
    // const history = useHistory();

    return (
        <Section id={"ForTeens"} theme="peach" z={orgZ}>
            <Intro
                className={styles.element}
                children={
                    <>
                        {/* <StaticGraphics shapeRef={shapeRef} /> */}
                        {/* <div ref={ref}>
                            <Future />
                        </div> */}
                        <div className={styles.howItWorksWrap}>
                            <img className={styles.render4} src={render4} alt="" />
                            <HowItWorks
                                heading="Afterschool providers responded."
                                buttonText="Afterschool Grants"
                                buttonLink="/afterschool-ideas"
                                steps={howItWorksSteps}
                                shape={howItWorksShape}
                                progressShape={progressShape}
                                anchorPrefix={"ForOrgs"}
                                backgroundColor={"peach"}
                            />
                        </div>
                        {/* <Convince
                            heading={<>The Power of Listening</>}
                            image={{ src: convincingGuy, alt: "Smiling Teen" }}
                            z={{ src: convinceZ, alt: "" }}
                            button={{
                                text: "Afterschool Ideas",
                                onClick: () => {
                                    history.push("/afterschool-ideas");
                                },
                            }}
                            shapes={
                                <Shapes
                                    offset={125}
                                    items={[
                                        {
                                            src: convinceblob,
                                            x: 30,
                                            text: (
                                                <>
                                                    <p>
                                                        Many of you are busy with your day-to-day and
                                                        figuring out what the future holds. We get that.
                                                        Generator Z was designed as a chance to pause,
                                                        reflect, and be creative so that you can do is what
                                                        you do best - listen to teens and create
                                                        opportunities.
                                                    </p>
                                                    <p>
                                                        All you need to do is what you do best - listen to
                                                        teens and create opportunities.
                                                    </p>
                                                    <p>
                                                        This is the space and support to experiment without
                                                        fear, make mistakes, learn from your peers, and
                                                        grow. Even though this grant opportunity is now
                                                        closed, the learning never ends. We hope you’ll
                                                        follow along for the ride.
                                                    </p>
                                                </>
                                            ),
                                            padding: "11rem 8rem 3rem 3rem",
                                        },
                                    ]}
                                    mobileItems={[
                                        {
                                            src: convinceblob,
                                            x: 50,
                                            text: (
                                                <>
                                                    <p>
                                                        Many of you are busy with your day-to-day and
                                                        figuring out what the future holds.. We get that.
                                                        This is a chance to pause, reflect, and be creative.
                                                    </p>
                                                    <p>All you need to do is listen to teens.</p>
                                                    <p>
                                                        Generator Z trusts you to translate their insights
                                                        into ideas and action.
                                                    </p>
                                                    <p>
                                                        You get the space and support to experiment without
                                                        fear, make mistakes, learn from your peers, and
                                                        grow.
                                                    </p>
                                                    <p>
                                                        Oh, and did we mention $10,000 to $100,000 in grant
                                                        funding?
                                                    </p>
                                                </>
                                            ),
                                            padding: "10rem 4rem 3rem 3rem",
                                            minWidth: 800,
                                        },
                                    ]}
                                />
                            }
                        /> */}
                    </>
                }
            />
        </Section>
    );
};

export default ForOrgs;

// const StaticGraphics = ({ shapeRef }) => {
//     return (
//         <div ref={shapeRef} className={styles.shape}>
//             <img src={shape} alt="Abstract Z shape" />
//         </div>
//     );
// };
