import React from "react"
import ReactTooltip from "react-tooltip";

import Blue from "svgs/tooltip-blue.svg"
import Green from "svgs/tooltip-green.svg"

import styles from "./tooltip.module.scss"

const Tooltip = ({
  copy,
  color,
  id
}) => {
  return <ReactTooltip className={`Tooltip ${styles.element} ${color}`} id={id}>
    <img src={color === "green" ? Green : Blue } alt={`tooltip`} />
    <p>{copy}</p>
  </ReactTooltip>
}

export default Tooltip