const ValidateMedia = (blocks, types, optional) => {
  if (!optional && blocks.length === 0) {
    return "You must add something";
  }

  const textBlocks = blocks.filter(({ type }) => type === "text");
  const mediaBlocks = blocks.filter(({ type }) => type !== "text");

  const textProblem = textBlocks.reduce((r, d) => {
    if (!d.value || d.value.length < 100) {
      r = true;
    }
    return r;
  }, false);

  const mediaProblem = mediaBlocks.reduce((r, d) => {
    if (!d.value) {
      r = true;
    }
    return r;
  }, false);

  const textSettings =
    types && types.find(({ type: findType }) => findType === "text");

  if (textProblem || mediaProblem) {
    let message = "";
    if (textProblem) {
      message += `There is a minimum character count of ${
        textSettings?.minLength || 100
      } characters long. `;
    }
    if (mediaProblem) {
      message +=
        "You are missing an upload – please add one or remove the block if you don't need it. ";
    }

    return message;
  }

  return true;
};

export default ValidateMedia;
