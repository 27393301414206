import React, { useState, createContext } from "react";

const FilterContext = createContext();

export default FilterContext;

export const FilterProvider = ({ children }) => {
  const [filteredStories, setFilteredStories] = useState(null);
  const [filteredProviders, setFilteredProviders] = useState(null);
  const [filterStoriesQS, setFilterStoriesQS] = useState(null);
  const [filterProvidersQS, setFilterProvidersQS] = useState(null);

  return (
    <FilterContext.Provider
      value={{
        filteredStories,
        filteredProviders,
        setFilteredStories: (value) => setFilteredStories(value),
        setFilteredProviders: (value) => setFilteredProviders(value),
        filterStoriesQS,
        setFilterStoriesQS,
        filterProvidersQS,
        setFilterProvidersQS,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
