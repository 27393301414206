import React, { useEffect, useRef } from "react";
import styles from "./storytellers.module.scss";
import shape from "./10000(3shape).svg";
import SVG from "react-inlinesvg";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
gsap.registerPlugin(ScrollTrigger);

const Storytellers = ({ onEnter, onLeaveBack }) => {
    //   const ref = useRef();
    //   useEffect(() => {
    //     const t = ScrollTrigger.create({
    //       trigger: ref.current,
    //       start: "top center",
    //       end: "bottom center",
    //       onEnter,
    //       onLeaveBack,
    //     });
    //     return () => {
    //       t.kill();
    //     };
    //   }, [onEnter, onLeaveBack]);

    return (
        <div className={styles.element} >
            <SVG src={shape} />
            <div className={styles.text}>
                <div className={styles.descriptor}>From</div>
                <div className={styles.number}>10,000</div>
                <div className={styles.descriptor}>Teens to</div>
                <div className={styles.number}>1,000</div>
                <div className={styles.descriptor}>Generators</div>
            </div>
        </div>
    );
};

export default Storytellers;
