import React, { useState, useRef, useCallback, useLayoutEffect } from "react";
//import { Link } from "react-router-dom";
import usePosts from "components/usePosts";
import styles from "./user-lookup.module.scss";
import { screenname } from "helpers";
import { ErrorMessage } from "@hookform/error-message";
import { useStorageState } from "react-storage-hooks";

const searchFilter = [
  { col: "Display Name", heading: "Autocomplete", type: "text" },
];
// const reducer = (state, action) => {
//   // return ...state, blocks:[...state.blocks]
// };
// const useLookup = (id) => {
//   const [state, dispatch] = useStorageReducer(
//     window.location,
//     `lookup--${id}`,
//     reducter,
//     initialState
//   );
// };

export const useUserLookup = () => {
  const [term, setTerm] = useState();
  const { loading, data } = usePosts("/api/stories", term);
  return {
    loading,
    data,
    setTerm,
  };
};

const UserLookup = ({
  forwardRef,
  errors,
  name,
  data,
  setTerm,
  showStoryName,
}) => {
  //   const [filters, setFilters] = useState(initFilters);
  //const [search, setSearch] = useState("");

  const [storedVal, setStoredVal] = useStorageState(
    localStorage,
    `${name}-val`,
    []
  );
  const displayName = (fields) => screenname(fields);

  const [textValue, setTextValue] = useState(
    storedVal
      ? storedVal.map((story) => {
          return displayName(story);
        })
      : ""
  );

  //console.log("Lookup", data);

  const handleChange = (e) => {
    const value = e.target.value;
    //console.log("typed", value, storedVal && screenname(storedVal[0]));

    //if (value.length > 0) checkedItems[item][0] = [value.toLowerCase(), true];
    //else checkedItems[item][0] = [value.toLowerCase(), false];

    if (
      storedVal &&
      storedVal.length > 0 &&
      value !== screenname(storedVal[0])
    ) {
      console.log("invalid");
      setStoredVal(null);
    }
    if (value.length === 0) {
      setTerm([{}]);
    } else setTerm([{ col: "Display Name", v: [[value.toLowerCase()], true] }]);
  };
  const setScreenname = (e, fields) => {
    e.preventDefault();
    //const allStories = [...storedVal, fields];
    const user = [fields];
    // setStories(allStories);
    setStoredVal(user);
    setTextValue(displayName(fields));
    setTerm([{}]);
    e.target.blur();
    setTimeout(() => triggerInput(name), 100);
  };
  // const removeStory = (story) => {
  //   const newStories = storedVal.filter(
  //     (s) => s["Survey ID"] !== story["Survey ID"]
  //   );
  //   // setStories(newStories);
  //   setStoredVal(newStories);
  //   setTimeout(() => triggerInput(name), 100);
  // };

  return (
    <div>
      {searchFilter.map((item, index) => {
        return (
          <div key={`col-${index}`} className={styles.autocompleter}>
            <Text
              onTypingEnd={handleChange}
              value={textValue}
              setValue={setTextValue}
            ></Text>
            {/* {!storedVal && (
              <div className="error">Please choose a valid screen name</div>
            )} */}
            <div className={styles.dropdown} tabIndex={-1}>
              {data &&
                data.map(({ fields, id }, index) => {
                  // if (
                  //   storedVal.filter(
                  //     (story) => story["Survey ID"] === fields["Survey ID"]
                  //   ).length > 0
                  // ) {
                  //   return "";
                  // }
                  return (
                    <button
                      key={`data-${index}`}
                      tabIndex={0}
                      onClick={(e) => setScreenname(e, fields)}
                    >
                      <div
                        className={styles.item}
                        tabIndex={0}
                        value={JSON.stringify({
                          id: fields["Survey ID"],
                          slug: fields["Slug"],
                          name: screenname(fields),
                        })}
                      >
                        <span className={styles.name}>
                          {screenname(fields)}
                        </span>{" "}
                        |{" "}
                        <span className={styles.title}>{fields["Title"]}</span>
                      </div>
                    </button>
                  );
                })}
            </div>
          </div>
        );
      })}
      {/* <div className={styles.inspiration}>
        {storedVal.length > 0 ? (
          <div className={styles.inspirationInner}>
            {storedVal.map((story, index) => (
              <div key={`story-${index}`} className={styles.selectedStory}>
                <Link
                  to={`/stories/${story["Slug"]}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className={styles.name}>{screenname(story)}</span> |{" "}
                  <span className={styles.title}>{story["Title"]}</span>
                </Link>

                <div
                  onClick={() => removeStory(story)}
                  className={styles.removeBtn}
                >
                  remove
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>Screenname</>
        )}
        
      </div> */}
      <input
        type="hidden"
        ref={forwardRef}
        id={name}
        readOnly
        name={name}
        value={
          storedVal?.length > 0
            ? JSON.stringify(
                storedVal.map((story) => {
                  return {
                    id: story["Survey ID"],
                    screenname: screenname(story),
                    region: story["Region"],
                  };
                })
              )
            : ""
        }
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="error">{message}</div>}
      />
    </div>
  );
};

export default UserLookup;

function triggerInput(enteredName) {
  const input = document.getElementById(enteredName);
  const event = new Event("input", { bubbles: true });

  input.dispatchEvent(event);
}

const Text = ({
  type = "text",
  onTypingEnd,
  name,
  value,
  setValue,
  placeholder = "Find your Screen Name",
}) => {
  const firstUpdate = useRef(true);
  const ref = useRef();
  const [inputData, setInputData] = useState();

  const [send, setSend] = useState(false);

  const typingDetect = useCallback(
    (e) => {
      setValue(e.target.value);
      clearTimeout(ref.current);

      setInputData({ ...e });
      const sendData = () => {
        setSend(true);
      };
      ref.current = setTimeout(() => {
        sendData();
      }, 750);
    },
    [setValue]
  );

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (send) onTypingEnd(inputData);
    setSend(false);
  }, [inputData, send, onTypingEnd]);

  return (
    <input
      type={type}
      autoComplete="off"
      name={name}
      onChange={typingDetect}
      value={value}
      placeholder={placeholder}
    />
  );
};
