import React from "react";
import ColumnLayout from "layouts";
import layout from "scss/layout.module.scss";
import styles from "./resources.module.scss";
import { ThemeProvider, themes } from "components/teaser/theme-context";
import { OutboundLink } from "react-ga";

const Resources = () => {
    const theme = themes[5];
    return (
        <ThemeProvider value={theme}>
            <ColumnLayout initTheme={theme}>
                <h1 className={layout.heading}>Resources & Support</h1>
                <p>
                    We all need help sometimes. Sharing our own personal stories and
                    hearing from others can trigger the need for support, whether you are
                    a young person or an adult. Generator Z has compiled the following
                    list of national and regional resources.{" "}
                </p>
                <p>
                    Do you have a recommendation for resources or support that should be
                    listed? Contact us at{" "}
                    <OutboundLink
                        eventLabel="email"
                        to="mailto;info@generator-z.org"
                        rel="noopener noreferrer"
                    >
                        info@generator-z.org
                    </OutboundLink>
                </p>
                <div className={styles.element}>
                    {content.map((sect, index) => (
                        <React.Fragment key={index}>
                            <h3 className={layout.sectionTitle}>{sect.heading}</h3>
                            {sect.text}
                        </React.Fragment>
                    ))}
                </div>
            </ColumnLayout>
        </ThemeProvider>
    );
};

export default Resources;

const content = [
    {
        heading: `Community Resources`,
        text: (
            <>
                <ul>
                    <li>
                        Southeast Michigan:{" "}
                        <OutboundLink
                            eventLabel="link"
                            to={"https://www.mi211.org/"}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            https://www.mi211.org/
                        </OutboundLink>
                    </li>
                    <li>
                        Western New York:{" "}
                        <OutboundLink
                            eventLabel="link"
                            to={"https://211nys.org/"}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            https://211nys.org/
                        </OutboundLink>
                        <br />
                        <li>
                            Text or Call{" "}
                            <OutboundLink
                                eventLabel="phone_number"
                                to={"tel:211"}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                211
                            </OutboundLink>{" "}
                        </li>
                    </li>
                </ul>
            </>
        ),
    },
    {
        heading: `National Suicide Lifeline`,
        text: (
            <p>
                <OutboundLink
                    eventLabel="link"
                    to={"https://suicidepreventionlifeline.org/"}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    https://suicidepreventionlifeline.org/
                </OutboundLink>
                <br />{" "}
                <OutboundLink
                    eventLabel="phone_number"
                    to={"tel:1-800-273-8255"}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    1-800-273-TALK (8255)
                </OutboundLink>
                <br /> Text "Go" to{" "}
                <OutboundLink
                    eventLabel="phone_number"
                    to={"tel:741741"}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    741741
                </OutboundLink>
            </p>
        ),
    },
    {
        heading: `National Eating Disorders Association	`,
        text: (
            <>
                <p>
                    <OutboundLink
                        eventLabel="link"
                        to={
                            "https://www.nationaleatingdisorders.org/help-support/contact-helpline"
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        https://www.nationaleatingdisorders.org/help-support/contact-helpline
                    </OutboundLink>
                    <br />
                    <OutboundLink
                        eventLabel="phone_number"
                        to={"tel:1-800-931-2237"}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        1-800-931-2237
                    </OutboundLink>
                </p>
            </>
        ),
    },
    {
        heading: `National Runaway Safeline`,
        text: (
            <>
                <p>
                    <OutboundLink
                        eventLabel="link"
                        to={"https://www.1800runaway.org/"}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        https://www.1800runaway.org/
                    </OutboundLink>
                    <br />{" "}
                    <OutboundLink
                        eventLabel="phone_number"
                        to={"tel:1-800-786-2929"}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        1-800-RUNAWAY
                    </OutboundLink>
                    <br />
                    Text{" "}
                    <OutboundLink
                        eventLabel="phone_number"
                        to={"tel:66008"}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        66008
                    </OutboundLink>
                </p>
            </>
        ),
    },
    {
        heading: `RAINN National Sexual Violence`,
        text: (
            <>
                <p>
                    <OutboundLink
                        eventLabel="link"
                        to={""}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        https://www.rainn.org/
                    </OutboundLink>
                    <br />{" "}
                    <OutboundLink
                        eventLabel="phone_number"
                        to={"tel:1-800-656-4673"}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        1-800-656-4673
                    </OutboundLink>
                </p>
            </>
        ),
    },
    {
        heading: `NY State Domestic and Sexual Violence Hotline	`,
        text: (
            <>
                <p>
                    <OutboundLink
                        eventLabel="phone_number"
                        to={"tel:1-800-942-6906"}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        1-800-942-6906
                    </OutboundLink>
                </p>
            </>
        ),
    },
    {
        heading: `Michigan Coalition to End Domestic & Sexual Violence`,
        text: (
            <>
                <p>
                    <OutboundLink
                        eventLabel="link"
                        to={"https://mcedsv.org/"}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        https://mcedsv.org/
                    </OutboundLink>
                    <br />{" "}
                    <OutboundLink
                        eventLabel="phone_number"
                        to={"tel:1-855-864-2374"}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        1-855-VOICES4
                    </OutboundLink>
                </p>
            </>
        ),
    },
    {
        heading: `CARE of Southeastern Michigan (Youth Services)`,
        text: (
            <>
                <p>
                    <OutboundLink
                        eventLabel="link"
                        to={"https://www.careofsem.com/student-youth-services/"}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        https://www.careofsem.com/student-youth-services/
                    </OutboundLink>{" "}
                    <br />
                    <OutboundLink
                        eventLabel="phone_number"
                        to={"tel:1-586-541-2273"}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        586-541-2273
                    </OutboundLink>
                </p>
            </>
        ),
    },
    {
        heading: `Detroit Wayne Integrated Health Network`,
        text: (
            <>
                <p>
                    <OutboundLink
                        eventLabel="link"
                        to={""}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        https://mcedsv.org/
                    </OutboundLink>
                    <br />
                    <OutboundLink
                        eventLabel="phone_number"
                        to={"tel:1-800-241-4949"}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        1-800-241-4949
                    </OutboundLink>
                </p>
            </>
        ),
    },
    {
        heading: `Mental Health Advocates of Western New York`,
        text: (
            <>
                <p>
                    <OutboundLink
                        eventLabel="link"
                        to={"https://mhawny.org/"}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        https://mhawny.org/
                    </OutboundLink>
                    <br />{" "}
                    <OutboundLink
                        eventLabel="phone_number"
                        to={"tel:1-716-886-1242"}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        716-886-1242
                    </OutboundLink>
                </p>
            </>
        ),
    },
    {
        heading: `Pride Center of Western New York`,
        text: (
            <>
                <p>
                    <OutboundLink
                        eventLabel="link"
                        to={"http://www.pridecenterwny.org/"}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        http://www.pridecenterwny.org/
                    </OutboundLink>
                    <br />
                    <OutboundLink
                        eventLabel="phone_number"
                        to={"tel:1-716-852-7743"}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        716-852-7743
                    </OutboundLink>
                </p>
            </>
        ),
    },
];
