import React from "react";

import Success from "components/form/success";

const StoriesFormSuccess = () => {
  return (
    <Success
      heading="Success!"
      body={
        <p>
          The Generator Z team will review your submission and pay you for your
          work. Your story will be published on the website in January 2021. We
          will let you know when your story is live. Have a great new year,
          Generator!
        </p>
      }
    />
  );
};

export default StoriesFormSuccess;
