import { counties } from "components/stories/counties";

const filterOpts = [
  {
    col: "Afterschool Theme",
    heading: "Theme",
    type: "checkboxes",
    options: [
      { value: "Mental, Social & Emotional Health" },
      { value: "Identity & Acceptance" },
      { value: "Growth & Wellness" },
      { value: "Friends & Community" },
      { value: "Excellence in Learning" },
      { value: "Jobs & Careers" },
      { value: "Creative Arts & Culture" },
      { value: "Fun & Games" },
      { value: "Play & Sports" },
      { value: "Life Skills & Balance" },
    ],
  },
  {
    col: "State",
    heading: "Region",
    type: "checkboxes",
    options: [
      { value: "Michigan", label: "Southeast Michigan" },
      { value: "New York", label: "Western New York" },
    ],
  },
  {
    col: "County",
    heading: "Michigan Counties",
    type: "checkboxes",
    options: counties["Michigan"].map((val) => {
      return {
        label: val.name,
        value: val.name + "-" + val.state,
        stateCondition: val.state,
      };
    }),
  },
  {
    col: "County",
    heading: "New York Counties",
    type: "checkboxes",
    options: counties["New York"].map((val) => {
      return {
        label: val.name,
        value: val.name + "-" + val.state,
        stateCondition: val.state,
      };
    }),
  },

  //   {
  //     col: "Zip Code",
  //     heading: "Zip Code",
  //     type: "text",
  //   },
  {
    col: "Age",
    heading: "Age",
    type: "checkboxes",

    options: [
      { value: "13" },
      { value: "14" },
      { value: "15" },
      { value: "16" },
      { value: "17" },
      { value: "18" },
      { value: "19" },
    ],
  },

  {
    col: "Gender Identity",
    heading: "Gender Pronouns",
    type: "checkboxes",
    options: [
      { value: "He/Him/His" },
      { value: "She/Her/Hers" },
      { value: "They/Them/Theirs" },
      { value: "Other" },
    ],
  },
  {
    col: "Identity Match",
    heading: "Identity",
    type: "checkboxes",
    options: [
      { value: "Black or African-American" },
      { value: "African" },
      { value: "Afro-Carribean" },
      { value: "North African" },
      { value: "Latinx or Hispanic" },
      { value: "Middle Eastern or Arab" },
      { value: "White or Caucasian" },
      { value: "European" },
      { value: "Asian" },
      { value: "East Asian" },
      { value: "South Asian" },
      { value: "Southeast Asian" },
      { value: "Native American or Alaskan Native" },
      { value: "Native Hawaiian or Other Pacific Islander" },
      { value: "Something Else / Specify", label: "Something Else" },
      { value: "Unknown" },
      // { value: "Greek" },
      // { value: "Saudi Arabian" },
      // { value: "Irish" },
    ],
  },
  {
    col: "Afterschool Program",
    heading: "Afterschool Participation",
    type: "checkboxes",
    options: [{ value: "Yes" }, { value: "No" }],
  },
  {
    col: "Display Name",
    heading: "Name",
    type: "text",
  },
];

export default filterOpts;
