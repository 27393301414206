import React, { useEffect, useState } from "react";
import data from "./mapData.topojson";
import { geoAlbersUsa, geoPath } from "d3-geo";
import { feature } from "topojson-client";
import ReactTooltip from "react-tooltip";
import styles from "./regions.module.scss";

const projection = geoAlbersUsa().scale(2800).translate([-120, 560]);
const path = geoPath().projection(projection);

const filledCounties = [
  "Allegany",
  "Cattaraugus",
  "Chautauqua",
  "Erie",
  "Genesee",
  "Monroe",
  "Niagara",
  "Orleans",
  "Wyoming",
  "Wayne",
  "Oakland",
  "Macomb",
  "Livingston",
  "Monroe",
  "St. Clair",
  "Washtenaw",
];
const markers = [
  {
    label: "Buffalo",
    coords: {
      lat: 42.8962175,
      lng: -78.9346535,
    },
  },
  {
    label: "Detroit",
    coords: {
      lat: 42.3526896,
      lng: -83.1694163,
    },
  },
];

const heading = "Teen opinions count.";
const text =
  "Before 2020, there was ‘Teen Opinions Count,’ an effort led by the Ralph C. Wilson, Jr. Foundation that asked young people, enrolled in school, what they wanted in their time after school. 10,000 teens responded across 16 counties in two regions, from cities to suburbs and towns to rural communities.";

const width = 800;
const height = 560;
const Map = ({ step, desktop }) => {
  const [geographies, setGeographies] = useState([]);

  useEffect(() => {
    fetch(data).then((response) => {
      if (response.status !== 200) {
        console.log(`There was a problem: ${response.status}`);
        return;
      }
      response.json().then((data) => {
        setGeographies(feature(data, data.objects.mapData).features);
        ReactTooltip.rebuild();
      });
    });
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [step]);

  return (
    <div className={styles.mapBlock}>
      <div className={styles.body}>
        <h2 className={styles.heading}>{heading}</h2>
        <p className={styles.text}>{text}</p>
      </div>
      <div style={{ position: "relative" }}>
        <svg
          width={width}
          height={height}
          viewBox={`${-60} ${60} ${width} ${height}`}
          className={styles.map}
        >
          <g transform="rotate(10)">
            <g className={styles.counties}>
              {geographies
                .sort((a) => {
                  if (filledCounties.includes(a.properties.NAME)) {
                    return 1;
                  } else {
                    return -1;
                  }
                })
                .map((d, i) => {
                  const activeCounty = filledCounties.includes(
                    d.properties.NAME
                  );
                  const isWater = !d.properties.NAME;
                  const className = isWater
                    ? styles.water
                    : step === "fadeIn"
                    ? styles.defaultCounty
                    : step === "counties"
                    ? activeCounty
                      ? styles.activeCounty
                      : styles.defaultCounty
                    : step === "residencies"
                    ? styles.residencies
                    : styles.defaultCounty;

                  const tt =
                    step === "counties"
                      ? {
                          ["data-tip"]: d.properties.NAME, // eslint-disable-line no-useless-computed-key
                          ["data-for"]: "MapTooltip", // eslint-disable-line no-useless-computed-key
                        }
                      : {};

                  return (
                    <path
                      key={`path-${i}`}
                      d={path(d)}
                      className={className}
                      {...tt}
                      vectorEffect="non-scaling-stroke"
                    />
                  );
                })}
            </g>
            <g>
              {step === "counties" &&
                markers.map(({ coords, label }) => {
                  const plot = projection([coords.lng, coords.lat]);
                  return (
                    <circle
                      key={label}
                      cx={plot[0]}
                      cy={plot[1]}
                      data-tip={label}
                      data-for={"MapTooltip"}
                      r={5}
                      className={styles.marker}
                    />
                  );
                })}
            </g>
          </g>
        </svg>
        <div className={styles.labelMi}>
          Southeast
          <br />
          Michigan
        </div>
        <div className={styles.labelNy}>
          Western
          <br />
          New York
        </div>
      </div>
      <ReactTooltip
        id="MapTooltip"
        arrowColor="transparent"
        //borderColor={"#fefd38"}
        className={styles.tooltip}
        textColor={"#fefd38"}
        backgroundColor={"#1b1a1f"}
      />
    </div>
  );
};

export default Map;
