import React from "react";
import TransformedVideo from "components/stories/transformed-video";
import Image from "components/stories/image";
import Audio from "components/stories/audio";
import Text from "components/stories/text";
import Slider from "react-slick";
import SVG from "react-inlinesvg";
import prevIcon from "components/posts-nav/prev.svg";
import nextIcon from "components/posts-nav/next.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "components/posts-nav/arrows.module.scss";

const comps = {
  video: TransformedVideo,
  image: Image,
  audio: Audio,
  text: Text,
};
const mediaTypes = ["video", "audio", "image"];

const Media = ({ blocks, slider }) => {
  const mediaCount = blocks.filter(
    ({ type }) => mediaTypes.indexOf(type) > -1
  ).length;
  const inner = blocks.map(({ type, id, value }) => {
    // console.log(type, id, value);
    const Comp = comps[type];
    return (
      <div
        key={id}
        className={`media ${type} ${
          mediaTypes.indexOf(type) > -1
            ? mediaCount > 1
              ? "single"
              : "multiple"
            : ""
        }`}
      >
        {Comp && <Comp value={value} width={800} {...value} />}
      </div>
    );
  });

  return (
    <>
      {slider ? (
        <Slider {...settings}>{inner}</Slider>
      ) : (
        <div className={mediaCount ? "has-media" : "textOnly"}>{inner} </div>
      )}
    </>
  );
};

export default Media;

const Button = ({ children, onClick, prev }) => {
  return (
    <button
      className={`${styles.el} ${prev ? styles.prev : ""} bg-color--inherit`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  slide: ".image",
  autoplay: true,
  prevArrow: (
    <Button prev={true}>
      <SVG src={prevIcon} />
    </Button>
  ),
  nextArrow: (
    <Button>
      <SVG src={nextIcon} />
    </Button>
  ),
};
